/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import SingleValueSetting from './SingleValueSetting';

class ClientSecret extends SingleValueSetting {

    constructor(props) {
        super('client_secret', props);
        this.state = {
            showSecret: false
        }
    }

    handleShowSecret = () => {
        this.setState( { showSecret: !this.state.showSecret })

    };

    render() {
        const clientSecret = this.props.collection.parameters.client_secret;

        return (
            <div className="field-group flex-auto">
                <input className={(this.state.showSecret || !clientSecret) ? 'field col-12' : 'field field-mono col-12'}
                       placeholder="Enter Client Secret"
                       name="client_secret"
                       type={(this.state.showSecret ? 'text' : 'password')}
                       autoComplete="off"
                       data-lpignore="true"
                       spellCheck="false"
                       onChange={this.updateValue}
                       value={(clientSecret) ? clientSecret : ''} />
                    <button className="show-password" aria-label={(this.state.showSecret) ? 'Hide Password' : 'Show Password'} type="button" onClick={this.handleShowSecret}>
                        <i className={ 'icon primary ' + (this.state.showSecret ? 'ion-eye-disabled' : 'ion-eye')}/>
                    </button>
            </div>)
    }

}

export default ClientSecret;

/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import { claimsArrayToRequestParameter, createNoneJwt, generateCodeChallenge, notEmpty } from '../../util/util';
import Environments from '../../data/Environments';
import Collection from '../../data/Collection';
import Token from '../../data/Token';
import { flows, oauthResponseTypes, tokenPurposes } from '../../util/appConstants';
import { logModalView } from '../../util/analytics';
import { isEmpty } from '../../util/validationUtils';
import jose from 'node-jose';

class InteractiveFlow extends React.Component {

    _isMounted = false;

    constructor(props, redirectPath, responseType) {
        super(props);
        this.state = {
            redirectPath,
            responseType,
            selectedToken: null,
            showModal: false,
            loginModalUrl: null,
            canBeFramed: !!this.props.collection.parameters.can_be_framed,
            showClaimsModal: false,
            showRarModal: false,
            forceEnableRedeemStep: false
        }
    }

    createJwtFromAuthCode = () => {
        const group = Object.keys(this.props.groups)
            .find(o => this.props.groups[o].order.indexOf(this.props.appState.currentCollection) > -1);
        this.props.createAndSelectCollectionWithToken('none', this.props.collection.provider,
            this.props.collection.parameters.authorization_code, group);
    };


    hideClaimsModal = () => {
        this.setState({ showClaimsModal: false });
        document.querySelector('html').style.overflow = '';
    };

    showClaimsModal = () => {
        this.setState({ showClaimsModal: true });
        document.querySelector('html').style.overflow = 'hidden';
    };

    hideRarModal = () => {
        this.setState({ showRarModal: false });
        document.querySelector('html').style.overflow = '';
    };

    showRarModal = () => {
        this.setState({ showRarModal: true });
        document.querySelector('html').style.overflow = 'hidden';
    };

    hideModal = () => {
        this.setState({ showModal: false })
    };

    showModal = () => {
        this.setState({ showModal: true });
        logModalView('/iframe/flow/' + this.props.collection.flow);
    };

    componentDidMount() {
        this.startUrl().then(url => {
            this.setState({ startUrl: url });
        });
        window.addEventListener('message', this.handleFrameTasks);
        document.addEventListener('keydown', this.escFunction);
    }

    escFunction = (event) => {
        if (this._isMounted) {
            if (event.keyCode === 27) {
                this.hideModal();
            }
        }
    };

    componentWillUnmount() {
        this._isMounted = false;
        window.removeEventListener('message', this.handleFrameTasks);
        document.removeEventListener('keydown', this.escFunction);
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setInteractiveFlowModalWrapper = (node) => {
        this.interactiveFlowModalWrapper = node;
    };

    handleClickOutside = (event) => {
        if (this.interactiveFlowModalWrapper && !this.interactiveFlowModalWrapper.contains(event.target)) {
            this.hideModal();
        }
    };

    handleImplicitFlowFramedCallback = (collectionId, e) => {
        if (e.data && e.data.hideModal) {
            const error = e.data.error;
            if (e.data.tokens) {
                const accessToken = e.data.tokens.access_token;
                const idToken = e.data.tokens.id_token;
                let tokens = [];
                if (accessToken) {
                    tokens.push(Token.createNewToken({
                        purpose: tokenPurposes.access_token.value,
                        name: 'Access Token',
                        value: accessToken
                    }));
                }
                if (idToken) {
                    tokens.push(Token.createNewToken({
                        purpose: tokenPurposes.id_token.value,
                        name: 'ID Token',
                        value: idToken
                    }));
                }

                const environments = Environments.create(this.props.environments);
                const environment = environments.getEnvironment(this.props.collections[collectionId].provider);
                //Update collection
                const keys = (environment.jwks !== undefined) ? environment.jwks : [];
                this.props.setTokensOnCollection(collectionId, tokens, keys, error);
            }
            this.props.setOAuthResponseOnCollection(collectionId, {
                type: oauthResponseTypes.url,
                url: e.data.callbackUrl
            }, 'ImplicitFlowAuthorizationCode');
            this.hideModal();
        }
    };

    handleCodeFlowFramedCallback = (collectionId, e) => {
        const collection = Collection.getCollectionById(collectionId, this.props.collections);
        const environments = Environments.create(this.props.environments);
        const environment = environments.getEnvironment(collection.provider);
        const parameters = collection.parameters;

        const keys = (environment && environment.jwks !== undefined) ? environment.jwks : [];
        let tokens = [];
        if (e.data && e.data.hideModal) {
            const error = e.data.error;

            if (e.data.tokens) {
                const accessToken = e.data.tokens.access_token;
                const idToken = e.data.tokens.id_token;

                if (accessToken) {
                    tokens.push(Token.createNewToken({
                        purpose: tokenPurposes.access_token.value,
                        name: 'frontend',
                        value: accessToken
                    }));
                }
                if (idToken) {
                    tokens.push(Token.createNewToken({
                        purpose: tokenPurposes.id_token.value,
                        name: 'frontend',
                        value: idToken
                    }));
                }
            }

            if (e.data.authorizationCode) {
                const authorizationCode = e.data.authorizationCode;


                const updatedParameters = parameters
                    .withUpdatedValue('authorization_code', authorizationCode)
                    .withUpdatedValue('session_state', e.data.session_state)
                    .withUpdatedValue('authorization_code_spent', false);

                this.props.updateParameters(collectionId, updatedParameters);

            }

            this.props.setTokensOnCollection(collectionId, tokens, keys, error);
            this.props.setOAuthResponseOnCollection(collectionId, {
                type: oauthResponseTypes.url,
                url: e.data.callbackUrl
            }, 'CodeFlowAuthorizationCode');

            this.hideModal();
            if (parameters.auto_redeem_code) {
                this.redeemCode();
                window.scrollTo(0, 0);
            }
        }
    };

    handleFrameTasks = (e) => {
        const collectionId = this.props.appState.currentCollection;
        const collection = Collection.getCollectionById(collectionId, this.props.collections);

        if (collection.flow === flows.implicit.id) {
            this.handleImplicitFlowFramedCallback(collectionId, e);
        } else if (collection.flow === flows.code.id || collection.flow === flows.hybrid.id) {
            this.handleCodeFlowFramedCallback(collectionId, e);
        }
    };

    clearErrorFromCollection = () => {
        this.props.setErrorOnCollection(this.props.collection.id, null)
    };

    isJwt = (value) => {
        return value && value.startsWith('ey') && value.includes('.');
    };

    updateAuthCode = (event) => {
        this.setState({ authCode: event.currentTarget.value });
    }

    saveAuthCode = () => {
        if (this.state.authCode) {
            const collectionId = this.props.appState.currentCollection;
            const collection = Collection.getCollectionById(collectionId, this.props.collections);
            const parameters = collection.parameters;

            const updatedParameters = parameters
                .withUpdatedValue('authorization_code', this.state.authCode)
                .withUpdatedValue('authorization_code_spent', false);

            this.props.updateParameters(collectionId, updatedParameters);
        }
    }

    clearResponsesAndState = (collectionId) => {
        this.setState({
            authCode: null,
            forceEnableRedeemStep: false
        })
        this.props.clearOAuthResponses(collectionId);
    }

    jwtFormatAuthCode = (authorizationCode) => {
        if (this.isJwt(authorizationCode)) {
            const parts = authorizationCode.split('.');
            return <code className="json">
                <span className="jwt-header">{parts[0]}</span><span className="jwt-dot">.</span>
                <span className="jwt-payload">{parts[1]}</span><span className="jwt-dot">.</span>
                <span className="jwt-signature">{parts[2]}</span></code>;
        }
        return authorizationCode;
    };

    getAuthorizationRequestParameters(collection, environment, state, redirect_uri) {
        const clientId = collection.parameters.client_id;
        const scopes = collection.parameters.scopes;
        const code_verifier = collection.parameters.code_verifier;
        const login_hint = collection.parameters.login_hint;
        const acrs = collection.parameters.acrs;
        const prompt = collection.parameters.prompt;
        const nonce = collection.parameters.nonce;
        const freshness = collection.parameters.freshness;
        const ui_locales = collection.parameters.locales;
        const responseTypes = collection.parameters.responseTypes;
        const formattedClaims = collection.parameters.claims ?
            claimsArrayToRequestParameter(collection.parameters.claims) : null;
        const authorization_details = collection.parameters.authorization_details

        const params = {
            client_id: clientId,
            response_type: collection.parameters.default_response_types.join(' '),
            redirect_uri: redirect_uri.toString(),
            state
        }

        if (scopes !== null && scopes !== undefined && scopes.length > 0) {
            let result = [];
            for (let i = 0; i < scopes.length; i++) {
                result.push(scopes[i].value);
            }
            params.scope = result.join(' ');
        }

        if (responseTypes !== null && responseTypes !== undefined) {
            let result = [];
            for (let i = 0; i < responseTypes.length; i++) {
                result = result.concat(responseTypes[i].value.split(' '));
            }
            result = result.filter((item, pos) => {
                return result.indexOf(item) === pos;
            })
            params.response_type = result.join(' ');
        }

        if (code_verifier) {
            params.code_challenge = generateCodeChallenge(code_verifier);
            params.code_challenge_method = 'S256';
        }

        if (acrs !== null && acrs !== undefined && acrs.length > 0) {
            let acrValues = [];
            for (let i = 0; i < acrs.length; i++) {
                acrValues.push(acrs[i].value)
            }
            params.acr_values = acrValues.join(' ');
        }

        if (prompt !== null && prompt !== undefined && prompt.length > 0) {
            let promptValues = [];
            for (let i = 0; i < prompt.length; i++) {
                promptValues.push(prompt[i].value);
            }
            params.prompt = promptValues.join(' ');
        }

        if (ui_locales) {
            params.ui_locales = ui_locales.value;
        }

        if (notEmpty(freshness)) {
            params.max_age = freshness;
        }

        if (login_hint) {
            params.login_hint = login_hint;
        }

        if (nonce) {
            params.nonce = nonce;
        }
        if (!isEmpty(formattedClaims)) {
            params.claims = formattedClaims;
        }
        if (authorization_details) {
            params.authorization_details = authorization_details;
        }
        return params;
    }

    async constructJarQuery(collection, environment, state, redirect_uri) {
        const clientId = collection.parameters.client_id;
        const jwtHeader = {
            alg: collection.parameters.jar_algorithm
        };
        const jwtBody = this.getAuthorizationRequestParameters(collection, environment, state, redirect_uri);

        jwtBody.iss = clientId;
        jwtBody.aud = environment.issuer;
        jwtBody.nbf = Math.floor(Date.now() / 1000);
        jwtBody.exp = jwtBody.nbf + 600;

        let jwt;
        if (collection.parameters.jar_algorithm === 'none') {
            jwt = createNoneJwt(JSON.stringify(jwtHeader), JSON.stringify(jwtBody));
        } else {
            const keystore = jose.JWK.createKeyStore();
            const key = await keystore.add(collection.parameters.jarKeys.private_key.jwk, 'jwk');
            const opt = { compact: true, jwk: key, fields: jwtHeader };
            jwt = await jose.JWS.createSign(opt, { key, reference: false })
                .update(JSON.stringify(jwtBody)).final();
        }



        return { client_id: clientId, request: jwt }
    }

    async constructInteractiveQuery(collection, environment, state, redirectUri) {
        if (collection.parameters.jar &&
            ((collection.parameters.jarKeys &&
                collection.parameters.jar_algorithm &&
                collection.parameters.jarKeys.private_key)
                || collection.parameters.jar_algorithm === 'none')) {
            return await this.constructJarQuery(collection, environment, state, redirectUri);
        }
        if (collection.flow === 'oidc_logout') {
            return {};
        }

        return this.getAuthorizationRequestParameters(collection, environment, state, redirectUri)
    }

    redirectUri = () => {
        const collectionId = this.props.appState.currentCollection;
        const collection = this.props.collections[collectionId];

        return collection.parameters.redirect_uri ? collection.parameters.redirect_uri : '' + this.defaultRedirectUri();
    };

    defaultRedirectUri = () => {
        const redirectUri = new URL(window.location.href);
        redirectUri.pathname = this.state.redirectPath;
        return redirectUri;
    }

    interactiveFlowCallbackUrlHelpText = () => {
        return 'Configure your client with the following redirect URI: ';
    };

    startUrl = async () => {

        const collectionId = this.props.appState.currentCollection;
        const collection = this.props.collections[collectionId];
        const environments = Environments.create(this.props.environments);
        const environment = environments.getEnvironment(collection.provider);

        if (!environment || !environment.endpoints || !environment.endpoints.authorization_endpoint) {
            return;
        }
        //Prepare the flow with the configured parameters
        const state = collectionId;
        const redirectUri = this.redirectUri();
        const url = new URL(environment.endpoints.authorization_endpoint);
        if (collection.parameters.par) {
            url.search = `request_uri=${collection.parameters.par_request_uri}&client_id=${collection.parameters.client_id}`
            return url.toString();
        } else {
            const params = await this.constructInteractiveQuery(collection, environment, state, redirectUri);

            url.search = Object.keys(params).map((key) => {
                if (typeof params[key] === 'object') {
                    return encodeURIComponent(key) + '=' + encodeURIComponent(JSON.stringify(params[key]))
                } else {
                    return encodeURIComponent(key) + '=' + params[key]
                }
            }).join('&');

            return url.toString();
        }
    };

    runFlow = () => {
        const collectionId = this.props.appState.currentCollection;
        const collection = Collection.getCollectionById(collectionId, this.props.collections);
        const canBeFramed = collection.parameters.can_be_framed && FEATURE_FRAMING_ENABLED;
        if (!collection.parameters.par) {
            this.props.clearOAuthResponses(collectionId);
        }
        if (canBeFramed && !collection.parameters.redirect_uri) {
            this.setState({ loginModalUrl: this.state.startUrl + '' });
            this.showModal();
        } else {
            // open in a new tab if the redirect_uri is manually set
            if (collection.parameters.redirect_uri) {

                this.setState({ forceEnableRedeemStep: true });
                const updatedParameters = collection.parameters
                    .withUpdatedValue('authorization_code', null)
                    .withUpdatedValue('authorization_code_spent', false);
                this.props.updateParameters(collectionId, updatedParameters);
                this.props.setTokensOnCollection(collectionId, [], null, null);

                if (IS_ELECTRON_BUILD) {
                    const shell = window.nodeRequire('electron').shell;
                    shell.openExternal(this.state.startUrl)
                } else {
                    window.open(this.state.startUrl, '_blank').focus();
                }
            } else {
                window.location = this.state.startUrl;
            }
        }
    };

    render() {
        return (<></>)
    }
}

export default InteractiveFlow;

/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import SingleValueSetting from './SingleValueSetting';

class Freshness extends SingleValueSetting {

    constructor(props) {
        super('freshness', props);
    }

    render() {
        const value = this.props.collection.parameters.freshness ? '='+ this.props.collection.parameters.freshness : '';

        return (
            <React.Fragment>
                <input className="field"
                       type="number"
                       min="0"
                       value={this.props.collection.parameters.freshness}
                       onChange={this.updateValue}
                       placeholder="Freshness"/>
                <span data-tooltip-long data-tooltip={`Adds the max_age${value} query parameter.`}
                      className="tooltip-holder inline-block ml1 mr1">
                    <i className="icon ion-information-circled"/>
                </span>
            </React.Fragment>
        );
    }
}

export default Freshness;

/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React, { useState } from 'react';
import FoldButton from '../FoldButton';
import PrettyJson from './PrettyJson';
import ValidationReport from './ValidationReport';
import CopyToClipboard from '../CopyToClipboard';

const TokenValidationBody = (props) => {
    const [isFolded, setIsFolded] = useState(false);

    const handleFold = () => {
        setIsFolded(!isFolded)
    };

    const tokenBody = (props.token.decoded_token.body) ? props.token.decoded_token.body : null;
    let outputData = 'No data available';
    let validationData = null;
    let tokenPurposeType;
    if (tokenBody !== null) {
        outputData = tokenBody;
        validationData = (props.token.validation_data !== undefined) ?
            props.token.validation_data.body : [];
        tokenPurposeType = props.token.purpose;
    } else if (props.token.value.split('.').length > 1) {
        outputData = 'JWT Payload is invalid.';
    }

    return (
        <section className="tools-sidebar-box tools-sidebar-box-body">
            <header className="tools-box-header">
                <div className="flex flex-center flex-justify">
                    <div className="tools-box-header-title"><i
                        className="icon ion-code inlineicon"/>{props.headerName}</div>
                    <div className="flex flex-center flex-justify">
                        <CopyToClipboard text={props.token.decoded_token.body}/>
                        <FoldButton handleFold={handleFold} isFolded={isFolded}/>
                    </div>
                </div>
            </header>

            {(isFolded) ? '' :
                <>
                    <pre className="tools-input">
                        {(tokenBody !== null) ? <PrettyJson json={outputData}/> : outputData}
                    </pre>

                    {validationData &&
                        <ValidationReport validationData={validationData}
                                          tokenPurposeType={tokenPurposeType}
                                          tokenBody={tokenBody}
                                          collection={props.collection}
                                          environment={props.environment}/>}

                </>
            }
        </section>
    )

}

export default TokenValidationBody;

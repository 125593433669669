/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import { createStore, applyMiddleware } from 'redux';
import { thunk } from 'redux-thunk';

import rootReducer from './reducers/index';

import { appState, collections, environments, groups } from './initstate';
import { LOCAL_STORAGE_APP_STATE } from './util/appConstants';


const defaultState = {
    collections,
    groups,
    appState,
    environments
};

const readStateFromLocalStorage = () => {
  const oldState = JSON.parse(localStorage[LOCAL_STORAGE_APP_STATE])
  oldState.collections = oldState.collections ? oldState.collections : {};
  if (!oldState.groups) {
    oldState.groups = { 'default': { order: Object.keys(oldState.collections) } }
  }

  // if a group is not assigned to an environment
  // create groups equivalent with the number of environments linked by the collections in the group
  // i.e groupX = { order: [A1, A2, B1]
  // becomes groupX1 = { order: [A1, A2], environment: A1 provider} and groupX2 = { order: [B1], environment: B1 provider}
  Object.keys(oldState.groups)
    .filter(g => g !== 'default' && !oldState.groups[g].environment)
    .forEach(g => {
      const environmentList = new Set()
      oldState.groups[g].order.forEach(c => {
        if (oldState.collections[c]) environmentList.add(oldState.collections[c].provider)
      })

      environmentList.forEach((env, key) => {
        oldState.groups[g + key] = { ...oldState.groups[g], environment: env }
      })
      delete oldState.groups[g]
    })

  Object.keys(oldState.collections).forEach(c => {
    // If a collection references an environment that has been deleted, re-create it
    if (Object.keys(oldState.environments).indexOf(oldState.collections[c].provider) === -1) {
      oldState.environments[oldState.collections[c].provider] = { name: 'Unnamed Workspace' }
    }

    // if collection is not in any group, add it to default group
    if (JSON.stringify(oldState.groups).indexOf(c) === -1) {
      if (!oldState.groups.default){
        oldState.groups.default = { order: [] };
      }
      oldState.groups.default.order.push(c)
    }
  })

  // cleanup collections that have been deleted in the default group
  oldState.groups.default.order = oldState.groups.default.order.filter(c =>
      Object.prototype.hasOwnProperty.call(oldState.collections, c));

  return oldState;
}

const initState = (localStorage[LOCAL_STORAGE_APP_STATE]) ? readStateFromLocalStorage() : defaultState;

const store = createStore(
  rootReducer,
  initState,
  applyMiddleware(thunk));

// Store all changes to local storage
store.subscribe(() => {
  localStorage[LOCAL_STORAGE_APP_STATE] = JSON.stringify(store.getState());
});

//export const history = syncHistoryWithStore(browserHistory, store);
export default store;

import React from 'react';

function EmptyStateIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      data-name="Lager 1"
      viewBox="0 0 473.28 256"
    >
      <circle cx="72.92" cy="59.42" r="24.76" className="cls-1"></circle>
      <rect
        width="323.32"
        height="44"
        x="121.07"
        y="37.42"
        className="cls-1"
        rx="22"
      ></rect>
      <circle cx="72.92" cy="131.18" r="24.76" className="cls-1"></circle>
      <rect
        width="280.67"
        height="44"
        x="121.07"
        y="109.18"
        className="cls-1"
        rx="22"
      ></rect>
      <circle cx="72.92" cy="202.94" r="24.76" className="cls-1"></circle>
      <rect
        width="247.65"
        height="44"
        x="121.07"
        y="180.94"
        className="cls-1"
        rx="22"
      ></rect>
    </svg>
  );
}

export default EmptyStateIcon;

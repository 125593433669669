/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React, { useId } from 'react';

const StepBox = ({ enabled, step, tooltip, title, toggle, handleToggle, children }) => {
    const fieldID = useId()

    const dimmed = enabled === true ? '' : 'fieldset-dimmed';
    const stepClass = `fieldset-step fieldset-${step} ${dimmed} mt2`;

    return (
        <fieldset className={stepClass} data-step={step}>
            <div className="flex flex-center justify-between">
                <h3 className={'m0 ' + (enabled && !toggle ? 'mb2' : '')}>
                    {title}

                    {tooltip &&
                    <span data-tooltip-long data-tooltip={`${tooltip}`}
                        className="tooltip-holder inline-block ml1 mr1 h4">
                        <i className="icon ion-information-circled"/>
                    </span>
                    }
                </h3>

                {toggle &&
                <div className="custom-checkbox">
                    <label className="toggle-switch" htmlFor={fieldID}>
                        <input className="form-control custom-checkbox"
                               type="checkbox"
                               id={fieldID}
                               name={fieldID}
                               checked={enabled}
                               onChange={(event) => handleToggle(event)}

                        />
                        <div className="toggle-slider round"/>
                    </label>
                    <label className="ml1">Enable {title}</label>
                </div>
                }

            </div>

            {enabled ? children : ''}

        </fieldset>
    );
};
export default StepBox;

/*
 * Copyright (C) 2022 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React, { useEffect, useState } from 'react'
import { convertToQueryParams } from '../util/util';
import { tokenPurposes } from '../util/appConstants';

const LogoutButton = props => {
    const idTokens = props.collection.getTokensByPurpose(tokenPurposes.id_token.value);
    const [loadModal, setLoadModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const getLogOutUrl = () => {
        const params = {
            'post_logout_redirect_uri': window.location.origin,
            'id_token_hint': idTokens[0] ? idTokens[0].value : null,
            state: props.collection.parameters.session_state
        };

        return `${props.environment.endpoints.end_session_endpoint}?${convertToQueryParams(params)}`;
    };

    const logout = () => {

        if (props.collection.parameters.can_be_framed && FEATURE_FRAMING_ENABLED) {
            setLoadModal(true);
        } else {
            setIsLoading(true);
            const updatedParameters = props.collection.parameters.withUpdatedValue('session_state', null);
            props.updateParameters(props.collection.id, updatedParameters);
            setTimeout(() => {
                setIsLoading(false);
                window.location.href = getLogOutUrl()
            }, 1000);
        }
    }

    const showButton = props.visible && props.environment &&
        props.environment.endpoints.end_session_endpoint && props.collection.parameters.session_state;

    useEffect(() => {
        if (loadModal) {
            setIsLoading(true);
            // open modal with timeout to remove it and load the url
            setTimeout(() => {
                const updatedParameters = props.collection.parameters.withUpdatedValue('session_state', null);
                props.updateParameters(props.collection.id, updatedParameters);
                setIsLoading(false);
            }, 2000);
        }

    }, [loadModal])

    const buttonLoadingClass = isLoading ? ' button-loading-active button-disabled' : '';

    return (
        <React.Fragment>
            {(showButton || isLoading) &&
            <button className={'button button-light button-loading button-tiny mr1' + buttonLoadingClass}
                    data-qa="interactive-flow-logout"
                    onClick={logout}>
                <span><i className="icon ion-log-out inlineicon"/>
                Logout</span>
            </button>
            }
            {loadModal && <iframe className={'hide'} src={getLogOutUrl()}/>}

        </React.Fragment>

    );
}

export default LogoutButton

/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react'

const LoadIcon = () => (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="100%" height="100%">
    <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16.65"
          d="M58.23 208.05c-16.97-16.97-27.47-40.42-27.47-66.31 0-10.15 1.61-19.93 4.6-29.09m89.18-64.7c51.79 0 93.78 41.99 93.78 93.78 0 19.05-5.68 36.77-15.44 51.57"></path>
    <path fill="currentColor"
          d="M201.59 149.7l4.28 39.69 39.69-4.31-15.02 18.67-39.68 4.3-4.3-39.68 15.03-18.67z"></path>
    <circle cx="50.47" cy="78.97" r="5.8" fill="currentColor"></circle>
    <circle cx="71.74" cy="57.7" r="5.8" fill="currentColor"></circle>
</svg>);

export default LoadIcon

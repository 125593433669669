/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import Endpoints from './Endpoints';
import OAuthClient from './OAuthClient';
import { randomTimeId } from '../util/util';
import DCRParameters from './DCRParameters';
import { flows } from '../util/appConstants';

class Environment {
    constructor(id, settings) {
        this._id = id;
        this._name = settings.name || '';
        this._metadata_url = settings.metadata_url;
        this._raw_metadata = settings.raw_metadata;
        this._endpoints = settings.endpoints;
        this._scopes = settings.scopes;
        this._claims_supported = settings.claims_supported;
        this._response_types_supported = settings.response_types_supported;
        this._token_endpoint_auth_methods_supported = settings.token_endpoint_auth_methods_supported;
        this._introspection_endpoint_auth_methods_supported = settings.introspection_endpoint_auth_methods_supported;
        this._revocation_endpoint_auth_methods_supported = settings.revocation_endpoint_auth_methods_supported;
        this._device_authorization_endpoint_auth_methods_supported =
            settings.device_authorization_endpoint_auth_methods_supported;
        this._backchannel_authentication_endpoint = settings.backchannel_authentication_endpoint;
        this._backchannel_authentication_request_signing_alg_values_supported =
            settings.backchannel_authentication_request_signing_alg_values_supported;
        this._backchannel_user_code_parameter_supported = settings.backchannel_user_code_parameter_supported;
        this._backchannel_token_delivery_modes_supported = settings.backchannel_token_delivery_modes_supported;
        this._claims_parameter_supported = settings.claims_parameter_supported;
        this._request_parameter_supported = settings.request_parameter_supported;
        this._request_object_signing_alg_values_supported = settings.request_object_signing_alg_values_supported;
        this._acrs = settings.acrs;
        this._locales = settings.locales;
        this._settings = settings;
        this._jwks = settings.jwks;
        this._issuer = settings.issuer;
        this._playground = settings.playground;
        this._error = settings.error;
        let clients = {};
        if (settings.clients) {
            Object.keys(settings.clients).forEach((key) => {
                clients[key] = new OAuthClient(key, settings.clients[key]).toMap();
            });
        }
        this._clients = clients;

        this._dcr_parameters = settings.dcr_parameters;

        // Verifiable credentials
        this._credential_metadata_url = settings.credential_metadata_url;
        this._credential_issuer = settings.credential_issuer || '';
        this._authorization_server = settings.authorization_server;
        this._credential_endpoint = settings.credential_endpoint || '';
        this._notification_endpoint = settings.notification_endpoint || '';
        this._deferred_credential_endpoint = settings.deferred_credential_endpoint || '';
        this._batch_credential_endpoint = settings.batch_credential_endpoint || '';
        this._credentials_supported = settings.credentials_supported || [];
        this._display = settings.display || {};
        this._raw_credential_metadata = settings.raw_credential_metadata || {};
    }

    get credential_metadata_url() {
        return this._credential_metadata_url;
    }

    get raw_credential_metadata() {
        return this._raw_credential_metadata;
    }

    get credential_issuer() {
        return this._credential_issuer;
    }

    get authorization_server() {
        return this._authorization_server;
    }

    get credential_endpoint() {
        return this._credential_endpoint;
    }

    get batch_credential_endpoint() {
        return this._batch_credential_endpoint;
    }

    get notification_endpoint() {
        return this._notification_endpoint;
    }

    get deferred_credential_endpoint() {
        return this._deferred_credential_endpoint;
    }

    get display() {
        return this._display;
    }

    get credentials_supported() {
        return this._credentials_supported;
    }

    get dcr_parameters() {
        return new DCRParameters(this._dcr_parameters);
    }

    get id() {
        return this._id;
    }

    get name() {
        return this._name;
    }

    get playground() {
        return this._playground;
    }

    get metadata_url() {
        return this._metadata_url;
    }

    get issuer() {
        return this._issuer;
    }

    get claims_parameter_supported() {
        return this._claims_parameter_supported;
    }

    /**
     * The Raw metadata.
     * @returns {*}
     */
    get raw_metadata() {
        return this._raw_metadata;
    }

    /**
     * Get endpoints defined for this environment.
     * @returns {Endpoints}
     */
    get endpoints() {
        return new Endpoints(this._endpoints);
    }

    get scopes() {
        return this._scopes;
    }

    get claims_supported() {
        return this._claims_supported;
    }

    get response_types_supported() {
        return this._response_types_supported;
    }

    get token_endpoint_auth_methods_supported() {
        return this._token_endpoint_auth_methods_supported;
    }

    get introspection_endpoint_auth_methods_supported() {
        return this._introspection_endpoint_auth_methods_supported;
    }

    get revocation_endpoint_auth_methods_supported() {
        return this._revocation_endpoint_auth_methods_supported;
    }

    get device_authorization_endpoint_auth_methods_supported() {
        return this._device_authorization_endpoint_auth_methods_supported;
    }

    get backchannel_token_delivery_modes_supported() {
        return this._backchannel_token_delivery_modes_supported;
    }

    get backchannel_authentication_endpoint() {
        return this._backchannel_authentication_endpoint;
    }

    get backchannel_authentication_request_signing_alg_values_supported() {
        return this._backchannel_authentication_request_signing_alg_values_supported;
    }

    get backchannel_user_code_parameter_supported() {
        return this._backchannel_user_code_parameter_supported;
    }
    get request_parameter_supported() {
        return this._request_parameter_supported;
    }

    get request_object_signing_alg_values_supported() {
        return this._request_object_signing_alg_values_supported;
    }

    get acrs() {
        return this._acrs;
    }

    get locales() {
        return this._locales;
    }

    get error() {
        return this._error;
    }

    get clients() {
        return this._clients;
    }

    get jwks() {
        return this._jwks;
    }

    canDoCodeFlow = () => {
        return !!(this.endpoints.authorization_endpoint && this.endpoints.token_endpoint)
    };

    canDoImplicit = () => {
        return !!(this.endpoints.authorization_endpoint);
    };

    canDoPar = () => {
        return !!(this.endpoints.pushed_authorization_request_endpoint);
    }

    canDoCIBA = () => {
        return !!(this.endpoints.backchannel_authentication_endpoint);
    };

    canDoRevoke = () => {
        return !!(this.endpoints.revocation_endpoint);
    };


    canDoRefresh = () => {
        return !!(this.endpoints.token_endpoint);
    };

    canDoIntrospect = () => {
        return !!(this.endpoints.introspection_endpoint);
    };

    canDoDynamicClientRegistration = () => {
        return !!(this.endpoints.registration_endpoint);
    };

    canDoClientCredentials = () => {
        return !!(this.endpoints.token_endpoint);
    };

    canDoUserinfo = () => {
        return !!(this.endpoints.userinfo_endpoint);
    };

    canDoResourceOwnerPasswordCredentials = () => {
        return !!(this.endpoints.token_endpoint);
    };

    hasClientWithClientId = (clientId) => {
        const clients = this._clients;
        let returnValue = false;
        Object.keys(clients).forEach((key) => {
            if (clients[key].client_id === clientId) {
                returnValue = true;
            }
        });
        return returnValue;
    };

    hasClientWithId = (id) => {
        return id in this._clients;
    };

    toMap = () => {
        return this._settings;
    };

    withUpdatedValue = (key, value) => {

        const prop = '_' + key;

        if (Object.prototype.hasOwnProperty.call(this, prop)) {
            const current = this.toMap();
            const next = {
                ...current,
                [key]: value
            };
            return new Environment(this._id, next);
        } else {
            throw 'Key ' + key + ' is not a property of Environment';
        }
    };

    withUpdatedDcrParameters = (dcr_parameter_name, value) => {
        const updatedDcrParameters = this.dcr_parameters.withUpdatedValue(dcr_parameter_name, value);
        return this.withUpdatedValue('dcr_parameters', updatedDcrParameters.toMap());
    };

    withUpdatedEndpoint = (endpoint_name, url) => {
        const updatedEndpoints = this.endpoints.withUpdatedValue(endpoint_name, url);
        return this.withUpdatedValue('endpoints', updatedEndpoints.toMap());
    };

    withUpdatedName = (name) => {
        return this.withUpdatedValue('name', name);
    };

    withClient = (id, client) => {
        let clients = this._clients;
        if (!clients) {
            clients = {};
        }
        clients[id] = new OAuthClient(id, client).toMap();

        return this.withUpdatedValue('clients', clients)
    };

    withClientFromDcr = (data) => {
        const newClientId = randomTimeId();
        let settings = data;

        settings.can_do_introspect = false;
        settings.can_do_code_flow = data.grant_types && (data.grant_types.includes(flows.code.grant_type));
        settings.can_do_device_flow = data.grant_types && (data.grant_types.includes(flows.device.grant_type));
        settings.can_do_ropc_flow = data.grant_types && (data.grant_types.includes(flows.password.grant_type));
        settings.can_do_implicit_flow = data.grant_types && (data.grant_types.includes(flows.implicit.grant_type));
        settings.can_do_ciba_flow = data.grant_types && (data.grant_types.includes(flows.ciba.grant_type));
        settings.can_do_hybrid_flow = data.grant_types
            && (data.grant_types.includes(flows.implicit.grant_type)
            && data.grant_types.includes(flows.code.grant_type));
        settings.can_do_client_credentials_flow = data.grant_types
            && (data.grant_types.includes(flows.client_credentials.grant_type));
        settings.can_be_framed = data.allowed_origins
            && (data.allowed_origins.includes(window.location.origin)
            || data.allowed_origins.includes('*'));
        settings.can_do_refresh_flow = settings.can_do_code_flow
            || settings.can_do_hybrid_flow
            || settings.can_do_device_flow
            || settings.can_do_ropc_flow;
        settings.can_do_revoke_flow = settings.can_do_code_flow
            || settings.can_do_implicit_flow
            || settings.can_do_hybrid_flow
            || settings.can_do_device_flow
            || settings.can_do_client_credentials_flow
            || settings.can_do_ropc_flow;

        return this.withClient(newClientId, settings);
    };

    withUpdatedClient = (id, property, value) => {
        let clients = this._clients;
        const newClient = new OAuthClient(id, clients[id]);
        const updatedClient = newClient.withUpdatedValue(property, value);
        clients[id] = updatedClient.toMap();

        return this.withUpdatedValue('clients', clients);
    };

    withoutClient = (clientId) => {
        let clients = this._clients;
        delete clients[clientId];
        return this.withUpdatedValue('clients', clients);
    };

    withNewClient = () => {
        const newClientId = randomTimeId();
        return this.withClient(newClientId, {});
    };

    withError = (component, error_description) => {
        const error = {};
        error[component] = error_description;
        return this.withUpdatedValue('error', error);
    };

    fromUpdatedMetadata = (metadata) => {
        const endpoints = new Endpoints(metadata.raw_metadata);
        const dcr_parameters = new DCRParameters();
        const scopes = metadata.raw_metadata ? metadata.raw_metadata.scopes_supported : [];
        const claims_supported = metadata.raw_metadata ? metadata.raw_metadata.claims_supported : [];
        const response_types_supported = metadata.raw_metadata ? metadata.raw_metadata.response_types_supported : [];
        const token_endpoint_auth_methods_supported = metadata.raw_metadata ?
            metadata.raw_metadata.token_endpoint_auth_methods_supported : '';
        const introspection_endpoint_auth_methods_supported = metadata.raw_metadata ?
            metadata.raw_metadata.introspection_endpoint_auth_methods_supported : '';
        const revocation_endpoint_auth_methods_supported = metadata.raw_metadata ?
            metadata.raw_metadata.revocation_endpoint_auth_methods_supported : '';
        const device_authorization_endpoint_auth_methods_supported = metadata.raw_metadata ?
            metadata.raw_metadata.device_authorization_endpoint_auth_methods_supported : '';
        const acrs = metadata.raw_metadata ? metadata.raw_metadata.acr_values_supported : [];
        const locales = metadata.raw_metadata ? metadata.raw_metadata.ui_locales_supported : [];
        const issuer = metadata.raw_metadata ? metadata.raw_metadata.issuer : '';
        const claims_parameter_supported = metadata.raw_metadata ?
            metadata.raw_metadata.claims_parameter_supported : false;
        const raw_metadata = metadata.raw_metadata;
        const backchannel_token_delivery_modes_supported =
            metadata.raw_metadata.backchannel_token_delivery_modes_supported;
        const backchannel_authentication_endpoint = metadata.raw_metadata.backchannel_authentication_endpoint;
        const backchannel_authentication_request_signing_alg_values_supported =
            metadata.raw_metadata.backchannel_authentication_request_signing_alg_values_supported;
        const backchannel_user_code_parameter_supported =
            metadata.raw_metadata.backchannel_user_code_parameter_supported;
        const request_parameter_supported = metadata.raw_metadata.request_parameter_supported;
        const request_object_signing_alg_values_supported =
            metadata.raw_metadata.request_object_signing_alg_values_supported;
        const jwks = metadata.jwks;

        const settings = {
            ...this._settings,
            endpoints: endpoints.toMap(),
            dcr_parameters: dcr_parameters.toMap(),
            scopes,
            claims_supported,
            response_types_supported,
            acrs,
            locales,
            jwks,
            request_parameter_supported,
            request_object_signing_alg_values_supported,
            backchannel_token_delivery_modes_supported,
            backchannel_authentication_endpoint,
            backchannel_authentication_request_signing_alg_values_supported,
            backchannel_user_code_parameter_supported,
            issuer,
            token_endpoint_auth_methods_supported,
            introspection_endpoint_auth_methods_supported,
            revocation_endpoint_auth_methods_supported,
            device_authorization_endpoint_auth_methods_supported,
            claims_parameter_supported,
            raw_metadata
        };
        return new Environment(this._id, settings);
    }
}

export default Environment;

/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */


import React, { Component } from 'react'
import CopyToClipboard from '../../../CopyToClipboard'
import { isEmpty } from '../../../../util/validationUtils';

import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';

class ClaimsJson extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPasteArea: false,
            intermediateValue: null,
            error: null
        }
    }


    setEditorRef = node => {
        this.editorRef = node;
    };

    clearError = () => {
        this.setState({ error: null });
    };

    handleSaveJson = () => {
        if (this.editorRef.editor) {
            const newValue = this.editorRef.editor.getValue();
            try {
                const value = JSON.parse(newValue);

                this.props.updateClaimsFromJson(value);
                this.setState({ intermediateValue: null, error: null });
            } catch (e) {
                this.setState({ intermediateValue: newValue });
                this.setState({ error: 'Could not parse JSON' });
            }
        }
    };

    handlePastedJson = (newValue) => {
        this.setState({ intermediateValue: newValue });
    };

    discardJsonChanges = () => {
        this.setState({ intermediateValue: null });
    };

    render() {
        const value = isEmpty(this.props.claimsJson) ? null : this.props.claimsJson;

        const textareaValue = this.state.intermediateValue !== null ? this.state.intermediateValue :
            (isEmpty(this.props.claimsJson) ? '' : JSON.stringify(this.props.claimsJson, undefined, 2));

        const error = (!this.state.error) ? '' :
            <div className="alert alert-danger mb2">
                <i className="icon ion-ios-close-outline inlineicon"/>
                {this.state.error}
                <button className="alert-close" onClick={this.clearError}><i className="icon ion-close"/>
                </button>
            </div>;

        const command = {
            bindKey: { win: 'Ctrl-S', mac: 'Command-S' },
            exec: this.handleSaveJson,
            name: 'Save'
        };

        return (
            <>
                {error}

                <div className="tools-form-token tools-form-token-active">
                    <header className="tools-box-header tools-box-header-token">
                        <div className="flex flex-center flex-justify">
                            <div>
                                <label className="white">JSON Output</label>
                                {this.state.intermediateValue &&
                                    <>
                                    <button className="button button-primary-dark button-tiny ml2 mr1" onClick={this.discardJsonChanges}>Discard</button>
                                    <button className="button button-success button-tiny" onClick={this.handleSaveJson}>Save</button>
                                    <small><span className="inline-block ml1 primary">(cmd/ctrl + s)</span></small>
                                    </>
                                }
                            </div>
                                <CopyToClipboard text={value} className="mr1" flow="left"/>
                        </div>
                    </header>
                    <div className="ace-editor-wrapper">
                        <AceEditor
                            ref={this.setEditorRef}
                            name="claims-editor"
                            placeholder="Paste JSON"
                            mode="json"
                            theme="monokai"
                            setOptions={{
                                enableBasicAutocompletion: false,
                                enableLiveAutocompletion: false,
                                enableSnippets: false,
                                showLineNumbers: true,
                                showGutter: true,
                                tabSize: 2
                            }}
                            commands={[command]}
                            indentedSoftWrap={false}
                            showPrintMargin={false}
                            onChange={this.handlePastedJson}
                            value={textareaValue}
                            width="100%"
                            maxLines={40}
                            minLines={10}
                            fontSize={12}
                            wrapEnabled={true}
                            editorProps={{ $blockScrolling: true }}
                        />
                    </div>
                </div>
            </>
        )
    }
}

export default ClaimsJson

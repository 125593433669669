/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import { generateRandomString } from '../../../util/util';
import RefreshIcon from '../../icons/RefreshIcon';
import CopyToClipboard from '../../CopyToClipboard';

class PKCE extends React.Component {
    constructor(props) {
        super(props);
        this.state = { checked: (this.props.collection.parameters.code_verifier) };
    }

    newCodeVerifier = () => {
        let code_verifier = generateRandomString(64);
        const updatedParameters =
            this.props.collection.parameters.withUpdatedValue('code_verifier', code_verifier);
        this.props.updateParameters(this.props.collection.id, updatedParameters);
    };

    onChange = (event) => {
        const checked = event.currentTarget.checked;
        this.setState({ checked });
        if (checked) {
            this.newCodeVerifier();
        } else {
            const updatedParameters =
                this.props.collection.parameters.withUpdatedValue('code_verifier', null);
            this.props.updateParameters(this.props.collection.id, updatedParameters);
        }
    };

    render() {
        const code_verifier = this.props.collection.parameters.code_verifier;
        return (
            <React.Fragment>
                <div className="custom-checkbox">
                    <label className="toggle-switch">
                        <input className="form-control custom-checkbox"
                               name="PKCE"
                               id="PKCE"
                               type="checkbox"
                               checked={this.state.checked}
                               onChange={this.onChange}
                        />
                        <div className="toggle-slider round"/>
                    </label>
                    <label className="ml1" htmlFor="PKCE">Use PKCE</label>
                    { code_verifier &&
                        <>
                        <button className="button button-link button-tiny button-loading"
                                onClick={this.newCodeVerifier}>
                                <span data-tooltip="New code_verifier">
                                    <RefreshIcon/>
                                </span>
                        </button>
                        <label className={'ml1'} >{code_verifier}<CopyToClipboard text={code_verifier} /></label>
                        </>
                    }
                </div>
            </React.Fragment>
        )
    }
}

export default PKCE;

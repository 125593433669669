/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import { exportAppState } from '../../util/util';

class ClientSettings extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showSecret: false,
            showClientId: false
        }
    }

    handleShowSecret = () => {
        this.setState({ showSecret: !this.state.showSecret })
    };

    handleShowClientId = () => {
        this.setState({ showClientId: !this.state.showClientId })
    };

    updateInputField = (event) => {
        const currentEnvironment = this.props.environment;
        const updatedEnvironment = currentEnvironment.withUpdatedClient(
            this.props.clientId, event.currentTarget.name, event.currentTarget.value);
        this.props.updateEnvironment(updatedEnvironment);
    };

    updateBooleanField = (event) => {
        const currentEnvironment = this.props.environment;
        const updatedEnvironment = currentEnvironment.withUpdatedClient(
            this.props.clientId, event.currentTarget.name, event.currentTarget.checked);
        this.props.updateEnvironment(updatedEnvironment);
        this.setState({ [event.currentTarget.name]: event.currentTarget.checked });
    };

    deleteClient = () => {
        const currentEnvironment = this.props.environment;
        const updatedEnvironment = currentEnvironment.withoutClient(this.props.clientId);
        this.props.updateEnvironment(updatedEnvironment);

    };

    exportClient = (e) => {
        e.preventDefault();
        const currentClient = this.props.client;
        const fileName = (currentClient.client_id || 'client')+'.json';
        if (currentClient && Object.keys(currentClient).length > 0) {
            exportAppState(JSON.stringify(currentClient), fileName, 'application/json', []);
        } else {
            console.info('No clients exists!');
        }
    };

    render() {
        const can_do_code_flow = !!this.props.client.can_do_code_flow;
        const can_do_implicit_flow = !!this.props.client.can_do_implicit_flow;
        const can_do_hybrid_flow = !!this.props.client.can_do_hybrid_flow;
        const can_do_client_credentials_flow = !!this.props.client.can_do_client_credentials_flow;
        const can_do_introspect = !!this.props.client.can_do_introspect;
        const can_do_device_flow = !!this.props.client.can_do_device_flow;
        const can_do_ciba_flow = !!this.props.client.can_do_ciba_flow;
        const can_do_ropc_flow = !!this.props.client.can_do_ropc_flow;
        const can_do_token_exchange = !!this.props.client.can_do_token_exchange;
        const can_be_framed = !!this.props.client.can_be_framed;
        return (
            <React.Fragment>

                <tr>
                    <td width="20%">
                        <div className="field-group flex-auto">
                            <input
                                placeholder="Client ID"
                                type="text"
                                name="client_id"
                                autoComplete="off"
                                data-lpignore="true"
                                spellCheck="false"
                                className="field col-12"
                                value={(this.props.client.client_name && !this.state.showClientId) ?
                                    this.props.client.client_name : this.props.client.client_id}
                                onChange={this.updateInputField}
                            />
                            {(this.props.client.client_name) ?
                                <button className="show-password"
                                        aria-label={(this.state.showSecret) ? 'Hide Password' : 'Show Password'}
                                        type="button" onClick={this.handleShowClientId}>
                                    <i className={'icon primary ion-arrow-swap'}/>
                                </button>
                                : ''}
                        </div>
                    </td>
                    <td width="20%">
                        <div className="field-group flex-auto">
                            <input
                                className={(this.state.showSecret || !this.props.client.client_secret) ? 'field col-12' : 'field field-mono col-12'}
                                placeholder="Client Secret"
                                name="client_secret"
                                type={(this.state.showSecret ? 'text' : 'password')}
                                autoComplete="off"
                                data-lpignore="true"
                                spellCheck="false"
                                onChange={this.updateInputField}
                                value={(this.props.client.client_secret) ? this.props.client.client_secret : ''}
                            />
                            <button className="show-password"
                                    aria-label={(this.state.showSecret) ? 'Hide Password' : 'Show Password'}
                                    type="button" onClick={this.handleShowSecret}>
                                <i className={'icon primary ' + (this.state.showSecret ? 'ion-eye-disabled' : 'ion-eye')}/>
                            </button>
                        </div>
                    </td>
                    <td>
                        <div className="custom-checkbox">
                            <label className="toggle-switch">
                                <input className="form-control custom-checkbox"
                                       type="checkbox"
                                       checked={can_do_code_flow}
                                       name="can_do_code_flow"
                                       onChange={this.updateBooleanField}
                                />
                                <div className="toggle-slider round"/>
                            </label>
                        </div>
                    </td>
                    <td>
                        <div className="custom-checkbox">
                            <label className="toggle-switch">
                                <input className="form-control custom-checkbox"
                                       type="checkbox"
                                       checked={can_do_implicit_flow}
                                       name="can_do_implicit_flow"
                                       onChange={this.updateBooleanField}
                                />
                                <div className="toggle-slider round"/>
                            </label>
                        </div>
                    </td>
                    <td>
                        <div className="custom-checkbox">
                            <label className="toggle-switch">
                                <input className="form-control custom-checkbox"
                                       type="checkbox"
                                       checked={can_do_hybrid_flow}
                                       name="can_do_hybrid_flow"
                                       onChange={this.updateBooleanField}
                                />
                                <div className="toggle-slider round"/>
                            </label>
                        </div>
                    </td>
                    <td>
                        <div className="custom-checkbox">
                            <label className="toggle-switch">
                                <input className="form-control custom-checkbox"
                                       type="checkbox"
                                       checked={can_do_client_credentials_flow}
                                       name="can_do_client_credentials_flow"
                                       onChange={this.updateBooleanField}
                                />
                                <div className="toggle-slider round"/>
                            </label>
                        </div>
                    </td>
                    <td>
                        <div className="custom-checkbox">
                            <label className="toggle-switch">
                                <input className="form-control custom-checkbox"
                                       type="checkbox"
                                       checked={can_do_token_exchange}
                                       name="can_do_token_exchange"
                                       onChange={this.updateBooleanField}
                                />
                                <div className="toggle-slider round"/>
                            </label>
                        </div>
                    </td>
                    <td>
                        <div className="custom-checkbox">
                            <label className="toggle-switch">
                                <input className="form-control custom-checkbox"
                                       type="checkbox"
                                       checked={can_do_ropc_flow}
                                       name="can_do_ropc_flow"
                                       onChange={this.updateBooleanField}
                                />
                                <div className="toggle-slider round"/>
                            </label>
                        </div>
                    </td>
                    <td>
                        <div className="custom-checkbox">
                            <label className="toggle-switch">
                                <input className="form-control custom-checkbox"
                                       type="checkbox"
                                       checked={can_do_introspect}
                                       name="can_do_introspect"
                                       onChange={this.updateBooleanField}
                                />
                                <div className="toggle-slider round"/>
                            </label>
                        </div>
                    </td>
                    <td>
                        <div className="custom-checkbox">
                            <label className="toggle-switch">
                                <input className="form-control custom-checkbox"
                                       type="checkbox"
                                       checked={can_do_device_flow}
                                       name="can_do_device_flow"
                                       onChange={this.updateBooleanField}
                                />
                                <div className="toggle-slider round"/>
                            </label>
                        </div>
                    </td>
                    <td>
                        <div className="custom-checkbox">
                            <label className="toggle-switch">
                                <input className="form-control custom-checkbox"
                                       type="checkbox"
                                       checked={can_do_ciba_flow}
                                       name="can_do_ciba_flow"
                                       onChange={this.updateBooleanField}
                                />
                                <div className="toggle-slider round"/>
                            </label>
                        </div>
                    </td>
                    {!IS_ELECTRON_BUILD && <td>
                        <div className="custom-checkbox">
                            <label className="toggle-switch">
                                <input className="form-control custom-checkbox"
                                       type="checkbox"
                                       checked={can_be_framed}
                                       name="can_be_framed"
                                       onChange={this.updateBooleanField}
                                />
                                <div className="toggle-slider round"/>
                            </label>
                        </div>
                    </td>}
                </tr>
                <tr className="delete">
                    <td colSpan="12" align="right">
                        <a className="success" href="#" title="Export client"
                           onClick={this.exportClient}>
                            <i className="icon ion-share inlineicon"/>Export
                        </a>
                        <a className="danger ml2" href="#" title="Delete client"
                           onClick={this.deleteClient}>
                            <i className="icon icon-cta-danger ion-android-delete inlineicon"/>Delete
                        </a>
                    </td>
                </tr>

            </React.Fragment>
        );
    }
}

export default ClientSettings;

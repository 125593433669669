/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react'

const CopyIcon = () => (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="100%" height="100%">
        <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="10"
              d="M89.62 127.91h76.76M89.62 154.5h76.76M72.85 53.32c-21.44 16.54-35.3 42.41-35.3 71.58 0 49.95 40.49 90.45 90.45 90.45s90.45-40.5 90.45-90.45c0-29.17-13.87-55.04-35.3-71.58"></path>
        <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="10"
              d="M128 40.65v64.34m32.85-38.4l-16.42 19.34L128 105.28l-16.43-19.35-16.42-19.34"></path>
    </svg>);

export default CopyIcon

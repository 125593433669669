/*
 * Copyright (C) 2022 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';

const ExportIcon = ({ width, height, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 177.66 172.31"
  >
    <path
      fill={color}
    />
    <path
      fill={color}
      d="M84.79 121.05c1.5 1.5 3.54 2.34 5.66 2.34s4.16-.84 5.66-2.35l37.9-37.93c3.12-3.12 3.12-8.19 0-11.31-3.12-3.12-8.19-3.12-11.31 0L98.45 96.07V8c0-4.42-3.58-8-8-8s-8 3.58-8 8v88.07L58.19 71.81c-3.12-3.12-8.19-3.12-11.31 0-3.12 3.12-3.12 8.19 0 11.31l37.93 37.93ZM172.88 159.52H8c-4.42 0-8 3.58-8 8s3.58 8 8 8h164.88c4.42 0 8-3.58 8-8s-3.58-8-8-8Z"
    />
  </svg>
);

export default ExportIcon;

/*
 * Copyright (C) 2022 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React, { useEffect, useRef, useState } from 'react'
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

function MoveFlowToWorkspaceModal(props) {
  const ref = useRef(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const environments = props.environments;

  const workspaceOptions = []
  Object.keys(environments).forEach(k => {
    if (props.flow && k !== props.flow.provider) {
      workspaceOptions.push({ value: k, label: environments[k].name });
    }
  })

  const selectWorkspace = (newValue, actionMeta) => {
    if (actionMeta.action === 'clear') {
      setSelectedOption(null);
    } else if (actionMeta.action === 'select-option') {
      setSelectedOption(newValue.value);
    }
  }

  const onHide = () => {
    setSelectedOption(null);
    props.hideModal();
  }

  const onConfirm = () => {

    const newCollection = {
      ...props.flow,
      provider: selectedOption
    };
    props.addCollectionToGroup(props.flowId, 'default', null, -1)
    props.updateCollection(props.flowId, newCollection);

    onHide();
  }

  useEffect(() => {
    const keyboardPresses = event => {
      if (event.keyCode === 27) {
        onHide();
      }
    };
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onHide();
      }
    };
    if (props.flowId) {
      document.addEventListener('keydown', keyboardPresses, false);
      document.addEventListener('mousedown', handleClickOutside, { capture: true });
    }
    return () => {
      document.removeEventListener('keydown', keyboardPresses, false);
      document.removeEventListener('mousedown', handleClickOutside, { capture: true });
    }
  }, [props.flowId])


  return (
    <>
      {props.flowId &&
      <div className={'modal modal-is-visible'} ref={ref}>
        <section className="modal-main modal-share">
          <header className="modal-main-header">
            <div className="flex flex-center justify-between">
              <h3 className="h4 m0">Moving {props.flow.name}</h3>
              <div className="flex flex-center justify-between">
                <button className="button button-close button-link ml2"
                        onClick={onHide}><i className="icon ion-close"/>
                </button>
              </div>
            </div>
          </header>
          <div className="modal-main-content">
            <div className="flex flex-column flex-center">
              <Select
                isClearable
                placeholder={'Select a workspace to move the flow to'}
                components={makeAnimated()}
                options={workspaceOptions}
                onChange={selectWorkspace}
                maxMenuHeight={125}
                className="select-container select-container-big"
                classNamePrefix="react-select"
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: '#f2f3f6',
                    primary: '#626c87'
                  }
                })}
              />

            </div>

            <div className="center mt3 mb3">
              {selectedOption ? <p>Move <b>{props.flow.name}</b> to <b>{environments[selectedOption].name}</b></p> :
                <p>Select a workspace from the dropdown above</p>}

              <button className="button button-small button-success-outline" disabled={!selectedOption}
                      onClick={onConfirm}>Confirm
              </button>
            </div>

          </div>
        </section>
      </div>}
    </>
  )

}

export default MoveFlowToWorkspaceModal

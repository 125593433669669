/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React, { useEffect, useRef, useState } from 'react';
import AceEditor from 'react-ace';
import CopyToClipboard from '../CopyToClipboard';

const JSONInput = (props) => {
    const editorRef = useRef()
    const [intermediateValue, setIntermediateValue] = useState(null);
    const [error, setError] = useState(null);
    const [touched, setTouched] = useState(false);
    // This is because of https://github.com/securingsincity/react-ace/issues/684
    // Otherwise, the command to save is only registered on mount of the ace editor
    // making possible to lose data from the Parameters if they were updated after the last mount
    const [editorKey, setEditorKey] = useState(Math.random().toString())

    useEffect(() => {
        setEditorKey(Math.random().toString());
    }, [props.collection.parameters])

    const handleSaveJson = () => {
        const newValue = editorRef.current.editor.getValue();
        if (newValue) {
            try {
                const updatedParameters =
                    props.collection.parameters
                        .withUpdatedValue(props.name, JSON.parse(newValue));
                props.updateParameters(props.collection.id, updatedParameters);
                setIntermediateValue(null);
                setError(null);
                setTouched(false);
            } catch {
                // Could not parse key as JSON, will try to use it raw
                setIntermediateValue(newValue);
                setError('Could not parse JSON');
            }
        } else {
            const updatedParameters =
                props.collection.parameters
                    .withUpdatedValue(props.name, undefined);
            props.updateParameters(props.collection.id, updatedParameters);
            setTouched(false);
        }
    };

    const handlePastedJson = (newValue) => {
        setTouched(true);
        setIntermediateValue(newValue);
    };

    const discardJsonChanges = () => {
        setIntermediateValue(null);
        setTouched(false);
    };

    const command = {
        bindKey: { win: 'Ctrl-S', mac: 'Command-S' },
        exec: handleSaveJson,
        name: 'Save'
    };

    const jsonAsStringOrEmpty = () => {
        if (props.collection.parameters[props.name]) {
            return JSON.stringify(props.collection.parameters[props.name], undefined, 2);
        }
        return ''
    }

    const editorValue = touched ? intermediateValue : jsonAsStringOrEmpty();


    return (
        <div className={'flex-auto'}>
            <div className="tools-form-token tools-form-token-active">
                <header className="tools-box-header tools-box-header-token">
                    <div className="flex flex-center flex-justify">
                        <div>
                            <label>{props.label}</label>
                            {touched &&
                                <>
                                    <button className="button button-primary-dark button-tiny ml2 mr1"
                                            onClick={discardJsonChanges}>Discard
                                    </button>
                                    <button className="button button-success button-tiny"
                                            onClick={handleSaveJson}>Save
                                    </button>
                                    <small><span className="inline-block ml1 primary">(cmd/ctrl + s)</span></small>
                                </>
                            }
                            {error && <small><span className={'ml2 red'}>{error}</span> </small>}
                        </div>
                        <CopyToClipboard text={editorValue} className="mr1"/>
                    </div>
                </header>
                <div className="ace-editor-wrapper">
                    <AceEditor
                        key={editorKey}
                        ref={editorRef}
                        placeholder="Paste JSON"
                        mode="json"
                        theme="monokai"
                        setOptions={{
                            enableBasicAutocompletion: false,
                            enableLiveAutocompletion: false,
                            enableSnippets: false,
                            showLineNumbers: true,
                            showGutter: true,
                            tabSize: 2
                        }}
                        commands={[command]}
                        indentedSoftWrap={false}
                        showPrintMargin={false}
                        onChange={c => handlePastedJson(c)}
                        value={editorValue}
                        width="100%"
                        maxLines={40}
                        minLines={12}
                        fontSize={12}
                        wrapEnabled={true}
                        editorProps={{ $blockScrolling: true }}
                    />
                </div>
            </div>
        </div>

    )

}

export default JSONInput;

/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React, { useState } from 'react';
import FoldButton from '../FoldButton';
import PrettyJson from './PrettyJson';
import CopyToClipboard from '../CopyToClipboard';
import { disclosedClaimsToMap } from '../../util/util';

const TokenValidationDisclosures = (props) => {
    const [isFolded, setIsFolded] = useState(false)

    const handleFold = () => {
        setIsFolded(!isFolded);
    };
    const disclosedClaims = props.token.decoded_token.disclosedClaims;
    const disclosedClaimsJson = disclosedClaimsToMap(disclosedClaims)

    return (<>
            {disclosedClaimsJson ? <section className="tools-sidebar-box tools-sidebar-box-body">
                <header className="tools-box-header">
                    <div className="flex flex-center flex-justify">
                        <div className="tools-box-header-title"><i
                            className="icon ion-code inlineicon"/>{props.headerName}</div>
                        <div className="flex flex-center flex-justify">
                            <CopyToClipboard text={disclosedClaimsJson}/>
                            <FoldButton handleFold={handleFold} isFolded={isFolded}/>
                        </div>
                    </div>
                </header>

                {(isFolded) ? '' :
                    <>
                        <pre className="tools-input">
                            <PrettyJson json={disclosedClaimsJson}/>
                        </pre>
                    </>
                }
            </section> : <></>}
        </>
    )
}

export default TokenValidationDisclosures;

/*
 * Copyright (C) 2022 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';

const ImportIcon = ({ width, height, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 171 197"
  >
    <path
      d="M158 197H13c-7.17 0-13-5.83-13-13V13C0 5.83 5.83 0 13 0h110.49c4.42 0 8 3.58 8 8s-3.58 8-8 8H16v165h139v-41.95c0-4.42 3.58-8 8-8s8 3.58 8 8V184c0 7.17-5.83 13-13 13Z"
      fill={color}
    />
    <g fill={color}>
      <path d="M133.78 103.58h-76.9c-4.42 0-8-3.58-8-8s3.58-8 8-8h76.91c10.08 0 18.27-8.2 18.27-18.27V45.75c0-4.42 3.58-8 8-8s8 3.58 8 8v23.56c0 18.9-15.38 34.27-34.27 34.27Z" />
      <path d="M88.38 130.53c-1.83 0-3.67-.62-5.17-1.9l-31.74-26.96a8 8 0 0 1-2.82-6.1 8 8 0 0 1 2.82-6.1l31.74-26.96a8.002 8.002 0 0 1 11.28.92 8.002 8.002 0 0 1-.92 11.28L69.01 95.57l24.56 20.86a8.002 8.002 0 0 1-5.18 14.1Z" />
    </g>
  </svg>
);

export default ImportIcon;

/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import * as React from 'react';
import Environments from '../../data/Environments';
import {
    arrayIntersection,
    decodeUrlParameter,
    stringArrayToValueLabelArray
} from '../../util/util';
import {
    dcrGrantTypes,
    formatOptionLabel,
    guides, subjectTypes,
    supportedClientAuthenticationMethods
} from '../../util/appConstants';
import FlowHeader from './FlowHeader';
import StepBox from './StepBox';
import CurlRequestPreview from './CurlRequestPreview';
import makeAnimated from 'react-select/animated';
import Creatable from 'react-select/creatable'
import MiddlePaneHeader from './MiddlePaneHeader';
import ServerResponse from '../shared/ServerResponse';
import ResizablePanels from '../ResizablePanels';
import { useEffect, useRef, useState } from 'react';
import DCRToggle from '../settings/DCR/DCRToggle';
import DCRInput from '../settings/DCR/DCRInput';
import DCRCreatableSelect from '../settings/DCR/DCRCreatableSelect';
import DCRSelect from '../settings/DCR/DCRSelect';
import RunButton from './RunButton';
import ClearTokens from '../ClearTokens';
import Guide from '../guides/Guide';
import EmptySidebar from '../EmptySidebar';
import DynamicClientResponse from '../sidebar/response/DynamicClientResponse';
import ExtraQueryParametersModal from '../modals/ExtraQueryParametersModal';

const DCRFlow = (props) => {
    const [showAdvanced, setShowAdvanced] = useState(false)
    const [guideIsVisible, setGuideIsVisible] = useState(false)
    const runButtonRef = useRef();
    const currentCollection = props.collection;
    const dcr_parameters = currentCollection.parameters.dcr_parameters;
    const workspaces = Environments.create(props.environments);
    const currentWorkspace = workspaces.getEnvironment(currentCollection.provider);
    const response = currentCollection.response;

    const clearErrorFromCollection = () => {
        props.setErrorOnCollection(currentCollection.id, null)
    };

    const runFlow = () => {
        clearErrorFromCollection();
        const initialAccessToken = dcr_parameters.authenticatedDCR && dcr_parameters.initialAccessToken ?
            dcr_parameters.initialAccessToken : null;

        props.runDCRRequest(currentWorkspace.id, dcrRequestParameters(), initialAccessToken, currentCollection);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (runButtonRef.current) {
            runButtonRef.current.classList.remove('button-loading-active', 'button-disabled');
        }
    }, [currentCollection.OAuthResponses, currentCollection.OAuthResponses.DynamicClientRegistrationResponse])


    const enableStep2 = !!(currentWorkspace && currentWorkspace.canDoDynamicClientRegistration());

    const error = (!currentCollection.error) ? '' :
        <div className="alert alert-danger flex">
            <div className="flex-auto">
                <i className="icon ion-ios-close-outline inlineicon"/>
                {decodeUrlParameter(currentCollection.error)}
            </div>
            <button className="alert-close flex-end" onClick={clearErrorFromCollection}><i className="icon ion-close"/>
            </button>
        </div>;

    const showHideAdvanced = showAdvanced ? 'Hide advanced parameters' : 'Show Advanced parameters';
    const showHideAdvancedIcon = showAdvanced ? 'ion-chevron-up' : 'ion-chevron-down';

    const toggleAdvancedParameters = () => {
        setShowAdvanced(!showAdvanced)
    };

    const clearResponse = () => {
        props.clearResponseInCollection(currentCollection.id);
        props.clearOAuthResponses(currentCollection.id)
        clearErrorFromCollection();
    };

    const useInitialAccessTokenFromCollection = (option) => {
        const value = option ? option.value : '';

        const updatedParameters = currentCollection.parameters.withUpdatedDcrParameters('initialAccessToken',
            value);

        props.updateParameters(currentCollection.id, updatedParameters);
    };

    const constructDCRRequest = () => {
        return 'curl -Ss -X POST \\\n' +
            currentWorkspace.endpoints.registration_endpoint + ' \\\n' +
            dcrAuthorizationHeader() +
            '-H \'Content-Type: application/json\' \\\n' +
            '-d \'' + JSON.stringify(dcrRequestParameters()) + '\''
    };

    const dcrAuthorizationHeader = () => {
        return dcr_parameters.authenticatedDCR && dcr_parameters.initialAccessToken ?
            '-H \'Authorization: Bearer ' + dcr_parameters.initialAccessToken + '\' \\\n' : '';
    };

    const dcrRequestParameters = () => {
        let selectedScopes = [];
        if (dcr_parameters.scopes) {
            Object.values(dcr_parameters.scopes).forEach((scope) => {
                selectedScopes.push(scope);
            });
        }

        const dcrRequestParams = dcr_parameters.useTemplateClient ?
            { software_id: dcr_parameters.softwareId && dcr_parameters.softwareId !== '' ? dcr_parameters.softwareId : undefined }
            : {
                token_endpoint_auth_method: dcr_parameters.tokenEndpointAuthMethod && dcr_parameters.tokenEndpointAuthMethod !== '' ? dcr_parameters.tokenEndpointAuthMethod : undefined,
                subject_type: dcr_parameters.subjectType && dcr_parameters.subjectType !== '' ? dcr_parameters.subjectType : undefined,
                scope: selectedScopes.length > 0 ? selectedScopes.join(' ') : undefined,
                grant_types: valueOrUndefined(dcr_parameters.grantTypes),
                default_acr_values: valueOrUndefined(dcr_parameters.acrs),
                redirect_uris: valueOrUndefined(dcr_parameters.redirectURIs),
                allowed_origins: valueOrUndefined(dcr_parameters.allowedOrigins),
                contacts: valueOrUndefined(dcr_parameters.contacts),

                default_max_age: dcr_parameters.defaultMaxAge && dcr_parameters.defaultMaxAge !== '' ? dcr_parameters.defaultMaxAge : undefined,
                access_token_ttl: dcr_parameters.accessTokenTTL && dcr_parameters.accessTokenTTL !== '' ? dcr_parameters.accessTokenTTL : undefined,
                id_token_ttl: dcr_parameters.idTokenTTL && dcr_parameters.idTokenTTL !== '' ? dcr_parameters.idTokenTTL : undefined,
                refresh_token_ttl: dcr_parameters.refreshTokenTTL && dcr_parameters.refreshTokenTTL !== '' ? dcr_parameters.refreshTokenTTL : undefined,
                requires_consent: dcr_parameters.requiresConsent ? dcr_parameters.requiresConsent : undefined,
                authenticator_filters: valueOrUndefined(dcr_parameters.authenticatorFilters),
                sector_identifier_uri: dcr_parameters.sectorIdentifierURI && dcr_parameters.sectorIdentifierURI !== '' ? dcr_parameters.sectorIdentifierURI : undefined,

                client_name: dcr_parameters.clientName && dcr_parameters.clientName !== '' ? dcr_parameters.clientName : undefined,
                client_uri: dcr_parameters.clientURI && dcr_parameters.clientURI !== '' ? dcr_parameters.clientURI : undefined,
                logo_uri: dcr_parameters.logoURI && dcr_parameters.logoURI !== '' ? dcr_parameters.logoURI : undefined,
                tos_uri: dcr_parameters.tosURI && dcr_parameters.tosURI !== '' ? dcr_parameters.tosURI : undefined,
                policy_uri: dcr_parameters.policyURI && dcr_parameters.policyURI !== '' ? dcr_parameters.policyURI : undefined
            };


        props.collection.parameters.request_extra_query_parameters?.filter(
            queryParam => queryParam.name !== '' || queryParam.value !== '')
            .forEach(queryParam => {
                dcrRequestParams[queryParam.name] = queryParam.value
            });

        return dcrRequestParams
    };

    const valueOrUndefined = (field) => {
        if (field && field.length > 0) {
            return field
        }
        return undefined
    };

    const saveClientInWorkspace = () => {
        if (!currentWorkspace.hasClientWithClientId(response.client_id)) {
            const updatedWorkspace = currentWorkspace.withClientFromDcr({ ...response })
            props.updateEnvironment(updatedWorkspace)
        }
    }

    let initialAccessTokenOptions = [];
    Object.values(props.collections)
        .filter(collection => collection.provider === currentWorkspace.id)
        .forEach(collection => {
            Object.values(collection.tokens).forEach(token => {
                if (token.purpose === 'access_token') {
                    const ellipsis = token.value.length > 20 ? '...' : '';
                    initialAccessTokenOptions.push({
                        value: token.value,
                        label: `${collection.name}: ${token.name}`,
                        tokenString: `${token.value.substring(0, 20)}${ellipsis}`
                    });
                }
            });
        });

    const initialAccessToken = dcr_parameters.authenticatedDCR && dcr_parameters.initialAccessToken ?
        { value: dcr_parameters.initialAccessToken, label: dcr_parameters.initialAccessToken } : null;


    const serverAllowedTokenEndpointAuthMethods = currentWorkspace.token_endpoint_auth_methods_supported ?
        currentWorkspace.token_endpoint_auth_methods_supported : supportedClientAuthenticationMethods;
    const methodList = arrayIntersection(serverAllowedTokenEndpointAuthMethods, supportedClientAuthenticationMethods);
    const allowedTokenEndpointAuthnMethods = stringArrayToValueLabelArray(methodList);

    let availableScopes = [];
    if (currentWorkspace && currentWorkspace.scopes) {
        const scopeList = currentWorkspace.scopes;
        availableScopes = stringArrayToValueLabelArray(scopeList);
    }

    let availableAcrs = [];
    if (currentWorkspace && currentWorkspace.acrs) {
        const acrList = currentWorkspace.acrs;
        availableAcrs = stringArrayToValueLabelArray(acrList);
    }

    const origin = window.location.origin;
    const availableRedirectURIs = [
        { value: origin + '/callback/code', label: origin + '/callback/code' },
        { value: origin + '/callback/implicit', label: origin + '/callback/implicit' },
        { value: origin + '/callback/hybrid', label: origin + '/callback/hybrid' }
    ];

    const availableAllowedOrigins = [{ value: origin, label: origin }];
    const dcrRequest = constructDCRRequest();

    return (
        <React.Fragment>
            <ResizablePanels {...props}>
                <section className="tools-form">
                    <MiddlePaneHeader
                        collection={currentCollection}
                        exportCurrentCollection={props.exportCurrentCollection}/>

                    <div className="tools-form-content">

                        <FlowHeader name={currentCollection.name}
                                    description={'Perform a Dynamic Client Registration request'}/>
                        {error}

                        <StepBox title={'Settings'} step={'1'} enabled={true}>
                            <div className="sm-flex flex-justify flex-center flex-gap-2 flex-wrap mt2">
                                <div className={'flex-auto'}>
                                    <DCRToggle
                                        collection={currentCollection}
                                        updateParameters={props.updateParameters}
                                        label="Authenticated DCR"
                                        field="authenticatedDCR"
                                        tooltip="Use an initial access token from a collection to authenticate this dynamic client registration request"
                                    />
                                </div>

                                <div className={'flex-auto'}>
                                    {dcr_parameters.authenticatedDCR ?
                                        <Creatable
                                            isClearable
                                            placeholder="Select token from a Collection"
                                            components={makeAnimated()}
                                            options={initialAccessTokenOptions}
                                            formatOptionLabel={formatOptionLabel}
                                            onChange={useInitialAccessTokenFromCollection}
                                            defaultValue={initialAccessToken}
                                            className="select-container select-container-big"
                                            classNamePrefix="react-select"
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#f2f3f6',
                                                    primary: '#626c87'
                                                }
                                            })}
                                        />

                                        : ''}
                                </div>
                            </div>

                            <div className="sm-flex flex-justify flex-center flex-gap-2 flex-wrap mt2">
                                <div className={'flex-auto'}>
                                    <DCRToggle
                                        collection={currentCollection}
                                        updateParameters={props.updateParameters}
                                        label="Use software_id (Client Template)"
                                        field="useTemplateClient"
                                        tooltip="The request will use the software_id parameter to register a template client"
                                    />
                                </div>
                                <div className="flex-auto">
                                    {dcr_parameters.useTemplateClient ?
                                        <div className="flex-50 pl1">
                                            <DCRInput
                                                collection={currentCollection}
                                                updateParameters={props.updateParameters}
                                                type="text"
                                                placeholder="Enter software_id"
                                                field="softwareId"
                                            />
                                        </div>
                                        : ''}
                                </div>
                            </div>

                            {dcr_parameters.useTemplateClient ? '' :
                                <React.Fragment>
                                    <div className="sm-flex flex-justify flex-center mt2">

                                        <div className="flex-50">
                                            <DCRCreatableSelect
                                                collection={currentCollection}
                                                updateParameters={props.updateParameters}
                                                field="acrs"
                                                placeholder="Select Authenticators"
                                                options={availableAcrs}
                                            />
                                        </div>

                                        <div className="flex-50 pl1">
                                            <DCRCreatableSelect
                                                collection={currentCollection}
                                                updateParameters={props.updateParameters}
                                                field="scopes"
                                                placeholder="Select Scopes"
                                                options={availableScopes}
                                            />

                                        </div>

                                    </div>

                                    <div className="sm-flex flex-justify flex-center mt2">
                                        <div className="flex-50">
                                            <DCRCreatableSelect
                                                collection={currentCollection}
                                                updateParameters={props.updateParameters}
                                                field="grantTypes"
                                                placeholder="Select Grant Types"
                                                options={dcrGrantTypes}
                                            />
                                        </div>

                                        <div className="flex-50 pl1">
                                            <DCRSelect
                                                collection={currentCollection}
                                                updateParameters={props.updateParameters}
                                                placeholder="Select Token Endpoint Authentication Method"
                                                options={allowedTokenEndpointAuthnMethods}
                                                field="tokenEndpointAuthMethod"
                                            />

                                        </div>
                                    </div>

                                    <div className="sm-flex flex-justify flex-center mt2">
                                        <div className="flex-50">
                                            <DCRCreatableSelect
                                                collection={currentCollection}
                                                updateParameters={props.updateParameters}
                                                field="redirectURIs"
                                                placeholder="Redirect URIs"
                                                options={availableRedirectURIs}
                                                noOptionsMessage={'Type other redirect URIs'}
                                            />
                                        </div>
                                        <div className="flex-50 pl1">
                                            <DCRCreatableSelect
                                                collection={currentCollection}
                                                updateParameters={props.updateParameters}
                                                field="allowedOrigins"
                                                placeholder="Allowed Origins"
                                                options={availableAllowedOrigins}
                                                noOptionsMessage={'Type other allowed origins'}
                                            />
                                        </div>
                                    </div>
                                    <div className="sm-flex flex-justify flex-center mt2">
                                        <button className="button button-tiny button-primary-outline"
                                                onClick={toggleAdvancedParameters}><i
                                            className={'ion inlineicon ' + showHideAdvancedIcon}/>{showHideAdvanced}
                                        </button>
                                    </div>
                                </React.Fragment>
                            }

                            {dcr_parameters.useTemplateClient || !showAdvanced ? '' :
                                <React.Fragment>

                                    <div className="sm-flex flex-justify flex-center mt2">
                                        <div className="flex-50">
                                            <DCRInput
                                                collection={currentCollection}
                                                updateParameters={props.updateParameters}
                                                type="text"
                                                placeholder="Enter Client Name"
                                                field="clientName"
                                            />

                                        </div>
                                        <div className="flex-50 pl1">
                                            <DCRCreatableSelect
                                                collection={currentCollection}
                                                updateParameters={props.updateParameters}
                                                field="contacts"
                                                placeholder="Contacts"
                                                noOptionsMessage={'Type an email responsible for this client'}
                                            />

                                        </div>
                                    </div>

                                    <div className="sm-flex flex-justify flex-center mt2">
                                        <div className="flex-50">
                                            <DCRToggle
                                                collection={currentCollection}
                                                updateParameters={props.updateParameters}
                                                label="Require Consent"
                                                field="requiresConsent"
                                            />
                                        </div>
                                        <div className="flex-50 pl1">
                                            <DCRCreatableSelect
                                                collection={currentCollection}
                                                updateParameters={props.updateParameters}
                                                field="authenticatorFilters"
                                                placeholder="Authenticator Filters"
                                                noOptionsMessage={'Enter the authenticator filter IDs'}
                                            />
                                        </div>
                                    </div>

                                    <div className="sm-flex flex-justify flex-center mt2">
                                        <div className="flex-50">
                                            <DCRInput
                                                collection={currentCollection}
                                                updateParameters={props.updateParameters}
                                                type="url"
                                                placeholder="Enter Logo URI"
                                                field="logoURI"
                                            />

                                        </div>
                                        <div className="flex-50 pl1">
                                            <DCRInput
                                                collection={currentCollection}
                                                updateParameters={props.updateParameters}
                                                type="url"
                                                placeholder="Enter Client URI"
                                                field="clientURI"
                                            />
                                        </div>
                                    </div>

                                    <div className="sm-flex flex-justify flex-center mt2">
                                        <div className="flex-50">
                                            <DCRInput
                                                collection={currentCollection}
                                                updateParameters={props.updateParameters}
                                                type="url"
                                                placeholder="Enter Terms of Service URI"
                                                field="tosURI"
                                            />
                                        </div>
                                        <div className="flex-50 pl1">
                                            <DCRInput
                                                collection={currentCollection}
                                                updateParameters={props.updateParameters}
                                                type="url"
                                                placeholder="Enter Privacy Policy URI"
                                                field="policyURI"
                                            />
                                        </div>
                                    </div>

                                    <div className="sm-flex flex-justify flex-center mt2">
                                        <div className="flex-50">
                                            <DCRSelect
                                                collection={currentCollection}
                                                updateParameters={props.updateParameters}
                                                placeholder="Select Subject Type"
                                                options={subjectTypes}
                                                field="subjectType"
                                            />
                                        </div>
                                        <div className="flex-50 pl1">
                                            <DCRInput
                                                collection={currentCollection}
                                                updateParameters={props.updateParameters}
                                                type="url"
                                                placeholder="Sector Identifier URI"
                                                field="sectorIdentifierURI"
                                            />
                                        </div>
                                    </div>

                                    <div className="mt2">
                                        <label htmlFor="" className="label">Time settings</label>
                                    </div>
                                    <div className="sm-flex flex-justify flex-center ">
                                        <div className="flex-50">
                                            <DCRInput
                                                collection={currentCollection}
                                                updateParameters={props.updateParameters}
                                                type="number"
                                                placeholder="Default Max Age"
                                                field="defaultMaxAge"
                                            />
                                        </div>
                                        <div className="flex-50 pl1">
                                            <DCRInput
                                                collection={currentCollection}
                                                updateParameters={props.updateParameters}
                                                type="number"
                                                placeholder="ID Token TTL"
                                                field="idTokenTTL"
                                            />
                                        </div>

                                    </div>

                                    <div className="sm-flex flex-justify flex-center mt2">
                                        <div className="flex-50">
                                            <DCRInput
                                                collection={currentCollection}
                                                updateParameters={props.updateParameters}
                                                type="number"
                                                placeholder="Access Token TTL"
                                                field="accessTokenTTL"
                                            />
                                        </div>
                                        <div className="flex-50 pl1">
                                            <DCRInput
                                                collection={currentCollection}
                                                updateParameters={props.updateParameters}
                                                type="number"
                                                placeholder="Refresh Token TTL"
                                                field="refreshTokenTTL"
                                            />
                                        </div>
                                    </div>

                                </React.Fragment>
                            }


                        </StepBox>

                        <StepBox title={'Call Dynamic Client Registration Endpoint'} step={'2'} enabled={enableStep2}>
                            <div className={'flex justify-end'}>
                                <ExtraQueryParametersModal
                                    updateParameters={props.updateParameters}
                                    collection={props.collection}
                                    parameter={'request_extra_query_parameters'}
                                />
                            </div>
                            <CurlRequestPreview request={dcrRequest}/>
                            <RunButton runFlow={runFlow} buttonText={'Run Dynamic Client Registration'}/>
                        </StepBox>

                        <ServerResponse response={currentCollection.OAuthResponses.DynamicClientRegistrationResponse}/>

                    </div>
                </section>

                {response ?
                    <aside className="tools-sidebar dcr-response">
                        <header className="tools-form-header tools-form-header-tokens">
                            <h4 className="m0"/>
                            <div className="flex justify-between flex-center">
                                <ClearTokens clearTokens={clearResponse}
                                             showClearButton={response}/>
                            </div>
                        </header>
                        <Guide area={guides.dcr} toggle={() => setGuideIsVisible(!guideIsVisible)}/>
                        {!guideIsVisible && <main role="contentinfo">
                            <DynamicClientResponse responseBody={response}
                                                   saveClientInWorkspace={saveClientInWorkspace}/>
                        </main>}
                    </aside>
                    :
                    <aside className="tools-sidebar">
                        <EmptySidebar
                            guide={guides.dcr}
                            clearTokens={clearResponse}
                            collection={currentCollection}
                            text="Run the flow to get the Dynamic Client Registration result"/>
                    </aside>

                }
            </ResizablePanels>
        </React.Fragment>
    );

}

export default DCRFlow

/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React, { useEffect, useRef, useState } from 'react'
import { flows } from '../../util/appConstants'
import { Link } from 'react-router-dom'


function HistoryEntry(props) {
    const ref = useRef(null);
    const [isEditing, setEditing] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const classes = `tools-history-badge ${badgeType(props.entry)}`;

    const handleActionButtonClick = () => {
        setShowDropdown(!showDropdown);
    }

    const onConfirm = () => {
        setEditing(false)
    };

    const onEdit = () => {
        const editing = !isEditing;
        setShowDropdown(false)
        setEditing(editing)
    };

    const onDuplicate = () => {
        setShowDropdown(false)
        props.duplicateCollection(props.index, props.groupId, props.order + 1)
    }

    const onDelete = () => {
        setShowDropdown(false)
        props.deleteCollection(props.index)
    };

    const updateName = (event) => {
        const newCollection = {
            ...props.entry,
            name: event.currentTarget.value
        };
        props.updateCollection(props.index, newCollection);
    };

    const handleFocus = (event) => {
        event.target.select();
    };

    const handleClick = () => {
        props.changeCollection(props.index, props.collections)
    }

    useEffect(() => {
        const addEventListeners = () => {
            removeEventListeners();
            document.addEventListener('keydown', escFunction, false);
            document.addEventListener('mousedown', handleClickOutside, false);
        }

        const removeEventListeners = () => {
            document.removeEventListener('keydown', escFunction, false);
            document.removeEventListener('mousedown', handleClickOutside, false);
        }

        const escFunction = (event) => {
            if (event.keyCode === 27 || event.keyCode === 13) {
                setEditing(false)
                setShowDropdown(false)
                removeEventListeners()
            }
        };

        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setShowDropdown(false)
                removeEventListeners();
            }
        }

        const handleRightClick = (event) => {
            if (ref.current && ref.current.contains(event.target)) {
                event.preventDefault();
                if (showDropdown) {
                    setShowDropdown(false)
                } else {
                    setShowDropdown(true)
                }
            }
        }
        document.addEventListener('contextmenu', handleRightClick, false);

        if (showDropdown || isEditing) {
            addEventListeners()
        } else {
            removeEventListeners();
        }

        return () => {
            removeEventListeners();
            document.removeEventListener('contextmenu', handleRightClick, false);
        }
    },[isEditing, showDropdown])

    return (
        <li ref={ref}
            className={isCurrentCollection(props.appState, props.index) ? 'tools-history-current' : null}>
            <Link
                to={'/collection/' + props.index}
                onClick={handleClick}
                data-qa={props.entry.name}
            >
                <span className={classes}>{badgeName(props.entry)}</span>

                {isEditing ?
                    <div className="tools-history-field-wrapper">
                        <input type="text" className="field tools-history-field mr1" onChange={updateName}
                               value={props.entry.name} onBlur={onConfirm} onFocus={handleFocus} autoFocus
                               autoComplete="off"
                               data-lpignore="true"
                               spellCheck="false"
                        />
                    </div>
                    :
                    <span className="tools-history-entry">{props.entry.name}</span>

                }

            </Link>

            <div className={(isEditing ? 'hide' : '')}>
                    <span className={'tools-history-edit ' + (showDropdown ? 'active' : '')}
                          onClick={handleActionButtonClick}>
                        <i className="icon ion-more" style={{ transform: 'rotate(90deg)' }}/>
                    </span>


                <div className={'context-dropdown-menu ' + (showDropdown ? 'active' : 'hide')}
                     aria-labelledby="dropdownButton">
                    <nav>
                        <ul className="m0 list-reset">
                            <li>
                                <div onClick={onEdit}>
                                    <i className="icon ion-edit mr2 "/>Rename
                                </div>
                            </li>
                            <li>
                                <div onClick={onDuplicate}>
                                    <i className="icon ion-ios-copy mr2 "/>Duplicate
                                </div>
                            </li>
                            <li>
                                <div onClick={props.onMove}>
                                    <i className="icon ion-arrow-swap mr2 "/>Move to
                                </div>
                            </li>
                            <li>
                                <div onClick={onDelete}>
                                    <i className="icon ion-trash-b mr2"/>Delete
                                </div>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </li>
    )
}

const badgeType = (entry) => {

    const flow = flows[entry.flow].short_label;
    const badge = flow ? flow.toLowerCase() : '-none';
    return 'tools-history-badge-' + badge;
};

const badgeName = (entry) => {
    const flow = flows[entry.flow].short_label.replace('_', ' ');
    return flow.toUpperCase();
};

const isCurrentCollection = (appState, index) => {
    return (appState.currentCollection === index)
};

export default HistoryEntry;

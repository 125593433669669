/*
 * Copyright (C) 2020 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react'

const ToastErrorIcon = () => (
    <svg viewBox="0 0 130 130" className="mr2" width="100%" height="100%">
        <circle
            className="path circle"
            fill="none"
            stroke="#a94442"
            strokeWidth={6}
            strokeMiterlimit={10}
            cx={65.1}
            cy={65.1}
            r={62.1}
        />
        <path
            className="path line"
            fill="none"
            stroke="#a94442"
            strokeWidth={6}
            strokeLinecap="round"
            strokeMiterlimit={10}
            d="M34.4 37.9l61.4 54.4M95.8 38L34.4 92.2"
        />
    </svg>
);

export default ToastErrorIcon

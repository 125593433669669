/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import * as React from 'react';
import { convertToQueryParams, decodeUrlParameter } from '../../util/util';
import {
    default as REDIRECT_URI_CONSTANTS,
    flows,
    formatOptionLabel,
    guides,
    REACT_SELECT_LIB_ACTIONS,
    tokenPurposes
} from '../../util/appConstants';
import { isEmpty } from '../../util/validationUtils';
import FlowHeader from './FlowHeader';
import StepBox from './StepBox';
import Environments from '../../data/Environments';
import MiddlePaneHeader from './MiddlePaneHeader';
import makeAnimated from 'react-select/animated';
import Guide from '../guides/Guide';
import InteractiveFlow from './InteractiveFlow';
import CallbackUriHelper from './settings/CallbackUriHelper';
import ResizablePanels from '../ResizablePanels';
import RunButton from './RunButton';
import ExtraQueryParametersModal from '../modals/ExtraQueryParametersModal';
import StartUrl from './StartUrl';
import Select from 'react-select';

class LogoutFlow extends InteractiveFlow {

    END_SESSION_ENDPOINT = 'end_session_endpoint';

    constructor(props) {
        super(props, REDIRECT_URI_CONSTANTS.END_SESSION_PATH, 'logout');
    }

    getIdTokens = () => {
        const currentCollection = this.props.collection;
        let accessTokensFromCollections = [];
        Object.values(this.getCollections())
          .filter(collection => collection.provider === currentCollection.provider)
          .forEach(collection => {
            if (flows[collection.flow].produces_tokens) {
                Object.values(collection.tokens).forEach(token => {
                    if (!isEmpty(token.value) && token.purpose === tokenPurposes.id_token.value) {
                        const ellipsis = token.value.length > 20 ? '...' : '';
                        accessTokensFromCollections.push({
                            value: token.value,
                            label: `${collection.name}: ${token.name}`,
                            tokenString: `${token.value.substring(0, 20)}${ellipsis}`,
                            meta: {
                                purpose: token.purpose,
                                name: token.name
                            }
                        });
                    }
                });
            }
        });

        return accessTokensFromCollections;
    };

    getCollections = () => {
        return this.props.collections;
    };

    getCurrentCollection = () => {
        return this.props.collection;
    };

    getEnvironments = () => {
        return Environments.create(this.props.environments);
    };

    isEndpointValueExist = (endpointName) => {
        const endpoints = this.getCurrentEnvironmentEndpoints();
        return !!(endpoints && endpoints[endpointName]);
    };

    getCurrentEnvironmentEndpoints = () => {
        const selectedEnvironment = this.getCurrentEnvironment();
        return selectedEnvironment ? selectedEnvironment.endpoints : null;
    };

    getCurrentEnvironment = () => {
        const environments = this.getEnvironments();
        const currentCollection = this.getCurrentCollection();
        return environments.getEnvironment(currentCollection.provider);
    };

    setTokenHint = (newValue, actionMeta) => {
        const reactSelectActions = REACT_SELECT_LIB_ACTIONS;
        if (actionMeta.action === reactSelectActions.SELECT_OPTION) {
            const currentCollection = this.getCurrentCollection();
            const updatedParameters = this.props.collection.parameters.withUpdatedValue('token_to_introspect', newValue.value);
            this.props.updateParameters(currentCollection.id, updatedParameters);
        } else if (actionMeta.action === reactSelectActions.CLEAR) {
            const updatedParameters =
                this.props.collection.parameters.withUpdatedValue('token_to_introspect', null);
            this.props.updateParameters(this.props.collection.id, updatedParameters);
        }
    };

    runLogoutFlow = () => {
        window.location.href = this.getLogOutUrl();
    };

    getLogOutUrl = () => {
        const endpoints = this.getCurrentEnvironmentEndpoints();
        const logoutEndpoint = endpoints && endpoints[this.END_SESSION_ENDPOINT];
        const currentCollectionParameters = this.getCurrentCollection().parameters;
        const { token_to_introspect, session_state } = currentCollectionParameters;
        const params = {
            'post_logout_redirect_uri11': window.location.origin
        };

        if (!logoutEndpoint) {
            return ''
        }
        if (token_to_introspect) {
            params.id_token_hint = token_to_introspect;
        }
        if (session_state) {
            params.state = session_state;
        }

        this.props.collection.parameters.request_extra_query_parameters?.filter(
            queryParam => queryParam.name !== '' || queryParam.value !== '')
            .forEach(queryParam => {
                params[queryParam.name] = queryParam.value
            });

        return `${logoutEndpoint}?${convertToQueryParams(params)}`;
    };

    interactiveFlowCallbackUrlHelpText = () => {
        return 'Configure your client with the following post logout redirect URI:'
    }

    redirectUri = () => {
        return window.origin
    }

    setEndSessionState = (event) => {
        const stateValue = event.target.value;
        const currentCollection = this.getCurrentCollection();
        const parameters = currentCollection.parameters.withUpdatedValue('session_state', stateValue);
        this.props.updateParameters(this.getCurrentCollection().id, parameters);
    };

    render() {
        const currentCollection = this.getCurrentCollection();
        const selectedToken = currentCollection.tokens[0];
        const selectedOption = selectedToken ?
            this.getIdTokens().filter(option => option.value === selectedToken.value) : null;
        const logoutUrl = this.getLogOutUrl();
        const environments = Environments.create(this.props.environments);
        const environment = environments.getEnvironment(currentCollection.provider);

        return (
            <React.Fragment>
                <ResizablePanels {...this.props}>
                    <section className="tools-form">

                        {/* TOOLS HEADER SECTION */}
                        <MiddlePaneHeader
                            workspace={environment}
                            collection={this.props.collection}
                            exportCurrentCollection={this.props.exportCurrentCollection}/>

                        {/* TOOLS MAIN/BODY SECTION */}

                        <div className="tools-form-content">

                            {/* LOGOUT FLOW HEADER SECTION */}
                            <React.Fragment>
                                <FlowHeader name={currentCollection.name}
                                            description={'Select ID token to logout particular client if none are selected ' +
                                            'all clients will be logged out.'}/>
                            </React.Fragment>

                            {/* DISPLAY ERROR SECTION */}
                            <React.Fragment>
                                {
                                    (currentCollection && currentCollection.error) &&
                                    <div className="alert alert-danger">
                                        <i className="icon ion-ios-close-outline inlineicon"/>
                                        {decodeUrlParameter(currentCollection.error)}
                                        <button className="alert-close" onClick={this.clearErrorFromCollection}><i
                                            className="icon ion-close"/>
                                        </button>
                                    </div>
                                }
                            </React.Fragment>

                            {/*SETTINGS-STEP-1 SECTION*/}

                            {this.isEndpointValueExist(this.END_SESSION_ENDPOINT) &&
                            <CallbackUriHelper text={this.interactiveFlowCallbackUrlHelpText()}
                                            showEditButton={false}
                                            url={this.redirectUri()}/>
                            }


                            <StepBox title={'Settings'} step={'1'} enabled={true}>

                                <div className="sm-flex flex-justify flex-center mt2">
                                    <div className="flex-100">
                                        <Select
                                            isClearable
                                            placeholder="Select an ID Token from a Collection"
                                            components={makeAnimated()}
                                            options={this.getIdTokens()}
                                            formatOptionLabel={formatOptionLabel}
                                            defaultValue={selectedOption}
                                            inputId={'select-id-token'}
                                            onChange={this.setTokenHint}
                                            className="select-container select-container-big"
                                            classNamePrefix="react-select"
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#f2f3f6',
                                                    primary: '#626c87'
                                                }
                                            })}
                                        />
                                    </div>
                                </div>

                                <div className="sm-flex flex-justify flex-center mt2">
                                    <div className="flex-100">
                                        <input className="field col-12" type="text" placeholder="State"
                                            onChange={this.setEndSessionState}
                                               value={this.props.collection.parameters.session_state}
                                            autoComplete="off"
                                            data-lpignore="true"
                                            spellCheck="false"
                                        />
                                    </div>
                                    <div className="flex-50 lg-ml1">
                                    </div>
                                </div>
                            </StepBox>

                            {/*LOGOUT-STEP-2 SECTION*/}

                            <StepBox title={'Logout endpoint'} step={'2'} enabled={true}>
                                <div className={'flex justify-end'}>
                                    <ExtraQueryParametersModal
                                        updateParameters={this.props.updateParameters}
                                        collection={this.props.collection}
                                        parameter={'request_extra_query_parameters'}
                                    />
                                </div>
                                <StartUrl startUrl={logoutUrl} title={'Logout URL'}/>

                                <RunButton
                                    isDisabled={!this.isEndpointValueExist(this.END_SESSION_ENDPOINT)}
                                    runFlow={this.runLogoutFlow}
                                    buttonText="Logout Client"/>
                            </StepBox>
                        </div>

                    </section>
                    <aside className="tools-sidebar">
                        <Guide area={guides.oidc_logout}/>
                    </aside>
                </ResizablePanels>

            </React.Fragment>
        );
    }
}

export default LogoutFlow

/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React, { useState } from 'react';

export const Checkbox = ({ enabled, title, classes, children }) => {
  const [isEnabled, setIsEnabled] = useState(!!enabled);

  return (
    <div className={classes}>
      <div className="custom-checkbox mb2">
        <label className="toggle-switch" htmlFor="checkbox-component">
          <input
            className="form-control custom-checkbox"
            type="checkbox"
            id="checkbox-component"
            name="checkbox-component"
            checked={isEnabled}
            onChange={(event) => setIsEnabled(event.target.checked)}
          />
          <div className="toggle-slider round" />
        </label>
        <label className="ml1">{title}</label>
      </div>
      {isEnabled && children}
    </div>
  );
};

/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';

import Guide from './guides/Guide';
import ClearTokens from './ClearTokens';
import { isEmptyObject } from '../util/validationUtils';

class EmptySidebar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            guideIsVisible: false
        };
    }

    toggleGuideVisible = (isVisible) => {
        this.setState( { guideIsVisible: isVisible })
    }

    render() {
        return (
            <div className="tools-sidebar-empty">

                {(this.props.collection && this.props.clearTokens
                        && !isEmptyObject(this.props.collection.OAuthResponses)) &&
                <div className="tools-sidebar-nav flex justify-end p1">
                    {this.props.collection && this.props.clearTokens &&
                            <ClearTokens clearTokens={this.props.clearTokens}
                                        showClearButton={!isEmptyObject(this.props.collection.OAuthResponses)}/>
                    }
                </div>
                }

                {this.props.guide &&
                        this.props.guide && <Guide area={this.props.guide} toggle={this.toggleGuideVisible}/>
                }

                {!this.state.guideIsVisible && <main role="contentinfo">
                    <div className="flex flex-justify flex-center justify-center tools-sidebar-empty-holder">

                        <div className="tools-sidebar-empty-content flex flex-column flex-center center">
                            <div className="pulse-wrapper">
                                <span className="pulse-stay"/>
                                <span className="pulse"/>
                                <i className="icon ion-android-arrow-back" />
                            </div>

                            <p className="mt2">{this.props.text}</p>
                        </div>
                    </div>
                </main>}
            </div>
        );
    }
}
export default EmptySidebar;

/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import * as React from 'react';
import Environments from '../../data/Environments';
import { base64encode, decodeUrlParameter, encodeUrlParameter } from '../../util/util';
import { flows, formatOptionLabel, guides, tokenTypeIdentifiers } from '../../util/appConstants';
import FlowHeader from './FlowHeader';
import StepBox from './StepBox';
import ClientCredentials from './settings/ClientCredentials';
import CurlRequestPreview from './CurlRequestPreview';
import makeAnimated from 'react-select/animated';
import Creatable from 'react-select/creatable'
import { isEmpty } from '../../util/validationUtils';
import MiddlePaneHeader from './MiddlePaneHeader';
import ClientAuthenticationMethod from './settings/ClientAuthenticationMethod';
import ReceivedTokensSidebar from '../token/ReceivedTokensSidebar';
import IntrospectionSettings from './settings/IntrospectionSettings';
import ServerResponse from '../shared/ServerResponse';
import ResizablePanels from '../ResizablePanels';
import Scopes from './settings/Scopes';
import { useEffect, useRef } from 'react';
import ExtraQueryParametersModal from '../modals/ExtraQueryParametersModal';

const TokenExchangeFlow = (props) => {
    const runButtonRef = useRef();
    const currentCollection = props.collection;

    const clearErrorFromCollection = () => {
        props.setErrorOnCollection(currentCollection.id, null)
    };

    const setSubjectToken = (newValue) => {
        const updatedParameters = currentCollection.parameters.withUpdatedValue('token_exchange_subject_token', newValue);
        props.updateParameters(currentCollection.id, updatedParameters);
    }

    const setSubjectTokenType = (newValue) => {
        const updatedParameters = currentCollection.parameters.withUpdatedValue('token_exchange_subject_token_type', newValue);
        props.updateParameters(currentCollection.id, updatedParameters);
    }

    const setActorToken = (newValue) => {
        const updatedParameters = currentCollection.parameters.withUpdatedValue('token_exchange_actor_token', newValue);
        props.updateParameters(currentCollection.id, updatedParameters);
    }

    const setActorTokenType = (newValue) => {
        const updatedParameters = currentCollection.parameters.withUpdatedValue('token_exchange_actor_token_type', newValue);
        props.updateParameters(currentCollection.id, updatedParameters);
    }

    const setRequestedTokenType = (newValue) => {
        const updatedParameters = currentCollection.parameters.withUpdatedValue('token_exchange_requested_token_type', newValue);
        props.updateParameters(currentCollection.id, updatedParameters);
    }

    const setResource = (event) => {
        const updatedParameters = currentCollection.parameters.withUpdatedValue('token_exchange_resource', event.currentTarget.value);
        props.updateParameters(currentCollection.id, updatedParameters);
    }

    const setAudience = (event) => {
        const updatedParameters = currentCollection.parameters.withUpdatedValue('token_exchange_audience', event.currentTarget.value);
        props.updateParameters(currentCollection.id, updatedParameters);
    }

    const previewRequest = () => {
        const environments = Environments.create(props.environments);
        const environment = environments.getEnvironment(currentCollection.provider);

        const tokenEndpoint = environment ? environment.endpoints.token_endpoint : '';

        let authHeader = '';
        if (!currentCollection.parameters.token_endpoint_auth_method || currentCollection.parameters.token_endpoint_auth_method === 'client_secret_basic') {
            authHeader = '-H \'Authorization: Basic ' + base64encode(currentCollection.parameters.client_id + ':' + (currentCollection.parameters.client_secret || '')) + '\' \\\n';
        }
        const requestParameters = resolveRequestParameters();

        return 'curl -Ss -X POST \\\n' +
            tokenEndpoint + ' \\\n' +
            authHeader +
            '-H \'Content-Type: application/x-www-form-urlencoded\' \\\n' +
            '-d \'' + encodeUrlParameter(requestParameters) + '\''
    };

    const resolveRequestParameters = () => {
        const requestParameters = {
            grant_type: flows.token_exchange.grant_type,
            subject_token: currentCollection.parameters.token_exchange_subject_token?.value,
            subject_token_type: currentCollection.parameters.token_exchange_subject_token_type?.value,
            actor_token: currentCollection.parameters.token_exchange_actor_token?.value,
            actor_token_type: currentCollection.parameters.token_exchange_actor_token_type?.value,
            requested_token_type: currentCollection.parameters.token_exchange_requested_token_type?.value,
            audience: currentCollection.parameters.token_exchange_audience,
            resource: currentCollection.parameters.token_exchange_resource
        };

        if (currentCollection.parameters.token_endpoint_auth_method === 'client_secret_post') {
            requestParameters.client_id = currentCollection.parameters.client_id;
            requestParameters.client_secret = currentCollection.parameters.client_secret || '';
        }
        if (currentCollection.parameters.scopes &&
            currentCollection.parameters.scopes.length > 0) {
            let result = [];
            currentCollection.parameters.scopes.forEach(scope => {
                result.push(scope.value);
            })
            requestParameters.scope = result.join(' ');
        }
        currentCollection.parameters.token_request_extra_query_parameters?.filter(
            queryParam => queryParam.name !== '' || queryParam.value !== '')
            .forEach(queryParam => {
                requestParameters[queryParam.name] = queryParam.value
            });
        return requestParameters;
    }

    const runFlow = () => {
        runButtonRef.current.classList.add('button-loading-active', 'button-disabled');

        const environments = Environments.create(props.environments);
        const environment = environments.getEnvironment(currentCollection.provider);
        const requestParameters = resolveRequestParameters();

        props.tokenEndpointRequest(currentCollection, environment, requestParameters, true, 'TokenExchangeResponse');
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (runButtonRef.current) {
            runButtonRef.current.classList.remove('button-loading-active', 'button-disabled');
        }
    }, [currentCollection.OAuthResponses, currentCollection.OAuthResponses.TokenExchangeResponse])


    const environments = Environments.create(props.environments);
    const environment = environments.getEnvironment(currentCollection.provider);

    const enableStep2 = true // !!(selectedToken && environment && environment.canDoTokenExchange());

    const error = (!currentCollection.error) ? '' :
        <div className="alert alert-danger">
            <i className="icon ion-ios-close-outline inlineicon"/>
            {decodeUrlParameter(currentCollection.error)}
            <button className="alert-close" onClick={clearErrorFromCollection}><i className="icon ion-close"/>
            </button>
        </div>;

    const curlRequest = previewRequest();

    let accessTokensFromCollections = [];

    Object.values(props.collections)
        .filter(collection => collection.provider === currentCollection.provider)
        .forEach(collection => {
            if (flows[collection.flow].produces_tokens) {
                Object.values(collection.tokens).forEach(token => {
                    if (!isEmpty(token.value)) {
                        const ellipsis = token.value.length > 20 ? '...' : '';
                        accessTokensFromCollections.push({
                            value: token.value,
                            label: `${collection.name}: ${token.name}`,
                            tokenString: `${token.value.substring(0, 20)}${ellipsis}`,
                            meta: {
                                purpose: token.purpose,
                                name: token.name
                            }
                        });
                    }
                });
            }
        });

    return (
        <React.Fragment>
            <ResizablePanels {...props}>
                <section className="tools-form">
                    <MiddlePaneHeader
                        collection={currentCollection}
                        exportCurrentCollection={props.exportCurrentCollection}/>

                    <div className="tools-form-content">

                        <FlowHeader name={currentCollection.name}
                                    description={'Select a token from an existing collection or type a new one to ' +
                                        'exchange it by calling the token endpoint.'}/>
                        {error}

                        <StepBox title={'Settings'} step={'1'} enabled={true}>

                            <ClientCredentials
                                updateParameters={props.updateParameters}
                                updateEnvironment={props.updateEnvironment}
                                collection={currentCollection}
                                environment={environment}
                                flow={flows.token_exchange}
                            />

                            <div className="sm-flex flex-justify flex-center flex-gap-2 flex-wrap mt2">
                                <div className={'flex-auto'}>
                                    <Scopes
                                        updateParameters={props.updateParameters}
                                        environment={environment}
                                        collection={currentCollection}
                                    />
                                </div>
                                <div className="flex-auto">
                                    <ClientAuthenticationMethod
                                        updateParameters={props.updateParameters}
                                        environment={environment}
                                        endpoint={'token_endpoint_auth_method'}
                                        serverConfigFrom={'token_endpoint_auth_methods_supported'}
                                        collection={currentCollection}/>
                                </div>
                            </div>

                            <fieldset className="fieldset mt2">
                                <legend>Subject token</legend>
                                <div className="sm-flex flex-justify flex-center flex-gap-2 flex-wrap">
                                    <div className="flex-auto">
                                        <Creatable
                                            isClearable
                                            placeholder="Enter or select a token"
                                            components={makeAnimated()}
                                            options={accessTokensFromCollections}
                                            formatOptionLabel={formatOptionLabel}
                                            defaultValue={currentCollection.parameters.token_exchange_subject_token}
                                            onChange={setSubjectToken}
                                            inputId={'subject-token'}
                                            className="select-container select-container-big"
                                            classNamePrefix="react-select"
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#f2f3f6',
                                                    primary: '#626c87'
                                                }
                                            })}
                                        />
                                    </div>
                                    <div className="flex-auto">
                                        <Creatable
                                            isClearable
                                            placeholder="Select Subject token type"
                                            components={makeAnimated()}
                                            options={tokenTypeIdentifiers}
                                            defaultValue={
                                                currentCollection.parameters.token_exchange_subject_token_type}
                                            onChange={setSubjectTokenType}
                                            inputId={'subject-token-type'}
                                            className="select-container select-container-big"
                                            classNamePrefix="react-select"
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#f2f3f6',
                                                    primary: '#626c87'
                                                }
                                            })}
                                        />
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className="fieldset mt2">
                                <legend>Actor token</legend>
                                <div className="sm-flex flex-justify flex-center flex-gap-2 flex-wrap">
                                    <div className="flex-auto">
                                        <Creatable
                                            isClearable
                                            placeholder="Enter or select a token"
                                            components={makeAnimated()}
                                            options={accessTokensFromCollections}
                                            formatOptionLabel={formatOptionLabel}
                                            defaultValue={currentCollection.parameters.token_exchange_actor_token}
                                            onChange={setActorToken}
                                            inputId={'actor-token'}
                                            className="select-container select-container-big"
                                            classNamePrefix="react-select"
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#f2f3f6',
                                                    primary: '#626c87'
                                                }
                                            })}
                                        />
                                    </div>
                                    <div className="flex-auto">
                                        <Creatable
                                            isClearable
                                            placeholder="Select Actor token type"
                                            components={makeAnimated()}
                                            options={tokenTypeIdentifiers}
                                            defaultValue={currentCollection.parameters.token_exchange_actor_token_type}
                                            onChange={setActorTokenType}
                                            inputId={'actor-token-type'}
                                            className="select-container select-container-big"
                                            classNamePrefix="react-select"
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#f2f3f6',
                                                    primary: '#626c87'
                                                }
                                            })}
                                        />
                                    </div>
                                </div>
                            </fieldset>

                            <div className="flex-auto mt2">
                                <Creatable
                                    isClearable
                                    placeholder="Requested Token Type"
                                    components={makeAnimated()}
                                    options={tokenTypeIdentifiers}
                                    defaultValue={currentCollection.parameters.token_exchange_requested_token_type}
                                    onChange={setRequestedTokenType}
                                    inputId={'requested-token-type'}
                                    className="select-container select-container-big"
                                    classNamePrefix="react-select"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#f2f3f6',
                                            primary: '#626c87'
                                        }
                                    })}
                                />
                            </div>

                            <div className="sm-flex flex-justify flex-center flex-gap-2 flex-wrap mt2">
                                <div className="flex-auto">
                                    <input className="field col-12" type="text" min="0" placeholder="Resource"
                                           value={currentCollection.parameters.token_exchange_resource}
                                           onChange={setResource}
                                           autoComplete="off"
                                           data-lpignore="true"
                                           spellCheck="false"
                                    />
                                </div>
                                <div className="flex-auto">
                                    <input className="field col-12" type="text" min="0" placeholder="Audience"
                                           value={currentCollection.parameters.token_exchange_audience}
                                           onChange={setAudience}
                                           autoComplete="off"
                                           data-lpignore="true"
                                           spellCheck="false"
                                    />
                                </div>
                            </div>


                            <IntrospectionSettings
                                collection={currentCollection}
                                environment={environment}
                                updateEnvironment={props.updateEnvironment}
                                updateParameters={props.updateParameters}
                            />
                        </StepBox>

                        <StepBox title={'Call Token Endpoint'} step={'2'} enabled={enableStep2}>
                            <div className={'flex justify-end'}>
                                <ExtraQueryParametersModal
                                    updateParameters={props.updateParameters}
                                    collection={props.collection}
                                    parameter={'token_request_extra_query_parameters'}
                                />
                            </div>
                            <CurlRequestPreview request={curlRequest}/>
                            <div className="mt2 center">
                                <button ref={runButtonRef}
                                        onClick={runFlow}
                                        className="button button-medium button-success button-run button-loading">
                                    <span><i className="icon ion-play inlineicon"/>Exchange Token</span>
                                </button>
                            </div>
                        </StepBox>

                        <ServerResponse response={currentCollection.OAuthResponses.TokenExchangeResponse}/>

                    </div>
                </section>

                <ReceivedTokensSidebar
                    guide={guides.token_exchange}
                    flow={flows.token_exchange}
                    collection={currentCollection}
                    environment={environment}
                    groups={props.groups}
                    clearOAuthResponses={props.clearOAuthResponses}
                    setTokensOnCollection={props.setTokensOnCollection}
                    introspectToken={props.introspectToken}
                    createAndSelectCollectionWithToken={props.createAndSelectCollectionWithToken}
                />
            </ResizablePanels>
        </React.Fragment>
    );

}

export default TokenExchangeFlow

/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React, { useEffect } from 'react';
import EmptyStateIcon from './icons/EmptyStateIcon';


function NoSelectionMiddlePane(props) {
  useEffect(() => {
    const historySidebar = getComputedStyle(document.documentElement)
      .getPropertyValue('--history-sidebar-width')
      .slice(0, -1);

    const emptyStateWidth = String(parseInt(100 - historySidebar));
    document.documentElement.style.setProperty(
      '--main-area-width',
      `${emptyStateWidth}%`
    );
  });

  return (
    <div className="split">
      <div style={{ width: '100%' }}>
        <section className="tools-form">
          <div className="empty-state p2 lg-p4">
            <div className="empty-state-icon">
              <EmptyStateIcon />
            </div>
            <h3 className="mt0 center">No flow is selected </h3>
            <p>Select an existing flow or start a new.</p>
            <button
              className="button button-success button-small"
              onClick={props.showNewFlowModal}
            >
              Start Flow
            </button>
          </div>
        </section>
      </div>
    </div>
  );
}

export default NoSelectionMiddlePane;

/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React, { useEffect, useRef, useState } from 'react';
import { decodeUrlParameter, generateKeyPair, notEmpty, removeLeadingZeros } from '../../util/util';
import {
    flows, formatOptionLabel, tokenPurposes
} from '../../util/appConstants';
import FlowHeader from './FlowHeader';
import CurlRequestPreview from './CurlRequestPreview';
import makeAnimated from 'react-select/animated';
import Creatable from 'react-select/creatable'
import { isEmpty } from '../../util/validationUtils';
import MiddlePaneHeader from './MiddlePaneHeader';
import ResizablePanels from '../ResizablePanels';
import Select from 'react-select';
import Environments from '../../data/Environments';
import StepBox from './StepBox';
import ClientId from './settings/ClientId';
import AlgorithmSelector from './settings/AlgorithmSelector';
import JSONInput from '../token/JSONInput';
import jose from 'node-jose';
import ServerResponse from '../shared/ServerResponse';
import ReceivedTokensSidebar from '../token/ReceivedTokensSidebar';
import RunButton from './RunButton';
import PrettyJson from '../validation/PrettyJson';
import Triplet from '../Triplet';
import CopyToClipboard from '../CopyToClipboard';
import { Tabs } from '../Tabs/Tabs';
import { Tab } from '../Tabs/Tab';
import { Checkbox } from '../CheckBox'

const VCIFlow = (props) => {
    const VC_SD_JWT = 'vc+sd-jwt';
    const [requestPreview, setRequestPreview] = useState(null);
    const [requestObject, setRequestObject] = useState(null);
    const [proof, setProof] = useState(null);
    const generateRef = useRef(0)

    const clearErrorFromCollection = () => {
        props.setErrorOnCollection(props.collection.id, null)
    };

    const setCNonceForVCI = (newValue, actionMeta) => {
        if (actionMeta.action === 'select-option' || actionMeta.action === 'create-option') {
            const updatedParameters = props.collection.parameters.withUpdatedValue('vci_c_nonce', newValue.value);
            props.updateParameters(props.collection.id, updatedParameters);
        }
        if (actionMeta.action === 'clear') {
            const updatedParameters = props.collection.parameters.withUpdatedValue('vci_c_nonce', undefined);
            props.updateParameters(props.collection.id, updatedParameters);
        }
    }

    const setTokenForVCI = (newValue, actionMeta) => {
        if (actionMeta.action === 'select-option' || actionMeta.action === 'create-option') {
            const updatedParameters = props.collection.parameters.withUpdatedValue('vci_token', newValue.value);
            props.updateParameters(props.collection.id, updatedParameters);
        }

        if (actionMeta.action === 'clear') {
            const updatedParameters = props.collection.parameters.withUpdatedValue('vci_token', undefined);
            props.updateParameters(props.collection.id, updatedParameters);
        }
    };

    const updateSelectedFormat = (newValue) => {
        const updatedParameters = props.collection.parameters.withUpdatedValue('vci_format', newValue ? newValue.value : null);
        props.updateParameters(props.collection.id, updatedParameters);
    }

    const updateSelectedType = (selectedOption) => {
        const updatedParameters =
            props.collection.parameters.withUpdatedValue('vci_types', selectedOption);
        props.updateParameters(props.collection.id, updatedParameters);
    }

    const saveAlgorithm = (alg) => {
        const updatedParameters = props.collection.parameters.withUpdatedValue('vci_algorithm', alg);
        props.updateParameters(props.collection.id, updatedParameters);
    };

    const updateKid = (event) => {
        const updatedParameters =
            props.collection.parameters.withUpdatedValue('vci_kid', event.currentTarget.value);
        props.updateParameters(props.collection.id, updatedParameters);
    }

    const updateVct = (selectedOption) => {
        const updatedParameters =
            props.collection.parameters.withUpdatedValue('vci_vct', selectedOption ? selectedOption : null);
        props.updateParameters(props.collection.id, updatedParameters);
    }

    const updateX5c = (event) => {
        const updatedParameters =
            props.collection.parameters.withUpdatedValue('vci_x5c', event.currentTarget.value);
        props.updateParameters(props.collection.id, updatedParameters);
    }

    const handleToggleProof = (event) => {
        const updatedParameters = props.collection.parameters.withUpdatedValue('vci_proof', event.currentTarget.checked);
        props.updateParameters(props.collection.id, updatedParameters);
    }

    const handleToggleCredentialSubject = (event) => {
        const updatedParameters = props.collection.parameters.withUpdatedValue('vci_credential_subject_toggle', event.currentTarget.checked);
        props.updateParameters(props.collection.id, updatedParameters);
    }

    const previewProof = async () => {
        const workspaces = Environments.create(props.environments);
        const currentWorkspace = workspaces.getEnvironment(props.collection.provider);

        let calculatedProof = null;
        if (props.collection.parameters.vci_proof &&
            props.collection.parameters.vci_c_nonce &&
            props.collection.parameters.vci_private_key &&
            ((props.collection.parameters.vci_kid_in_header && notEmpty(props.collection.parameters.vci_kid)) ||
                (props.collection.parameters.vci_x5c_in_header && notEmpty(props.collection.parameters.vci_x5c)) ||
                (props.collection.parameters.vci_x5t_in_header && props.collection.parameters.vci_public_key))
        ) {
            const body = {
                aud: currentWorkspace.credential_issuer,
                iat: Math.floor(new Date().getTime() / 1000),
                nonce: props.collection.parameters.vci_c_nonce
            }
            const header = {
                typ: 'openid4vci-proof+jwt',
                alg: props.collection.parameters.vci_algorithm
            };

            if (props.collection.parameters.vci_kid_in_header && notEmpty(props.collection.parameters.vci_kid)) {
                header.kid = props.collection.parameters.vci_kid
            }

            if (props.collection.parameters.vci_x5c_in_header && notEmpty(props.collection.parameters.vci_x5c)) {
                header.kid = props.collection.parameters.vci_x5c
            }

            if (props.collection.parameters.vci_x5t_in_header && props.collection.parameters.vci_public_key) {
                header.jwk = props.collection.parameters.vci_public_key
            }

            if (props.collection.parameters.client_id) {
                body.iss = props.collection.parameters.client_id
            }

            let keystore = jose.JWK.createKeyStore();

            const normalizedKey = removeLeadingZeros(props.collection.parameters.vci_private_key)
            const key = await keystore.add(normalizedKey, 'jwk');
            const opt = { compact: true, jwk: key, fields: header };

            const jwt = await jose.JWS.createSign(opt, { key, reference: false })
                .update(JSON.stringify(body)).final();

            calculatedProof = {
                proof_type: 'jwt',
                jwt
            }
            setProof({ header, body })
            return calculatedProof
        }
        setProof(null)
        return null;
    }

    const requestObjectC = async () => {
        const workspaces = Environments.create(props.environments);
        const currentWorkspace = workspaces.getEnvironment(props.collection.provider);
        const proofPreview = await previewProof().catch(console.log);
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + props.collection.parameters.vci_token
        }
        const body = {
            format: props.collection.parameters.vci_format
        }
        if (props.collection.parameters.vci_format === VC_SD_JWT) {
            body.vct = props.collection.parameters.vci_vct ? props.collection.parameters.vci_vct.value : null;
        } else {
            body.credential_definition = {}
            body.credential_definition.type = props.collection.parameters.vci_types ?
                props.collection.parameters.vci_types.map(type => type.value) : null
        }

        if (proofPreview) {
            body.proof = proofPreview;
        }
        if (props.collection.parameters.vci_credential_subject_toggle
            && notEmpty(props.collection.parameters.vci_credential_subject)) {
            body.credentialSubject = props.collection.parameters.vci_credential_subject
        }

        return { body, headers, url: currentWorkspace.credential_endpoint }

    }


    const curlPreview = () => {
        if (requestObject &&
            props.collection.parameters.vci_token && props.collection.parameters.vci_format) {
            const request_body = ' \\\n--data-raw \'' + JSON.stringify(requestObject.body) + '\''
            let headers = ''
            Object.keys(requestObject.headers).forEach(key => {
                headers += ` \\\n-H \' ${key}: ${requestObject.headers[key]}\'`
            })
            return 'curl -Ss -X POST \\\n' + requestObject.url + headers + request_body
        }
    };

    const generateKeyPairForJwt = async () => {
        const alg = props.collection.parameters.vci_algorithm;

        if (alg) {
            const [publicKey, privateKey] = await generateKeyPair(alg);

            const updatedParameters =
                props.collection.parameters
                    .withUpdatedValue('vci_private_key', privateKey)
                    .withUpdatedValue('vci_public_key', publicKey);
            props.updateParameters(props.collection.id, updatedParameters);
        }
    };

    const runFlow = () => {
        props.issueCredentialsRequest(currentCollection, currentWorkspace, requestObject);
    };

    const generateKeyPairVci = (event) => {
        if (props.collection.parameters.vci_algorithm) {
            event.currentTarget.classList.add('button-loading-active', 'button-disabled');
            event.currentTarget.blur();
            generateKeyPairForJwt();
        }
    };

    const toggleTriplet = (event) => {
        const updatedParameters = props.collection.parameters
            .withUpdatedValue(event.currentTarget.getAttribute('data-name'), event.currentTarget.checked);
        props.updateParameters(props.collection.id, updatedParameters);
    }

    const currentCollection = props.collection;
    const workspaces = Environments.create(props.environments);
    const currentWorkspace = workspaces.getEnvironment(currentCollection.provider);

    const error = (!currentCollection.error) ? '' : <div className="alert alert-danger mb2">
        <i className="icon ion-ios-close-outline inlineicon"/>
        {decodeUrlParameter(currentCollection.error)}
        <button className="alert-close" onClick={clearErrorFromCollection}><i className="icon ion-close"/>
        </button>
    </div>;

    const accessTokensFromCollections = [];
    const cNonceFromCollections = [];
    Object.values(props.collections)
        .filter(collection => collection.provider === currentCollection.provider)
        .forEach(collection => {
            if (flows[collection.flow].produces_tokens) {
                Object.values(collection.tokens).forEach(token => {
                    if (!isEmpty(token.value) && token.purpose !== tokenPurposes.id_token.value
                        && token.purpose !== tokenPurposes.refresh_token.value) {
                        const ellipsis = token.value.length > 20 ? '...' : '';
                        accessTokensFromCollections.push({
                            value: token.value,
                            label: `${collection.name}: ${token.name}`,
                            tokenString: `${token.value.substring(0, 20)}${ellipsis}`,
                            meta: {
                                purpose: token.purpose, name: token.name
                            }
                        });
                    }
                });
            }
            if (collection.parameters.c_nonce) {
                cNonceFromCollections.push({
                    value: collection.parameters.c_nonce,
                    label: `${collection.name}: `,
                    tokenString: collection.parameters.c_nonce,
                    meta: {
                        name: collection.parameters.c_nonce
                    }
                });
            }
        });

    let selectedAccessToken = null;
    if (currentCollection.parameters.vci_token) {
        let maybeSelectedToken = accessTokensFromCollections.filter(
            option => option.value === currentCollection.parameters.vci_token);
        if (maybeSelectedToken.length === 0) {
            maybeSelectedToken = {
                value: currentCollection.parameters.vci_token,
                label: currentCollection.parameters.vci_token
            }
            accessTokensFromCollections.push(maybeSelectedToken);
        }
        selectedAccessToken = maybeSelectedToken;
    }


    const selectedCNonce = currentCollection.parameters.vci_c_nonce;
    let selectedCNonceOption = null;
    if (selectedCNonce) {
        let maybeCNonceOption = cNonceFromCollections.filter(option => option.value === selectedCNonce);
        if (maybeCNonceOption.length === 0) {
            maybeCNonceOption = {
                value: selectedCNonce, label: selectedCNonce, meta: {
                    name: selectedCNonce
                }
            }
            cNonceFromCollections.push(maybeCNonceOption);
        }
        selectedCNonceOption = maybeCNonceOption;
    }

    const formatOptions = [];
    const formatOptionsSet = new Set();
    Object.values(currentWorkspace.credentials_supported).forEach(credential => {
        formatOptionsSet.add(credential.format);
    })
    formatOptionsSet.forEach(format => {
        formatOptions.push({
            value: format, label: format
        })
    })
    const selectedVciFormat = formatOptions.filter(option => option.value === currentCollection.parameters.vci_format);

    const typeOptions = [];
    const typeOptionsSet = new Set();
    Object.values(currentWorkspace.credentials_supported).forEach(credential => {
        if (credential.credential_definition && credential.credential_definition.type) {
            credential.credential_definition.type.forEach(type => {
                typeOptionsSet.add(type);
            })
        }
    })

    typeOptionsSet.forEach(format => {
        typeOptions.push({
            value: format, label: format
        })
    })

    const vctOptions = [];
    const vctOptionsSet = new Set();
    Object.values(currentWorkspace.credentials_supported).forEach(credential => {
        if (credential.vct) {
            vctOptionsSet.add(credential.vct);
        }
    })

    vctOptionsSet.forEach(format => {
        vctOptions.push({
            value: format, label: format
        })
    })


    const configuredSdJwt = props.collection.parameters.vci_format === VC_SD_JWT && currentCollection.parameters.vci_vct;
    const configuredTypes = props.collection.parameters.vci_format !== VC_SD_JWT && currentCollection.parameters.vci_types
        && currentCollection.parameters.vci_types.length > 0 && notEmpty(currentCollection.parameters.vci_format)

    const enableStep4 = notEmpty(currentCollection.parameters.vci_token) &&
        (configuredSdJwt || configuredTypes) &&
        (!currentCollection.parameters.vci_credential_subject_toggle
            || notEmpty(currentCollection.parameters.vci_credential_subject)) &&
        (!currentCollection.parameters.vci_proof ||
            (!!requestObject && notEmpty(requestObject.body.proof && notEmpty(requestObject.body.proof.jwt))));

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        setRequestPreview(curlPreview(requestObject))
    }, [requestObject])

    useEffect(() => {
        requestObjectC().then(request => {
            setRequestObject(request);
        })
    }, [props.collection.parameters])

    useEffect(() => {
        if (generateRef) {
            setTimeout(() => {
                if (generateRef.current && generateRef.current.classList) {
                    generateRef.current.classList.remove('button-loading-active', 'button-disabled');
                }
            }, 300)
        }
    }, [currentCollection.parameters.parameters.vci_public_key,
        currentCollection.parameters.parameters.vci_private_key]);

    return (<>
        <ResizablePanels {...props}>
            <section className="tools-form">
                <MiddlePaneHeader
                    collection={props.collection}
                    exportCurrentCollection={props.exportCurrentCollection}/>
                <div className="tools-form-content">

                    <FlowHeader name={currentCollection.name}
                                description={'Build a Verifiable Credentials Issuance request.'}/>
                    {error}


                    <StepBox title={'Settings'} step={'1'} enabled={true}>


                        <div className="lg-flex flex-justify flex-center flex-wrap flex-gap-2 mt2">
                            <div className="flex-auto">
                                <Creatable
                                    isClearable
                                    placeholder="Type a token or select one from a Collection"
                                    components={makeAnimated()}
                                    inputId={'select-token-vci'}
                                    options={accessTokensFromCollections}
                                    formatOptionLabel={formatOptionLabel}
                                    defaultValue={selectedAccessToken}
                                    onChange={setTokenForVCI}
                                    className="select-container select-container-big"
                                    classNamePrefix="react-select"
                                    theme={(theme) => ({
                                        ...theme, borderRadius: 0, colors: {
                                            ...theme.colors, primary25: '#f2f3f6', primary: '#626c87'
                                        }
                                    })}
                                />
                            </div>
                        </div>

                        <div className="lg-flex flex-justify flex-center flex-wrap flex-gap-2 mt2">
                            <div className="flex-auto">
                                <Select
                                    isClearable
                                    value={selectedVciFormat}
                                    onChange={updateSelectedFormat}
                                    options={formatOptions}
                                    placeholder="Select Format..."
                                    className="select-container"
                                    classNamePrefix="react-select"
                                    id="formatSelector"
                                    theme={(theme) => ({
                                        ...theme, borderRadius: 0, colors: {
                                            ...theme.colors, primary25: '#f2f3f6', primary: '#626c87'
                                        }
                                    })}
                                />
                            </div>
                        </div>

                        {currentCollection.parameters.vci_format !== VC_SD_JWT &&
                            <div className="lg-flex flex-justify flex-center flex-wrap flex-gap-2 mt2">
                                <div className="flex-auto">
                                    <Select
                                        isMulti
                                        isClearable
                                        value={currentCollection.parameters.vci_types}
                                        onChange={updateSelectedType}
                                        options={typeOptions}
                                        placeholder="Select Types..."
                                        className="select-container"
                                        classNamePrefix="react-select"
                                        id="formatSelector"
                                        theme={(theme) => ({
                                            ...theme, borderRadius: 0, colors: {
                                                ...theme.colors, primary25: '#f2f3f6', primary: '#626c87'
                                            }
                                        })}
                                    />
                                </div>
                            </div>}

                        {currentCollection.parameters.vci_format === VC_SD_JWT &&
                            <div className="lg-flex flex-justify flex-center flex-wrap flex-gap-2 mt2">
                                <div className="flex-auto">
                                    <Select
                                        isClearable
                                        value={currentCollection.parameters.vci_vct}
                                        onChange={updateVct}
                                        options={vctOptions}
                                        placeholder="Select VCT..."
                                        className="select-container"
                                        classNamePrefix="react-select"
                                        id="formatSelector"
                                        theme={(theme) => ({
                                            ...theme, borderRadius: 0, colors: {
                                                ...theme.colors, primary25: '#f2f3f6', primary: '#626c87'
                                            }
                                        })}
                                    />
                                </div>
                            </div>}
                    </StepBox>

                    <StepBox title={'Proof'} step={'2'}
                             enabled={!!currentCollection.parameters.vci_proof} toggle={true}
                             handleToggle={handleToggleProof}>

                        <div className="lg-flex flex-justify flex-center flex-wrap flex-gap-2 mt2">
                            <div className="flex-auto">
                                <Creatable
                                    isClearable
                                    placeholder="Type a c_nonce or select one from a Collection"
                                    components={makeAnimated()}
                                    inputId={'select-c-nonce-vci'}
                                    options={cNonceFromCollections}
                                    formatOptionLabel={formatOptionLabel}
                                    defaultValue={selectedCNonceOption}
                                    onChange={setCNonceForVCI}
                                    className="select-container select-container-big"
                                    classNamePrefix="react-select"
                                    theme={(theme) => ({
                                        ...theme, borderRadius: 0, colors: {
                                            ...theme.colors, primary25: '#f2f3f6', primary: '#626c87'
                                        }
                                    })}
                                />
                            </div>
                        </div>

                        <div className="lg-flex flex-justify flex-center flex-wrap flex-gap-2 mt2">
                            <div className="flex-auto">
                                <ClientId
                                    updateParameters={props.updateParameters}
                                    collection={currentCollection}
                                    environment={currentWorkspace}
                                    flow={flows[currentCollection.flow]}
                                />
                            </div>
                        </div>

                        <fieldset className="label block mt2">
                            <legend>Key Settings</legend>

                            <Triplet
                                collection={currentCollection}
                                updateParameters={props.updateParameters}
                                toggleTriplet={toggleTriplet}
                                params={[
                                    { label: 'Key ID', name: 'vci_kid_in_header' },
                                    { label: 'JWK', name: 'vci_x5t_in_header' },
                                    { label: 'x5c', name: 'vci_x5c_in_header' }]}>
                                <div className="mt2">
                                    <label htmlFor="id" className="label block">Key ID</label>
                                    <div className="flex-auto">
                                        <input className="field col-12"
                                               type="text"
                                               id="kid"
                                               placeholder="Enter Key ID"
                                               defaultValue={currentCollection.parameters.vci_kid}
                                               onChange={updateKid}
                                               autoComplete="off"
                                               data-lpignore="true"
                                               spellCheck="false"
                                        />
                                    </div>
                                </div>
                                <div></div>
                                <div className="tools-sidebar-box-content-area flex flex-justify flex-column mt2">
                                    <div className="flex-auto">
                                        <div
                                            className="tools-sidebar-box-content-area-header flex flex-center justify-between">
                                            <label className="label label-key">
                                                X5C
                                                <CopyToClipboard text={currentCollection.parameters.vci_x5c}/>
                                            </label>
                                        </div>
                                        <textarea rows={8} spellCheck="false"
                                                  name="signature-upload" className="tools-input tools-input-signature"
                                                  value={currentCollection.parameters.vci_x5c}
                                                  onChange={updateX5c}
                                                  placeholder="Enter a x5c"
                                        />
                                    </div>
                                </div>
                            </Triplet>

                        </fieldset>

                        <div className="mt2">
                            <label className="label block">Algorithm</label>
                            <AlgorithmSelector saveAlgorithm={saveAlgorithm}
                                               excludeSymmetric={true}
                                               selectedAlgorithm={currentCollection.parameters.vci_algorithm}/>
                        </div>


                        <div className="mt2">
                            <div className="flex justify-between flex-center mt2">
                                <label className="label block">Keys</label>

                                <button
                                    ref={generateRef}
                                    className="button-small button-primary-outline button-tiny mr1"
                                    disabled={!currentCollection.parameters.vci_algorithm}
                                    onClick={generateKeyPairVci}
                                ><span>Generate keys</span>
                                </button>
                            </div>

                            <Tabs>
                                <Tab label="Public Key">
                                    <JSONInput
                                        label="Public key"
                                        name={'vci_public_key'}
                                        collection={currentCollection}
                                        environment={currentWorkspace}
                                        updateParameters={props.updateParameters}
                                    />
                                </Tab>
                                <Tab label="Private Key">
                                    <JSONInput
                                        label="Private key"
                                        name={'vci_private_key'}
                                        collection={currentCollection}
                                        environment={currentWorkspace}
                                        updateParameters={props.updateParameters}
                                    />
                                </Tab>
                            </Tabs>
                        </div>

                        {proof &&
                            <Checkbox classes="mt2" title="Inspect proof">
                                <PrettyJson json={proof.header}/>
                                <PrettyJson json={proof.body}/>
                            </Checkbox>}
                    </StepBox>

                    <StepBox title={'Credential Subject'} step={'3'}
                             enabled={!!currentCollection.parameters.vci_credential_subject_toggle} toggle={true}
                             handleToggle={handleToggleCredentialSubject}>
                        <div className={'mb1'}></div>
                        <JSONInput
                            label="Credential Subject"
                            name={'vci_credential_subject'}
                            collection={currentCollection}
                            environment={currentWorkspace}
                            updateParameters={props.updateParameters}
                        />

                    </StepBox>

                    <StepBox title={'Request'} step={'4'} enabled={enableStep4}>
                        {requestPreview && <CurlRequestPreview request={requestPreview}/>}
                        <RunButton runFlow={runFlow}/>
                    </StepBox>

                    <ServerResponse response={currentCollection.OAuthResponses.VerifiableCredentialIssuanceResponse}/>

                </div>
            </section>

            <ReceivedTokensSidebar
                // TODO add guide
                // guide={guides.vci}
                flow={flows.vci}
                collection={currentCollection}
                environment={currentWorkspace}
                groups={props.groups}
                clearOAuthResponses={props.clearOAuthResponses}
                setTokensOnCollection={props.setTokensOnCollection}
                introspectToken={props.introspectToken}
                createAndSelectCollectionWithToken={props.createAndSelectCollectionWithToken}
            />
        </ResizablePanels>
    </>);

}

export default VCIFlow

/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React, { useState } from 'react';

import SaveIcon from './icons/SaveIcon';

const SaveDynamicClient = (props) => {
    const [copyMessage, setCopyMessage] = useState('Save to Workspace')
    const [copySuccess, setCopySuccess] = useState(false)

    const saveToWorkspace = () => {
        props.saveClientInWorkspace()

        setCopySuccess(true)
        setCopyMessage('Saved to Workspace!')

        setTimeout(() => {
            setCopySuccess(false)
            setCopyMessage('Save to Workspace')
        }, 750)

    };

    return (
            <button onClick={saveToWorkspace} type="button" aria-label="Save"
                    className={`button button-tiny button-link ${(props.cssClass ? props.cssClass : '')}`}
                    data-tooltip={copyMessage}
                    data-tool-tip-copied={copySuccess}>
                <SaveIcon/>
            </button>)

}
export default SaveDynamicClient;

/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import * as React from 'react';
import Environments from '../../data/Environments';
import {
    decodeUrlParameter, mergeObjects
} from '../../util/util';
import {
    DCRM_REQUEST_METHODS,
    formatOptionLabel,
    guides
} from '../../util/appConstants';
import FlowHeader from './FlowHeader';
import StepBox from './StepBox';
import CurlRequestPreview from './CurlRequestPreview';
import makeAnimated from 'react-select/animated';
import Creatable from 'react-select/creatable'
import MiddlePaneHeader from './MiddlePaneHeader';
import ServerResponse from '../shared/ServerResponse';
import ResizablePanels from '../ResizablePanels';
import { useEffect, useRef, useState } from 'react';
import RunButton from './RunButton';
import ClearTokens from '../ClearTokens';
import Guide from '../guides/Guide';
import EmptySidebar from '../EmptySidebar';
import DynamicClientResponse from '../sidebar/response/DynamicClientResponse';
import Select from 'react-select';
import JSONInput from '../token/JSONInput';

const DCRMFlow = (props) => {
    const [guideIsVisible, setGuideIsVisible] = useState(false)
    const runButtonRef = useRef();
    const currentCollection = props.collection;
    const parameters = currentCollection.parameters;
    const workspaces = Environments.create(props.environments);
    const currentWorkspace = workspaces.getEnvironment(currentCollection.provider);
    const response = currentCollection.response;
    const selectedClientMapId = currentWorkspace.clientMapId(parameters.dcrm_client_id);
    const selectedClientObject = currentWorkspace.clients[selectedClientMapId];
    const maybeRegistrationAccessToken = selectedClientObject && selectedClientObject.registration_access_token ?
            selectedClientObject.registration_access_token : null;

    const clearErrorFromCollection = () => {
        props.setErrorOnCollection(currentCollection.id, null)
    };

    const runFlow = () => {
        clearErrorFromCollection();
        props.runDCRMRequest(currentWorkspace.id, currentCollection);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (runButtonRef.current) {
            runButtonRef.current.classList.remove('button-loading-active', 'button-disabled');
        }
    }, [currentCollection.OAuthResponses, currentCollection.OAuthResponses.DynamicClientManagementResponse])


    const enableStep2 = !!(currentWorkspace && currentWorkspace.canDoDynamicClientRegistration()
        && selectedClientMapId
        && parameters.dcrm_request_method && parameters.dcrm_client_id && (
            parameters.dcrm_request_method?.value !== 'PUT' || parameters.dcrm_request_body
        ));

    const error = (!currentCollection.error) ? '' :
        <div className="alert alert-danger flex">
            <div className="flex-auto">
                <i className="icon ion-ios-close-outline inlineicon"/>
                {decodeUrlParameter(currentCollection.error)}
            </div>
            <button className="alert-close flex-end" onClick={clearErrorFromCollection}><i className="icon ion-close"/>
            </button>
        </div>;

    const clearResponse = () => {
        props.clearResponseInCollection(currentCollection.id);
        props.clearOAuthResponses(currentCollection.id)
        clearErrorFromCollection();
    };

    const updateAccessToken = (option) => {
        const value = option ? option.value : null;

        const updatedParameters = currentCollection.parameters.withUpdatedValue('dcrm_token',
            value);

        props.updateParameters(currentCollection.id, updatedParameters);
    };

    const updateSelectedClient = (option) => {
        const value = option ? option.value : '';

        const updatedParameters = currentCollection.parameters.withUpdatedValue('dcrm_client_id', value);
        props.updateParameters(currentCollection.id, updatedParameters);
    };


    const constructDCRMRequest = () => {
        if (!selectedClientObject) {
            return '';
        }

        const body = parameters.dcrm_request_method?.value === 'PUT' ?
            ' \\\n-H \'Content-Type: application/json\' \\\n' +
            '--data-raw \'' + JSON.stringify(parameters.dcrm_request_body) + '\'' : ''

        return 'curl -Ss -X ' + parameters.dcrm_request_method?.value + ' \\\n' +
            selectedClientObject.registration_client_uri + ' \\\n' +
            dcrmAuthorizationHeader() + body
    };

    const dcrmAuthorizationHeader = () => {
        const token = parameters.dcrm_token ? parameters.dcrm_token
            : maybeRegistrationAccessToken
        return '-H \'Authorization: Bearer ' + token + '\'';
    };

    const sanitizeClientObject = (client) => {
        const sanitized = { ...client };
        delete sanitized.registration_access_token;
        delete sanitized.registration_client_uri;
        delete sanitized.client_secret_expires_at;
        delete sanitized.client_id_issued_at;
        delete sanitized.can_do_code_flow;
        delete sanitized.can_do_introspect;
        delete sanitized.can_do_device_flow;
        delete sanitized.can_do_ciba_flow;
        delete sanitized.can_do_ropc_flow;
        delete sanitized.can_do_implicit_flow;
        delete sanitized.can_do_hybrid_flow;
        delete sanitized.can_do_client_credentials_flow;
        delete sanitized.can_do_revoke_flow;
        delete sanitized.can_do_refresh_flow;
        delete sanitized.can_be_framed;
        return sanitized
    }

    const loadClientDataFromWorkspace = () => {
        if (selectedClientObject) {
            const updatedParameters = parameters.withUpdatedValue('dcrm_request_body', sanitizeClientObject(selectedClientObject));
            props.updateParameters(currentCollection.id, updatedParameters);
        }
    }

    const updateParametersOverrideForBody = (collectionId, inputParameters) => {
        const updatedParameters = parameters.withUpdatedValue('dcrm_request_body', sanitizeClientObject(inputParameters.dcrm_request_body));
        props.updateParameters(currentCollection.id, updatedParameters);
    }

    const saveClientInWorkspace = () => {
        const updatedWorkspace = currentWorkspace.withClient(selectedClientMapId,
            mergeObjects(selectedClientObject,{ ...response }));
        props.updateEnvironment(updatedWorkspace)
    }

    let initialAccessTokenOptions = [];
    if (selectedClientObject && maybeRegistrationAccessToken) {
        initialAccessTokenOptions.push({
            value: maybeRegistrationAccessToken,
            label: `Registration Token: ${maybeRegistrationAccessToken}`
        });
    }
    Object.values(props.collections)
        .filter(collection => collection.provider === currentWorkspace.id)
        .forEach(collection => {
            Object.values(collection.tokens).forEach(token => {
                if (token.purpose === 'access_token') {
                    const ellipsis = token.value.length > 20 ? '...' : '';
                    initialAccessTokenOptions.push({
                        value: token.value,
                        label: `${collection.name}: ${token.name}`,
                        tokenString: `${token.value.substring(0, 20)}${ellipsis}`
                    });
                }
            });
        });

    const selectedRequestMethod = parameters.dcrm_request_method;
    const setRequestMethod = (selectedOption) => {
        const updatedParameters =
            parameters.withUpdatedValue('dcrm_request_method', selectedOption);
        props.updateParameters(currentCollection.id, updatedParameters);
    };

    const selectedClient = parameters.dcrm_client_id ? {
        label: parameters.dcrm_client_id,
        value: parameters.dcrm_client_id
    } : undefined;
    let clientOptions = [];
    Object.values(currentWorkspace.clients)
        .filter(client => client.registration_client_uri)
        .forEach(client => {
            clientOptions.push({
                value: client.client_id,
                label: client.client_id
            })
        });

    const initialAccessToken = parameters.dcrm_token ?
        { value: parameters.dcrm_token, label: parameters.dcrm_token } :
        { value: maybeRegistrationAccessToken, label: maybeRegistrationAccessToken };

    return (
        <React.Fragment>
            <ResizablePanels {...props}>
                <section className="tools-form">
                    <MiddlePaneHeader
                        collection={currentCollection}
                        exportCurrentCollection={props.exportCurrentCollection}/>

                    <div className="tools-form-content">

                        <FlowHeader name={currentCollection.name}
                                    description={'Perform a Dynamic Client Registration Management request'}/>
                        {error}

                        <StepBox title={'Settings'} step={'1'} enabled={true}>
                            <div className="sm-flex flex-justify flex-center flex-gap-2 flex-wrap mt2">
                                <div className={'flex-auto'}>
                                    <Creatable
                                        isClearable
                                        placeholder="Select a client from the Workspace"
                                        components={makeAnimated()}
                                        options={clientOptions}
                                        onChange={updateSelectedClient}
                                        defaultValue={selectedClient}
                                        className="select-container select-container-big"
                                        classNamePrefix="react-select"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#f2f3f6',
                                                primary: '#626c87'
                                            }
                                        })}
                                    />
                                </div>
                            </div>
                            <div className="sm-flex flex-justify flex-center flex-gap-2 flex-wrap mt2">
                                <div className="flex-auto">
                                    <Select
                                        isMulti={false}
                                        isClearable={true}
                                        inputProps={{ id: 'select-method' }}
                                        placeholder={'Select a request method'}
                                        components={makeAnimated()}
                                        options={DCRM_REQUEST_METHODS}
                                        value={selectedRequestMethod}
                                        onChange={setRequestMethod}
                                        className="select-container select-container-big"
                                        classNamePrefix="react-select"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#f2f3f6',
                                                primary: '#626c87'
                                            }
                                        })}
                                    />
                                </div>
                                <div className={'flex-auto'}>
                                    <Creatable
                                        isClearable
                                        placeholder="Select token from a Collection"
                                        components={makeAnimated()}
                                        options={initialAccessTokenOptions}
                                        formatOptionLabel={formatOptionLabel}
                                        onChange={updateAccessToken}
                                        defaultValue={initialAccessToken}
                                        className="select-container select-container-big"
                                        classNamePrefix="react-select"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#f2f3f6',
                                                primary: '#626c87'
                                            }
                                        })}
                                    />
                                </div>
                            </div>

                            {parameters.dcrm_request_method?.value === 'PUT' &&
                                <div className="mt2">
                                    <JSONInput
                                    label="Value for request_body"
                                    name={'dcrm_request_body'}
                                    collection={props.collection}
                                    updateParameters={updateParametersOverrideForBody}
                                    loadData={selectedClientObject ? loadClientDataFromWorkspace : null}
                                />
                                </div>
                            }


                        </StepBox>

                        <StepBox title={'Call Dynamic Client Management Endpoint'} step={'2'} enabled={enableStep2}>

                            <CurlRequestPreview request={constructDCRMRequest()}/>
                            <RunButton runFlow={runFlow} buttonText={'Manage Dynamic Client'}/>
                        </StepBox>

                        <ServerResponse response={currentCollection.OAuthResponses.DynamicClientManagementResponse}/>

                    </div>
                </section>

                {response ?
                    <aside className="tools-sidebar dcr-response">
                        <header className="tools-form-header tools-form-header-tokens">
                            <h4 className="m0"/>
                            <div className="flex justify-between flex-center">
                                <ClearTokens clearTokens={clearResponse}
                                             showClearButton={response}/>
                            </div>
                        </header>
                        <Guide area={guides.dcrm} toggle={() => setGuideIsVisible(!guideIsVisible)}/>
                        {!guideIsVisible && <main role="contentinfo">
                            <DynamicClientResponse responseBody={response}
                                                   saveClientInWorkspace={saveClientInWorkspace}/>
                        </main>}
                    </aside>
                    :
                    <aside className="tools-sidebar">
                        <EmptySidebar
                            guide={guides.dcrm}
                            clearTokens={clearResponse}
                            collection={currentCollection}
                            text="Run the Dynamic Client Registration Management Request"/>
                    </aside>

                }
            </ResizablePanels>
        </React.Fragment>
    );

}

export default DCRMFlow

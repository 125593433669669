/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React, { useState } from 'react'
import { logModalView } from '../../util/analytics';
import CreateGroupModal from '../modals/CreateGroupModal';
import CollectionGroup from './CollectionGroup';
import { DragDropContext, Droppable } from '@atlaskit/pragmatic-drag-and-drop-react-beautiful-dnd-migration'
import PlusIcon from '../icons/PlusIcon'
import FolderIconAdd from '../icons/FolderIconAdd'
import WorkspaceMenu from '../WorkspaceMenu'
import Environments from '../../data/Environments';

function History(props) {
    const [showNewGroupModal, setShowNewGroupModal] = useState(false);
    const [filter, setFilter] = useState('');
    const environments = Environments.create(props.environments);
    const currentWorkspace = environments.getEnvironment(props.appState.activeTab);

    const handleDelete = (collectionToDelete) => {
            props.deleteCollection(collectionToDelete)
    };

    const filterHistory = (event) => {
        setFilter(event.currentTarget.value.toLowerCase())
    };

    const resetFilter = () => {
        setFilter('')
    };

    const onShowCreateGroup = () => {
        setShowNewGroupModal(true);
        document.querySelector('html').style.overflow = 'hidden';
        logModalView('/new-group');
    };

    const hideNewGroupModal = () => {
        setShowNewGroupModal(false);
        document.querySelector('html').style.overflow = '';
    };

    const currentEnvironment = props.appState.activeTab;

    const onDragEnd = (result) => {
        const { source, destination, draggableId } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        if (destination.droppableId === 'no-group') {
            props.addCollectionToGroup(draggableId, 'default', source.droppableId, destination.index)
        } else {
            props.addCollectionToGroup(draggableId, destination.droppableId, source.droppableId, destination.index)
        }
    }

    const getDropTargetClassName = isDraggingOver => {
        return isDraggingOver ? 'drop-target' : '';
    };

    const filteredGroups = Object.keys(props.groups)
      .filter(gkey => props.groups[gkey].environment === props.appState.activeTab || gkey === 'default');


    const historyClassName = currentWorkspace.playground ?
      'tools-history tools-history-' + currentWorkspace.playground : 'tools-history';

    return (
        <div className={historyClassName}>
            <WorkspaceMenu
              showSettings={props.showSettings}
              setShowSettings={props.setShowSettings}
              editing={props.editing}
              defaultGroup={props.groups.default}
              setEditing={props.setEditing}
              environments={props.environments}
              collections={props.collections}
              duplicateEnvironment={props.duplicateEnvironment}
              deleteEnvironment={props.deleteEnvironment}
              createAndSelectEnvironment={props.createAndSelectEnvironment}
              changeSelectedEnvironmentWrapper={props.changeSelectedEnvironmentWrapper}
              workspaceId={currentEnvironment}/>
            <header className="tools-history-header p2">
                <div className="tools-history-history-filter-toggle">
                    <div className="flex justify-between">
                        <div className="field-group mr1">
                            <input type="search" onChange={filterHistory} className="tools-history-filter"
                                   placeholder="Find flows..."
                                   autoComplete="off"
                                   data-lpignore="true"
                                   spellCheck="false"
                                   value={filter}
                            />
                            <button onClick={resetFilter}
                                    className={'show-password field-clear ' + (filter.length ? 'active' : '')}
                                    type="reset"
                                    aria-label="Clear"
                                    >
                                <i className="icon ion-close"/>
                            </button>
                        </div>

                        <button className="button button-tiny button-white-outline"
                                data-qa="start-flow"
                                onClick={props.showNewFlowModal} title="Start Flow">
                            <PlusIcon/>
                        </button>
                        <button className="button button-tiny button-white-outline"
                                onClick={onShowCreateGroup} title="Create Group">
                            <FolderIconAdd/>
                        </button>
                    </div>

                </div>
            </header>
            <DragDropContext onDragEnd={onDragEnd}>
                {filteredGroups
                  .sort((a, b) => {
                    if (b === 'default') {
                        return -1;
                    }
                    if (a === 'default') {
                        return 1;
                    }
                    return 0;
                }).map(key =>
                    <Droppable key={key} droppableId={key}>
                        {(provided, snapshot) => (
                            <div ref={provided.innerRef}
                                 className={getDropTargetClassName(snapshot.isDraggingOver)}
                            >
                                <CollectionGroup groupId={key}
                                                 appState={props.appState}
                                                 handleDelete={handleDelete}
                                                 environments={props.environments}
                                                 changeCollection={props.changeCollection}
                                                 updateCollection={props.updateCollection}
                                                 addCollectionToGroup={props.addCollectionToGroup}
                                                 duplicateCollection={props.duplicateCollection}
                                                 collapsed={props.groups[key].collapsed}
                                                 setGroupCollapsed={props.setGroupCollapsed}
                                                 createAndSelectCollection={props.createAndSelectCollection}
                                                 renameGroup={props.renameGroup}
                                                 deleteGroup={props.deleteGroup}
                                                 showNewFlowModal={props.showNewFlowModal}
                                                 name={props.groups[key].name}
                                                 numberOfGroups={filteredGroups.length}
                                                 filter={filter}
                                                 order={props.groups[key].order}
                                                 collections={props.collections}/>
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                )}
            </DragDropContext>
            <CreateGroupModal
                show={showNewGroupModal}
                handleClose={hideNewGroupModal}
                createGroup={props.createNewGroup}
                environment={currentEnvironment}
            />
        </div>
    )
}

export default History;

/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import { isMapEmpty } from '../../../util/util';

class ResponseHeaders extends React.Component {
    constructor(props) {
        super(props);
    }

    handleCollapse = (e) => {
        e.preventDefault();
        const receivedToken = e.currentTarget.parentElement;
        receivedToken.classList.toggle('folded');
        receivedToken.setAttribute('aria-expanded', receivedToken.getAttribute('aria-expanded') === 'true' ? 'false' : 'true');
    };

    render() {
        const rawHeaders = this.props.responseHeaders ? this.props.responseHeaders : {};

        return (
            <>
                {!isMapEmpty(rawHeaders) &&
                <div className={'received-token '} aria-expanded="true">
                    <header className="received-token-header flex flex-center justify-between"
                            onClick={this.handleCollapse}>
                        <h4 className="m0">Response Headers</h4>
                        <button className="button button-tiny button-fold"/>
                    </header>

                    <div className="tools-form-token tools-form-token-active">
                        <React.Fragment>
                <pre className="tools-input tools-input-readonly">
                    {Object.keys(rawHeaders).map((key) =>
                        <div key={key}>
                            <span className={'key'}>{key}:</span> <span className={'string'}>{rawHeaders[key]}</span>
                        </div>
                    )}
                </pre>
                        </React.Fragment>
                    </div>
                </div>}
            </>

        )
    }
}

export default ResponseHeaders

/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import * as React from 'react';

class CanBeFramed extends React.Component {
    updateCanBeFramed = (event) => {
        const updatedParameters =
            this.props.collection.parameters.withUpdatedValue(event.currentTarget.name, event.currentTarget.checked);

        this.props.updateParameters(this.props.collection.id, updatedParameters);
    };

    render() {
        const can_be_framed = this.props.collection.parameters.can_be_framed;
        const hostName = `${window.location.protocol}//${window.location.host}`;

        return (
            <React.Fragment>
                {FEATURE_FRAMING_ENABLED &&
                <div className="custom-checkbox">
                    <label className="toggle-switch">
                        <input className="form-control custom-checkbox"
                               type="checkbox"
                               id="can_be_framed"
                               name="can_be_framed"
                               checked={can_be_framed}
                               onChange={this.updateCanBeFramed}
                        />
                        <div className="toggle-slider round"/>
                    </label>
                    <label className="ml1" htmlFor="can_be_framed">Open in iFrame
                        <span
                            data-tooltip-long
                            data-tooltip={'Your client should be configured to allow framing from ' + hostName}
                            className="tooltip-holder inline-block ml1 mr1">
                                        <i className="icon ion-information-circled"/>
                                    </span>
                    </label>
                </div>
                }
            </React.Fragment>
        )
    }
}

export default CanBeFramed;

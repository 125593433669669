/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React, { useState } from 'react';
import CopyToClipboard from '../../CopyToClipboard';

function CallbackUriHelper(props) {
  const [isEditing, setEditing] = useState(false);
  const [url, setUrl] = useState(props.url.toString());


  const updateEditing = () => {
      setEditing(true)
  }

  const edit = (event) => {
    if (event.currentTarget.value) {
        setUrl(event.currentTarget.value)
    } else {
        setUrl('')
    }
  }

  const finishEditing = () => {
    let updatedParameters;
    if (url && props.defaultUrl.toString() !== url) {
      updatedParameters = props.collection.parameters.withUpdatedValue('redirect_uri', url);
    } else {
      updatedParameters = props.collection.parameters.withUpdatedValue('redirect_uri', '');
    }
    props.updateParameters(props.collection.id, updatedParameters);
      setEditing(false)
  }


  return (
    <fieldset data-urihelper className={'mt2'}>
      <p>
        {props.text}
      </p>
      <div className="field-group">
        {isEditing ?
          <div className={'flex'}>
            <input type={'text'} className={'field col-6 mr1'} value={url} onChange={edit}/>
            <button className={`button button-small button-success-outline button-input`}
                    onClick={finishEditing}
            >Save
            </button>
          </div>
          : <>
          <div className="inline-code inline-block">
            {props.url}
          </div>
            {props.showEditButton &&
            <button className={`button button-tiny button-link`} data-tooltip={'Edit redirect URI'}
                    onClick={updateEditing}
            ><i className="icon ion-edit"/></button>}
            <CopyToClipboard text={props.url}/>
          </>
        }
      </div>
    </fieldset>
  )

}

export default CallbackUriHelper;

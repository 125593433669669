/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import * as React from 'react';
import Environments from '../../data/Environments';
import { base64encode, decodeUrlParameter, encodeUrlParameter, stringArrayToValueLabelArray } from '../../util/util';
import {
    flows,
    formatOptionLabel,
    guides,
    REACT_SELECT_LIB_ACTIONS,
    TOKEN_HINTS,
    tokenPurposes
} from '../../util/appConstants';
import FlowHeader from './FlowHeader';
import StepBox from './StepBox';
import StartHere from './StartHere';
import ClientCredentials from './settings/ClientCredentials';
import CurlRequestPreview from './CurlRequestPreview';
import makeAnimated from 'react-select/animated';
import Creatable from 'react-select/creatable'
import { isEmpty } from '../../util/validationUtils';
import Token from '../../data/Token';
import EmptySidebar from '../EmptySidebar';
import OpaqueTokenValidation from '../validation/OpaqueTokenValidation';
import Guide from '../guides/Guide';
import MiddlePaneHeader from './MiddlePaneHeader';
import ClearTokens from '../ClearTokens';
import ClientAuthenticationMethod from './settings/ClientAuthenticationMethod';
import ServerResponse from '../shared/ServerResponse';
import ResizablePanels from '../ResizablePanels';
import RunButton from './RunButton';
import ExtraQueryParametersModal from '../modals/ExtraQueryParametersModal';

class RevokeFlow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            guideIsVisible: false
        };
    }

    toggleGuideVisible = (isVisible) => {
        this.setState( { guideIsVisible: isVisible })
    }

    clearErrorFromCollection = () => {
        this.props.setErrorOnCollection(this.props.collection.id, null)
    };

    setTokenForRevocation = (newValue, actionMeta) => {
        const tokenHintsType = TOKEN_HINTS;
        const actionType = REACT_SELECT_LIB_ACTIONS;
        let tokens = [];

        if (actionMeta.action === 'select-option') {
            const updatedParameters = this.props.collection.parameters.withUpdatedValue('token_to_revoke', newValue.value);
            this.props.updateParameters(this.props.collection.id, updatedParameters);
            const { name, purpose } = newValue.meta;
            tokens.push(Token.createNewToken({
                purpose,
                name,
                value: newValue.value
            }));

            if (tokenHintsType.includes(purpose)) {
                let label, value;
                const accessToken = 'access_token';
                const refreshToken = 'refresh_token';

                if (purpose === accessToken) {
                    label = accessToken;
                    value = accessToken;
                } else if (purpose === refreshToken) {
                    label = refreshToken;
                    value = refreshToken;
                }

                let hintValue;
                hintValue = {
                    label,
                    value
                };
                const setActionMeta = {
                    action: actionType.SELECT_OPTION
                };

                setTimeout(() => {
                    this.setTokenHintForRevocation(hintValue, setActionMeta);
                }, 500);
            }
        }

        if (actionMeta.action === 'create-option') {
            const updatedParameters =
                this.props.collection.parameters
                    .withUpdatedValue('token_to_revoke', newValue.value);
            this.props.updateParameters(this.props.collection.id, updatedParameters);
            tokens.push(Token.createNewToken({
                purpose: tokenPurposes.unknown.value,
                name: 'Unknown token',
                value: newValue.value
            }));
        }

        if (actionMeta.action === 'clear') {
            const updatedParameters =
                this.props.collection.parameters.withUpdatedValue('token_to_revoke', undefined);
            this.props.updateParameters(this.props.collection.id, updatedParameters);
        }
        this.props.setTokensOnCollection(this.props.collection.id, tokens, [], null, true)
    };

    setTokenHintForRevocation = (newValue, actionMeta) => {
        const actionType = REACT_SELECT_LIB_ACTIONS;

        if (actionMeta.action === actionType.SELECT_OPTION || actionMeta.action === actionType.CREATE_OPTION) {
            const updatedParameters = this.props.collection.parameters.withUpdatedValue('token_type_hint', newValue.value);
            this.props.updateParameters(this.props.collection.id, updatedParameters);
        } else if (actionMeta.action === actionType.CLEAR) {
            const updatedParameters = this.props.collection.parameters.withUpdatedValue('token_type_hint', null);
            this.props.updateParameters(this.props.collection.id, updatedParameters);
        }
    };

    previewRequest = () => {
        const environments = Environments.create(this.props.environments);
        const environment = environments.getEnvironment(this.props.collection.provider);
        const currentCollection = this.props.collection;
        const revocationEndpoint = environment ? environment.endpoints.revocation_endpoint : '';
        let authHeader = '';
        if (!currentCollection.parameters.revocation_endpoint_auth_method || currentCollection.parameters.revocation_endpoint_auth_method === 'client_secret_basic') {
            authHeader = "-H 'Authorization: Basic " + base64encode(currentCollection.parameters.client_id + ':' + (currentCollection.parameters.client_secret || '')) + "' \\\n";
        }
        const requestParameters = {
            token: this.props.collection.parameters.token_to_revoke,
            token_type_hint: this.props.collection.parameters.token_type_hint
        };
        if (currentCollection.parameters.revocation_endpoint_auth_method === 'client_secret_post') {
            requestParameters.client_id = currentCollection.parameters.client_id;
            requestParameters.client_secret = currentCollection.parameters.client_secret || '';
        }

        this.props.collection.parameters.request_extra_query_parameters?.filter(
            queryParam => queryParam.name !== '' || queryParam.value !== '')
            .forEach(queryParam => {
                requestParameters[queryParam.name] = queryParam.value
            });

        return 'curl -Ss -X POST \\\n' +
            revocationEndpoint + ' \\\n' +
            authHeader +
            "-H 'Content-Type: application/x-www-form-urlencoded' \\\n" +
            "-d '" + encodeUrlParameter(requestParameters) + "'"
    };

    runFlow = () => {
        const environments = Environments.create(this.props.environments);
        const environment = environments.getEnvironment(this.props.collection.provider);
        this.props.revocationRequest(this.props.collection, environment);
    };

    clearTokens = () => {
        const selectedToken = this.props.collection.tokens[0];
        let tokens = [];
        tokens.push(Token.createNewToken({
            purpose: selectedToken.purpose,
            name: selectedToken.name,
            value: selectedToken.value
        }));
        this.props.clearOAuthResponses(this.props.collection.id);
        this.props.setTokensOnCollection(this.props.collection.id, tokens, null, null, true);
    };

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const currentCollection = this.props.collection;
        const environments = Environments.create(this.props.environments);
        const environment = environments.getEnvironment(currentCollection.provider);
        const selectedToken = this.props.collection.tokens[0];
        const enableStep2 = !!(selectedToken && environment && environment.canDoRevoke());

        const error = (!currentCollection.error) ? '' :
            <div className="alert alert-danger">
                <i className="icon ion-ios-close-outline inlineicon"/>
                {decodeUrlParameter(currentCollection.error)}
                <button className="alert-close" onClick={this.clearErrorFromCollection}><i className="icon ion-close"/>
                </button>
            </div>;


        let accessTokensFromCollections = [];
        Object.values(this.props.collections)
          .filter(collection => collection.provider === currentCollection.provider)
          .forEach(collection => {
            if (flows[collection.flow].produces_tokens) {
                Object.values(collection.tokens).forEach(token => {
                    if (!isEmpty(token.value) && token.purpose !== tokenPurposes.id_token.value
                        && collection.id !== this.props.collection.id) {
                        const ellipsis = token.value.length > 20 ? '...' : '';
                        accessTokensFromCollections.push({
                            value: token.value,
                            label: `${collection.name}: ${token.name}`,
                            tokenString: `${token.value.substring(0, 20)}${ellipsis}`,
                            meta: {
                                purpose: token.purpose,
                                name: token.name
                            }
                        });
                    }
                });
            }
        });

        const curlRequest = this.previewRequest();
        const selectedOption = selectedToken ?
            accessTokensFromCollections.filter(option => option.value === selectedToken.value) : null;
        const tokenHintOptions = stringArrayToValueLabelArray(TOKEN_HINTS);

        let selectedTokenHint;
        if (currentCollection.parameters.token_type_hint) {
            selectedTokenHint = {
                label: currentCollection.parameters.token_type_hint,
                value: currentCollection.parameters.token_type_hint
            };
        }

        return (
            <React.Fragment>
                <ResizablePanels {...this.props}>
                    <section className="tools-form">
                        <MiddlePaneHeader
                            collection={this.props.collection}
                            exportCurrentCollection={this.props.exportCurrentCollection}/>

                        <div className="tools-form-content">

                            <FlowHeader name={currentCollection.name}
                                        description={'Select a token from an existing collection or type a new one to call the revocation endpoint.'}/>
                            {error}

                            <StepBox title={'Settings'} step={'1'} enabled={true}>

                                <StartHere clientId={selectedOption}/>

                                <ClientCredentials
                                    updateParameters={this.props.updateParameters}
                                    updateEnvironment={this.props.updateEnvironment}
                                    collection={currentCollection}
                                    environment={environment}
                                    flow={flows.revoke}
                                />

                                <div className="sm-flex flex-justify flex-center mt2">
                                    <div className="flex-auto">
                                        <ClientAuthenticationMethod
                                            updateParameters={this.props.updateParameters}
                                            environment={environment}
                                            endpoint={'revocation_endpoint_auth_method'}
                                            serverConfigFrom={'revocation_endpoint_auth_methods_supported'}
                                            collection={currentCollection}/>
                                    </div>
                                </div>

                                <div className="sm-flex flex-justify flex-center mt2">
                                    <div className="flex-100">
                                        <Creatable
                                            isClearable
                                            placeholder="Enter a token or select one from a Collection"
                                            components={makeAnimated()}
                                            options={accessTokensFromCollections}
                                            formatOptionLabel={formatOptionLabel}
                                            defaultValue={selectedOption}
                                            onChange={this.setTokenForRevocation}
                                            inputId={'select-token-revoke-flow'}
                                            className="select-container select-container-big"
                                            classNamePrefix="react-select"
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#f2f3f6',
                                                    primary: '#626c87'
                                                }
                                            })}
                                        />
                                    </div>
                                </div>

                                <div className="sm-flex flex-justify flex-center mt2">
                                    <div className="flex-100">
                                        <Creatable
                                            isMulti={false}
                                            isClearable={true}
                                            placeholder="Select token hint"
                                            components={makeAnimated()}
                                            options={tokenHintOptions}
                                            name="token_type_hint"
                                            inputId={'select-token-hint-revoke-flow'}
                                            value={selectedTokenHint}
                                            onChange={this.setTokenHintForRevocation}
                                            className="select-container select-container-big"
                                            classNamePrefix="react-select"
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#f2f3f6',
                                                    primary: '#626c87'
                                                }
                                            })}
                                        />
                                    </div>
                                </div>

                            </StepBox>

                            <StepBox title={'Call Revocation Endpoint'} step={'2'} enabled={enableStep2}>
                                <div className={'flex justify-end'}>
                                    <ExtraQueryParametersModal
                                        updateParameters={this.props.updateParameters}
                                        collection={this.props.collection}
                                        parameter={'request_extra_query_parameters'}
                                    />
                                </div>
                                <CurlRequestPreview request={curlRequest}/>
                                <RunButton runFlow={this.runFlow} buttonText={'Call Revocation Endpoint'}/>
                            </StepBox>

                            <ServerResponse response={currentCollection.OAuthResponses.RevocationResponse}/>

                        </div>
                    </section>

                    {selectedToken && Object.prototype.hasOwnProperty.call(selectedToken, 'decoded_token')
                    && !isEmpty(selectedToken.decoded_token) ?
                        <aside className="tools-sidebar">

                        <header className="tools-form-header tools-form-header-tokens">
                                <h4 className="m0"/>
                                <div className="flex justify-between flex-center">
                                    <ClearTokens clearTokens={this.clearTokens}
                                                showClearButton={this.props.collection.getTokenIds().length > 0}/>
                                    <Guide area={guides.revoke} toggle={this.toggleGuideVisible}/>
                                </div>
                            </header>
                            {!this.state.guideIsVisible &&
                            <OpaqueTokenValidation token={selectedToken} headerName={'Revocation Response'}/>
                            }
                        </aside>
                        :
                        <aside className="tools-sidebar">
                        <EmptySidebar
                            guide={guides.revoke}
                            clearTokens={this.clearTokens}
                            collection={this.props.collection}
                            text="Run the flow to get the revocation result"/>
                        </aside>

                    }
                </ResizablePanels>
            </React.Fragment>
        );
    }
}

export default RevokeFlow

/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';

const highlight = (json) => {
    const space = ' ';
    const highlightAndIndent = (jsonObject, indent, showKey) => {
        return Object.keys(jsonObject).map(key => {
            let value = jsonObject[key];
            let valueType = typeof value;
            const isPrimitive =
                ['string', 'number', 'boolean'].includes(valueType) || !value;
            if (isPrimitive && valueType === 'object') {
                valueType = 'null';
            }

            if (['exp', 'iat', 'nbf', 'auth_time', 'updated_at'].includes(key)) {
                let d = new Date(0); // The 0 there is the key, which sets the date to the epoch
                d.setUTCSeconds(value);
                value = <span data-tooltip-long data-tooltip={d.toUTCString()}>{value}</span>;
            } else if (valueType === 'boolean') {
                value = `${value}`
            }

            const showKeyNext = !(value instanceof Array);

            return (
                <div key={key} className="line">
                    <span className="key">{space.repeat(indent)}{showKey ? key : ''}</span>
                    {isPrimitive ? (
                        <span className={valueType}> {value}</span>
                    ) : (
                        highlightAndIndent(value, indent + 2, showKeyNext)
                    )}
                </div>
            );
        });
    };
    return highlightAndIndent(json, 2, true);
};

const PrettyJson = (props) => {
        if (!props.json) {
            return (<code className="json"/>);
        } else if (typeof props.json === 'string') {
            const parts = props.json.split('.');
            const disclosures = parts[2].split('~')
            const signature = disclosures.shift();
            const disclosureOutput = disclosures.map((d) =>
                <>
                    <span className="jwt-dot">~</span>
                    <span className="jwt-disclosure">{d}</span>
                </>
            )
            return (<code className="json">
                <span className="jwt-header">{parts[0]}</span><span className="jwt-dot">.</span>
                <span className="jwt-payload">{parts[1]}</span><span className="jwt-dot">.</span>
                <span className="jwt-signature">{signature}</span>{disclosureOutput}</code>);
        } else {
            return (<pre className={'field field-mono col-12 mb2 ' + props.className}><span className="line">&#123;</span>{highlight(props.json)}<span
                className="line">&#125;</span></pre>);
        }
    }
;

export default PrettyJson;

/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';

class FoldButton extends React.Component {
    button = React.createRef();

    getClassName = () => {
        const folded = this.props.isFolded ? ' ' : ' folded';
        return 'button button-tiny button-fold' + folded;
    };

    render() {
        return (
            <button
                onClick={this.props.handleFold}
                aria-label={`${this.props.isFolded ? 'Expand' : 'Collapse'}`}
                className={this.getClassName()}
            />
        );
    }
}

export default FoldButton;

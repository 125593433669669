/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React, { useState } from 'react';
import App from './App';
import { Provider } from 'react-redux'
import store from './Store';
import History from './components/history/History';
import MiddlePane from './components/MiddlePane';
import ActivityBarContainer from './components/activity-bar/ActivityBarContainer';
import HeaderStripe from './components/HeaderStripe';
import FlowModal from './components/token/FlowModal';
import { logModalView } from './util/analytics';

function AppContainer(props) {
  const [showNewFlowModal, setShowNewFlowModal] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState('default');
  const [showSettings, setShowSettings] = useState(false)
  const [editing, setEditing] = useState(false)

  const onShowNewFlowModal = (event, groupId) => {
    setShowNewFlowModal(true);
    if (groupId) {
      setSelectedGroup(groupId);
    }
    document.querySelector('html').style.overflow = 'hidden';
    logModalView('/new-flow');
  };

  const onHideNewFlowModal = () => {
    setShowNewFlowModal(false);
    setSelectedGroup('default');
    document.querySelector('html').style.overflow = '';
  };

  return (
    <main className={`tools-main ${IS_ELECTRON_BUILD && 'tools-main-electron'}`}>
      <Provider store={store}>
        <App>
          <HeaderStripe/>
          <ActivityBarContainer {...props}
                                showSettings={showSettings}
                                setShowSettings={setShowSettings}
                                editing={editing}
                                setEditing={setEditing}
          />
          <History
            showNewFlowModal={onShowNewFlowModal}
            showSettings={showSettings}
            setShowSettings={setShowSettings}
            editing={editing}
            setEditing={setEditing}
          />
          <MiddlePane {...props} showNewFlowModal={onShowNewFlowModal}/>
          <FlowModal
            {...props}
            show={showNewFlowModal}
            groupId={selectedGroup}
            handleClose={onHideNewFlowModal}
            createAndSelectCollection={props.createAndSelectCollection}
          />
        </App>
      </Provider>
    </main>
  )
}

export default AppContainer;

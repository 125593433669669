/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import * as React from 'react';
import { flows } from '../../../util/appConstants';
import { randomTimeId } from '../../../util/util';
import { clientConfigInParameters } from '../../../data/OAuthClient';

class AddClientToEnvironmentModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            showSecret: false
        };
        this.canBeFramedRef = React.createRef();
        this.canDoCodeFlowRef = React.createRef();
        this.canDoClientCredentialsFlowRef = React.createRef();
        this.canDoImplicitFlowRef = React.createRef();
        this.canDoHybridFlowRef = React.createRef();
        this.canDoDeviceFlowRef = React.createRef();
        this.canDoCibaFlowRef = React.createRef();
        this.canDoROPCFlowRef = React.createRef();
        this.canDoIntrospectRef = React.createRef();
        this.canDoTokenExchangeRef = React.createRef();
        this.clientSecretRef = React.createRef();
    }

    addClientInEnvironment = () => {
        const newClientId = randomTimeId();
        const clientSettings = {};
        clientSettings.can_be_framed = this.canBeFramedRef.current.checked;
        clientSettings.can_do_code_flow = this.canDoCodeFlowRef.current.checked;
        clientSettings.can_do_client_credentials_flow = this.canDoClientCredentialsFlowRef.current.checked;
        clientSettings.can_do_implicit_flow = this.canDoImplicitFlowRef.current.checked;
        clientSettings.can_do_hybrid_flow = this.canDoHybridFlowRef.current.checked;
        clientSettings.can_do_device_flow = this.canDoDeviceFlowRef.current.checked;
        clientSettings.can_do_ciba_flow = this.canDoCibaFlowRef.current.checked;
        clientSettings.can_do_ropc_flow = this.canDoROPCFlowRef.current.checked;
        clientSettings.can_do_introspect = this.canDoIntrospectRef.current.checked;
        clientSettings.can_do_token_exchange = this.canDoTokenExchangeRef.current.checked;
        clientSettings.client_secret = this.clientSecretRef.current.value;
        clientSettings.client_id = this.props.clientId;
        const updatedEnvironment = this.props.environment.withClient(newClientId, clientSettings);
        this.props.updateEnvironment(updatedEnvironment);

        let updatedParameters = this.props.collection.parameters.withUpdatedValue('client_from_environment', newClientId);
        Object.keys(clientConfigInParameters).forEach((key) => {
            updatedParameters = updatedParameters.withUpdatedValue(key, clientSettings[key]);
        });
        this.props.updateParameters(this.props.collection.id, updatedParameters);

        this.setState({ showModal: !this.state.showModal });
    };

    updateClientInEnvironment = (e) => {
        e.preventDefault(); // avoid "#" on URL
        const parameters = this.props.collection.parameters;
        let environment = this.props.environment;

        if (parameters.client_from_environment) {
            Object.keys(clientConfigInParameters).forEach((key) => {
                try {
                    environment = environment.withUpdatedClient(
                        parameters.client_from_environment, key, parameters[key]);
                } catch (ex) {
                    // ignoring parameter that doesn't exist
                }
            });
            this.props.updateEnvironment(environment);
        }
    };

    reImportClientFromEnvironment = (e) => {
        e.preventDefault(); // avoid "#" on URL
        let parameters = this.props.collection.parameters;

        if (parameters.client_from_environment) {
            const clientFromEnvironment = this.props.environment.clients[parameters.client_from_environment];

            Object.keys(clientConfigInParameters).forEach((key) => {
                try {
                    parameters = parameters.withUpdatedValue(key, undefined);
                } catch (ex) {
                    // ignoring parameter that doesn't exist
                }
            });

            Object.keys(clientFromEnvironment).forEach((metaKey) => {
                try {
                    parameters = parameters.withUpdatedValue(metaKey, clientFromEnvironment[metaKey]);
                } catch (ex) {
                    // ignoring parameter that doesn't exist
                }
            });
            this.props.updateParameters(this.props.collection.id, parameters);
        }
    };

    escFunction = (event) => {
        if (event.keyCode === 27) {
            this.hideClientModal();
        }
    };

    setClientModalWrapper = (node) => {
        this.clientModalWrapper = node;
    };

    handleClickOutside = (event) => {
        if (this.clientModalWrapper && !this.clientModalWrapper.contains(event.target)) {
            this.hideClientModal();
        }
    };

    showClientModal = (e) => {
        e.preventDefault(); // avoid "#" on URL
        this.setState({ showModal: true });
        document.addEventListener('keydown', this.escFunction, false);
        document.addEventListener('mousedown', this.handleClickOutside);
    };

    hideClientModal = () => {
        this.setState({ showModal: false });
        document.removeEventListener('keydown', this.escFunction, false);
        document.removeEventListener('mousedown', this.handleClickOutside);
    };

    handleShowSecret = () => {
        this.setState({ showSecret: !this.state.showSecret });
    };

    render() {
        const environment = this.props.environment;
        const collection = this.props.collection;
        let showAddClientInEnvironment = false;

        if (environment && this.props.clientId) {
            showAddClientInEnvironment = !environment.hasClientWithClientId(this.props.clientId);
        }

        let couldUpdateClient = false;

        if (environment && collection.parameters.client_from_environment &&
            environment.hasClientWithId(collection.parameters.client_from_environment)) {
            Object.keys(clientConfigInParameters).forEach((key) => {
                couldUpdateClient = couldUpdateClient || (collection.parameters[key] !==
                    environment.clients[collection.parameters.client_from_environment][key]);
            });
        }

        const showHideClassName = this.state.showModal
            ? 'modal modal-is-visible'
            : 'modal modal-is-hidden';
        return (<>
                {(couldUpdateClient) ?
                    <React.Fragment>
                        <div className="flex flex-wrap flex-gap-2 py2">
                            <a href="#" onClick={this.updateClientInEnvironment}>Update client in workspace</a>
                            <a href="#" onClick={this.reImportClientFromEnvironment}>Re-import client from workspace</a>
                        </div>
                    </React.Fragment> : ''}
                {(showAddClientInEnvironment) ?
                    <React.Fragment>
                        <div className="mt1">
                            <a href="#" onClick={this.showClientModal}>Save client in
                                workspace</a>
                        </div>
                        <div className={showHideClassName}>
                            <section className="modal-main" ref={this.setClientModalWrapper}>
                                <header className="modal-main-header">
                                    <div className="flex flex-center justify-between"><h3
                                        className="h4 m0">Add <code
                                        className="inline-code">{collection.parameters.client_id}</code> to <code
                                        className="inline-code">{environment.name}</code></h3>
                                        <button type="button" className="button button-close button-link"
                                                onClick={this.hideClientModal}><i
                                            className="icon ion-close"/></button>
                                    </div>
                                </header>
                                <div className="modal-main-content">


                                    <div className="mt2 ">

                                        <div className="field-group flex-auto mt1">
                                            <input
                                                className={(this.state.showSecret || !collection.parameters.client_secret) ? 'field col-12' : 'field field-mono col-12'}
                                                placeholder="Client Secret"
                                                name="client_secret"
                                                type={(this.state.showSecret ? 'text' : 'password')}
                                                autoComplete="off"
                                                data-lpignore="true"
                                                spellCheck="false"
                                                ref={this.clientSecretRef}
                                                defaultValue={collection.parameters.client_secret}
                                            />
                                            <button className="show-password"
                                                    aria-label={(this.state.showSecret) ? 'Hide Password' : 'Show Password'}
                                                    type="button"
                                                    onClick={this.handleShowSecret}>
                                                <i className={'icon primary ' + (this.state.showSecret ? 'ion-eye-disabled' : 'ion-eye')}/>
                                            </button>
                                        </div>

                                        <div className="sm-flex flex-justify flex-center mt1">
                                            <div className="custom-checkbox">
                                                <label className="toggle-switch">
                                                    <input className="form-control custom-checkbox"
                                                           type="checkbox"
                                                           id="can_do_code_flow"
                                                           defaultChecked={collection.parameters.can_do_code_flow
                                                               || this.props.flow === flows.code}
                                                           name="can_do_code_flow"
                                                           ref={this.canDoCodeFlowRef}
                                                    />
                                                    <div className="toggle-slider round"/>
                                                </label>
                                                <label htmlFor="can_do_code_flow" className="ml1">Code flow</label>
                                            </div>
                                        </div>

                                        <div className="sm-flex flex-justify flex-center mt1">
                                            <div className="custom-checkbox">
                                                <label className="toggle-switch">
                                                    <input className="form-control custom-checkbox"
                                                           type="checkbox"
                                                           id="can_do_implicit_flow"
                                                           defaultChecked={collection.parameters.can_do_implicit_flow
                                                               || this.props.flow === flows.implicit}
                                                           name="can_do_implicit_flow"
                                                           ref={this.canDoImplicitFlowRef}
                                                    />
                                                    <div className="toggle-slider round"/>
                                                </label>
                                                <label htmlFor="can_do_implicit_flow" className="ml1">Implicit
                                                    Flow</label>
                                            </div>
                                        </div>

                                        <div className="sm-flex flex-justify flex-center mt1">
                                            <div className="custom-checkbox">
                                                <label className="toggle-switch">
                                                    <input className="form-control custom-checkbox"
                                                           type="checkbox"
                                                           id="can_do_hybrid_flow"
                                                           defaultChecked={collection.parameters.can_do_hybrid_flow
                                                               || this.props.flow === flows.hybrid}
                                                           name="can_do_hybrid_flow"
                                                           ref={this.canDoHybridFlowRef}
                                                    />
                                                    <div className="toggle-slider round"/>
                                                </label>
                                                <label htmlFor="can_do_hybrid_flow" className="ml1">Hybrid
                                                    Flow</label>
                                            </div>
                                        </div>

                                        <div className="sm-flex flex-justify flex-center mt1">
                                            <div className="custom-checkbox">
                                                <label className="toggle-switch">
                                                    <input className="form-control custom-checkbox"
                                                           type="checkbox"
                                                           id="can_do_client_credentials_flow"
                                                           defaultChecked={
                                                               collection.parameters.can_do_client_credentials_flow
                                                               || this.props.flow === flows.client_credentials}
                                                           name="can_do_client_credentials_flow"
                                                           ref={this.canDoClientCredentialsFlowRef}
                                                    />
                                                    <div className="toggle-slider round"/>
                                                </label>
                                                <label htmlFor="can_do_client_credentials_flow" className="ml1">Client
                                                    Credentials Flow</label>
                                            </div>
                                        </div>

                                        <div className="sm-flex flex-justify flex-center mt1">
                                            <div className="custom-checkbox">
                                                <label className="toggle-switch">
                                                    <input className="form-control custom-checkbox"
                                                           type="checkbox"
                                                           id="can_do_token_exchange"
                                                           defaultChecked={
                                                               collection.parameters.can_do_token_exchange ||
                                                               this.props.flow === flows.token_exchange }
                                                           name="can_do_token_exchange"
                                                           ref={this.canDoTokenExchangeRef}
                                                    />
                                                    <div className="toggle-slider round"/>
                                                </label>
                                                <label htmlFor="can_do_token_exchange"
                                                       className="ml1">Token Exchange</label>
                                            </div>
                                        </div>

                                        <div className="sm-flex flex-justify flex-center mt1">
                                            <div className="custom-checkbox">
                                                <label className="toggle-switch">
                                                    <input className="form-control custom-checkbox"
                                                           type="checkbox"
                                                           id="can_do_introspect"
                                                           defaultChecked={
                                                               !collection.parameters
                                                                   .use_separate_client_for_introspection ||
                                                               (collection.parameters
                                                                       .use_separate_client_for_introspection
                                                                   && this.props.clientId !==
                                                                   collection.parameters.client_id)}
                                                           name="can_do_introspect"
                                                           ref={this.canDoIntrospectRef}
                                                    />
                                                    <div className="toggle-slider round"/>
                                                </label>
                                                <label htmlFor="can_do_introspect"
                                                       className="ml1">Introspect</label>
                                            </div>
                                        </div>

                                        <div className="sm-flex flex-justify flex-center mt1">
                                            <div className="custom-checkbox">
                                                <label className="toggle-switch">
                                                    <input className="form-control custom-checkbox"
                                                           type="checkbox"
                                                           id="can_do_ropc_flow"
                                                           defaultChecked={collection.parameters.can_do_ropc_flow ||
                                                               this.props.flow === flows.password}
                                                           name="can_do_ropc_flow"
                                                           ref={this.canDoROPCFlowRef}
                                                    />
                                                    <div className="toggle-slider round"/>
                                                </label>
                                                <label htmlFor="can_do_ropc_flow" className="ml1">Resource Owner
                                                    Password Credentials</label>
                                            </div>
                                        </div>

                                        <div className="sm-flex flex-justify flex-center mt1">
                                            <div className="custom-checkbox">
                                                <label className="toggle-switch">
                                                    <input className="form-control custom-checkbox"
                                                           type="checkbox"
                                                           id="can_do_device_flow"
                                                           defaultChecked={collection.parameters.can_do_device_flow
                                                               || this.props.flow === flows.device}
                                                           name="can_do_device_flow"
                                                           ref={this.canDoDeviceFlowRef}
                                                    />
                                                    <div className="toggle-slider round"/>
                                                </label>
                                                <label htmlFor="can_do_device_flow" className="ml1">Device
                                                    flow</label>
                                            </div>
                                        </div>

                                        <div className="sm-flex flex-justify flex-center mt1">
                                            <div className="custom-checkbox">
                                                <label className="toggle-switch">
                                                    <input className="form-control custom-checkbox"
                                                           type="checkbox"
                                                           id="can_do_ciba_flow"
                                                           defaultChecked={collection.parameters.can_do_ciba_flow
                                                               || this.props.flow === flows.ciba}
                                                           name="can_do_ciba_flow"
                                                           ref={this.canDoCibaFlowRef}
                                                    />
                                                    <div className="toggle-slider round"/>
                                                </label>
                                                <label htmlFor="can_do_ciba_flow" className="ml1">CIBA
                                                    flow</label>
                                            </div>
                                        </div>

                                        <div className="sm-flex flex-justify flex-center mt1">
                                            <div className="custom-checkbox">
                                                <label className="toggle-switch">
                                                    <input className="form-control custom-checkbox"
                                                           type="checkbox"
                                                           id="can_be_framed"
                                                           defaultChecked={collection.parameters.can_be_framed}
                                                           name="can_be_framed"
                                                           ref={this.canBeFramedRef}
                                                    />
                                                    <div className="toggle-slider round"/>
                                                </label>
                                                <label htmlFor="can_be_framed" className="ml1">Can be framed</label>
                                            </div>
                                        </div>
                                        <div className="sm-flex flex-justify flex-center mt2">
                                            <button type="button" className="button button-small button-success"
                                                    onClick={this.addClientInEnvironment}>
                                                Save
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </section>
                        </div>
                    </React.Fragment>
                    : ''
                }</>
        );
    }
}

export default AddClientToEnvironmentModal

/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';

const ClearTokens = ({ showClearButton, clearTokens }) => {

  return (
    <>
      {showClearButton && (
        <button
          className="button button-light button-tiny"
          data-qa="clear-token-results"
          onClick={clearTokens}
        >
          <i className="icon ion-trash-a inlineicon" />
          Clear
        </button>
      )}
    </>
  );
};
export default ClearTokens;

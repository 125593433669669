/*
 * Copyright (C) 2021 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import MiddlePaneHeader from './MiddlePaneHeader';
import Environments from '../../data/Environments';
import FlowHeader from './FlowHeader';
import { base64encode, claimsArrayToRequestParameter, decodeUrlParameter, encodeUrlParameter } from '../../util/util';
import StartHere from './StartHere';
import StepBox from './StepBox';
import ReceivedTokensSidebar from '../token/ReceivedTokensSidebar';
import { flows, formatOptionLabel, guides, tokenPurposes } from '../../util/appConstants';
import ClientCredentials from './settings/ClientCredentials';
import Scopes from './settings/Scopes';
import Acrs from './settings/Acrs';
import LoginHint from './settings/LoginHint';
import BindingMessage from './settings/BindingMessage';
import UserCode from './settings/UserCode';
import RequestedExpiry from './settings/RequestedExpiry';
import RunButton from './RunButton';
import { isEmpty } from '../../util/validationUtils';
import CurlRequestPreview from './CurlRequestPreview';
import ClientAuthenticationMethod from './settings/ClientAuthenticationMethod';
import Nonce from './settings/Nonce';
import ClaimsModal from '../modals/ClaimsModal';
import ServerResponse from '../shared/ServerResponse';
import Creatable from 'react-select/creatable';
import makeAnimated from 'react-select/animated';
import ResizablePanels from '../ResizablePanels';
import ExtraQueryParametersModal from '../modals/ExtraQueryParametersModal';

class CIBAFlow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showClaimsModal: false
        }
    }

    clearErrorFromCollection = () => {
        this.props.setErrorOnCollection(this.props.collection.id, null)
    };

    hideClaimsModal = () => {
        this.setState({ showClaimsModal: false });
        document.querySelector('html').style.overflow = '';
    };

    showClaimsModal = () => {
        this.setState({ showClaimsModal: true });
        document.querySelector('html').style.overflow = 'hidden';
    };

    previewBackChannelRequest = () => {
        const environments = Environments.create(this.props.environments);
        const environment = environments.getEnvironment(this.props.collection.provider);
        const currentCollection = this.props.collection;

        const scopeList = (this.props.collection.parameters.scopes) ?
            this.props.collection.parameters.scopes.map((scope) => scope.value) : [];
        const scope = (scopeList.length > 0) ? scopeList.join(' ') : null;

        const acrList = (this.props.collection.parameters.acrs) ?
            this.props.collection.parameters.acrs.map((acr) => acr.value) : [];
        const acr_values = (acrList.length > 0) ? acrList.join(' ') : null;

        const claims = !isEmpty(currentCollection.parameters.claims) ?
            JSON.stringify(claimsArrayToRequestParameter(currentCollection.parameters.claims)) : null;

        const requestParameters = {
            login_hint: this.props.collection.parameters.login_hint,
            login_hint_token: this.props.collection.parameters.login_hint_token,
            id_token_hint: this.props.collection.parameters.id_token_hint,
            acr_values,
            scope,
            claims,
            nonce: currentCollection.parameters.nonce,
            binding_message: currentCollection.parameters.binding_message,
            user_code: currentCollection.parameters.user_code,
            requested_expiry: currentCollection.parameters.requested_expiry
        };

        let authHeader = '';
        if (!currentCollection.parameters.backchannel_endpoint_auth_method || currentCollection.parameters.backchannel_endpoint_auth_method === 'client_secret_basic') {
            authHeader = "-H 'Authorization: Basic " + base64encode(currentCollection.parameters.client_id + ':' + (currentCollection.parameters.client_secret || '')) + "' \\\n";
        }
        if (currentCollection.parameters.backchannel_endpoint_auth_method === 'client_secret_post') {
            requestParameters.client_id = currentCollection.parameters.client_id;
            requestParameters.client_secret = currentCollection.parameters.client_secret || '';
        }

        this.props.collection.parameters.request_extra_query_parameters?.filter(
            queryParam => queryParam.name !== '' || queryParam.value !== '')
            .forEach(queryParam => {
                requestParameters[queryParam.name] = queryParam.value
            });

        const backchannelAuthenticationEndpoint = environment ? environment.endpoints.backchannel_authentication_endpoint : '';
        return 'curl -Ss -X POST \\\n' +
            backchannelAuthenticationEndpoint + ' \\\n' +
            authHeader +
            "-H 'Content-Type: application/x-www-form-urlencoded' \\\n" +
            "-d '" + encodeUrlParameter(requestParameters) + "'"
    };

    callBackChannelEndpoint = () => {
        const environments = Environments.create(this.props.environments);
        const environment = environments.getEnvironment(this.props.collection.provider);
        this.props.backChannelEndpointRequest(this.props.collection, environment);

        let updatedParameters = this.props.collection.parameters;
        updatedParameters = updatedParameters.withUpdatedValue('auth_req_id_spent', false);
        this.props.updateParameters(this.props.collection.id, updatedParameters);
    };

    tokenEndpointRequestParameters = () => {
        const requestParameters = {
            grant_type: flows.ciba.grant_type,
            auth_req_id: this.props.collection.parameters.auth_req_id
        };

        if (this.props.collection.parameters.token_endpoint_auth_method === 'client_secret_post') {
            requestParameters.client_id = this.props.collection.parameters.client_id;
            requestParameters.client_secret = this.props.collection.parameters.client_secret || '';
        }

        this.props.collection.parameters.token_request_extra_query_parameters?.filter(
            queryParam => queryParam.name !== '' || queryParam.value !== '')
            .forEach(queryParam => {
                requestParameters[queryParam.name] = queryParam.value
            });

        return requestParameters;
    }

    pollTokenEndpoint = () => {
        const environments = Environments.create(this.props.environments);
        const environment = environments.getEnvironment(this.props.collection.provider);
        this.props.tokenEndpointRequest(this.props.collection, environment, this.tokenEndpointRequestParameters(), true, 'CibaFlowPollingResponse');
        window.scrollTo(0, 0);
    };

    previewTokenEndpointRequest = () => {
        const environments = Environments.create(this.props.environments);
        const environment = environments.getEnvironment(this.props.collection.provider);
        const currentCollection = this.props.collection;

        const requestParameters = {
            grant_type: flows.ciba.grant_type,
            auth_req_id: this.props.collection.parameters.auth_req_id
        };

        let authHeader = '';
        if (!currentCollection.parameters.token_endpoint_auth_method || currentCollection.parameters.token_endpoint_auth_method === 'client_secret_basic') {
            authHeader = "-H 'Authorization: Basic " + base64encode(currentCollection.parameters.client_id + ':' + (currentCollection.parameters.client_secret || '')) + "' \\\n";
        }
        if (currentCollection.parameters.token_endpoint_auth_method === 'client_secret_post') {
            requestParameters.client_id = currentCollection.parameters.client_id;
            requestParameters.client_secret = currentCollection.parameters.client_secret || '';
        }

        currentCollection.parameters.token_request_extra_query_parameters?.filter(
            queryParam => queryParam.name !== '' || queryParam.value !== '')
            .forEach(queryParam => {
                requestParameters[queryParam.name] = queryParam.value
            });

        const tokenEndpoint = environment ? environment.endpoints.token_endpoint : '';
        return 'curl -Ss -X POST \\\n' +
            tokenEndpoint + ' \\\n' +
            authHeader +
            "-H 'Content-Type: application/x-www-form-urlencoded' \\\n" +
            "-d '" + encodeUrlParameter(requestParameters) + "'"
    };

    setIdTokenHint = (newValue) => {
        const value = newValue ? newValue.value : '';
        const updatedParameters = this.props.collection.parameters.withUpdatedValue('id_token_hint', value);
        this.props.updateParameters(this.props.collection.id, updatedParameters);
    };

    setLoginHintToken = (newValue) => {
        const value = newValue ? newValue.value : '';
        const updatedParameters = this.props.collection.parameters.withUpdatedValue('login_hint_token', value);
        this.props.updateParameters(this.props.collection.id, updatedParameters);
    };


    render() {
        const currentCollection = this.props.collection;

        let clientId = null;
        if (currentCollection.parameters.client_id) {
            clientId = currentCollection.parameters.client_id;
        }
        const environments = Environments.create(this.props.environments);
        const environment = environments.getEnvironment(currentCollection.provider);

        const enableStep2 = (clientId !== null && environment && environment.canDoCIBA() &&
            (!!currentCollection.parameters.id_token_hint ||
                !!currentCollection.parameters.login_hint ||
                !!currentCollection.parameters.login_hint_token));

        const warning = environment && environment.backchannel_token_delivery_modes_supported &&
        environment.backchannel_token_delivery_modes_supported.indexOf('poll') >= 0 ? '' :
            <div className="alert alert-warning">
                <i className="icon ion-ios-information inlineicon"/>
                The selected environment does not support poll token delivery modes.
            </div>;


        const backChannelRequest = this.previewBackChannelRequest();

        const error = (!currentCollection.error) ? '' :
            <div className="alert alert-danger mt2">
                <i className="icon ion-ios-close-outline inlineicon"/>
                {decodeUrlParameter(currentCollection.error)}
                <button className="alert-close" onClick={this.clearErrorFromCollection}><i className="icon ion-close"/>
                </button>
            </div>;


        let idTokensFromCollection = [];
        Object.values(this.props.collections)
          .filter(collection => collection.provider === currentCollection.provider)
          .forEach(collection => {
            if (flows[collection.flow].produces_tokens) {
                Object.values(collection.tokens).forEach(token => {
                    if (!isEmpty(token.value) && token.purpose === tokenPurposes.id_token.value) {
                        const ellipsis = token.value.length > 20 ? '...' : '';
                        idTokensFromCollection.push({
                            value: token.value,
                            label: `${collection.name}: ${token.name}`,
                            tokenString: `${token.value.substring(0, 20)}${ellipsis}`
                        });
                    }
                });
            }
        });
        const selectedIdToken = this.props.collection.parameters.id_token_hint;
        if (selectedIdToken && idTokensFromCollection.filter(option => option.value === selectedIdToken).length === 0) {
            const ellipsis = selectedIdToken > 20 ? '...' : '';
            idTokensFromCollection.push({
                value: selectedIdToken,
                label: 'ID Token Hint',
                tokenString: `${selectedIdToken.substring(0, 20)}${ellipsis}`
            });
        }
        const selectedIdTokenOption = selectedIdToken ?
            idTokensFromCollection.filter(option => option.value === selectedIdToken) : null;

        let loginHintTokensFromCollection = [];
        Object.values(this.props.collections)
          .filter(collection => collection.provider === currentCollection.provider)
          .forEach(collection => {
            if (flows[collection.flow].produces_tokens) {
                Object.values(collection.tokens).forEach(token => {
                    if (!isEmpty(token.value)) {
                        const ellipsis = token.value.length > 20 ? '...' : '';
                        loginHintTokensFromCollection.push({
                            value: token.value,
                            label: `${collection.name}: ${token.name}`,
                            tokenString: `${token.value.substring(0, 20)}${ellipsis}`
                        });
                    }
                });
            }
        });
        const selectedLoginHintToken = this.props.collection.parameters.login_hint_token;
        if (selectedLoginHintToken &&
            loginHintTokensFromCollection.filter(option => option.value === selectedLoginHintToken).length === 0) {
            const ellipsis = selectedLoginHintToken > 20 ? '...' : '';
            loginHintTokensFromCollection.push({
                value: selectedLoginHintToken,
                label: 'Login Hint Token',
                tokenString: `${selectedLoginHintToken.substring(0, 20)}${ellipsis}`
            });
        }
        const selectedLoginHintTokenOption = selectedLoginHintToken ?
            loginHintTokensFromCollection.filter(option => option.value === selectedLoginHintToken) : null;

        const editableIdTokenHint = !!this.props.collection.parameters.login_hint ||
            !!this.props.collection.parameters.login_hint_token;
        const editableLoginHintToken = !!this.props.collection.parameters.id_token_hint ||
            !!this.props.collection.parameters.login_hint;
        const editableLoginHint = !!this.props.collection.parameters.id_token_hint ||
            !!this.props.collection.parameters.login_hint_token;

        const requiredHintWarning = !this.props.collection.parameters.login_hint &&
            !this.props.collection.parameters.login_hint_token &&
            !this.props.collection.parameters.id_token_hint &&
            <div className="alert alert-warning mt1">
                <i className="icon ion-ios-information inlineicon"/>
                One of the <code className={'inline-code'}>login_hint</code>, <code className={'inline-code'}>
                login_hint_token</code> or <code className={'inline-code'}>id_token_hint</code> has to be configured
            </div>;


        return (
            <React.Fragment>
                <ResizablePanels {...this.props}>
                    <section className={'tools-form'}>
                        <MiddlePaneHeader
                            collection={this.props.collection}
                            exportCurrentCollection={this.props.exportCurrentCollection}/>
                        <div className={'tools-form-content'}>

                            <FlowHeader name={currentCollection.name}
                                        description={'Client Initiated Backchannel Authentication'}/>
                            {warning}
                            {error}

                            <StepBox title={'Settings'} step={'1'} enabled={true}>

                                <StartHere clientId={clientId}/>

                                <ClientCredentials
                                    updateParameters={this.props.updateParameters}
                                    updateEnvironment={this.props.updateEnvironment}
                                    collection={currentCollection}
                                    disableSecret={false}
                                    environment={environment}
                                    flow={flows.ciba}
                                />

                                <div className={'sm-flex flex-justify flex-center flex-wrap flex-gap-2 mt2'}>
                                    <div>
                                        <ClientAuthenticationMethod
                                            updateParameters={this.props.updateParameters}
                                            environment={environment}
                                            endpoint={'backchannel_endpoint_auth_method'}
                                            serverConfigFrom={'token_endpoint_auth_methods_supported'}
                                            collection={currentCollection}
                                        />
                                    </div>
                                    <div>
                                        <Nonce collection={currentCollection} environment={this.props.environment}
                                            updateParameters={this.props.updateParameters}/>
                                    </div>
                                </div>

                                <div className={'sm-flex flex-justify flex-center flex-wrap flex-gap-2 mt2'}>
                                    <div className={'flex-auto'}>
                                        <Scopes
                                            updateParameters={this.props.updateParameters}
                                            environment={environment}
                                            collection={currentCollection}
                                        />
                                    </div>
                                    {environment && environment.claims_parameter_supported ?
                                        <div className={'flex-auto'}>
                                            <ClaimsModal
                                                updateParameters={this.props.updateParameters}
                                                environment={environment}
                                                collection={currentCollection}
                                                showClaimsModal={this.state.showClaimsModal}
                                                handleClose={this.hideClaimsModal}/>
                                            <button onClick={this.showClaimsModal}
                                                    className={'button button-small button-primary-outline button-input'}>
                                                Add Claims
                                            </button>
                                        </div>
                                        : ''}
                                </div>

                                <div className={'sm-flex flex-justify flex-center flex-wrap flex-gap-2 mt2'}>
                                    <div className={'flex-100'}>
                                        <Acrs
                                            updateParameters={this.props.updateParameters}
                                            environment={environment}
                                            collection={currentCollection}
                                        />
                                    </div>
                                </div>

                                <div className={'sm-flex flex-justify flex-center flex-wrap flex-gap-2 mt2'}>
                                    <div>
                                        <LoginHint
                                            isDisabled={editableLoginHint}
                                            collection={currentCollection}
                                            updateParameters={this.props.updateParameters}
                                        />
                                    </div>
                                    <div>
                                    </div>
                                </div>

                                <div className={'sm-flex flex-justify flex-center flex-wrap flex-gap-2 mt2'}>
                                    <div className={'flex-auto'}>
                                        <Creatable
                                            isClearable
                                            placeholder="Login Hint Token"
                                            components={makeAnimated()}
                                            isDisabled={editableLoginHintToken}
                                            options={loginHintTokensFromCollection}
                                            formatOptionLabel={formatOptionLabel}
                                            defaultValue={selectedLoginHintTokenOption}
                                            inputId={'select-login-hint-token-ciba-flow'}
                                            onChange={this.setLoginHintToken}
                                            className={'select-container select-container-big'}
                                            classNamePrefix={'react-select'}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#f2f3f6',
                                                    primary: '#626c87'
                                                }
                                            })}
                                        />
                                    </div>
                                    <div className={'flex-auto'}>
                                        <Creatable
                                            isClearable
                                            placeholder={'ID Token Hint'}
                                            components={makeAnimated()}
                                            isDisabled={editableIdTokenHint}
                                            options={idTokensFromCollection}
                                            formatOptionLabel={formatOptionLabel}
                                            defaultValue={selectedIdTokenOption}
                                            inputId={'select-id-token-hint-ciba-flow'}
                                            onChange={this.setIdTokenHint}
                                            className={'select-container select-container-big'}
                                            classNamePrefix={'react-select'}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#f2f3f6',
                                                    primary: '#626c87'
                                                }
                                            })}
                                        />
                                    </div>
                                </div>
                                {requiredHintWarning}

                                <div className={'sm-flex flex-justify flex-center flex-wrap flex-gap-2 mt2'}>
                                    <div className={'flex-100'}>
                                        <BindingMessage
                                            collection={currentCollection}
                                            updateParameters={this.props.updateParameters}
                                        />
                                    </div>
                                </div>

                                <div className={'sm-flex flex-justify flex-center flex-wrap flex-gap-2 mt2'}>
                                    <div className={'flex-auto'}>
                                        <UserCode
                                            collection={currentCollection}
                                            updateParameters={this.props.updateParameters}
                                        />
                                    </div>
                                    <div className={'flex-auto'}>
                                        <RequestedExpiry
                                            collection={currentCollection}
                                            updateParameters={this.props.updateParameters}
                                        />
                                    </div>
                                </div>

                            </StepBox>

                            <StepBox title={'Start Flow'} step={'2'} enabled={enableStep2}
                                     tooltip={'Define a backchannel authentication endpoint to start using this flow'}>
                                <div className={'flex justify-end'}>
                                    <ExtraQueryParametersModal
                                        updateParameters={this.props.updateParameters}
                                        collection={this.props.collection}
                                        parameter={'request_extra_query_parameters'}
                                    />
                                </div>
                                <CurlRequestPreview request={backChannelRequest}/>
                                <RunButton runFlow={this.callBackChannelEndpoint}/>
                            </StepBox>

                            <ServerResponse response={currentCollection.OAuthResponses.CIBAFlowAuthorizationResponse}/>

                            <StepBox title={'Poll the token endpoint'} step={'3'}
                                     enabled={!!this.props.collection.parameters.auth_req_id}>

                                <div className={'flex flex-auto'}>
                                    <div className={'field field-mono col-12'}>
                                        <span>auth_req_id: </span>
                                        <span className={'value'}>{this.props.collection.parameters.auth_req_id}</span>
                                    </div>
                                </div>

                                <div className={'sm-flex flex-justify flex-center mt2'}>
                                    <div className={'flex-50'}>
                                        <ClientAuthenticationMethod
                                            updateParameters={this.props.updateParameters}
                                            environment={environment}
                                            endpoint={'token_endpoint_auth_method'}
                                            serverConfigFrom={'token_endpoint_auth_methods_supported'}
                                            collection={currentCollection}
                                        />
                                    </div>
                                    <div className={'justify-end'}>
                                        <ExtraQueryParametersModal
                                            updateParameters={this.props.updateParameters}
                                            collection={this.props.collection}
                                            parameter={'token_request_extra_query_parameters'}
                                        />
                                    </div>
                                </div>

                                <CurlRequestPreview request={this.previewTokenEndpointRequest()}/>

                                <div className={'mt2'}>
                                    <button
                                        data-qa={'poll-button'}
                                        onClick={this.pollTokenEndpoint}
                                        disabled={this.props.collection.parameters.auth_req_id_spent}
                                        className={'button button-medium button-primary button-run button-fullwidth button-loading'}>
                                        <span><i className={'icon ion-code inlineicon'}/>Poll</span>
                                    </button>
                                </div>

                            </StepBox>

                            <ServerResponse response={currentCollection.OAuthResponses.CibaFlowPollingResponse}/>


                        </div>
                    </section>

                    <ReceivedTokensSidebar
                        guide={guides.ciba}
                        flow={flows.ciba}
                        collection={currentCollection}
                        environment={environment}
                        groups={this.props.groups}
                        clearOAuthResponses={this.props.clearOAuthResponses}
                        setTokensOnCollection={this.props.setTokensOnCollection}
                        updateParameters={this.props.updateParameters}
                        refreshTokens={this.props.refreshTokens}
                        introspectToken={this.props.introspectToken}
                        createAndSelectCollectionWithToken={this.props.createAndSelectCollectionWithToken}
                    />
                </ResizablePanels>
            </React.Fragment>
        );
    }
}

export default CIBAFlow;

/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import ImplicitFlow from './flows/ImplicitFlow';
import CodeFlow from './flows/CodeFlow';
import NoSelectionMiddlePane from './NoSelectionMiddlePane';
import Collection from '../data/Collection';
import ClientCredentialsFlow from './flows/ClientCredentialsFlow';
import ResourceOwnerPasswordCredentialsFlow from './flows/ResourceOwnerPasswordCredentialsFlow';
import DeviceFlow from './flows/DeviceFlow';
import IntrospectionFlow from './flows/IntrospectionFlow';
import HybridFlow from './flows/HybridFlow';
import UserinfoFlow from './flows/UserinfoFlow';
import { exportAppState } from '../util/util';
import CallAPIFlow from './flows/CallAPIFlow';
import CreateJWTFlow from './flows/CreateJWTFlow';
import RefreshFlow from './flows/RefreshFlow';
import RevokeFlow from './flows/RevokeFlow';
import LogoutFlow from './flows/LogoutFlow';
import CIBAFlow from './flows/CIBAFlow';
import VCIFlow from './flows/VCIFlow';
import PreAuthorizedCodeFlow from './flows/PreAuthorizedCodeFlow';
import DCRFlow from './flows/DCRFlow';
import DecodeTokenPane from './flows/DecodeTokenPane';

function MiddlePane(props) {

  const exportCurrentCollection = (collection) => {
    if (collection) {
      const fileName = (collection.name ? collection.name.replace(/\s/g, '-') : collection.id) + '.json';
      const fileType = 'application/json';

      // !Imp: As Individual export will not have `environment` as `key` thus added  manually;
      const dataToExport = JSON.stringify({
        'collections': {
          [collection.id]: collection.toMap()
        }
      });
      exportAppState(dataToExport, fileName, fileType, []);
    } else {
      alert('Invalid file to export collection');
    }
  };

  let currentCollection = null;
  let currentFlow = '';
  if (Object.keys(props.collections).length > 0 && props.appState.currentCollection) {
    currentCollection = Collection.getCollection(props.appState, props.collections);
    currentFlow = currentCollection.flow;
  }

  if (currentFlow !== '') {

    const historySidebarWidth = getComputedStyle(document.documentElement)
    .getPropertyValue('--history-sidebar-width').slice(0, -1)
    const tokenSidebarWidth = getComputedStyle(document.documentElement)
    .getPropertyValue('--token-sidebar-width').slice(0, -1)

    const initialMainAreaWidth = String(parseInt(100 - historySidebarWidth - tokenSidebarWidth));
    document.documentElement.style.setProperty('--main-area-width', `${initialMainAreaWidth}%`);
  }


  let SubPane;
  if (currentFlow === 'none') {
    SubPane = DecodeTokenPane;
  } else if (currentFlow === 'implicit') {
    SubPane = ImplicitFlow;
  } else if (currentFlow === 'code') {
    SubPane = CodeFlow;
  } else if (currentFlow === 'hybrid') {
    SubPane = HybridFlow;
  } else if (currentFlow === 'client_credentials') {
    SubPane = ClientCredentialsFlow;
  } else if (currentFlow === 'device') {
    SubPane = DeviceFlow;
  } else if (currentFlow === 'password') {
    SubPane = ResourceOwnerPasswordCredentialsFlow;
  } else if (currentFlow === 'introspect') {
    SubPane = IntrospectionFlow;
  } else if (currentFlow === 'userinfo') {
    SubPane = UserinfoFlow;
  } else if (currentFlow === 'apicall') {
    SubPane = CallAPIFlow;
  } else if (currentFlow === 'vci') {
    SubPane = VCIFlow;
  } else if (currentFlow === 'precode') {
    SubPane = PreAuthorizedCodeFlow;
  } else if (currentFlow === 'refresh') {
    SubPane = RefreshFlow;
  } else if (currentFlow === 'revoke') {
    SubPane = RevokeFlow;
  } else if (currentFlow === 'create_jwt') {
    SubPane = CreateJWTFlow;
  } else if (currentFlow === 'oidc_logout') {
    SubPane = LogoutFlow;
  } else if (currentFlow === 'ciba') {
    SubPane = CIBAFlow;
  } else if (currentFlow === 'dcr') {
    SubPane = DCRFlow;
  } else {
    SubPane = NoSelectionMiddlePane;
  }

  return (
    // Adding a key here will cause the component to reload when the selected collection changes
    // this is currently desired due to the internal state of that component.
    <SubPane {...props}
             key={currentCollection ? currentCollection.id : null}
             collection={currentCollection ? currentCollection : null}
             exportCurrentCollection={exportCurrentCollection}
    />
  )
}

export default MiddlePane;

/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import Scopes from './settings/Scopes';
import IntrospectionSettings from './settings/IntrospectionSettings';
import Environments from '../../data/Environments';
import StepBox from './StepBox';
import RunButton from './RunButton';
import ReceivedTokensSidebar from '../token/ReceivedTokensSidebar';
import ClientCredentials from './settings/ClientCredentials';
import StartHere from './StartHere';
import { flows, guides } from '../../util/appConstants';
import { base64encode, decodeUrlParameter, encodeUrlParameter } from '../../util/util';
import CurlRequestPreview from './CurlRequestPreview';
import FlowHeader from './FlowHeader';
import ClientAuthenticationMethod from './settings/ClientAuthenticationMethod';
import MiddlePaneHeader from './MiddlePaneHeader';
import ServerResponse from '../shared/ServerResponse';
import ResizablePanels from '../ResizablePanels';
import ExtraQueryParametersModal from '../modals/ExtraQueryParametersModal';

class ClientCredentialsFlow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedToken: null
        }
    }

    previewRequest = () => {
        const environments = Environments.create(this.props.environments);
        const environment = environments.getEnvironment(this.props.collection.provider);
        const currentCollection = this.props.collection;

        const scopeList = (currentCollection.parameters.scopes) ?
            currentCollection.parameters.scopes.map((scope) => scope.value) : [];
        const scope = scopeList.join(' ');

        const requestParameters = {
            grant_type: 'client_credentials',
            scope
        };

        let authHeader = '';
        if (!currentCollection.parameters.token_endpoint_auth_method || currentCollection.parameters.token_endpoint_auth_method === 'client_secret_basic') {
            authHeader = '-H \'Authorization: Basic ' + base64encode(currentCollection.parameters.client_id + ':' + (currentCollection.parameters.client_secret || '')) + '\' \\\n';
        }
        if (currentCollection.parameters.token_endpoint_auth_method === 'client_secret_post') {
            requestParameters.client_id = currentCollection.parameters.client_id;
            requestParameters.client_secret = currentCollection.parameters.client_secret || '';
        }

        this.props.collection.parameters.token_request_extra_query_parameters?.filter(
            queryParam => queryParam.name !== '' || queryParam.value !== '')
            .forEach(queryParam => {
                requestParameters[queryParam.name] = queryParam.value
            });

        const tokenEndpoint = environment ? environment.endpoints.token_endpoint : '';
        return 'curl -Ss -X POST \\\n' +
            tokenEndpoint + ' \\\n' +
            authHeader +
            '-H \'Content-Type: application/x-www-form-urlencoded\' \\\n' +
            '-d \'' + encodeUrlParameter(requestParameters) + '\''
    };

    runFlow = () => {
        const currentCollection = this.props.collection;
        const environments = Environments.create(this.props.environments);
        const environment = environments.getEnvironment(currentCollection.provider);

        const requestParameters = {
            grant_type: 'client_credentials'
        };

        if (currentCollection.parameters.token_endpoint_auth_method === 'client_secret_post') {
            requestParameters.client_id = currentCollection.parameters.client_id;
            requestParameters.client_secret = currentCollection.parameters.client_secret || '';
        }

        const scopeList = (currentCollection.parameters.scopes) ?
            currentCollection.parameters.scopes.map((scope) => scope.value) : [];
        if (scopeList.length > 0) {
            requestParameters.scope = scopeList.join(' ');
        }

        this.props.collection.parameters.token_request_extra_query_parameters?.filter(
            queryParam => queryParam.name !== '' || queryParam.value !== '')
            .forEach(queryParam => {
                requestParameters[queryParam.name] = queryParam.value
            });

        this.props.tokenEndpointRequest(currentCollection, environment, requestParameters, true, 'ClientCredentialsResponse');
    };

    clearErrorFromCollection = () => {
        this.props.setErrorOnCollection(this.props.collection.id, null)
    };

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {

        const currentCollection = this.props.collection;

        let clientId = null;
        if (currentCollection.parameters.client_id) {
            clientId = currentCollection.parameters.client_id;
        }
        const environments = Environments.create(this.props.environments);
        const environment = environments.getEnvironment(currentCollection.provider);

        const enableStep2 = !!(clientId !== null && environment && environment.canDoClientCredentials());

        const error = (!currentCollection.error) ? '' :
            <div className="alert alert-danger">
                <i className="icon ion-ios-close-outline inlineicon"/>
                {decodeUrlParameter(currentCollection.error)}
                <button className="alert-close" onClick={this.clearErrorFromCollection}><i className="icon ion-close"/>
                </button>
            </div>;

        const curlRequest = this.previewRequest();

        return (
            <React.Fragment>
                <ResizablePanels {...this.props}>
                    <section className="tools-form">
                        <MiddlePaneHeader
                            workspace={environment}
                            collection={this.props.collection}
                            exportCurrentCollection={this.props.exportCurrentCollection}/>

                        <div className="tools-form-content">

                            <FlowHeader name={currentCollection.name}
                                        description={'Enter client ID, client Secret and run a new client credentials flow.'}/>
                            {error}

                            <StepBox title={'Settings'} step={'1'} enabled={true}>

                                <StartHere clientId={clientId}/>

                                <ClientCredentials
                                    updateParameters={this.props.updateParameters}
                                    updateEnvironment={this.props.updateEnvironment}
                                    collection={currentCollection}
                                    environment={environment}
                                    flow={flows.client_credentials}
                                />

                                <div className="sm-flex flex-justify flex-center flex-wrap flex-gap-2 mt2">
                                    <div className={'flex-auto'}>
                                        <Scopes
                                            updateParameters={this.props.updateParameters}
                                            environment={environment}
                                            collection={currentCollection}
                                        />
                                    </div>
                                    <div className={'flex-auto'}>
                                        <ClientAuthenticationMethod
                                            updateParameters={this.props.updateParameters}
                                            environment={environment}
                                            endpoint={'token_endpoint_auth_method'}
                                            serverConfigFrom={'token_endpoint_auth_methods_supported'}
                                            collection={currentCollection}
                                        />
                                    </div>
                                </div>

                                <IntrospectionSettings
                                    collection={currentCollection}
                                    environment={environment}
                                    updateEnvironment={this.props.updateEnvironment}
                                    updateParameters={this.props.updateParameters}
                                />
                            </StepBox>

                            <StepBox title={'Call token endpoint'} step={'2'} enabled={enableStep2}>
                                <div className={'flex justify-end'}>
                                    <ExtraQueryParametersModal
                                        updateParameters={this.props.updateParameters}
                                        collection={this.props.collection}
                                        parameter={'token_request_extra_query_parameters'}
                                    />
                                </div>
                                <CurlRequestPreview request={curlRequest}/>
                                <RunButton runFlow={this.runFlow}/>
                            </StepBox>

                            <ServerResponse response={currentCollection.OAuthResponses.ClientCredentialsResponse}/>

                        </div>
                    </section>

                    <ReceivedTokensSidebar
                        guide={guides.clientcredentials}
                        flow={flows.client_credentials}
                        collection={currentCollection}
                        environment={environment}
                        groups={this.props.groups}
                        clearOAuthResponses={this.props.clearOAuthResponses}
                        setTokensOnCollection={this.props.setTokensOnCollection}
                        introspectToken={this.props.introspectToken}
                        createAndSelectCollectionWithToken={this.props.createAndSelectCollectionWithToken}
                    />
                </ResizablePanels>
            </React.Fragment>
        );
    }
}

export default ClientCredentialsFlow;

/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React, { useEffect, useRef, useState } from 'react';

function FlowModal(props) {
    const ref = useRef(null);
    const [selectedOption, setSelectedOption] = useState(null)

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value)
    };

    const handleStartFlow = () => {
        // Fire event to create new collection and select it.
        props.createAndSelectCollection(selectedOption, props.appState.activeTab, props.groupId);
        props.handleClose();
    };

    useEffect(() => {
        if (selectedOption) {
            handleStartFlow();
            setSelectedOption(null)
        }
    }, [selectedOption])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                props.handleClose();
            }
        };

        const escFunction = (event) => {
            if (event.keyCode === 27) {
                props.handleClose();
            }
        };

        if (props.show) {
            document.addEventListener('mousedown', handleClickOutside, false);
            document.addEventListener('keydown', escFunction, false);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside, false);
            document.removeEventListener('keydown', escFunction, false);
        }
    }, [props.show])


    return (<>
        {props.show &&
        <div className={'modal modal-is-visible'}>
            <section className="modal-main modal-start" ref={ref}>
                <header className="modal-main-header">
                    <div className="flex flex-center justify-between">
                        <h3 className="h4 m0">Start a new flow</h3>
                        <button className="button button-close button-link" onClick={props.handleClose}>
                            <i className="icon ion-close"/>
                        </button>
                    </div>
                </header>

                <section className="modal-main-content modal-main-content-flows">
                    <h4 className="mt0 mb1 primary">Tokens</h4>
                    <div className="action-item-wrapper">
                        <div className="action-item action-item-jwt">

                            <>
                                <input
                                    type="radio"
                                    id={'decode-jwt-tile'}
                                    value="none"
                                    onClick={handleOptionChange}
                                />
                                <label htmlFor={'decode-jwt-tile'}>Decode JWT</label>
                            </>
                        </div>

                        <div className="action-item action-item-create_jwt">
                            <>
                                <input
                                    type="radio"
                                    id={'create-jwt-tile'}
                                    value="create_jwt"
                                    onClick={handleOptionChange}
                                />
                                <label htmlFor={'create-jwt-tile'}>Create JWT</label>
                            </>

                        </div>

                        <div className="action-item action-item-introspect">
                            <>
                                <input
                                    type="radio"
                                    id={'introspect-tile'}
                                    value="introspect"
                                    onClick={handleOptionChange}
                                />
                                <label htmlFor={'introspect-tile'}>Introspection Flow</label>
                            </>
                        </div>

                        <div className="action-item action-item-refresh">
                            <>
                                <input
                                    type="radio"
                                    id={'refresh-tile'}
                                    value="refresh"
                                    onClick={handleOptionChange}
                                />
                                <label htmlFor={'refresh-tile'}>Refresh Tokens</label>
                            </>
                        </div>

                        <div className="action-item action-item-revoke">
                            <>
                                <input
                                    type="radio"
                                    id={'revoke-tile'}
                                    value="revoke"
                                    onClick={handleOptionChange}
                                />
                                <label htmlFor={'revoke-tile'}>Revoke Tokens</label>
                            </>
                        </div>
                    </div>

                    <h4 className="mt3 mb1 primary">Web and Mobile Flows</h4>
                    <div className="action-item-wrapper">
                        <div className="action-item action-item-codeflow">
                            <>
                                <input
                                    type="radio"
                                    id={'codeflow-tile'}
                                    value="code"
                                    data-qa="start-code-flow"
                                    onClick={handleOptionChange}
                                />
                                <label htmlFor={'codeflow-tile'}>Code Flow</label>
                            </>
                        </div>

                        <div className="action-item action-item-implicitflow">
                            <>
                                <input
                                    type="radio"
                                    id={'implicitflow-tile'}
                                    value="implicit"
                                    onClick={handleOptionChange}
                                />
                                <label htmlFor={'implicitflow-tile'}>Implicit Flow</label>
                            </>
                        </div>

                        <div className="action-item action-item-hybrid">
                            <>
                                <input
                                    type="radio"
                                    id={'hybridflow-tile'}
                                    value="hybrid"
                                    onClick={handleOptionChange}
                                />
                                <label htmlFor={'hybridflow-tile'}>Hybrid Flow</label>
                            </>
                        </div>

                        <div className="action-item action-item-device">
                            <>
                                <input
                                    type="radio"
                                    id={'deviceflow-tile'}
                                    value="device"
                                    onClick={handleOptionChange}
                                />
                                <label htmlFor={'deviceflow-tile'}>Device Flow</label>
                            </>
                        </div>

                        <div className="action-item action-item-ciba">
                            <>
                                <input
                                    type="radio"
                                    id={'ciba-tile'}
                                    value="ciba"
                                    onClick={handleOptionChange}
                                />
                                <label htmlFor={'ciba-tile'}>CIBA Flow</label>
                            </>
                        </div>
                    </div>

                    <h4 className="mt3 mb1 primary">API and Backend Flows</h4>
                    <div className="action-item-wrapper">
                        <div className="action-item action-item-clientcredentials">
                            <>
                                <input
                                    type="radio"
                                    id={'client-credentials-tile'}
                                    value="client_credentials"
                                    onClick={handleOptionChange}
                                />
                                <label htmlFor={'client-credentials-tile'}>Client Credentials Flow</label>
                            </>
                        </div>

                        <div className="action-item action-item-tokenexchange">
                            <>
                                <input
                                    type="radio"
                                    id={'token-exchange-tile'}
                                    value="token_exchange"
                                    onClick={handleOptionChange}
                                />
                                <label htmlFor={'token-exchange-tile'}>Token Exchange Flow</label>
                            </>
                        </div>

                        <div className="action-item action-item-ropc">
                            <>
                                <input
                                    type="radio"
                                    id={'ropc-tile'}
                                    value="password"
                                    onClick={handleOptionChange}
                                />
                                <label htmlFor={'ropc-tile'}>ROPC Flow</label>
                            </>
                        </div>

                        <div className="action-item action-item-userinfo">
                            <>
                                <input
                                    type="radio"
                                    id={'userinfo-tile'}
                                    value="userinfo"
                                    onClick={handleOptionChange}
                                />
                                <label htmlFor={'userinfo-tile'}>Userinfo Flow</label>
                            </>
                        </div>
                        <div className="action-item action-item-dcr">
                            <>
                                <input
                                    type="radio"
                                    id={'dcr-tile'}
                                    value="dcr"
                                    onClick={handleOptionChange}
                                />
                                <label htmlFor={'dcr-tile'}>DCR</label>
                            </>
                        </div>

                        <div className="action-item action-item-dcrm">
                            <>
                                <input
                                    type="radio"
                                    id={'dcrm-tile'}
                                    value="dcrm"
                                    onClick={handleOptionChange}
                                />
                                <label htmlFor={'dcrm-tile'}>DCRM</label>
                            </>
                        </div>

                        <div className="action-item action-item-apicall">
                            <>
                                <input
                                    type="radio"
                                    id={'apicall-tile'}
                                    value="apicall"
                                    onClick={handleOptionChange}
                                />
                                <label htmlFor={'apicall-tile'}>Call External API</label>
                            </>
                        </div>
                        <div className="action-item action-item-logout">
                            <>
                                <input
                                    type="radio"
                                    id={'oidc-logout-tile'}
                                    value="oidc_logout"
                                    onClick={handleOptionChange}
                                />
                                <label htmlFor={'oidc-logout-tile'}>Logout</label>
                            </>
                        </div>
                        <div className="action-item action-item-vci">
                            <>
                                <input
                                    type="radio"
                                    id={'vci-tile'}
                                    value="vci"
                                    onClick={handleOptionChange}
                                />
                                <label htmlFor={'vci-tile'}>VCI</label>
                            </>
                        </div>
                        <div className="action-item action-item-precode">
                            <>
                                <input
                                    type="radio"
                                    id={'precode-tile'}
                                    value="precode"
                                    onClick={handleOptionChange}
                                />
                                <label htmlFor={'precode-tile'}>Pre Authorized Code</label>
                            </>
                        </div>
                    </div>
                </section>
            </section>
        </div>
        }
    </>);

}

export default FlowModal;

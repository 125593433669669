/*
 * Copyright (C) 2023 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React, { useEffect, useRef } from 'react'
import JSONInput from '../token/JSONInput';

function RarModal(props) {
    const modalRef = useRef(null);


    useEffect(() => {
        const keyboardPresses = event => {
            if (event.keyCode === 27) {
                props.handleClose();
            }
        };
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                props.handleClose();
            }
        };
        if (props.showRarModal) {
            document.addEventListener('keydown', keyboardPresses, false)
            document.addEventListener('mousedown', handleClickOutside, false)
        }
        return () => {
            document.removeEventListener('keydown', keyboardPresses, false)
            document.removeEventListener('mousedown', handleClickOutside, false)
        }
    }, [props.showRarModal])


    const modalTitle = 'Define authorization_details';

    const showHideClassName = props.showRarModal
        ? 'modal modal-claims modal-is-visible'
        : 'modal modal-claims modal-is-hidden';

    return (
        <div className={showHideClassName}>
            {props.showRarModal &&
            <section
                className="modal-main modal-main-wide"
                ref={modalRef}
            >
                <header className="modal-main-header">
                    <div className="flex flex-center justify-between">
                        <h3 className="h4 m0">{modalTitle}</h3>
                        <div className="flex flex-center justify-between">
                            <button
                                aria-label="Close modal"
                                className="button button-close button-link ml2"
                                onClick={props.handleClose}
                            >
                                <i className="icon ion-close"/>
                            </button>
                        </div>
                    </div>
                </header>
                <div className="modal-main-content">

                    <JSONInput
                        label="Value for authorization_details (if empty, no authorization_details parameter will be sent): "
                        name={'authorization_details'}
                        collection={props.collection}
                        updateParameters={props.updateParameters}
                    />
                </div>
            </section>}
        </div>
    )
}

export default RarModal

/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import * as React from 'react';
import Environments from '../../data/Environments';
import { base64encode, decodeUrlParameter, encodeUrlParameter } from '../../util/util';
import { flows, formatOptionLabel, guides, tokenPurposes } from '../../util/appConstants';
import FlowHeader from './FlowHeader';
import StepBox from './StepBox';
import StartHere from './StartHere';
import ClientCredentials from './settings/ClientCredentials';
import CurlRequestPreview from './CurlRequestPreview';
import makeAnimated from 'react-select/animated';
import Creatable from 'react-select/creatable'
import { isEmpty } from '../../util/validationUtils';
import MiddlePaneHeader from './MiddlePaneHeader';
import ClientAuthenticationMethod from './settings/ClientAuthenticationMethod';
import ReceivedTokensSidebar from '../token/ReceivedTokensSidebar';
import IntrospectionSettings from './settings/IntrospectionSettings';
import ServerResponse from '../shared/ServerResponse';
import ResizablePanels from '../ResizablePanels';
import Scopes from './settings/Scopes';
import { useEffect, useRef } from 'react';
import ExtraQueryParametersModal from '../modals/ExtraQueryParametersModal';

const RefreshFlow = (props) => {
    const runButtonRef = useRef();
    const currentCollection = props.collection;

    const clearErrorFromCollection = () => {
        props.setErrorOnCollection(currentCollection.id, null)
    };

    const setTokenForRefresh = (newValue, actionMeta) => {
        if (actionMeta.action === 'select-option') {
            const updatedParameters = currentCollection.parameters.withUpdatedValue('token_to_refresh', newValue.value);
            props.updateParameters(currentCollection.id, updatedParameters);
        }

        if (actionMeta.action === 'create-option') {
            const updatedParameters =
                currentCollection.parameters
                    .withUpdatedValue('token_to_refresh', newValue.value);
            props.updateParameters(currentCollection.id, updatedParameters);
        }

        if (actionMeta.action === 'clear') {
            const updatedParameters =
                currentCollection.parameters.withUpdatedValue('token_to_refresh', undefined);
            props.updateParameters(currentCollection.id, updatedParameters);
        }
        props.setTokensOnCollection(currentCollection.id, [], [], null, true)
    };

    const previewRequest = () => {
        const environments = Environments.create(props.environments);
        const environment = environments.getEnvironment(currentCollection.provider);

        const tokenEndpoint = environment ? environment.endpoints.token_endpoint : '';

        let authHeader = '';
        if (!currentCollection.parameters.token_endpoint_auth_method || currentCollection.parameters.token_endpoint_auth_method === 'client_secret_basic') {
            authHeader = '-H \'Authorization: Basic ' + base64encode(currentCollection.parameters.client_id + ':' + (currentCollection.parameters.client_secret || '')) + '\' \\\n';
        }
        const requestParameters = resolveRequestParameters();

        return 'curl -Ss -X POST \\\n' +
            tokenEndpoint + ' \\\n' +
            authHeader +
            '-H \'Content-Type: application/x-www-form-urlencoded\' \\\n' +
            '-d \'' + encodeUrlParameter(requestParameters) + '\''
    };
    const resolveRequestParameters = () => {
        const requestParameters = {
            grant_type: 'refresh_token',
            refresh_token: currentCollection.parameters.token_to_refresh
        };
        if (currentCollection.parameters.token_endpoint_auth_method === 'client_secret_post') {
            requestParameters.client_id = currentCollection.parameters.client_id;
            requestParameters.client_secret = currentCollection.parameters.client_secret || '';
        }
        if (currentCollection.parameters.scopes &&
            currentCollection.parameters.scopes.length > 0) {
            let result = [];
            currentCollection.parameters.scopes.forEach(scope => {
                result.push(scope.value);
            })
            requestParameters.scope = result.join(' ');
        }
        currentCollection.parameters.token_request_extra_query_parameters?.filter(
            queryParam => queryParam.name !== '' || queryParam.value !== '')
            .forEach(queryParam => {
                requestParameters[queryParam.name] = queryParam.value
            });
        return requestParameters;
    }

    const runFlow = () => {
        runButtonRef.current.classList.add('button-loading-active', 'button-disabled');

        const environments = Environments.create(props.environments);
        const environment = environments.getEnvironment(currentCollection.provider);
        const requestParameters = resolveRequestParameters();

        props.tokenEndpointRequest(currentCollection, environment, requestParameters, true, 'RefreshTokensResponse');
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (runButtonRef.current){
            runButtonRef.current.classList.remove('button-loading-active', 'button-disabled');
        }
    }, [currentCollection.OAuthResponses, currentCollection.OAuthResponses.RefreshTokensResponse])



    const environments = Environments.create(props.environments);
    const environment = environments.getEnvironment(currentCollection.provider);

    const selectedToken = currentCollection.parameters.token_to_refresh;

    const enableStep2 = !!(selectedToken && environment && environment.canDoRefresh());

    const error = (!currentCollection.error) ? '' :
        <div className="alert alert-danger">
            <i className="icon ion-ios-close-outline inlineicon"/>
            {decodeUrlParameter(currentCollection.error)}
            <button className="alert-close" onClick={clearErrorFromCollection}><i className="icon ion-close"/>
            </button>
        </div>;

    const curlRequest = previewRequest();

    let accessTokensFromCollections = [];
    const tokenToRefresh = currentCollection.parameters.token_to_refresh;

    Object.values(props.collections)
        .filter(collection => collection.provider === currentCollection.provider)
        .forEach(collection => {
            if (flows[collection.flow].produces_tokens) {
                Object.values(collection.tokens).forEach(token => {
                    if (!isEmpty(token.value) && token.purpose === tokenPurposes.refresh_token.value) {
                        const ellipsis = token.value.length > 20 ? '...' : '';
                        accessTokensFromCollections.push({
                            value: token.value,
                            label: `${collection.name}: ${token.name}`,
                            tokenString: `${token.value.substring(0, 20)}${ellipsis}`,
                            meta: {
                                purpose: token.purpose,
                                name: token.name
                            }
                        });
                    }
                });
            }
        });

    const foundTokenInOptions = accessTokensFromCollections.filter(option => option.value === tokenToRefresh);
    if (foundTokenInOptions.length === 0 && tokenToRefresh) {
        accessTokensFromCollections.push({
            value: tokenToRefresh,
            label: tokenToRefresh,
            meta: {
                purpose: 'refresh_token',
                name: ''
            }
        });
    }
    const selectedOption = foundTokenInOptions.length === 0 ?
        accessTokensFromCollections.filter(option => option.value === tokenToRefresh) : foundTokenInOptions;

    return (
        <React.Fragment>
            <ResizablePanels {...props}>
                <section className="tools-form">
                    <MiddlePaneHeader
                        collection={currentCollection}
                        exportCurrentCollection={props.exportCurrentCollection}/>

                    <div className="tools-form-content">

                        <FlowHeader name={currentCollection.name}
                                    description={'Select a token from an existing collection or type a new one to refresh by calling the token endpoint.'}/>
                        {error}

                        <StepBox title={'Settings'} step={'1'} enabled={true}>

                            <StartHere clientId={selectedOption}/>

                            <ClientCredentials
                                updateParameters={props.updateParameters}
                                updateEnvironment={props.updateEnvironment}
                                collection={currentCollection}
                                environment={environment}
                                flow={flows.refresh}
                            />

                            <div className="sm-flex flex-justify flex-center flex-gap-2 flex-wrap mt2">
                                <div className={'flex-auto'}>
                                    <Scopes
                                        updateParameters={props.updateParameters}
                                        environment={environment}
                                        collection={currentCollection}
                                    />
                                </div>
                                <div className="flex-auto">
                                    <ClientAuthenticationMethod
                                        updateParameters={props.updateParameters}
                                        environment={environment}
                                        endpoint={'token_endpoint_auth_method'}
                                        serverConfigFrom={'token_endpoint_auth_methods_supported'}
                                        collection={currentCollection}/>
                                </div>
                            </div>

                            <div className="sm-flex flex-justify flex-center mt2">
                                <div className="flex-100">
                                    <Creatable
                                        isClearable
                                        placeholder="Enter a token or select one from a Collection"
                                        components={makeAnimated()}
                                        options={accessTokensFromCollections}
                                        formatOptionLabel={formatOptionLabel}
                                        defaultValue={selectedOption}
                                        onChange={setTokenForRefresh}
                                        inputId={'select-token-refresh-flow'}
                                        className="select-container select-container-big"
                                        classNamePrefix="react-select"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#f2f3f6',
                                                primary: '#626c87'
                                            }
                                        })}
                                    />
                                </div>
                            </div>
                            <IntrospectionSettings
                                collection={currentCollection}
                                environment={environment}
                                updateEnvironment={props.updateEnvironment}
                                updateParameters={props.updateParameters}
                            />
                        </StepBox>

                        <StepBox title={'Call Token Endpoint'} step={'2'} enabled={enableStep2}>
                            <div className={'flex justify-end'}>
                                <ExtraQueryParametersModal
                                    updateParameters={props.updateParameters}
                                    collection={props.collection}
                                    parameter={'token_request_extra_query_parameters'}
                                />
                            </div>
                            <CurlRequestPreview request={curlRequest}/>
                            <div className="mt2 center">
                                <button ref={runButtonRef}
                                        onClick={runFlow}
                                        className="button button-medium button-success button-run button-loading">
                                    <span><i className="icon ion-play inlineicon"/>Refresh Token</span>
                                </button>
                            </div>
                        </StepBox>

                        <ServerResponse response={currentCollection.OAuthResponses.RefreshTokensResponse}/>

                    </div>
                </section>

                <ReceivedTokensSidebar
                    guide={guides.refresh}
                    flow={flows.refresh}
                    collection={currentCollection}
                    environment={environment}
                    groups={props.groups}
                    clearOAuthResponses={props.clearOAuthResponses}
                    setTokensOnCollection={props.setTokensOnCollection}
                    introspectToken={props.introspectToken}
                    createAndSelectCollectionWithToken={props.createAndSelectCollectionWithToken}
                />
            </ResizablePanels>
        </React.Fragment>
    );

}

export default RefreshFlow

/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import Danger from '../icons/Danger';
import Warning from '../icons/Warning';
import Parameters from '../../data/Parameters';
import { flows, tokenPurposes } from '../../util/appConstants';

const listErrors = (fields) => {
    if (fields) {
        return (
            fields.length > 0 ?
                [
                    <div key="missing_fields"
                         className="m1 tools-sidebar-box-validation tools-sidebar-box-validation-error">
                        <label className="block mb2">Errors</label>
                        {fields.map((item, index) => {
                            return (<div key={index}><span><Danger/> {item.name} - {item.message}</span></div>)
                        })}
                    </div>
                ]
                :
                null
        )
    }
    return '';
};

const listWarnings = (fields) => {
    if (fields) {
        return (
            fields.length > 0 ?
                [
                    <div key="missing_optional_fields"
                         className="m1 tools-sidebar-box-validation tools-sidebar-box-validation-warning">
                        <label className="block mb2">Warnings</label>
                        {fields.map((item, index) => {
                            return (<div key={index}><span><Warning/> {item.name} - {item.message}</span></div>)
                        })}
                    </div>
                ]
                :
                null
        )
    }
    return '';
};

const populateMismatchFields = (missingFields, tokenBody, props) => {
    const { tokenPurposeType, collection, environment } = props;
    let errorsListOnCollection = [];
    let errorsListOnEnvironment = [];

    // Run for all Flows except JWT Token as 'JWT Token' flow with field of 'id' with value of `none`:
    if ((collection && collection.flow !== flows.none.id) && (tokenPurposeType === tokenPurposes.id_token.value)) {
        errorsListOnCollection = populateMismatchFieldsOnCollection(tokenBody, collection);
    }
    if ((environment && collection.flow !== flows.none.id) && (tokenPurposeType === tokenPurposes.id_token.value)) {
        errorsListOnEnvironment = populateMismatchFieldsOnEnvironment(tokenBody, environment);
    }
    if (errorsListOnCollection.length > 0 || errorsListOnEnvironment.length > 0) {
        return [
            ...missingFields,
            ...errorsListOnCollection,
            ...errorsListOnEnvironment
        ];
    }
    return missingFields;
};

const populateMismatchFieldsOnCollection = (tokenBody, collection) => {
    const { aud, nonce } = tokenBody;
    const { parameters } = collection;
    const mismatchedFields = [];

    if (parameters && Object.keys(parameters).length > 0) {
        const params = new Parameters(parameters);
        if (aud) {
            if ((Array.isArray(aud) && !aud.includes(params.client_id)) || (params.client_id !== aud && typeof aud === 'string')) {
                mismatchedFields.push({
                    'name': 'aud',
                    'message': 'Does not match client ID'
                });
            }
        }
        if (nonce && params.nonce !== nonce) {
            mismatchedFields.push({
                'name': 'nonce',
                'message': 'Does not match nonce'
            });
        }
        return mismatchedFields;
    }
    return mismatchedFields;
};

const populateMismatchFieldsOnEnvironment = (tokenBody, environment) => {
    const { iss } = tokenBody;
    const { issuer } = environment;
    const mismatchedFields = [];

    if (iss && issuer) {
        let matched = true;
        if (typeof iss === 'string' && issuer !== iss) {
            matched = false;
        }
        if (!matched) {
            mismatchedFields.push({
                'name': 'iss',
                'message': "Does not match environment's issuer"
            });
        }
        return mismatchedFields;
    }
    return mismatchedFields;
};


class ValidationReport extends React.Component {
    render() {
        if (this.props.validationData && Object.keys(this.props.validationData).length>0 ) {
            const { missing_fields, missing_optional_fields } = this.props.validationData;
            const errorsList = populateMismatchFields(missing_fields, this.props.tokenBody, this.props);
            const errorsMarkup = listErrors(errorsList);
            const warningsMarkup = listWarnings(missing_optional_fields);

            return (
                <React.Fragment>
                    {errorsMarkup}
                    {warningsMarkup}
                </React.Fragment>
            )
        } else {
            return '';
        }
    }
}

export default ValidationReport;

/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React, { Component } from 'react';
import AceEditor from 'react-ace';
import { decodeUrlParameter } from '../../util/util';
import {
    API_CALL_REQUEST_METHODS,
    CONTENT_TYPE_HEADERS_BY_BODY_TYPE,
    flows,
    formatOptionLabel,
    guides,
    HEADER_SUGGESTIONS,
    HEADER_VALUE_SUGGESTIONS,
    QUERY_PARAMETER_SUGGESTIONS,
    tokenPurposes
} from '../../util/appConstants';
import FlowHeader from './FlowHeader';
import CurlRequestPreview from './CurlRequestPreview';
import makeAnimated from 'react-select/animated';
import Creatable from 'react-select/creatable'
import { isEmpty } from '../../util/validationUtils';
import Token from '../../data/Token';
import EmptySidebar from '../EmptySidebar';
import Guide from '../guides/Guide';
import MiddlePaneHeader from './MiddlePaneHeader';
import ClearTokens from '../ClearTokens';
import Select from 'react-select';
import ResponseBody from '../sidebar/response/ResponseBody';
import ResponseStatusCode from '../sidebar/response/ResponseStatusCode';
import ResponseHeaders from '../sidebar/response/ResponseHeaders';
import Autosuggest from 'react-autosuggest';
import ResizablePanels from '../ResizablePanels';

function escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function getSuggestions(value, suggestions) {
    const escapedValue = escapeRegexCharacters(value.trim());

    if (escapedValue === '') {
        return [];
    }

    const regex = new RegExp('' + escapedValue, 'i');

    return suggestions.filter(suggestion => regex.test(suggestion));
}

function getSuggestionValue(suggestion) {
    return suggestion;
}

function renderSuggestion(suggestion) {
    return (
        <span>{suggestion}</span>
    );
}


class CallAPIFlow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            option: 'headers',
            suggestions: [],
            guideIsVisible: false
        };
        this.runButtonRef = React.createRef();
        this.aceEditorRef = React.createRef();
    }

    toggleGuideVisible = (isVisible) => {
        this.setState( { guideIsVisible: isVisible })
    }

    clearErrorFromCollection = () => {
        this.props.setErrorOnCollection(this.props.collection.id, null)
    };

    setRequestMethod = (selectedOption) => {
        const updatedParameters =
            this.props.collection.parameters.withUpdatedValue('api_call_request_method', selectedOption);
        this.props.updateParameters(this.props.collection.id, updatedParameters);
    };

    setAPIEndpoint = (event) => {
        const updatedParameters = this.props.collection.parameters.withUpdatedValue('api_call_endpoint', event.currentTarget.value);
        this.props.updateParameters(this.props.collection.id, updatedParameters);
    };

    setTokenForApiCall = (newValue, actionMeta) => {
        let tokens = [];
        if (actionMeta.action === 'select-option') {
            const updatedParameters = this.props.collection.parameters.withUpdatedValue('api_call_token', newValue.value);
            this.props.updateParameters(this.props.collection.id, updatedParameters);
            tokens.push(Token.createNewToken({
                purpose: newValue.meta.purpose,
                name: newValue.meta.name,
                value: newValue.value
            }));

        }

        if (actionMeta.action === 'create-option') {
            const updatedParameters =
                this.props.collection.parameters
                    .withUpdatedValue('api_call_token', newValue.value);
            this.props.updateParameters(this.props.collection.id, updatedParameters);
            tokens.push(Token.createNewToken({
                purpose: tokenPurposes.unknown.value,
                name: 'Unknown token',
                value: newValue.value
            }));
        }

        if (actionMeta.action === 'clear') {
            const updatedParameters =
                this.props.collection.parameters.withUpdatedValue('api_call_token', undefined);
            this.props.updateParameters(this.props.collection.id, updatedParameters);
        }
        this.props.setTokensOnCollection(this.props.collection.id, tokens, [], null, true)
    };

    previewRequest = () => {
        const requestMethod = this.props.collection.parameters.api_call_request_method ?
            this.props.collection.parameters.api_call_request_method.value : null;
        let apiEndpoint = this.props.collection.parameters.api_call_endpoint;

        const headers = this.props.collection.parameters.api_call_headers;
        let extraHeaders = '';
        headers.filter(header => header.name !== '').forEach(header => {
            extraHeaders += ' \\\n';
            extraHeaders += "-H '" + header.name;
            extraHeaders += header.value === '' ? ';' : ': ' + header.value + "'";
        });

        const queryParameters = this.props.collection.parameters.api_call_parameters;
        let queryParametersMap = queryParameters.filter(queryParam => queryParam.name !== '' || queryParam.value !== '')
            .map(queryParam => {
                return encodeURIComponent(queryParam.name) + '=' + encodeURIComponent(queryParam.value)
            });

        if (queryParametersMap.length > 0) {
            apiEndpoint += '?' + queryParametersMap.join('&')
        }

        let body = this.props.collection.parameters.api_call_body;
        let request_body = '';
        if (requestMethod !== 'GET' && requestMethod !== 'OPTIONS' && body !== '') {
            const bodyType = this.props.collection.parameters.api_call_body_type;
            if (bodyType === 'form') {
                this.props.collection.parameters.api_call_form_body.forEach(formParam => {
                    if (formParam.name !== '') {
                        request_body += " \\\n--data-urlencode '" + formParam.name + '=' + formParam.value + "'"
                    }
                });
            } else {

                request_body = " \\\n--data-raw '" + this.props.collection.parameters.api_call_body + "'"

            }
        }

        const bearerHeader = this.props.collection.parameters.api_call_token ?
            " \\\n-H 'Authorization: Bearer " + this.props.collection.parameters.api_call_token + "'" : '';

        return 'curl -Ss -X ' + requestMethod + ' \\\n' +
            apiEndpoint +
            extraHeaders +
            bearerHeader +
            request_body

    };

    runFlow = event => {
        event.currentTarget.classList.add('button-loading-active', 'button-disabled');
        event.currentTarget.blur();
        const token = this.props.collection.getTokenById(0);
        this.props.externalAPIRequest(token, this.props.collection);
    };

    componentDidUpdate() {
        this.aceEditorRef.current.editor.resize();
        if (this.runButtonRef) {
            this.runButtonRef.current.classList.remove('button-loading-active', 'button-disabled');
        }
    }

    clearResponse = () => {
        this.props.clearResponseInCollection(this.props.collection.id);
        this.clearErrorFromCollection();
    };

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    changeApiOptions = option => {
        this.setState({
            option
        });
    };

    addEmptyFormParam = () => {
        let parameters = this.props.collection.parameters.api_call_form_body;
        const parameterExists = parameters.find(param => param.name === '' && param.value === '');
        if (parameterExists === undefined) {
            parameters.push({ name: '', value: '', id: Math.random() });
            const updatedParameters =
                this.props.collection.parameters.withUpdatedValue('api_call_form_body', parameters);
            this.props.updateParameters(this.props.collection.id, updatedParameters);
        }
    };

    onUpdateFormParam = (value, i, field) => {
        let parameters = Object.assign(this.props.collection.parameters.api_call_form_body);
        parameters[i][field] = value;
        const updatedParameters = this.props.collection.parameters
            .withUpdatedValue('api_call_form_body', parameters);

        this.addEmptyQueryParameter();
        this.expandFormParametersToBody(updatedParameters, parameters);
    };

    expandFormParametersToBody = (collectionParameters, formParameters) => {
        let expandedFormParams = [];
        formParameters.filter(formParam => formParam.name !== '').forEach(formParam => {
            expandedFormParams.push(formParam.name + '=' + encodeURIComponent(formParam.value));
        });
        const updatedParameters = collectionParameters
            .withUpdatedValue('api_call_body', expandedFormParams.join('&'));
        this.props.updateParameters(this.props.collection.id, updatedParameters);
    };

    onDeleteFormParam = (event, i) => {
        let parameters = [...this.props.collection.parameters.api_call_form_body];
        parameters.splice(i, 1);
        if (parameters.length === 0) {
            parameters.push({ name: '', value: '', id: Math.random() })
        }
        const updatedParameters =
            this.props.collection.parameters.withUpdatedValue('api_call_form_body', parameters);
        this.expandFormParametersToBody(updatedParameters, parameters);
    };

    addEmptyQueryParameter = () => {
        let parameters = this.props.collection.parameters.api_call_parameters;
        const parameterExists = parameters.find(param => param.name === '' && param.value === '');
        if (parameterExists === undefined) {
            parameters.push({ name: '', value: '', id: Math.random() });
            const updatedParameters =
                this.props.collection.parameters.withUpdatedValue('api_call_parameters', parameters);
            this.props.updateParameters(this.props.collection.id, updatedParameters);
        }
    };

    onUpdateQueryParameter = (value, i, field) => {
        let parameters = Object.assign(this.props.collection.parameters.api_call_parameters);
        parameters[i][field] = value;
        const updatedParameters =
            this.props.collection.parameters.withUpdatedValue('api_call_parameters', parameters);
        this.props.updateParameters(this.props.collection.id, updatedParameters);
        this.addEmptyQueryParameter()
    };

    onDeleteQueryParameter = (event, i) => {
        let parameters = [...this.props.collection.parameters.api_call_parameters];
        parameters.splice(i, 1);
        if (parameters.length === 0) {
            parameters.push({ name: '', value: '', id: Math.random() })
        }
        const updatedParameters =
            this.props.collection.parameters.withUpdatedValue('api_call_parameters', parameters);
        this.props.updateParameters(this.props.collection.id, updatedParameters);
    };

    addHeader = (name, value) => {
        let headers = this.props.collection.parameters.api_call_headers ?
            this.props.collection.parameters.api_call_headers : [];
        const headerPos = headers.findIndex(header => (header.name === name) || (header.name === '' && header.value === ''));
        if (headerPos === -1) {
            headers.push({ name, value, id: Math.random() });
        } else {
            headers[headerPos] = { name, value, id: Math.random() };
        }
        return this.props.collection.parameters.withUpdatedValue('api_call_headers', headers);
    };

    addEmptyHeader = () => {
        let headers = this.props.collection.parameters.api_call_headers;
        const headerExists = headers.find(h => h.name === '' && h.value === '');
        if (headerExists === undefined) {
            headers.push({ name: '', value: '', id: Math.random() });
            const updatedParameters =
                this.props.collection.parameters.withUpdatedValue('api_call_headers', headers);
            this.props.updateParameters(this.props.collection.id, updatedParameters);
        }
    };

    onUpdateHeader = (value, i, field) => {
        let headers = this.props.collection.parameters.api_call_headers;
        headers[i][field] = value;
        const updatedParameters =
            this.props.collection.parameters.withUpdatedValue('api_call_headers', headers);
        this.props.updateParameters(this.props.collection.id, updatedParameters);
        this.addEmptyHeader()
    };

    onDeleteHeader = (event, i) => {
        let headers = [...this.props.collection.parameters.api_call_headers];
        headers.splice(i, 1);
        if (headers.length === 0) {
            headers.push({ name: '', value: '', id: Math.random() })
        }
        const updatedParameters =
            this.props.collection.parameters.withUpdatedValue('api_call_headers', headers);
        this.props.updateParameters(this.props.collection.id, updatedParameters);
    };

    setBodyType = (event) => {
        const bodyType = event.currentTarget.value;
        const updatedParameters =
            this.addHeader('Content-Type', CONTENT_TYPE_HEADERS_BY_BODY_TYPE[bodyType])
                .withUpdatedValue('api_call_body_type', bodyType);
        this.props.updateParameters(this.props.collection.id, updatedParameters);
    };

    setBody = (newValue) => {
        const splitParams = newValue.split('&');
        const formParameters = [];
        splitParams.forEach(param => {

            const splitted = param.split('=');
            const value = splitted[1] ? splitted[1] : '';
            if (splitParams.length === 1 && splitted.length === 1) {
                formParameters.push({ name: '', value: '', id: Math.random() })
            } else {
                formParameters.push({ name: splitted[0], value, id: Math.random() })
            }
        });

        const updatedParameters = this.props.collection.parameters
            .withUpdatedValue('api_call_body', newValue)
            .withUpdatedValue('api_call_form_body', formParameters);

        this.props.updateParameters(this.props.collection.id, updatedParameters);
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    onSuggestionsFetchRequested = ({ value }, suggestions) => {
        this.setState({
            suggestions: getSuggestions(value, suggestions)
        });
    };

    render() {

        const currentCollection = this.props.collection;

        const selectedToken = this.props.collection.tokens[0];
        const selectedAPIEndpoint = this.props.collection.parameters.api_call_endpoint ? this.props.collection.parameters.api_call_endpoint : '';
        const selectedRequestMethod = this.props.collection.parameters.api_call_request_method;

        const enableStep2 = !!(selectedAPIEndpoint !== '' && selectedRequestMethod);

        const error = (!currentCollection.error) ? '' :
            <div className="alert alert-danger mb2">
                <i className="icon ion-ios-close-outline inlineicon"/>
                {decodeUrlParameter(currentCollection.error)}
                <button className="alert-close" onClick={this.clearErrorFromCollection}><i className="icon ion-close"/>
                </button>
            </div>;

        const curlRequest = this.previewRequest();

        let accessTokensFromCollections = [];
        Object.values(this.props.collections)
          .filter(collection => collection.provider === currentCollection.provider)
          .forEach(collection => {
            if (flows[collection.flow].produces_tokens) {
                Object.values(collection.tokens).forEach(token => {
                    if (!isEmpty(token.value) && token.purpose !== tokenPurposes.id_token.value &&
                        token.purpose !== tokenPurposes.refresh_token.value) {
                        const ellipsis = token.value.length > 20 ? '...' : '';
                        accessTokensFromCollections.push({
                            value: token.value,
                            label: `${collection.name}: ${token.name}`,
                            tokenString: `${token.value.substring(0, 20)}${ellipsis}`,
                            meta: {
                                purpose: token.purpose,
                                name: token.name
                            }
                        });
                    }
                });
            }
        });

        const selectedOption = selectedToken ?
            accessTokensFromCollections.filter(option => option.value === selectedToken.value) : null;

        const response = currentCollection.response;

        const api_call_headers = currentCollection.parameters.api_call_headers;
        const api_call_params = currentCollection.parameters.api_call_parameters;
        const api_call_form_body = currentCollection.parameters.api_call_form_body;

        const show_body_tab = selectedRequestMethod && selectedRequestMethod.value !== 'GET' && selectedRequestMethod.value !== 'OPTIONS';
        const bodyType = currentCollection.parameters.api_call_body_type;

        return (
            <React.Fragment>
                <ResizablePanels {...this.props}>
                    <section className="tools-form">
                        <MiddlePaneHeader
                            collection={this.props.collection}
                            exportCurrentCollection={this.props.exportCurrentCollection}/>
                        <div className="tools-form-content">

                            <FlowHeader name={currentCollection.name}
                                        description={'Select a token from an existing collection or type a new one to call an external API endpoint.'}/>
                            {error}

                            <fieldset className="fieldset fieldset-step-api">
                                <h3 className="m0 mb2">Settings</h3>
                                <div className="sm-flex flex-justify flex-center flex-wrap flex-gap-2 mt2">
                                    <div className="flex-auto">
                                        <input className="field col-12" type="url" pattern="https?://.+"
                                            placeholder="API Endpoint"
                                            value={selectedAPIEndpoint}
                                            onChange={this.setAPIEndpoint}
                                            autoComplete="off"
                                            data-lpignore="true"
                                            spellCheck="false"
                                        />
                                    </div>
                                </div>

                                <div className="lg-flex flex-justify flex-center flex-wrap flex-gap-2 mt2">
                                    <div className="flex-auto">
                                        <Select
                                            isMulti={false}
                                            isClearable={true}
                                            inputProps={{ id: 'select-method' }}
                                            placeholder={'Select a request method'}
                                            components={makeAnimated()}
                                            options={API_CALL_REQUEST_METHODS}
                                            value={selectedRequestMethod}
                                            onChange={this.setRequestMethod}
                                            className="select-container select-container-big"
                                            classNamePrefix="react-select"
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#f2f3f6',
                                                    primary: '#626c87'
                                                }
                                            })}
                                        />
                                    </div>
                                    <div className="flex-auto">
                                        <Creatable
                                            isClearable
                                            placeholder="Type a token or select one from a Collection"
                                            components={makeAnimated()}
                                            inputId={'select-token-api-call'}
                                            options={accessTokensFromCollections}
                                            formatOptionLabel={formatOptionLabel}
                                            defaultValue={selectedOption}
                                            onChange={this.setTokenForApiCall}
                                            className="select-container select-container-big"
                                            classNamePrefix="react-select"
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#f2f3f6',
                                                    primary: '#626c87'
                                                }
                                            })}
                                        />
                                    </div>
                                    <div>
                                        <button ref={this.runButtonRef}
                                                disabled={!enableStep2}
                                                onClick={this.runFlow}
                                                className="button button-medium button-success button-run button-input button-loading">
                                            <span>Send <i className="icon ion-ios-paperplane ml1"
                                                        style={{ transform: 'rotate(45deg) scale(1.1)' }}/></span>
                                        </button>
                                    </div>
                                </div>

                                <header className="api-options mt2">
                                    <div role="tablist" className="flex list-reset m0-flex mb2">
                                        <button role="tab"
                                                className={`${this.state.option === 'headers' ? 'active' : null}`}
                                                onClick={() => {
                                                    this.changeApiOptions('headers');
                                                }}>
                                            Headers
                                        </button>
                                        <button role="tab" className={`${this.state.option === 'params' ? 'active' : null}`}
                                                onClick={() => {
                                                    this.changeApiOptions('params');
                                                }}>
                                            Query Parameters
                                        </button>

                                        {show_body_tab ?
                                            <button tabIndex="0" role="tab"
                                                    className={`${this.state.option === 'body' ? 'active' : null}`}
                                                    onClick={() => {
                                                        this.changeApiOptions('body');
                                                    }}>
                                                Body
                                            </button>
                                            : ''}
                                    </div>
                                </header>

                                <div className={`h4 ${this.state.option === 'params' ? '' : 'hide'}`}>
                                    <div className="api-options-headers">
                                        {api_call_params.map((parameter, i) => (
                                            <div className="flex justify-between mb1" key={parameter.id}>
                                                <div className="flex-auto pr1">
                                                    <Autosuggest
                                                        suggestions={this.state.suggestions}
                                                        inputProps={{
                                                            className: 'field col-12',
                                                            placeholder: 'New parameter',
                                                            type: 'text',
                                                            onFocus: this.addEmptyQueryParameter(),
                                                            onChange: (e, { newValue }) =>
                                                                this.onUpdateQueryParameter(newValue, i, 'name'),
                                                            value: parameter.name
                                                        }}
                                                        onSuggestionsFetchRequested={({ value, reason }) =>
                                                            this.onSuggestionsFetchRequested({ value, reason },
                                                                QUERY_PARAMETER_SUGGESTIONS)}
                                                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                                        getSuggestionValue={getSuggestionValue}
                                                        renderSuggestion={renderSuggestion}
                                                    />
                                                </div>
                                                <div className="flex-auto pr1">
                                                    <input
                                                        className="field col-12"
                                                        type="text"
                                                        placeholder="Value"
                                                        onFocus={this.addEmptyQueryParameter()}
                                                        onChange={e => this.onUpdateQueryParameter(e.currentTarget.value, i, 'value')}
                                                        defaultValue={parameter.value}
                                                    />
                                                </div>
                                                <div>
                                                    <button
                                                        className="button button-light button-small button-input"
                                                        onClick={e => this.onDeleteQueryParameter(e, i)}
                                                    >
                                                        <i className="icon ion-trash-a h4"/>
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <div className={`h4 ${this.state.option === 'headers' ? '' : 'hide'}`}>
                                    <div className="api-options-headers">
                                        {api_call_headers.map((header, i) => (

                                            <div className="flex justify-between mb1" key={header.id}>
                                                <div className="flex-auto pr1">
                                                    <Autosuggest
                                                        suggestions={this.state.suggestions}
                                                        inputProps={{

                                                            className: 'field col-12',
                                                            placeholder: 'Header Name',
                                                            type: 'text',
                                                            onFocus: this.addEmptyHeader(),
                                                            onChange: (e, { newValue }) => this.onUpdateHeader(newValue, i, 'name'),
                                                            value: header.name
                                                        }}
                                                        onSuggestionsFetchRequested={({ value, reason }) =>
                                                            this.onSuggestionsFetchRequested({ value, reason },
                                                                HEADER_SUGGESTIONS)}
                                                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                                        getSuggestionValue={getSuggestionValue}
                                                        renderSuggestion={renderSuggestion}
                                                    />
                                                </div>
                                                <div className="flex-auto pr1">
                                                    {HEADER_VALUE_SUGGESTIONS[header.name] ?
                                                        <Autosuggest
                                                            suggestions={this.state.suggestions}
                                                            inputProps={{

                                                                className: 'field col-12',
                                                                placeholder: 'Header Value',
                                                                type: 'text',
                                                                onFocus: this.addEmptyHeader(),
                                                                onChange: (e, { newValue }) => this.onUpdateHeader(newValue, i, 'value'),
                                                                value: header.value
                                                            }}
                                                            onSuggestionsFetchRequested={
                                                            ({ value, reason }) => this.onSuggestionsFetchRequested({
                                                                value,
                                                                reason
                                                            }, HEADER_VALUE_SUGGESTIONS[header.name])}
                                                            onSuggestionsClearRequested={
                                                            this.onSuggestionsClearRequested}
                                                            getSuggestionValue={getSuggestionValue}
                                                            renderSuggestion={renderSuggestion}
                                                        />
                                                        :
                                                        <input
                                                            className="field col-12"
                                                            type="text"
                                                            placeholder="Header Value"
                                                            onFocus={() => this.addEmptyHeader()}
                                                            onChange={e => this.onUpdateHeader(e.currentTarget.value, i, 'value')}
                                                            defaultValue={header.value}
                                                        />
                                                    }
                                                </div>
                                                <div>
                                                    <button
                                                        className="button button-light button-small button-input"
                                                        onClick={e => this.onDeleteHeader(e, i)}
                                                    >
                                                        <i className="icon ion-trash-a h4"/>
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <div
                                    className={`h4 ${show_body_tab && this.state.option === 'body' ? '' : 'hide'}`}>
                                    <div className="flex mb2">
                                        <div className="custom-checkbox mr1">
                                            <input
                                                className="form-control custom-checkbox"
                                                name="bodytype"
                                                id="json"
                                                type="radio"
                                                value="json"
                                                onChange={this.setBodyType}
                                                checked={bodyType === 'json'}
                                            />
                                            <label className="ml1" htmlFor="json">
                                                JSON
                                            </label>
                                        </div>
                                        <div className="custom-checkbox mr1">
                                            <input
                                                className="form-control custom-checkbox"
                                                name="bodytype"
                                                id="text"
                                                type="radio"
                                                value="text"
                                                onChange={this.setBodyType}
                                                checked={bodyType === 'text'}
                                            />

                                            <label className="ml1" htmlFor="text">
                                                Plain text
                                            </label>
                                        </div>
                                        <div className="custom-checkbox">
                                            <input
                                                className="form-control"
                                                name="bodytype"
                                                id="form"
                                                type="radio"
                                                value="form"
                                                onChange={this.setBodyType}
                                                checked={bodyType === 'form'}
                                            />

                                            <label className="ml1" htmlFor="form">
                                                Form URL encoded
                                            </label>
                                        </div>
                                    </div>
                                    <div className={`h4 ${bodyType !== 'form' ? '' : 'hide'}`}>
                                        <div className="ace-editor-wrapper px2 py2">
                                            <AceEditor
                                                ref={this.aceEditorRef}
                                                placeholder="Enter the request body"
                                                mode={bodyType}
                                                theme="monokai"
                                                name="body-editor"
                                                onChange={this.setBody}
                                                fontSize={14}
                                                focus={this.state.option === 'body'}
                                                showPrintMargin={true}
                                                showGutter={true}
                                                highlightActiveLine={true}
                                                indentedSoftWrap={false}
                                                wrapEnabled={true}
                                                width="100%"
                                                value={currentCollection.parameters.api_call_body}
                                                maxLines={20}
                                                minLines={10}
                                                editorProps={{ $blockScrolling: true }}
                                                setOptions={{
                                                    indentedSoftWrap: false,
                                                    enableBasicAutocompletion: true,
                                                    enableLiveAutocompletion: false,
                                                    enableSnippets: false,
                                                    showLineNumbers: false,
                                                    tabSize: 2
                                                }}/>
                                        </div>
                                    </div>
                                    <div className={`h4 ${bodyType === 'form' ? '' : 'hide'}`}>
                                        <div className="api-options-body-form">
                                            {api_call_form_body.map((formParam, i) => (

                                                <div className="flex justify-between mb1" key={formParam.id}>
                                                    <div className="flex-auto pr1">
                                                        <input
                                                            className="field col-12"
                                                            type="text"
                                                            placeholder="Key"
                                                            onFocus={this.addEmptyFormParam()}
                                                            onChange={e => this.onUpdateFormParam(e.currentTarget.value, i, 'name')}
                                                            defaultValue={formParam.name}
                                                        />
                                                    </div>
                                                    <div className="flex-auto pr1">
                                                        <input
                                                            className="field col-12"
                                                            type="text"
                                                            placeholder="Value"
                                                            onFocus={this.addEmptyFormParam()}
                                                            onChange={e => this.onUpdateFormParam(e.currentTarget.value, i, 'value')}
                                                            defaultValue={formParam.value}
                                                        />
                                                    </div>
                                                    <div>
                                                        <button
                                                            className="button button-light button-small button-input"
                                                            onClick={e => this.onDeleteFormParam(e, i)}
                                                        >
                                                            <i className="icon ion-trash-a h4"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>


                                {enableStep2 ? <CurlRequestPreview request={curlRequest}/> : ''}
                            </fieldset>

                        </div>
                    </section>

                    {response ?
                        <aside className="tools-sidebar">
                            <header className="tools-form-header tools-form-header-tokens">
                                <h4 className="m0"/>
                                <div className="flex justify-between flex-center">
                                    <ClearTokens clearTokens={this.clearResponse}
                                                showClearButton={response.status || response.body || response.headers}/>
                                </div>
                            </header>
                            <Guide area={guides.apicall} toggle={this.toggleGuideVisible}/>
                            {!this.state.guideIsVisible && <main role="contentinfo">
                                <ResponseStatusCode responseStatusCode={response.status}/>
                                <ResponseBody responseBody={response.body}
                                            responseHeaders={response.headers}/>
                                <ResponseHeaders responseHeaders={response.headers}/>
                            </main>}
                        </aside>
                        :
                        <aside className="tools-sidebar">
                        <EmptySidebar
                            guide={guides.apicall}
                            clearTokens={this.clearResponse}
                            collection={this.props.collection}
                            text="Run the flow to get the external API call result"/>
                        </aside>

                    }
                </ResizablePanels>
            </React.Fragment>
        );
    }
}

export default CallAPIFlow

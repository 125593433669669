/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';

import CopyIcon from './icons/CopyIcon';

class CopyToClipboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            copySuccess: false,
            copyMessage: 'Copy to Clipboard'
        }

    }

    copyToClipboard = () => {
        let text = this.props.text;
        if (typeof text === 'undefined') {
            text = '';
        }
        if (typeof text === 'object') {
            text = JSON.stringify(this.props.text);
        }

        if (text.charAt(0) === '"' && text.charAt(text.length - 1) === '"') {
            text = text.substr(1, text.length - 2);
        }

        const textField = document.createElement('textarea');
        textField.classList.add('visually-hidden');
        textField.innerText = text;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();

        this.setState({
            copySuccess: true,
            copyMessage: 'Copied to Clipboard!'
        });

        setTimeout(() => {
            this.setState({
                copySuccess: false,
                copyMessage: 'Copy to Clipboard'
            })
        }, 750)

    };

    render() {

        return (
            <button onClick={this.copyToClipboard} type="button" aria-label="Copy"
                    className={`button button-tiny button-link ${(this.props.cssclass ? this.props.cssclass : '')}`}
                    data-tooltip={this.state.copyMessage}
                    data-tool-tip-copied={this.state.copySuccess}
                    flow={this.props.flow}>
                <CopyIcon />
            </button>
        )
    }
}
export default CopyToClipboard;

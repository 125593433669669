/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import CopyToClipboard from '../CopyToClipboard';

const StartUrl = ({ startUrl }) => {

    const baseUrl = startUrl ? startUrl.split('?')[0] : '';
    const queryParams = startUrl ? startUrl.split('?')[1] : null;
    const params = queryParams ? queryParams.split('&') : [];

    let pair = null;
    let data = [];

    params.forEach((d) => {
        pair = d.split('=');
        data.push(pair)
    });

    const pairItems = data.map((item, index) =>
        <React.Fragment key={index}>
            <span className="key">&{item[0]}</span>=
            <span className="value">{item[1]}</span><br/>
        </React.Fragment>
    );

    return (
        <div className="mt2">
            <div
                className={'sm-flex flex-center mb1 mt1 start-here justify-between'}>
                <div>
                    <label className="label">Start URL <CopyToClipboard text={startUrl}/></label>
                </div>
            </div>

            <div className="flex flex-justify flex-center">
                <pre className="field field-mono col-12">
                    {baseUrl}?<br/>
                    {pairItems}
                </pre>

            </div>
        </div>
    )
};

export default StartUrl;

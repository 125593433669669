/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import IntrospectButton from '../IntrospectButton';
import TokenPurpose from './TokenPurpose';
import CopyToClipboard from '../CopyToClipboard';
import RefreshTokenButton from '../RefreshTokenButton';
import CreateJwtIcon from '../icons/CreateJwtIcon';
import PrettyJson from '../validation/PrettyJson';
import { tokenPurposes } from '../../util/appConstants';

class ReceivedToken extends React.Component {

    isIntrospectEnabled = () => {

        return (this.props.environment && this.props.environment.canDoIntrospect()
            && this.props.collection.parameters.canIntrospect());
    };

    introspect = () => {
        if (this.isIntrospectEnabled()) {
            const environment = this.props.environment;
            const collection = this.props.collection;
            const token = this.props.token;

            this.props.introspectToken(token, collection, environment);
        } else {
            //TODO error
        }
    };

    createJwtFlowFromToken = () => {
        const group = Object.keys(this.props.groups)
            .find(o => this.props.groups[o].order.indexOf(this.props.collection.id) > -1);
        this.props.createAndSelectCollectionWithToken('none', this.props.collection.provider,
            this.props.token.value, group);
    };

    refresh = () => {
        const environment = this.props.environment;
        const collection = this.props.collection;
        const token = this.props.token;
        this.props.refreshTokens(token, collection, environment);
    };

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {

        const purposeIsIntrospectable = (
            this.props.token.purpose === 'access_token'
            || this.props.token.purpose === 'refresh_token'
        );

        const inputClass = (this.props.token.isJwt()) ? 'tools-input tools-input-readonly tools-input-jwt' :
            'tools-input tools-input-readonly';

        let value = this.props.token.value;
        if (this.props.token.isJwt()) {
            value = <PrettyJson json={this.props.token.value}/>
        }
        if (this.props.token.purpose === tokenPurposes.authorization_details.value) {
            value = this.props.token.authorization_details;
        }

        return (
            <div className="tools-form-token tools-form-token-active" data-qa={this.props.token.purpose}>
                <header className="tools-box-header tools-box-header-opaque">
                    <div className="flex flex-center flex-justify">
                        <div className="flex flex-center flex-justify">
                            {this.props.token.purpose !== tokenPurposes.authorization_details.value &&
                                <TokenPurpose token={this.props.token}
                                              editable={false}
                                              labelOverride={this.props.tokenPurposeLabelOverride}
                                              collection={this.props.collection}
                                              environment={this.props.environment}
                                />
                            }
                        </div>
                        <div className="flex flex-center flex-justify">
                            {this.props.refreshTokens ?
                                <RefreshTokenButton action={this.refresh} token={this.props.token}/> : <></>}
                            <CopyToClipboard text={this.props.token.value}/>
                            {(purposeIsIntrospectable) ?
                                <IntrospectButton enabled={this.isIntrospectEnabled()} action={this.introspect}/>
                                : ''}
                            {this.props.token.isJwt() ?
                                <button onClick={this.createJwtFlowFromToken}
                                        className={`button button-tiny button-link button-loading`}
                                        data-tooltip="Create a new JWT Flow">
                                    <CreateJwtIcon/>
                                </button> : ''}
                        </div>
                    </div>
                </header>

                <React.Fragment>
                    {this.props.token.purpose === tokenPurposes.authorization_details.value ?
                        <PrettyJson className={'border-none'} json={value}/>
                        :
                        <pre className={inputClass}>{value}</pre>}
                </React.Fragment>

            </div>
        )
    }
}

export default ReceivedToken;

/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import { Link } from 'react-router-dom';

class NotFound extends React.Component {
    render() {
        return (
            <main className="p4 flex flex-column flex-center justify-center ">
                <div className="header-logo">
                    <Link
                        to="/"
                        title="Tools for exploring and testing OAuth and OpenID Connect flows">
                        <h1 className="flex flex-center justify-center m0">
                            <span className="header-logo-symbol" />
                            <span className="visually-hidden">OAuth Tools</span>
                        </h1>
                    </Link>
                </div>

                <div className="center">
                    <h1 className="primary">404</h1>
                    <p>Sorry, the page you were looking for doesn&apos;t exist.</p>

                    <Link to="/" className="button button-small button-primary">
                        <i className="icon ion-android-arrow-back mr1"/>Back to OAuth Tools
                    </Link>
                </div>
            </main>
        );
    }
}

export default NotFound;

/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

export const clientConfigInParameters = {
    can_do_code_flow: true,
    can_do_introspect: true,
    can_do_device_flow: true,
    can_do_ciba_flow: true,
    can_do_ropc_flow: true,
    can_do_implicit_flow: true,
    can_do_hybrid_flow: true,
    can_do_client_credentials_flow: true,
    can_do_revoke_flow: true,
    can_do_refresh_flow: true,
    can_be_framed: true,
    client_id: true,
    client_secret: true,
    client_name: true,
    token_endpoint_auth_method: true
};


class OAuthClient {
    constructor(id, settings) {
        const sett = settings ? settings : {};


        this._id = id;
        this._settings = sett;

        this._can_do_introspect = sett.can_do_introspect;
        this._can_do_code_flow = sett.can_do_code_flow;
        this._can_do_revoke_flow = sett.can_do_code_flow || sett.can_do_implicit_flow ||
            sett.can_do_hybrid_flow || sett.can_do_ciba_flow || sett.can_do_device_flow ||
            sett.can_do_client_credentials_flow || sett.can_do_ropc_flow;
        this._can_do_refresh_flow = sett.can_do_code_flow || sett.can_do_hybrid_flow ||
            sett.can_do_ciba_flow || sett.can_do_device_flow || sett.can_do_ropc_flow;

        // save derived parameters in settings for export.
        this._settings.can_do_revoke_flow = this._can_do_revoke_flow;
        this._settings.can_do_refresh_flow = this._can_do_refresh_flow;


        this._can_do_device_flow = sett.can_do_device_flow;
        this._can_do_ciba_flow = sett.can_do_ciba_flow;
        this._can_do_ropc_flow = sett.can_do_ropc_flow;
        this._can_do_implicit_flow = sett.can_do_implicit_flow;
        this._can_do_hybrid_flow = sett.can_do_hybrid_flow;
        this._can_do_client_credentials_flow = sett.can_do_client_credentials_flow;
        this._can_be_framed = sett.can_be_framed ||
            (sett.allowed_origins && sett.allowed_origins.indexOf(window.host) > -1);

        this._client_name = sett.client_name;
        this._client_id = sett.client_id;
        this._client_secret = sett.client_secret;
        this._token_endpoint_auth_method = sett.token_endpoint_auth_method;
        this._redirect_uris = sett.redirect_uris;
        this._logo_uri = sett.logo_uri;
        this._subject_type = sett.subject_type;
        this._grant_types = sett.grant_types;
        this._application_type = sett.application_type;
        this._contacts = sett.contacts;
        this._client_uri = sett.client_uri;
        this._policy_uri = sett.policy_uri;
        this._jwks_uri = sett.jwks_uri;
        this._jwks = sett.jwks;
        this._sector_identifier_uri = sett.sector_identifier_uri;
        this._token_endpoint_auth_signing_alg = sett.token_endpoint_auth_signing_alg;
        this._tos_uri = sett.tos_uri;
        this._default_max_age = sett.default_max_age;
        this._require_auth_time = sett.require_auth_time;
        this._default_acr_values = sett.default_acr_values;
        this._initiate_login_uri = sett.initiate_login_uri;
        this._request_uris = sett.request_uris;
        this._client_secret_expires_at = sett.client_secret_expires_at;
        this._scope = sett.scope;
        this._client_id_issued_at = sett.client_id_issued_at;
        this._response_types = sett.response_types;
        this._allowed_origins = sett.allowed_origins;
        this._refresh_token_ttl = sett.refresh_token_ttl;
        this._id_token_signed_response_alg = sett.id_token_signed_response_alg;
    }

    get id() {
        return this._id;
    }

    get client_id() {
        return this._client_id;
    }

    get client_secret() {
        return this._client_secret;
    }

    get client_name() {
        return this._client_name;
    }

    get can_do_introspect() {
        return this._can_do_introspect;
    }

    get can_do_code_flow() {
        return this._can_do_code_flow;
    }

    get can_do_refresh_flow() {
        return this._can_do_refresh_flow;
    }

    get can_do_revoke_flow() {
        return this._can_do_revoke_flow;
    }

    get can_do_device_flow() {
        return this._can_do_device_flow;
    }

    get can_do_ciba_flow() {
        return this._can_do_ciba_flow;
    }

    get can_do_ropc_flow() {
        return this._can_do_ropc_flow;
    }

    get can_do_implicit_flow() {
        return this._can_do_implicit_flow;
    }

    get can_do_hybrid_flow() {
        return this._can_do_hybrid_flow;
    }

    get can_do_client_credentials_flow() {
        return this._can_do_client_credentials_flow;
    }

    get can_be_framed() {
        return this._can_be_framed;
    }

    get token_endpoint_auth_method() {
        return this._token_endpoint_auth_method;
    }

    get logo_uri() {
        return this._logo_uri;
    }

    get redirect_uris() {
        return this._redirect_uris;
    }

    get subject_type() {
        return this._subject_type;
    }

    get grant_types() {
        return this._grant_types;
    }

    get application_type() {
        return this._application_type;
    }

    get contacts() {
        return this._contacts;
    }

    get client_uri() {
        return this._client_uri;
    }

    get policy_uri() {
        return this._policy_uri;
    }

    get jwks_uri() {
        return this._jwks_uri;
    }

    get jwks() {
        return this._jwks;
    }

    get sector_identifier_uri() {
        return this._sector_identifier_uri;
    }

    get token_endpoint_auth_signing_alg() {
        return this._token_endpoint_auth_signing_alg;
    }

    get tos_uri() {
        return this._tos_uri;
    }

    get default_max_age() {
        return this._default_max_age;
    }

    get require_auth_time() {
        return this._require_auth_time;
    }

    get default_acr_values() {
        return this._default_acr_values;
    }

    get initiate_login_uri() {
        return this._initiate_login_uri;
    }

    get request_uris() {
        return this._request_uris;
    }

    get client_secret_expires_at() {
        return this._client_secret_expires_at;
    }

    get scope() {
        return this._scope;
    }

    get client_id_issued_at() {
        return this._client_id_issued_at;
    }

    get response_types() {
        return this._response_types;
    }

    get allowed_origins() {
        return this._allowed_origins;
    }

    get refresh_token_ttl() {
        return this._refresh_token_ttl;
    }

    get id_token_signed_response_alg() {
        return this._id_token_signed_response_alg;
    }

    toMap = () => {
        return this._settings;
    };

    withUpdatedValue = (key, value) => {

        const prop = '_' + key;

        if (Object.prototype.hasOwnProperty.call(this, prop)) {
            const current = this.toMap();
            const next = {
                ...current,
                [key]: value
            };
            return new OAuthClient(this._id, next);
        } else {
            throw 'Key ' + key + ' is not a property of OAuthClient';
        }
    };
}

export default OAuthClient;

/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';

class KeysDropdown extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showKeys: false,
            showSettings: false
        };

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    handleMenu = () => {
        const environmentStatus = !this.state.showKeys;
        this.setState({
            showKeys: environmentStatus
        })
    };

    handleSelectKey = (event) => {
        const environment = this.props.environment;
        const keys = (environment.jwks !== undefined) ? environment.jwks : [];
        const key = keys[event.currentTarget.id];

        this.props.updateKeyForToken(key, this.props.token.id, this.props.collection);
        this.setState({
            showKeys: false
        })
    };

    escFunction = (event) => {
        if (this.state.showKeys) {
            if (event.keyCode === 27) {
                this.setState({
                    showKeys: false
                })
            }
        }
    };

    componentDidMount() {
        document.addEventListener('keydown', this.escFunction, false);
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({
                showKeys: false
            })
        }
    }

    render() {

        const token = this.props.token;
        const environment = this.props.environment;
        const selectedKeyValue = token.validation_key;

        const selectedKeyId = (selectedKeyValue && selectedKeyValue.jwk && selectedKeyValue.jwk.kid) ?
            selectedKeyValue.jwk.kid : null;
        const keys = (environment.jwks !== undefined) ? environment.jwks : [];

        let selectKeyLabel = 'Select Key';
        if (selectedKeyId && Object.prototype.hasOwnProperty.call(environment.jwks, selectedKeyId)) {
            selectKeyLabel = selectedKeyId;
        }

        const keyNumber = Object.keys(keys).length;

        return (
            <div className="relative" ref={this.setWrapperRef}>

                <button
                    className={'button button-tiny button-light has-context-dropdown-menu ' + (this.state.showKeys ? 'active' : null)}
                    onClick={this.handleMenu}>
                    <span>Key</span><span className="kid">{selectKeyLabel}</span>
                    <i className="icon ion-chevron-down"/>
                </button>

                <div className={'context-dropdown-menu ' + (this.state.showKeys ? 'active' : null)}>
                    <nav>
                        <ul className="m0 list-reset context-dropdown-menu-items">
                            <li className="context-dropdown-menu-items-header">Keys ({keyNumber})</li>
                            {Object.keys(keys).map((keyId) => {

                                const active = (selectedKeyId === keyId) ? 'active' : '';
                                return (<li onClick={this.handleSelectKey}
                                            className={active}
                                            id={keyId}
                                            key={keyId}>kid: <span className="kid">{keyId}</span></li>)
                            })}
                        </ul>
                    </nav>
                </div>

            </div>
        )
    }

}

export default KeysDropdown;

/*
 * Copyright (C) 2021 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import PrettyJson from '../validation/PrettyJson';
import CopyToClipboard from '../CopyToClipboard';
import CreateJwtIcon from '../icons/CreateJwtIcon';

class SidebarIntrospectionResult extends React.Component {

    state = {
        isFolded: false
    };

    handleFold = () => {
        this.setState((state) => ({
            isFolded: !state.isFolded
        }));
    };

    createJwtFlowFromToken = () => {
        const group = Object.keys(this.props.groups)
            .find( o => this.props.groups[o].order.indexOf(this.props.collection.id) > -1);
        this.props.createAndSelectCollectionWithToken('none', this.props.collection.provider,
            this.props.data, group);
    };

    render() {
        const tokenBody = (this.props.data) ? this.props.data : null;
        const expandResultText = this.state.isFolded ? 'Show Introspection Result' : 'Hide Introspection Result';
        const isJwt = tokenBody && typeof tokenBody === 'string' && tokenBody.startsWith('ey') && tokenBody.includes('.');

        return (
            <React.Fragment>
                <div className={'decoded-jwt-data flex flex-center justify-between ' + (!this.state.isFolded ? '': 'folded')}
                     onClick={this.handleFold}>
                    <p className={'mb0'}>{expandResultText}</p>
                    <div>
                        {isJwt ?
                            <button onClick={this.createJwtFlowFromToken}
                                    className={`button button-tiny button-link button-loading`}
                                    data-tooltip="Create a new JWT Flow">
                                <CreateJwtIcon/>
                            </button> : ''}
                        <CopyToClipboard text={tokenBody} />
                        <button className={'button button-expand'}/>
                    </div>
                </div>

                {(!this.state.isFolded) &&
                    <div className={'tools-sidebar-box'} data-qa="side-bar-introspect-result">
                        <pre className="tools-sidebar-box tools-sidebar-box-body tools-input">
                            <PrettyJson json={tokenBody}/>
                        </pre>
                    </div>
                }
            </React.Fragment>
        )
    }
}

export default SidebarIntrospectionResult;

/*
 * Copyright (C) 2020 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react'

const EmptyIcon = props => (
	<svg
		viewBox="0 0 473.28 256"
		aria-hidden="true"
		width="6.25em"
		height="6.25em"
		{...props}
	>
		<defs>
			<style>{'.empty{fill:#ededed}'}</style>
		</defs>
		<circle className="empty" cx={72.92} cy={59.42} r={24.76} />
		<rect
			className="empty"
			x={121.07}
			y={37.42}
			width={323.32}
			height={44}
			rx={22}
		/>
		<circle className="empty" cx={72.92} cy={131.18} r={24.76} />
		<rect
			className="empty"
			x={121.07}
			y={109.18}
			width={280.67}
			height={44}
			rx={22}
		/>
		<circle className="empty" cx={72.92} cy={202.94} r={24.76} />
		<rect
			className="empty"
			x={121.07}
			y={180.94}
			width={247.65}
			height={44}
			rx={22}
		/>
	</svg>
);

export default EmptyIcon

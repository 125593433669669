import React from 'react';

export function Moon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      className="lucide lucide-moon"
      viewBox="0 0 24 24"
    >
      <path d="M12 3a6 6 0 009 9 9 9 0 11-9-9z"></path>
    </svg>
  );
}

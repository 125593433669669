/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import C from './actionConstants';
import { fetchMetaData, proxiedBackendCall } from '../util/serverUtil';
import { mergeDeep, randomTimeId } from '../util/util';
import Environment from '../data/Environment';
import Environments from '../data/Environments';
import {
    IMPORT_OPTION,
    backendUrl,
    getSignicatDemoFlows,
    flows
} from '../util/appConstants';
import { selectCollection } from './workspaceActions';
import {
    createDemoCollection
} from './collectionActions';
import { curityPlaygroundEnvironment } from '../initstate';
import { isEmptyObject } from '../util/validationUtils';
import { createNewGroupWithId } from './groupActions';

export const createEnvironmentWithWebFinger = (newEnvironmentName) => {
    const newEnvironmentId = randomTimeId();
    const createEnvironmentAction = createEnvironment(newEnvironmentId, newEnvironmentName);
    const selectEnvironmentAction = changeSelectedEnvironment(newEnvironmentId);

    return function (dispatch, getState) {
        const currentEnvironments = Environments.create(getState().environments);
        if (currentEnvironments.getEnvironmentByName(newEnvironmentName) === null) {
            //These will run synchronously.
            dispatch(createEnvironmentAction);
            dispatch(selectEnvironmentAction);
            dispatch(discoverWebFinger(newEnvironmentId, newEnvironmentName))
        } else {
            return {
                type: C.DO_NOTHING
            }
        }
    };
};

export const createAndSelectEnvironment = (newEnvironmentName, isPlayground, addDemoFlows, environmentId) => {
    const newEnvironmentId = environmentId ? environmentId : randomTimeId();
    const createEnvironmentAction = createEnvironment(newEnvironmentId, newEnvironmentName, isPlayground);
    const selectEnvironmentAction = changeSelectedEnvironment(newEnvironmentId);

    return (dispatch, getState) => {
        //These will run synchronously.
        dispatch(createEnvironmentAction);
        if (addDemoFlows) {
            dispatch(discoverMetaData({
                id: newEnvironmentId,
                issuer: curityPlaygroundEnvironment.issuer
            }, 'DISCOVERY', false, true));
            const newGroupId = randomTimeId()
            dispatch(createNewGroupWithId('VCI', newEnvironmentId, newGroupId))
            Object.entries(flows).forEach(([key, flow]) => {
                if (flow.demoFlow) {
                    const collection = {
                        ...flow.demoFlow,
                        provider: newEnvironmentId
                    };
                    const collectionId = randomTimeId();
                    dispatch(createDemoCollection(collectionId, collection));
                    if (flow.id === 'vci' || flow.id === 'precode' || flow.id === 'codeprecode') {
                        dispatch(addCollectionToGroup(collectionId, newGroupId, null, -1))
                    } else {
                        dispatch(addCollectionToGroup(collectionId, 'default', null, -1))
                    }
                }
            });
        }
        dispatch(selectEnvironmentAction);
        const collections = getState().collections;
        const firstCollectionInEnvironment = Object.keys(collections)
            .find(i => collections[i].provider === newEnvironmentId)
        dispatch(selectCollection(firstCollectionInEnvironment))
    };
};

export const createAndSelectSignicatEnvironment = (environmentId, issuer, clientOverrides) => {

    return (dispatch, getState) => {
        //These will run synchronously.
        dispatch(createSignicatEnvironment(environmentId, issuer, clientOverrides));
        getSignicatDemoFlows(environmentId, !isEmptyObject(clientOverrides)).forEach(collection => {
            const collectionId = randomTimeId();
            dispatch(createDemoCollection(collectionId, collection));
            dispatch(addCollectionToGroup(collectionId, 'default', null, -1))
        })
        dispatch(changeSelectedEnvironment(environmentId));
        const collections = getState().collections;
        const firstCollectionInEnvironment = Object.keys(collections)
            .find(i => collections[i].provider === environmentId)
        dispatch(selectCollection(firstCollectionInEnvironment))

    };
};

export const createSignicatEnvironment = (environmentId, issuer, clientOverrides) => {
    return {
        type: C.CREATE_SIGNICAT_ENVIRONMENT,
        id: environmentId,
        issuer,
        clientOverrides
    }
};

export const createEnvironment = (environmentId, name, isPlayground) => {
    return {
        type: C.CREATE_ENVIRONMENT,
        id: environmentId,
        name,
        isPlayground
    }
};

export const updateEnvironment = (environment) => {
    return {
        type: C.UPDATE_ENVIRONMENT,
        id: environment.id,
        environment
    }
};

export const duplicateEnvironment = (environmentId, defaultGroupOrder) => {
    const newEnvironmentId = randomTimeId();
    return {
        type: C.DUPLICATE_ENVIRONMENT,
        environmentId,
        newEnvironmentId,
        defaultGroupOrder
    }
};

export const mergeEnvironment = (environmentId, environment) => {
    return {
        type: C.MERGE_ENVIRONMENT,
        environmentId,
        environment
    }
};

export const addCollectionToGroup = (collectionId, groupId, oldGroupId, index) => {
    return {
        type: C.ADD_COLLECTION_TO_GROUP,
        groupId,
        oldGroupId,
        collectionId,
        index
    }
}

export const deleteEnvironment = (environment) => {
    return {
        type: C.DELETE_ENVIRONMENT,
        environment
    }
};

export const changeSelectedEnvironment = (environmentId) => {

    return {
        type: C.CHANGE_SELECTED_ENVIRONMENT,
        id: environmentId
    }
};

export const discoverMetaData = (environment, component, fromWebfinger, withCredentials) => {
    if ((environment.metadata_url === undefined || environment.metadata_url === null)
        && (environment.issuer === undefined || environment.issuer === null)) {
        throw 'Cannot discover, no metadata url nor issuer defined';
    }
    const metadata_url = environment.metadata_url ?
        environment.metadata_url : environment.issuer.indexOf('.well-known') >= 0 ?
            environment.issuer : (environment.issuer.trim() + '/.well-known/openid-configuration').replace(/([^:]\/)\/+/g, '$1');

    return (dispatch, getState) => {
        const currentEnvironments = Environments.create(getState().environments);
        const currentEnvironment = currentEnvironments.getEnvironment(environment.id);
        fetchMetaData(metadata_url)
            .then((metadata) => {
                // Update the provider's metadata
                if (currentEnvironment !== null && Object.prototype.hasOwnProperty.call(metadata, 'raw_metadata')) {
                    const { issuer } = metadata.raw_metadata;
                    if (metadata_url && fromWebfinger) {
                        const resourceUrl = new URL(metadata_url);
                        const issuerUrl = new URL(issuer);
                        if (resourceUrl.hostname !== issuerUrl.hostname) {
                            let updatedEnvironment = currentEnvironment.fromUpdatedMetadata({})
                                .withError(component, 'Issuer mismatch between WebFinger response and metadata.');
                            dispatch(updateEnvironment(updatedEnvironment));
                            return;
                        }
                    }
                    let updatedEnvironment = currentEnvironment.fromUpdatedMetadata(metadata);
                    if (Object.prototype.hasOwnProperty.call(metadata, 'raw_metadata')) {
                        updatedEnvironment = updatedEnvironment.withUpdatedValue('metadata_url', metadata_url);
                    }
                    updatedEnvironment = updatedEnvironment.withError(null, null);
                    dispatch(updateEnvironment(updatedEnvironment));
                }

                if (withCredentials) {
                    dispatch(discoverCredentialMetaData(
                        { id: environment.id, credential_issuer: environment.issuer }, component))
                }
            })
            .catch((error) => {
                const compError = typeof error === 'string' ? error : 'Something went wrong';
                const updatedEnvironment = currentEnvironment.withError(component, compError);
                dispatch(updateEnvironment(updatedEnvironment));
            })
    };
};

export const discoverCredentialMetaData = (environment, component) => {
    if ((environment.credential_metadata_url === undefined || environment.credential_metadata_url === null)
        && (environment.credential_issuer === undefined || environment.credential_issuer === null)) {
        throw 'Cannot discover, no metadata url nor issuer defined';
    }
    const credential_metadata_url = environment.credential_metadata_url ?
        environment.credential_metadata_url : environment.credential_issuer.indexOf('.well-known') >= 0 ?
            environment.credential_issuer : (environment.credential_issuer.trim() + '/.well-known/openid-credential-issuer').replace(/([^:]\/)\/+/g, '$1');

    return (dispatch, getState) => {
        const currentEnvironments = Environments.create(getState().environments);
        const currentEnvironment = currentEnvironments.getEnvironment(environment.id);
        proxiedBackendCall('GET', credential_metadata_url, {}, {})
            .then((response) => {
                // Update the provider's metadata
                if (response.status_code === 200 && Object.prototype.hasOwnProperty.call(response, 'data')) {
                    const {
                        credential_issuer, credential_endpoint, display, credential_configurations_supported,
                        notification_endpoint, deferred_credential_endpoint,
                        batch_credential_endpoint
                    } = response.data;

                    let updatedEnvironment = currentEnvironment
                        .withUpdatedValue('credential_metadata_url', credential_metadata_url)
                        .withUpdatedValue('raw_credential_metadata', response.data)
                        .withUpdatedValue('credential_issuer', credential_issuer)
                        .withUpdatedValue('display', display)
                        .withUpdatedValue('credential_endpoint', credential_endpoint)
                        .withUpdatedValue('batch_credential_endpoint', batch_credential_endpoint)
                        .withUpdatedValue('notification_endpoint', notification_endpoint)
                        .withUpdatedValue('deferred_credential_endpoint', deferred_credential_endpoint)
                        .withUpdatedValue('credentials_supported', credential_configurations_supported)
                        .withError(null, null);

                    dispatch(updateEnvironment(updatedEnvironment));
                } else {
                    console.log(response.data)
                    const compError = typeof response.data === 'string' ? response.data : 'Something went wrong';
                    const updatedEnvironment = currentEnvironment.withError(component, compError);
                    dispatch(updateEnvironment(updatedEnvironment));
                }
            })
            .catch((error) => {
                console.log(error)
                const compError = typeof error === 'string' ? error : 'Something went wrong';
                const updatedEnvironment = currentEnvironment.withError(component, compError);
                dispatch(updateEnvironment(updatedEnvironment));
            })
    };
};

export const discoverWebFinger = (environmentId, resource, component) => {

    return (dispatch, getState) => {
        const currentEnvironments = Environments.create(getState().environments);
        const currentEnvironment = currentEnvironments.getEnvironment(environmentId);
        try {
            const schemeStart = resource.indexOf('://');
            const hasScheme = schemeStart > -1;
            const authorityStart = schemeStart + 3;
            const pathStart = hasScheme ? resource.indexOf('/', authorityStart) : resource.indexOf('/');
            const hasPort = hasScheme ? resource.substring(authorityStart, pathStart).indexOf(':') : false;
            const hasPath = pathStart > -1;
            const hasQuery = resource.substring(pathStart).indexOf('?') > -1;
            const fragmentStart = resource.indexOf('#');
            const hasFragment = fragmentStart > -1;
            const userInfoEnd = resource.indexOf('@');
            const hasUserInfo = userInfoEnd > -1;
            const hasHost = resource.substring(userInfoEnd + 1).length > 0;
            let webFingerScheme = 'https://';
            let host, formattedResource;
            if (hasUserInfo && hasHost && !hasScheme && !hasPath && !hasQuery && !hasPort && !hasFragment) {
                const scheme = resource.startsWith('acct:') ? '' : 'acct:';
                host = resource.substring(userInfoEnd + 1);
                formattedResource = scheme + resource;
            } else {
                const resourceWithoutFragment = hasFragment ? resource.substring(0, fragmentStart) : resource;
                const hostLength = hasPath ? pathStart : resource.length;

                if (hasScheme) {
                    host = resourceWithoutFragment.substring(authorityStart, hostLength);
                    formattedResource = resourceWithoutFragment;
                    webFingerScheme = resource.substring(0, schemeStart + 3);
                } else {
                    host = resourceWithoutFragment.substring(0, hostLength);
                    formattedResource = 'https://' + resourceWithoutFragment;
                }
            }
            const webFingerUrl = webFingerScheme + host + '/.well-known/webfinger?rel=http://openid.net/specs/connect/1.0/issuer&resource=' + formattedResource;
            proxiedBackendCall('GET', webFingerUrl, {}, {})
                .then((jsonResponse) => {
                        if (jsonResponse.data.links && jsonResponse.data.links[0].rel === 'http://openid.net/specs/connect/1.0/issuer') {
                            const issuer = jsonResponse.data.links[0].href;
                            const updatedEnvironment = currentEnvironment.withUpdatedValue('issuer', issuer);
                            dispatch(updateEnvironment(updatedEnvironment));
                            dispatch(discoverMetaData(updatedEnvironment, 'DISCOVERY'), true, false);
                        } else {
                            const updatedEnvironment = currentEnvironment.withError(component, 'Unable to load data.');
                            dispatch(updateEnvironment(updatedEnvironment));
                        }
                    }
                )
                .catch(() => {
                    const updatedEnvironment = currentEnvironment
                        .withError(component, 'Could not discover ' + resource);
                    dispatch(updateEnvironment(updatedEnvironment));
                })
        } catch (e) {
            const updatedEnvironment = currentEnvironment
                .withError(component, 'Invalid URL: ' + resource);
            dispatch(updateEnvironment(updatedEnvironment));
        }
    }
};

export const jwksRequest = (jwksUrl) => {
    return new Promise((resolve, reject) => {
        if (jwksUrl) {
            fetchJwksKeys(jwksUrl)
                .then((jwkskeys) => {
                    const { status, jwks } = jwkskeys;
                    if (status) {
                        resolve(jwks);
                    } else {
                        throw Error();
                    }
                })
                .catch((error) => {
                    reject({
                        error
                    });
                })
        } else {
            console.warn(`Invalid jwks url: ${jwksUrl}`)
        }
    });
};

const fetchJwksKeys = (jwksUrl) => {
    return new Promise(((resolve, reject) => {
        fetch(backendUrl() + '/api/client/jwks', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                jwks_url: jwksUrl
            })
        })
            .then((response) => {
                if (!response.ok) {
                    reject(response);
                }
                resolve(response.json());
            })
            .catch(() => {
                reject(Response.error());
            })
    }));
};


// IMPORT ENVIRONMENT ACTION CREATOR
export function importEnvironmentActionCreator(environment) {
    return {
        type: C.IMPORT_ENVIRONMENT,
        environment
    }
}

// IMPORT ENVIRONMENT ACTION DISPATCHER:
export const importEnvironmentsActions = (environments, importActionType) => {
    return (dispatch, getState) => {
        return new Promise((resolve) => {
            let environmentsArr = [];
            const state = getState();
            if (importActionType === IMPORT_OPTION.MERGE) {
                // GET ENVIRONMENT INSTANCES TO ADD `NEW ENVIRONMENT` AND `IGNORE` SAME `KEY` ENV PRESENT IN BROWSER APP STATE:
                const uploadedFileKeys = Object.keys(environments);
                const appStateKeys = Object.keys(state.environments);
                const { keysToAdd, keysExists } = filterKeys({ uploadedFileKeys, appStateKeys });

                if (keysToAdd.length > 0) {
                    environmentsArr = getEnvironmentsFromImportFile(environments, keysToAdd);
                }
                // UPDATE IF EXISTS:
                if (keysExists.length > 0) {
                    const stateEnvironments = state.environments;
                    keysExists.forEach((key) => {
                        const mergeObj = mergeDeep(stateEnvironments[key], environments[key]);
                        dispatch(mergeEnvironment(key, mergeObj));
                    });
                }
            } else if (importActionType === IMPORT_OPTION.OVERRIDE) {
                // Override Case:
                const uploadedEnvironmentsKeys = Object.keys(environments);
                environmentsArr = getEnvironmentsFromImportFile(environments, uploadedEnvironmentsKeys);
            } else if (importActionType === IMPORT_OPTION.REPLACE) {
                // Delete current App State Environments:
                const deleteAllEnvironments = state.environments;
                Object.keys(deleteAllEnvironments).forEach((envKey) => {
                    dispatch(deleteEnvironment(envKey));
                });

                // // Add Environments from Uploaded file:
                const uploadedEnvironmentsKeys = Object.keys(environments);
                environmentsArr = getEnvironmentsFromImportFile(environments, uploadedEnvironmentsKeys);
            }

            // By-Default `FIRST ENV` is selected for activeTab
            const selectEnvironmentAction = environmentsArr.length > 0 ?
                changeSelectedEnvironment(environmentsArr[0].id) : changeSelectedEnvironment(state.appState.activeTab);

            environmentsArr.forEach((environment) => {
                if (Object.keys(environment).length > 0) {
                    dispatch(importEnvironmentActionCreator(environment));
                }
            });
            dispatch(selectEnvironmentAction);
            resolve(true);
        });
    };
};

// GET ENVIRONMENT INSTANCES FOR UPLOADED FILE:
function getEnvironmentsFromImportFile(uploadedEnvironments, filteredKeys) {
    let environmentsArr = [];
    filteredKeys.forEach((key) => {
        const currentEnvironment = uploadedEnvironments[key];
        if (currentEnvironment) {
            const addNewEnvironment = new Environment(key, currentEnvironment);
            if (!(addNewEnvironment instanceof Environment)) {
                console.error('importEnvironment called with incorrect input of environment value');
            }
            environmentsArr.push(addNewEnvironment);
        }
    });

    return environmentsArr;
}

// IMPORT ENVIRONMENT CLIENTS ACTION DISPATCHER:
export const importEnvironmentsClientsActions = (clients) => {
    return (dispatch, getState) => {
        return new Promise((resolve) => {
            let clientsArr;
            const state = getState();
            const activeTab = state.appState.activeTab;
            const currentEnvironment = Environments.create(state.environments).getEnvironment(activeTab);

            clientsArr = getEnvironmentsClientsFromImportFile(clients);
            clientsArr.forEach((client) => {
                const updatedEnvironment = currentEnvironment.withClient(client.id, client);
                dispatch(updateEnvironment(updatedEnvironment));
            });

            resolve(true);
        });
    };
}


// GET ENVIRONMENT INSTANCES FOR UPLOADED FILE:
function getEnvironmentsClientsFromImportFile(uploadedClients) {
    let clientsArr = [];
    const uploadedClientsArray = Array.isArray(uploadedClients) ? uploadedClients : [uploadedClients];

    uploadedClientsArray.forEach((currentClient) => {
        const newEnvironmentId = randomTimeId();
        // console.log(newEnvironmentId);
        if (currentClient) {
            clientsArr.push({ ...currentClient, id: newEnvironmentId });
        }
    });

    return clientsArr;
}

// Used In EnvironmentDropdown;
export const changeSelectedEnvironmentWrapper = (environmentId, selectFirstFlow = false) => {
    return (dispatch, getState) => {

        dispatch(changeSelectedEnvironment(environmentId));
        if (selectFirstFlow) {
            const collections = getState().collections;
            const firstCollectionInEnvironment = Object.keys(collections)
                .find(i => collections[i].provider === environmentId)
            dispatch(selectCollection(firstCollectionInEnvironment))
        }
    }
};

const filterKeys = ({ uploadedFileKeys, appStateKeys }) => {
    let keysToAdd = [];
    let keysExists = [];

    uploadedFileKeys.filter((key) => {
        if (appStateKeys.includes(key)) {
            keysExists.push(key);
        } else {
            keysToAdd.push(key);
        }
    });
    return {
        keysToAdd,
        keysExists
    }
};


export const createNewEnvironment = (environmentName) => {
    return function (dispatch) {
        return new Promise(((resolve) => {
            const newEnvironmentId = randomTimeId();
            const createEnvironmentActionCreator = createEnvironment(newEnvironmentId, environmentName);
            dispatch(createEnvironmentActionCreator);
            resolve(newEnvironmentId);
        }));
    };
};

export const selectEnvironment = (environmentId) => {
    return function (dispatch, getState) {
        return new Promise(((resolve) => {
            const currentEnvironments = getState().environments;
            const createEnvironments = Environments.create(currentEnvironments);
            const environment = createEnvironments.getEnvironment(environmentId);
            const selectEnvironmentActionCreator = changeSelectedEnvironment(environment.id);
            dispatch(selectEnvironmentActionCreator);
            resolve(environment);
        }));
    };
};

export const updateEnvironmentWrapper = (updatedEnvironment) => {
    return (dispatch) => {
        return new Promise(((resolve) => {
            dispatch(updateEnvironment(updatedEnvironment));
            resolve(true);
        }));
    }
};

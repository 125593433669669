/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react'
import CopyToClipboard from '../CopyToClipboard';
import { XIcon } from '../icons/XIcon'

class ShareConfigPermalinkModal extends React.Component {

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            isCopied:false
        }
    }

    closeSharedConfigModal = () => {
        this.props.handleClose();
    };

    setShareConfigModalRef = (node) => {
        this.shareConfigModalRef = node;
    };

    handleClickOutside = (event) => {
        const path = event.path || (event.composedPath && event.composedPath());
        if (this.shareConfigModalRef && !this.shareConfigModalRef.contains(event.target)
            && !path.includes(this.shareConfigModalRef)) {
            this.props.handleClose();
        }
    };

    keyboardPresses = event => {
        if (this._isMounted) {
            if (event.keyCode === 27) {
                this.props.handleClose()
            }
        }
    };

    componentDidMount() {
        this._isMounted = true;
        document.addEventListener('keydown', this.keyboardPresses, false)
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleFocus = (event) => event.target.select();

    render() {
        const showHideClassName = this.props.shareConfigPermalinkModal.showSharedConfigModal
            ? 'modal modal-is-visible'
            : 'modal modal-is-hidden';

        if (this.props.shareConfigPermalinkModal.showSharedConfigModal) {
            document.addEventListener('mousedown', this.handleClickOutside);
        } else {
            document.removeEventListener('mousedown', this.handleClickOutside);
        }

        return (
            /*****Import AppState Modal*****/
            <div className={showHideClassName} >
                <section className="modal-main modal-share" ref={this.setShareConfigModalRef}>
                    <header className="modal-main-header">
                        <div className="flex flex-center justify-between">
                            <h3 className="h4 m0">Share link</h3>
                            <div className="flex flex-center justify-between">
                                <button className="button button-close button-link ml2"
                                        onClick={this.closeSharedConfigModal}><i className="icon ion-close"/></button>
                            </div>
                        </div>
                    </header>
                    <div className="modal-main-content">

                        {/* ERROR SECTION*/}
                        <section
                            className={'modal-panel-section mb2 ' + (this.props.shareConfigPermalinkModal.errorExists ? '' : 'visually-hidden')}>
                            <div className="alert alert-danger flex justify-between">
                                <p className="m0">
                                    {this.props.shareConfigPermalinkModal.errorMessage}
                                </p>
                                <button className="alert-close" onClick={this.props.hideSharedConfigModalError} aria-label="Close"><i className="icon ion-close"/></button>
                            </div>
                        </section>

                        <p>The configuration is stored on the server for 1 year.
                            It is encrypted with the key appended in the URL.</p>

                        <div className="field-group">
                            <input className="field col-12"
                                    type="text"
                                    id="configlink"
                                    defaultValue={this.props.shareConfigPermalinkModal.permalinkValue}
                                    onFocus={this.handleFocus}
                                    />
                            <div className="show-password">
                                <CopyToClipboard text={this.props.shareConfigPermalinkModal.permalinkValue} />
                            </div>
                        </div>

                        <div className="mt2">
                            <a target="_blank" rel="noopener noreferrer" className="button button-primary button-small flex-gap-2" aria-label="Share on Twitter" href={`https://twitter.com/intent/tweet?text=${encodeURIComponent('Check out my config on Oauth tools: ')}&url=${encodeURIComponent(this.props.shareConfigPermalinkModal.permalinkValue)}&via=curityio&hashtags=oauth`} style={{ backgroundColor: '#000' }}>
                                <XIcon width="20px" height="20px" color="white" />Share
                            </a>
                        </div>

                        <input
                            type="text"
                            defaultValue={this.props.shareConfigPermalinkModal.permalinkValue}
                            className="visuallyhidden"
                        />
                    </div>
                </section>
            </div>
        )
    }
}

export default ShareConfigPermalinkModal

/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import { createCollection, setErrorOnCollection, updateParameters, updateToken } from './collectionActions';
import Collection from '../data/Collection';
import Environments from '../data/Environments';
import C from './actionConstants';
import { isEmpty } from '../util/validationUtils';
import { decodeJwt, isJwtSignatureValid, stringToArrayBuffer } from '../util/jwtUtil';
import jose from 'node-jose'
import { selectCollection } from './workspaceActions';
import { createNoneJwt, notEmpty, removeLeadingZeros } from '../util/util';
import { addCollectionToGroup } from './environmentActions';
import Parameters from '../data/Parameters';

export const updateTokenAndSelectProvider = (collectionId, updatedToken) => {
    return function (dispatch, getState) {
        // Select a provider for the collection based on issuer, if no provider is selected
        dispatch(selectProviderForCollectionIfApplicable(collectionId, updatedToken, getState));
        // update the token in the collection
        dispatch(updateToken(collectionId, updatedToken)).then(() => {
            // Select key based on kid
            dispatch(selectKeyForToken(collectionId, updatedToken, getState));
        });
    }
};

const selectProviderForCollectionIfApplicable = (collectionId, updatedToken, getState) => {
    const currentCollection = Collection.getCollectionById(collectionId, getState().collections);
    const decodedToken = decodeJwt(updatedToken.value);

    if (!currentCollection.provider && decodedToken.body && decodedToken.body.iss) {
        const environments = Environments.create(getState().environments);
        const autoSelectedEnvironmentId = environments.getEnvironmentIdByIssuer(decodedToken.body.iss);
        return {
            type: C.SELECT_PROVIDER_FOR_COLLECTION,
            providerId: autoSelectedEnvironmentId,
            collectionId: currentCollection.id
        }
    } else {
        return {
            type: C.DO_NOTHING
        }
    }
};

const selectKeyForToken = (collectionId, updatedToken, getState) => {
    const currentCollection = Collection.getCollectionById(collectionId, getState().collections);
    const selectedToken = currentCollection.getTokenById(updatedToken.id);
    const decodedToken = decodeJwt(updatedToken.value);

    if (currentCollection.provider && isEmpty(selectedToken.validation_key)
        && decodedToken.header && decodedToken.header.kid) {

        const environments = Environments.create(getState().environments);
        const selectedEnvironment = environments.getEnvironment(currentCollection.provider);

        if (selectedEnvironment.jwks && selectedEnvironment.jwks[decodedToken.header.kid]) {
            return updateKeyForToken(selectedEnvironment.jwks[decodedToken.header.kid],
                updatedToken.id, currentCollection)
        }
    }
    return {
        type: C.DO_NOTHING
    }
};

export const createAndSelectCollectionWithToken = (flow, provider, tokenValue, group) => {
    const createCollectionAction = createCollection(flow, provider);
    const collectionId = createCollectionAction.index;
    const addCollectionToDefaultGroup = addCollectionToGroup(createCollectionAction.index, group, null, -1)

    const parameters = new Parameters({ jwt_flow_token_input: tokenValue })
    const updateCollectionParameters = updateParameters(collectionId, parameters)
    const selectCollectionAction = selectCollection(createCollectionAction.index);

    return function (dispatch) {
        dispatch(createCollectionAction);
        dispatch(addCollectionToDefaultGroup);
        dispatch(updateCollectionParameters);
        dispatch(selectCollectionAction);
    }
};

export const generateJwtForCollection = (collection) => {
    return async (dispatch) => {
        const tokenIds = collection.getTokenIds();
        const tokenId = tokenIds[0];

        let body = {}, header = {};

        collection.parameters.create_jwt_header.map((parameter, i) => {
            if (notEmpty(parameter.name)) {
                if (parameter.type === 'date' || parameter.type === 'int') {
                    header[parameter.name] = Number(parameter.value)
                } else {
                    header[parameter.name] = parameter.value
                }
            }
        });
        header.alg = collection.parameters.create_jwt_algorithm;


        collection.parameters.create_jwt_body.map((parameter, i) => {
            if (notEmpty(parameter.name)) {
                if (parameter.type === 'date' || parameter.type === 'int') {
                    body[parameter.name] = Number(parameter.value)
                } else {
                    body[parameter.name] = parameter.value
                }
            }
        });
        const payload = JSON.stringify(body);

        let keystore = jose.JWK.createKeyStore();
        let key;
        if (header.alg === 'none') {
            const jwt = createNoneJwt(JSON.stringify(header), payload);
            const noneToken = collection.getTokenById(tokenId)
                .withNewValue(jwt)
                .withUpdatedProperty('validation_data', {
                    body,
                    header: 'header'
                });
            dispatch(updateToken(collection.id, noneToken));

            return;
        } else if (header.alg.indexOf('HS') !== -1) {
            key = await jose.JWK.asKey({
                kty: 'oct',
                use: 'sig',
                alg: header.alg,
                k: jose.util.base64url
                    .encode(stringToArrayBuffer(collection.getTokenById(tokenId).private_key.jwk, header.alg))
            });
        } else {
            const normalizedKey = removeLeadingZeros(collection.getTokenById(tokenId).private_key.jwk)
            key = await keystore.add(normalizedKey, 'jwk');
        }

        // Sign payload
        const opt = { compact: true, jwk: key, fields: header };
        try {
            const jwt = await jose.JWS.createSign(opt, { key, reference: false })
                .update(payload).final();

            const token = collection.getTokenById(tokenId)
                .withNewValue(jwt)
                .withUpdatedProperty('validation_data', {
                    body,
                    header: 'header'
                });

            dispatch(updateToken(collection.id, token));
        } catch (e) {
            dispatch(setErrorOnCollection(collection.id, e))
        }
    }
};

export const updatePrivateKeyForToken = (key, tokenId, collection) => {
    return async function (dispatch) {
        if (Object.prototype.hasOwnProperty.call(key, 'jwk')) {
            try {
                const joseJwk = await jose.JWK.asKey(key.jwk);
                key.pem = joseJwk.toPEM(true);
            } catch {
                // Could not parse key as JWK
            }
        } else {
            try {
                let keyStore = await jose.JWK.createKeyStore();
                const parsedKey = await keyStore.add(key.pem, 'pem');
                key.jwk = parsedKey.toJSON(true);
            } catch (e) {
                if (!isEmpty(key.pem)) {
                    // Could not parse key as JWK
                    console.warn(e);
                    dispatch(setErrorOnCollection(collection.id, 'Could not parse key'));
                }
            }
        }
        dispatch(updatePrivateKeyForTokenDispatch(key, tokenId, collection.id));

    }
};

export const updateKeyForToken = (key, tokenId, collection) => {
    return async (dispatch) => {
        const currentToken = collection.tokens[tokenId];
        let validKey = {};

        if (Object.prototype.hasOwnProperty.call(key, 'jwk')) {
            if (Object.prototype.hasOwnProperty.call(key.jwk, 'keys')) {
                let currentKey = {};
                for (let i in key.jwk.keys) {
                    try {
                        const joseJwk = await jose.JWK.asKey(key.jwk.keys[i]);
                        if ((await isJwtSignatureValid(currentToken.value,
                            currentToken.decoded_token.header.alg, joseJwk.toPEM()))) {
                            // found key that validates the JWT, use it and stop the loop
                            currentKey.pem = joseJwk.toPEM();
                            currentKey.jwk = joseJwk.toJSON();
                            break;
                        }
                    } catch {
                        // Could not parse key as JWK, continue to next
                    }
                }
                if (!isEmpty(currentKey)) {
                    validKey = currentKey
                }

            } else {
                validKey.jwk = key.jwk;
                try {
                    const joseJwk = await jose.JWK.asKey(key.jwk);
                    validKey.pem = joseJwk.toPEM();
                } catch {
                    // Could not parse key as JWK
                    validKey.pem = key.jwk;
                }
            }
        } else {
            validKey.pem = key.pem;
            try {
                let keyStore = await jose.JWK.createKeyStore();
                const parsedKey = await keyStore.add(key.pem, 'pem');
                validKey.jwk = parsedKey.toJSON();
            } catch {
                // Could not parse key as JWK
            }
        }

        //validate JWT (no need to decode again as the value didn't change)
        let validation = currentToken.validation_data;
        if (!validation) {
            validation = {};
        }
        validation.signature = {};
        if (Object.keys(currentToken.decoded_token).length !== 0) {
            validation.signature.value = await isJwtSignatureValid(currentToken.value,
                currentToken.decoded_token.header.alg, validKey.pem);
        } else {
            validation.signature.value = false;
        }
        dispatch(updateKeyForTokenDispatch(validKey, tokenId, collection.id, validation));
    }
};

const updatePrivateKeyForTokenDispatch = (keyValue, tokenId, collectionId) => {
    return {
        type: C.UPDATE_PRIVATE_KEY_FOR_TOKEN,
        keyValue,
        tokenId,
        collectionId
    }
};

const updateKeyForTokenDispatch = (keyValue, tokenId, collectionId, validation) => {
    return {
        type: C.UPDATE_KEY_FOR_TOKEN,
        keyValue,
        tokenId,
        collectionId,
        validation
    }
};

/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import * as React from 'react';
import Environments from '../../data/Environments';
import { decodeUrlParameter } from '../../util/util';
import { flows, formatOptionLabel, guides, tokenPurposes } from '../../util/appConstants';
import FlowHeader from './FlowHeader';
import StepBox from './StepBox';
import StartHere from './StartHere';
import CurlRequestPreview from './CurlRequestPreview';
import makeAnimated from 'react-select/animated';
import Creatable from 'react-select/creatable'
import { isEmpty } from '../../util/validationUtils';
import EmptySidebar from '../EmptySidebar';
import OpaqueTokenValidation from '../validation/OpaqueTokenValidation';
import Guide from '../guides/Guide';
import MiddlePaneHeader from './MiddlePaneHeader';
import ClearTokens from '../ClearTokens';
import ServerResponse from '../shared/ServerResponse';
import JwtTokenValidation from '../validation/JwtTokenValidation';
import ReceivedToken from '../token/ReceivedToken';
import ResizablePanels from '../ResizablePanels';
import RunButton from './RunButton';

class Userinfo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            guideIsVisible: false
        };
    }

    toggleGuideVisible = (isVisible) => {
        this.setState( { guideIsVisible: isVisible })
    }

    clearErrorFromCollection = () => {
        this.props.setErrorOnCollection(this.props.collection.id, null)
    };

    setTokenForUserinfo = (newValue, actionMeta) => {
        if (actionMeta.action === 'select-option') {
            const updatedParameters = this.props.collection.parameters.withUpdatedValue('token_to_userinfo', newValue.value);
            this.props.updateParameters(this.props.collection.id, updatedParameters);
        }

        if (actionMeta.action === 'create-option') {
            const updatedParameters =
                this.props.collection.parameters
                    .withUpdatedValue('token_to_userinfo', newValue.value);
            this.props.updateParameters(this.props.collection.id, updatedParameters);
        }

        if (actionMeta.action === 'clear') {
            const updatedParameters =
                this.props.collection.parameters.withUpdatedValue('token_to_userinfo', undefined);
            this.props.updateParameters(this.props.collection.id, updatedParameters);
        }
        this.props.setTokensOnCollection(this.props.collection.id, [], [], null, true)
    };

    previewRequest = () => {
        const environments = Environments.create(this.props.environments);
        const environment = environments.getEnvironment(this.props.collection.provider);

        const userInfoEndpoint = environment ? environment.endpoints.userinfo_endpoint : '';

        return 'curl -Ss -X POST \\\n' +
            userInfoEndpoint + ' \\\n' +
            "-H 'Authorization: Bearer " + this.props.collection.parameters.token_to_userinfo + "'"

    };

    runFlow = () => {
        const environments = Environments.create(this.props.environments);
        const environment = environments.getEnvironment(this.props.collection.provider);
        const token = this.props.collection.parameters.token_to_userinfo;
        this.props.userInfoRequest(token, this.props.collection, environment);
    };

    clearTokens = () => {
        this.props.clearOAuthResponses(this.props.collection.id);
        this.props.setTokensOnCollection(this.props.collection.id, [], null, null, true);
    };

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {

        const currentCollection = this.props.collection;

        const environments = Environments.create(this.props.environments);
        const environment = environments.getEnvironment(currentCollection.provider);

        const selectedToken = this.props.collection.parameters.token_to_userinfo;
        const userinfoResponseData = this.props.collection.getTokenById(0);
        const Validation = userinfoResponseData &&
        userinfoResponseData.isJwt() ? JwtTokenValidation : OpaqueTokenValidation;

        const enableStep2 = !!(selectedToken && environment && environment.canDoUserinfo());

        const error = (!currentCollection.error) ? '' :
            <div className="alert alert-danger">
                <i className="icon ion-ios-close-outline inlineicon"/>
                {decodeUrlParameter(currentCollection.error)}
                <button className="alert-close" onClick={this.clearErrorFromCollection}><i className="icon ion-close"/>
                </button>
            </div>;

        const curlRequest = this.previewRequest();

        let accessTokensFromCollections = [];
        Object.values(this.props.collections)
          .filter(collection => collection.provider === currentCollection.provider)
          .forEach(collection => {
            if (flows[collection.flow].produces_tokens) {
                Object.values(collection.tokens).forEach(token => {
                    if (!isEmpty(token.value) && token.purpose !== tokenPurposes.id_token.value &&
                        token.purpose !== tokenPurposes.refresh_token.value) {
                        const ellipsis = token.value.length > 20 ? '...' : '';
                        accessTokensFromCollections.push({
                            value: token.value,
                            label: `${collection.name}: ${token.name}`,
                            tokenString: `${token.value.substring(0, 20)}${ellipsis}`,
                            meta: {
                                purpose: token.purpose,
                                name: token.name
                            }
                        });
                    }
                });
            }
        });

        const selectedOption = selectedToken ?
            accessTokensFromCollections.filter(option => option.value === selectedToken) : null;

        return (
            <React.Fragment>
                <ResizablePanels {...this.props}>
                    <section className="tools-form">
                        <MiddlePaneHeader
                            workspace={environment}
                            collection={this.props.collection}
                            exportCurrentCollection={this.props.exportCurrentCollection}/>

                        <div className="tools-form-content">

                            <FlowHeader name={currentCollection.name}
                                        description={'Select a token from an existing collection or type a new one to call the userinfo endpoint.'}/>
                            {error}

                            <StepBox title={'Settings'} step={'1'} enabled={true}>

                                <StartHere clientId={selectedOption} label={'Start with a Token'}/>

                                <div className="sm-flex flex-justify flex-center mt2">
                                    <div className="flex-100">
                                        <Creatable
                                            isClearable
                                            placeholder="Enter a token or select one from a Collection"
                                            components={makeAnimated()}
                                            options={accessTokensFromCollections}
                                            formatOptionLabel={formatOptionLabel}
                                            defaultValue={selectedOption}
                                            onChange={this.setTokenForUserinfo}
                                            className="select-container select-container-big"
                                            inputId={'select-token-userinfo-flow'}
                                            classNamePrefix="react-select"
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#f2f3f6',
                                                    primary: '#626c87'
                                                }
                                            })}
                                        />
                                    </div>
                                </div>
                            </StepBox>

                            <StepBox title={'Call userinfo endpoint'} step={'2'} enabled={enableStep2}>

                                <CurlRequestPreview request={curlRequest}/>
                                <RunButton runFlow={this.runFlow} buttonText={'Call Userinfo Endpoint'}/>
                            </StepBox>

                            <ServerResponse response={currentCollection.OAuthResponses.UserInfoResponse}/>

                        </div>
                    </section>

                    {userinfoResponseData ?
                        <aside className="tools-sidebar">


                            <div className={'received-token'}
                                aria-expanded="true">
                                <header className="tools-form-header tools-form-header-tokens">
                                    <h4 className="m0"/>
                                    <div className="flex justify-between flex-center">
                                        <ClearTokens clearTokens={this.clearTokens}
                                                    showClearButton={this.props.collection.getTokenIds().length > 0}/>
                                        <Guide area={guides.userinfo} toggle={this.toggleGuideVisible}/>
                                    </div>
                                </header>
                                {!this.state.guideIsVisible && <>
                                {userinfoResponseData.isJwt() &&
                                <ReceivedToken
                                    key={userinfoResponseData.id}
                                    token={userinfoResponseData}
                                    collection={this.props.collection}
                                    environment={this.props.environment}
                                    tokenPurposeLabelOverride={'USERINFO RESPONSE'}
                                    groups={this.props.groups}
                                    sendData={this.getData}
                                    createAndSelectCollectionWithToken=
                                        {this.props.createAndSelectCollectionWithToken}
                                />}
                                <Validation token={userinfoResponseData}
                                            flow={this.props.flow}
                                            collection={this.props.collection}
                                            headerName={'Userinfo Response'}
                                            environment={this.props.environment}/>
                                    </>}
                            </div>
                        </aside>
                        :
                        <aside className="tools-sidebar">
                        <EmptySidebar
                            guide={guides.userinfo}
                            text="Run the flow to get the userinfo result"/>
                        </aside>

                    }
                </ResizablePanels>
            </React.Fragment>
        );
    }
}

export default Userinfo

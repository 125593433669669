/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import { getUnique } from '../util/util';
import DCRParameters from './DCRParameters';

class Parameters {

    constructor(parameters) {
        this._client_id = parameters.client_id;
        this._client_secret = parameters.client_secret;
        this._client_name = parameters.client_name;

        this._username = parameters.username;
        this._password = parameters.password;
        this._claims = parameters.claims ? parameters.claims : [];
        this._authorization_details = parameters.authorization_details;

        this._can_do_introspect = parameters.can_do_introspect;
        this._can_do_code_flow = parameters.can_do_code_flow;
        this._can_do_device_flow = parameters.can_do_device_flow;
        this._can_do_ciba_flow = parameters.can_do_ciba_flow;
        this._can_do_ropc_flow = parameters.can_do_ropc_flow;
        this._can_do_implicit_flow = parameters.can_do_implicit_flow;
        this._can_do_hybrid_flow = parameters.can_do_hybrid_flow;
        this._can_do_refresh_flow = parameters.can_do_refresh_flow;
        this._can_do_revoke_flow = parameters.can_do_revoke_flow;
        this._can_do_client_credentials_flow = parameters.can_do_client_credentials_flow;
        this._can_be_framed = parameters.can_be_framed;
        this._redirect_uri = parameters.redirect_uri;

        this._introspection_client_id = parameters.introspection_client_id;
        this._introspection_client_secret = parameters.introspection_client_secret;
        this._use_separate_client_for_introspection =
            (parameters.use_separate_client_for_introspection === undefined) ?
                false : parameters.use_separate_client_for_introspection;
        this._use_application_jwt = parameters.use_application_jwt;
        this._acrs = parameters.acrs;
        this._scopes = parameters.scopes;
        this._locales = parameters.locales;
        this._freshness = parameters.freshness;
        this._prompt = parameters.prompt;
        this._responseTypes = getUnique(parameters.responseTypes, 'value');

        this._device_user_code = parameters.device_user_code;
        this._device_device_code = parameters.device_device_code;
        this._device_verification_uri = parameters.device_verification_uri;
        this._device_verification_uri_complete = parameters.device_verification_uri_complete;
        this._device_qr_code = parameters.device_qr_code;

        this._authorization_code = parameters.authorization_code;
        this._auto_redeem_code = parameters.auto_redeem_code;
        this._authorization_code_spent = parameters.authorization_code_spent; // used also for device_code

        this._nonce = parameters.nonce;
        this._c_nonce = parameters.c_nonce;
        this._pre_authorized_code = parameters.pre_authorized_code;
        this._login_hint = parameters.login_hint;
        this._login_hint_token = parameters.login_hint_token;
        this._id_token_hint = parameters.id_token_hint;
        this._backchannel_endpoint_auth_method = parameters.backchannel_endpoint_auth_method;
        this._auth_req_id = parameters.auth_req_id;
        this._auth_req_id_spent = parameters.auth_req_id_spent;
        this._expires_in = parameters.expires_in;
        this._interval = parameters.interval;
        this._binding_message = parameters.binding_message;
        this._user_code = parameters.user_code;
        this._requested_expiry = parameters.requested_expiry;
        this._default_response_types = parameters.default_response_types;
        this._code_verifier = parameters.code_verifier;
        this._token_to_introspect = parameters.token_to_introspect;
        this._token_to_userinfo = parameters.token_to_userinfo;
        this._token_to_refresh = parameters.token_to_refresh;
        this._token_to_revoke = parameters.token_to_revoke;
        this._client_from_environment = parameters.client_from_environment;
        this._token_endpoint_auth_method = parameters.token_endpoint_auth_method;
        this._introspection_endpoint_auth_method = parameters.introspection_endpoint_auth_method;
        this._revocation_endpoint_auth_method = parameters.revocation_endpoint_auth_method;
        this._device_authorization_endpoint_auth_method = parameters.device_authorization_endpoint_auth_method;
        this._token_type_hint = parameters.token_type_hint;

        this._session_state = parameters.session_state;

        this._par = parameters.par;
        this._par_request_uri = parameters.par_request_uri;
        this._jar = parameters.jar;
        this._jar_algorithm = parameters.jar_algorithm ? parameters.jar_algorithm : '';
        this._jarKeys = parameters.jarKeys ? parameters.jarKeys : { private_key: '', validation_key: '' };

        this._api_call_endpoint = parameters.api_call_endpoint;
        this._api_call_request_method = parameters.api_call_request_method;
        this._api_call_token = parameters.api_call_token;
        this._api_call_headers = parameters.api_call_headers ? parameters.api_call_headers : [{
            name: '',
            value: '',
            id: Math.random()
        }];
        this._api_call_parameters = parameters.api_call_parameters ? parameters.api_call_parameters : [{
            name: '',
            value: '',
            id: Math.random()
        }];
        this._api_call_body = parameters.api_call_body ? parameters.api_call_body : '';
        this._api_call_form_body = parameters.api_call_form_body ? parameters.api_call_form_body : [{
            name: '',
            value: '',
            id: Math.random()
        }];
        this._api_call_body_type = parameters.api_call_body_type ? parameters.api_call_body_type : '';

        this._create_jwt_algorithm = parameters.create_jwt_algorithm ? parameters.create_jwt_algorithm : 'HS256';
        this._create_jwt_header = parameters.create_jwt_header ? parameters.create_jwt_header : [{
            name: '',
            value: '',
            type: 'text',
            id: Math.random()
        }];
        this._create_jwt_body = parameters.create_jwt_body ? parameters.create_jwt_body : [{
            name: '',
            value: '',
            type: 'text',
            id: Math.random()
        }];

        this._create_jwt_json_header_mode = parameters.create_jwt_json_header_mode ?
            parameters.create_jwt_json_header_mode : false;
        this._create_jwt_json_body_mode = parameters.create_jwt_json_body_mode ?
            parameters.create_jwt_json_body_mode : false;
        this._create_jwt_token_purpose = parameters.create_jwt_token_purpose;

        this._vci_c_nonce = parameters.vci_c_nonce;
        this._vci_token = parameters.vci_token;
        this._vci_format = parameters.vci_format;
        this._vci_types = parameters.vci_types;
        this._vci_vct = parameters.vci_vct;
        this._vci_proof = parameters.vci_proof;
        this._vci_credential_subject_toggle = parameters.vci_credential_subject_toggle;
        this._vci_credential_subject = parameters.vci_credential_subject;
        this._vci_kid = parameters.vci_kid;
        this._vci_x5c = parameters.vci_x5c;
        this._vci_kid_in_header = parameters.vci_kid_in_header;
        this._vci_x5t_in_header = parameters.vci_x5t_in_header;
        this._vci_x5c_in_header = parameters.vci_x5c_in_header;
        this._vci_algorithm = parameters.vci_algorithm;
        this._vci_public_key = parameters.vci_public_key
        this._vci_private_key = parameters.vci_private_key
        this._precode_code = parameters.precode_code;
        this._precode_tx_code = parameters.precode_tx_code;

        this._jwt_flow_token_input = parameters.jwt_flow_token_input;
        this._jwt_flow_token_purpose = parameters.jwt_flow_token_purpose;
        this._jwt_flow_key = parameters.jwt_flow_key ? parameters.jwt_flow_key : { validation_key: '' };


        this._dcr_parameters = parameters.dcr_parameters;
        this._parameters = { ...parameters };
    }

    get client_id() {
        return this._client_id;
    }

    get client_secret() {
        return this._client_secret;
    }

    get redirect_uri() {
        return this._redirect_uri;
    }

    get client_name() {
        return this._client_name;
    }

    get username() {
        return this._username;
    }

    get password() {
        return this._password;
    }


    get claims() {
        return this._claims;
    }

    get authorization_details() {
        return this._authorization_details;
    }

    get introspection_client_id() {
        return this._introspection_client_id;
    }

    get introspection_client_secret() {
        return this._introspection_client_secret;
    }

    get use_separate_client_for_introspection() {
        return this._use_separate_client_for_introspection;
    }

    get use_application_jwt() {
        return this._use_application_jwt;
    }

    get acrs() {
        return this._acrs;
    }

    get scopes() {
        return this._scopes;
    }

    get locales() {
        return this._locales;
    }

    get freshness() {
        return this._freshness;
    }

    get prompt() {
        return this._prompt;
    }

    get device_user_code() {
        return this._device_user_code;
    }

    get device_device_code() {
        return this._device_device_code;
    }

    get device_verification_uri() {
        return this._device_verification_uri;
    }

    get device_verification_uri_complete() {
        return this._device_verification_uri_complete;
    }

    get device_qr_code() {
        return this._device_qr_code;
    }

    get responseTypes() {
        return this._responseTypes;
    }

    get default_response_types() {
        return this._default_response_types;
    }

    get nonce() {
        return this._nonce;
    }

    get c_nonce() {
        return this._c_nonce;
    }

    get pre_authorized_code() {
        return this._pre_authorized_code;
    }

    get login_hint() {
        return this._login_hint;
    }

    get login_hint_token() {
        return this._login_hint_token;
    }

    get id_token_hint() {
        return this._id_token_hint;
    }

    get backchannel_endpoint_auth_method() {
        return this._backchannel_endpoint_auth_method;
    }

    get auth_req_id() {
        return this._auth_req_id;
    }

    get auth_req_id_spent() {
        return this._auth_req_id_spent;
    }

    get expires_in() {
        return this._expires_in;
    }

    get interval() {
        return this._interval;
    }

    get binding_message() {
        return this._binding_message;
    }

    get user_code() {
        return this._user_code;
    }

    get requested_expiry() {
        return this._requested_expiry;
    }

    get authorization_code() {
        return this._authorization_code;
    }

    get auto_redeem_code() {
        return this._auto_redeem_code
    }

    get authorization_code_spent() {
        return this._authorization_code_spent; // used also for device_code
    }

    get parameters() {
        return this._parameters;
    }

    get can_do_introspect() {
        return this._can_do_introspect;
    }

    get can_do_code_flow() {
        return this._can_do_code_flow;
    }

    get can_do_ropc_flow() {
        return this._can_do_ropc_flow;
    }

    get can_do_device_flow() {
        return this._can_do_device_flow;
    }

    get can_do_ciba_flow() {
        return this._can_do_ciba_flow;
    }

    get can_do_implicit_flow() {
        return this._can_do_implicit_flow;
    }

    get can_do_hybrid_flow() {
        return this._can_do_hybrid_flow;
    }

    get can_do_refresh_flow() {
        return this._can_do_refresh_flow;
    }

    get can_do_revoke_flow() {
        return this._can_do_revoke_flow;
    }

    get can_do_client_credentials_flow() {
        return this._can_do_client_credentials_flow;
    }

    get can_be_framed() {
        return this._can_be_framed;
    }

    get code_verifier() {
        return this._code_verifier;
    }

    get token_to_introspect() {
        return this._token_to_introspect;
    }

    get token_to_userinfo() {
        return this._token_to_userinfo;
    }

    get token_to_refresh() {
        return this._token_to_refresh;
    }

    get token_to_revoke() {
        return this._token_to_revoke;
    }

    get token_endpoint_auth_method() {
        return this._token_endpoint_auth_method;
    }

    get introspection_endpoint_auth_method() {
        return this._introspection_endpoint_auth_method;
    }

    get revocation_endpoint_auth_method() {
        return this._revocation_endpoint_auth_method;
    }

    get device_authorization_endpoint_auth_method() {
        return this._device_authorization_endpoint_auth_method;
    }

    get token_type_hint() {
        return this._token_type_hint;
    }

    get client_from_environment() {
        return this._client_from_environment;
    }

    get api_call_endpoint() {
        return this._api_call_endpoint;
    }

    get api_call_token() {
        return this._api_call_token;
    }

    get api_call_request_method() {
        return this._api_call_request_method;
    }

    get api_call_headers() {
        return this._api_call_headers;
    }

    get api_call_parameters() {
        return this._api_call_parameters;
    }

    get api_call_body() {
        return this._api_call_body;
    }

    get api_call_form_body() {
        return this._api_call_form_body;
    }

    get api_call_body_type() {
        return this._api_call_body_type;
    }


    get vci_c_nonce() {
        return this._vci_c_nonce;
    }

    get vci_token() {
        return this._vci_token;
    }

    get vci_format() {
        return this._vci_format;
    }

    get vci_types() {
        return this._vci_types;
    }

    get vci_vct() {
        return this._vci_vct;
    }

    get vci_proof() {
        return this._vci_proof;
    }

    get vci_credential_subject_toggle() {
        return this._vci_credential_subject_toggle;
    }

    get vci_credential_subject() {
        return this._vci_credential_subject;
    }

    get vci_kid() {
        return this._vci_kid;
    }

    get vci_x5c() {
        return this._vci_x5c;
    }

    get vci_kid_in_header() {
        return this._vci_kid_in_header;
    }

    get vci_x5c_in_header() {
        return this._vci_x5c_in_header;
    }

    get vci_x5t_in_header() {
        return this._vci_x5t_in_header;
    }

    get vci_algorithm() {
        return this._vci_algorithm;
    }

    get vci_public_key() {
        return this._vci_public_key;
    }

    get vci_private_key() {
        return this._vci_private_key;
    }

    get precode_code() {
        return this._precode_code;
    }

    get precode_tx_code() {
        return this._precode_tx_code;
    }

    get create_jwt_header() {
        return this._create_jwt_header;
    }

    get create_jwt_body() {
        return this._create_jwt_body;
    }

    get create_jwt_json_header_mode() {
        return this._create_jwt_json_header_mode;
    }

    get create_jwt_json_body_mode() {
        return this._create_jwt_json_body_mode;
    }

    get create_jwt_algorithm() {
        return this._create_jwt_algorithm;
    }

    get create_jwt_token_purpose() {
        return this._create_jwt_token_purpose;
    }

    get session_state() {
        return this._session_state;
    }

    get jar() {
        return this._jar;
    }

    get par() {
        return this._par;
    }

    get par_request_uri() {
        return this._par_request_uri;
    }

    get jar_algorithm() {
        return this._jar_algorithm;
    }

    get jarKeys() {
        return this._jarKeys;
    }

    get dcr_parameters() {
        return new DCRParameters(this._dcr_parameters);
    }

    get jwt_flow_token_input() {
        return this._jwt_flow_token_input;
    }

    get jwt_flow_token_purpose() {
        return this._jwt_flow_token_purpose;
    }

    get jwt_flow_key() {
        return this._jwt_flow_key;
    }

    canIntrospect = () => {
        return !!((
                this._use_separate_client_for_introspection && this._introspection_client_id
                && this._introspection_client_secret)
            || (this._use_separate_client_for_introspection === false && this._client_id
                && this._client_secret && !!this._can_do_introspect))
    };

    toMap = () => {
        return this._parameters;
    };

    withUpdatedDcrParameters = (dcr_parameter_name, value) => {
        const updatedDcrParameters = this.dcr_parameters.withUpdatedValue(dcr_parameter_name, value);
        return this.withUpdatedValue('dcr_parameters', updatedDcrParameters.toMap());
    };

    withUpdatedValue = (key, value) => {

        const prop = '_' + key;

        if (Object.prototype.hasOwnProperty.call(this, prop)) {
            const current = this.toMap();
            const next = {
                ...current,
                [key]: value
            };
            return new Parameters(next);
        } else {
            throw 'Key ' + key + ' is not a property of Parameters';
        }
    }
}

export default Parameters;

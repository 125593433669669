/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import ReceivedToken from './ReceivedToken';
import OpaqueTokenValidation from '../validation/OpaqueTokenValidation';
import EmptySidebar from '../EmptySidebar';
import JwtTokenValidation from '../validation/JwtTokenValidation';
import { isMapEmpty } from '../../util/util';
import Guide from '../guides/Guide';
import ClearTokens from '../ClearTokens';
import { isEmptyObject } from '../../util/validationUtils';
import SidebarIntrospectionResult from './SidebarIntrospectionResult';
import LogoutButton from '../LogoutButton';
import { tokenPurposes } from '../../util/appConstants';

class ReceivedTokensSidebar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            guideIsVisible: false
        };
    }

    handleCollapse = (e) => {
        e.preventDefault();
        const receivedToken = e.currentTarget.parentElement;
        receivedToken.classList.toggle('folded');
        receivedToken.setAttribute('aria-expanded', receivedToken.getAttribute('aria-expanded') === 'true' ? 'false' : 'true');
    };

    toggleGuideVisible = (isVisible) => {
        this.setState( { guideIsVisible: isVisible })
    }

    clearTokens = () => {
        if (this.props.updateParameters) {
            const updatedParameters =
                this.props.collection.parameters
                    .withUpdatedValue('authorization_code', null)
                    .withUpdatedValue('par_request_uri', null)
                    .withUpdatedValue('authorization_code_spent', null)
                    .withUpdatedValue('device_user_code', null)
                    .withUpdatedValue('device_device_code', null)
                    .withUpdatedValue('device_verification_uri', null)
                    .withUpdatedValue('device_verification_uri_complete', null)
                    .withUpdatedValue('device_qr_code', null)
                    .withUpdatedValue('auth_req_id', null);
            this.props.updateParameters(this.props.collection.id, updatedParameters);
        }

        this.props.setTokensOnCollection(this.props.collection.id, [], null, null);
        this.props.clearOAuthResponses(this.props.collection.id);
    };

    render() {
        const tokenIds = this.props.collection.getTokenIds();
        if (tokenIds.length === 0) {
            return (
                <aside className="tools-sidebar">
                    <EmptySidebar guide={this.props.guide}
                                clearTokens={this.clearTokens}
                                collection={this.props.collection}
                                text="Start flow to show tokens"/>
                </aside>
            );
        } else {

            return (
                <React.Fragment>
                    <aside className="tools-sidebar">


                        <header className="tools-form-header tools-form-header-tokens">

                                <LogoutButton
                                    collection={this.props.collection}
                                    environment={this.props.environment}
                                    updateParameters={this.props.updateParameters}
                                    visible={this.props.showLogoutButton}/>
                                <ClearTokens clearTokens={this.clearTokens}
                                            showClearButton={
                                                !isEmptyObject(this.props.collection.OAuthResponses) ||
                                                this.props.collection.getTokenIds().length > 0
                                            }/>

                        </header>
                        <Guide area={this.props.guide} toggle={this.toggleGuideVisible}/>

                        {!this.state.guideIsVisible && <main role="contentinfo">

                            {this.props.collection.getTokenIds().map((tokenId, index) => {
                                const token = this.props.collection.getTokenById(tokenId);
                                const Validation = (token.isJwt()) ? JwtTokenValidation : OpaqueTokenValidation;
                                return (

                                    <div key={tokenId}
                                         className={'received-token received-token-' + parseInt(index + 1)}
                                         aria-expanded="true">
                                        <header className="received-token-header flex flex-center justify-between"
                                                onClick={this.handleCollapse}>
                                            {token.purpose === tokenPurposes.authorization_details.value ?
                                            <h4 className="m0">Authorization Details</h4>
                                            :
                                                <h4 className="m0">Token {index + 1}</h4>
                                            }
                                            <button className="button button-tiny button-fold"/>
                                        </header>
                                        <ReceivedToken
                                            key={tokenId}
                                            token={token}
                                            collection={this.props.collection}
                                            environment={this.props.environment}
                                            refreshTokens={this.props.refreshTokens}
                                            introspectToken={this.props.introspectToken}
                                            groups={this.props.groups}
                                            sendData={this.getData}
                                            createAndSelectCollectionWithToken=
                                                {this.props.createAndSelectCollectionWithToken}
                                        />
                                        {(!isMapEmpty(token.decoded_token)) &&
                                        <Validation token={token}
                                                    flow={this.props.flow}
                                                    collection={this.props.collection}
                                                    environment={this.props.environment}/>
                                        }
                                        {(!isMapEmpty(token.introspected_token_data)) &&
                                        <SidebarIntrospectionResult
                                            createAndSelectCollectionWithToken={
                                                this.props.createAndSelectCollectionWithToken}
                                            collection={this.props.collection}
                                            groups={this.props.groups}
                                            environment={this.props.environment}
                                            data={token.introspected_token_data}/>
                                        }

                                    </div>)
                            })}
                        </main>}
                    </aside>
                </React.Fragment>
            );
        }
    }

}

export default ReceivedTokensSidebar;

/*
 * Copyright (C) 2021 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React, { useEffect, useRef, useState } from 'react';
import { flows } from '../../util/appConstants';
import HistoryEntry from './HistoryEntry';
import { Draggable } from 'react-beautiful-dnd';
import ConfirmDeleteModal from '../modals/ConfirmDeleteModal';
import FolderIcon from '../icons/FolderIcon'
import FolderIconOpen from '../icons/FolderIconOpen'
import MoveFlowToWorkspaceModal from '../modals/MoveFlowToWorkspaceModal';

function CollectionGroup(props) {
    const ref = useRef(null);
    const [isEditing, setEditing] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [isMoving, setMoving] = useState(null)

    const toggleCollapse = () => {
        props.setGroupCollapsed(props.groupId, !props.collapsed)
    }

    const handleActionButtonClick = (event) => {
        event.stopPropagation();
        setShowDropdown(!showDropdown);
    }

    const hideConfirmModal = () => {
        setShowConfirmModal(false);
    }

    const onDeleteGroup = () => {
        setShowConfirmModal(false);
        props.deleteGroup(props.groupId, props.order)
    }

    const onEdit = (event) => {
        event.stopPropagation();
        const editing = !isEditing;
        setShowDropdown(false)
        setEditing(editing)
    };

    const onDelete = (event) => {
        event.stopPropagation();
        setShowDropdown(false);
        setShowConfirmModal(true)
    }

    const onAddFlow = (event) => {
        event.stopPropagation();
        props.showNewFlowModal({}, props.groupId)
        setShowDropdown(false);
    }

    const updateName = (event) => {
        props.renameGroup(props.groupId, event.currentTarget.value);
    };

    const handleFocus = (event) => {
        event.target.select();
    };

    const onConfirm = () => {
        setEditing(false)
    };

    useEffect(() => {
        const addEventListeners = () => {
            removeEventListeners();
            document.addEventListener('keydown', escFunction, false);
            document.addEventListener('mousedown', handleClickOutside, false);
        }

        const removeEventListeners = () => {
            document.removeEventListener('keydown', escFunction, false);
            document.removeEventListener('mousedown', handleClickOutside, false);
        }

        const escFunction = (event) => {
            if (event.keyCode === 27 || event.keyCode === 13) {
                removeEventListeners()
                setEditing(false)
                setShowDropdown(false)
            }
        };

        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                removeEventListeners();
                setShowDropdown(false)
            }
        }

        const handleRightClick = (event) => {
            if (ref.current && ref.current.contains(event.target)) {
                event.preventDefault();
                if (showDropdown) {
                    setShowDropdown(false)
                } else {
                    setShowDropdown(true)
                }
            }
        }
        document.addEventListener('contextmenu', handleRightClick, false);

        if (showDropdown || isEditing) {
            addEventListeners()
        } else {
            removeEventListeners();
        }

        return () => {
            removeEventListeners();
            document.removeEventListener('contextmenu', handleRightClick, false);
        }
    },[isEditing, showDropdown])

    const listClass = props.groupId === 'default' ? 'm0 list-reset history-list' : 'm0 list-reset history-list ml2'
    const groupIcon = props.collapsed ? <FolderIcon/> : <FolderIconOpen/>;

    const defaultGroupEmptyMessage = props.numberOfGroups === 1 ? "You don't have any groups or flows in this workspace" : '';
    const emptyMessage = props.groupId === 'default' ? defaultGroupEmptyMessage : "You don't have any flows in this group";

    return (
        <>
            {props.groupId !== 'default' &&
            <div ref={ref} key={props.groupId} className={'tools-history-group'} onClick={toggleCollapse}>

                <div className="tools-history-group-icon inline-flex flex-center mr1">
                    {groupIcon}
                </div>
                {isEditing ?
                    <div className="tools-history-field-wrapper">
                        <input type="text" className="field tools-history-field mr1" onChange={updateName}
                               value={props.name} onBlur={onConfirm} onFocus={handleFocus} autoFocus
                               autoComplete="off"
                               data-lpignore="true"
                               spellCheck="false"
                        />
                    </div>
                    :
                    <span className={'tools-history-entry'}>{props.name}</span>
                }

                <span className={'tools-history-edit ' + (showDropdown ? 'active' : '')}
                      onClick={handleActionButtonClick}>
                        <i className="icon ion-more" style={{ transform: 'rotate(90deg)' }}/>
                    </span>

                <div className={(isEditing ? 'hide' : '')}>
                    <div className={'context-dropdown-menu ' + (showDropdown ? 'active' : 'hide')}
                         aria-labelledby="dropdownButton">
                        <nav>
                            <ul className="m0 list-reset">
                                <li>
                                    <div onClick={onAddFlow}>
                                        <i className="icon ion-plus mr2 primary"/>Add Flow
                                    </div>
                                </li>
                                <li>
                                    <div onClick={onEdit}>
                                        <i className="icon ion-edit mr2 primary"/>Rename
                                    </div>
                                </li>
                                <li>
                                    <div onClick={onDelete}>
                                        <i className="icon ion-trash-a mr2 primary"/>Delete
                                    </div>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>}
            <ul className={`list-reset m0 ${props.collapsed ? 'hide' : listClass}`} data-empty-message={emptyMessage}>
                {
                    Object.keys(props.collections)
                        .filter(ckey =>
                            (props.appState.activeTab === props.collections[ckey].provider && (!props.filter ||
                                (props.collections[ckey].name.toLowerCase().includes(props.filter) ||
                                flows[props.collections[ckey].flow.toLowerCase()]
                                    .short_label.toLowerCase().includes(props.filter))))
                            && props.order.indexOf(ckey) > -1
                        )
                        .sort((a, b) => {
                            if (props.order.indexOf(a) > props.order.indexOf(b)) {
                                return 1;
                            }
                            if (props.order.indexOf(a) < props.order.indexOf(b)) {
                                return -1;
                            }
                            return 0;
                        })
                        .map((key, index) =>
                            <Draggable key={key} draggableId={key} index={index}>
                                {(provided, snapshot) => (
                                    <div
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        ref={provided.innerRef}>
                                        <HistoryEntry
                                            index={key}
                                            order={index}
                                            onMove={()=> setMoving(key)}
                                            groupId={props.groupId}
                                            appState={props.appState}
                                            changeCollection={props.changeCollection}
                                            updateCollection={props.updateCollection}
                                            deleteCollection={props.handleDelete}
                                            duplicateCollection={props.duplicateCollection}
                                            collections={props.collections}
                                            entry={props.collections[key]}/>
                                    </div>
                                )}
                            </Draggable>
                        )
                }
            </ul>
            <MoveFlowToWorkspaceModal
              flowId={isMoving}
              flow={props.collections[isMoving]}
              addCollectionToGroup={props.addCollectionToGroup}
              updateCollection={props.updateCollection}
              environments={props.environments}
              hideModal={() => setMoving(null)}
            />
            <ConfirmDeleteModal
            show={showConfirmModal}
            title={'Delete group'}
            question={'Are you sure you want to delete this group?'}
            info={'All flows within this group will be deleted as well.'}
            hideConfirmModal={hideConfirmModal}
            delete={onDeleteGroup}
            />
        </>
    )
}

export default CollectionGroup;

/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react'
import {
    DATA_IMPORT_STATUS,
    IMPORT_TYPE,
    IMPORT_OPTION,
    TOAST_NOTIFICATION_TYPE,
    ADD_ENVIRONMENT_DEFAULT_NAME,
    IMPORT_CONFIG_MODAL_SOURCE,
    ERROR_HANDLING_MESSAGES
} from '../../util/appConstants';
import FileDropzone from '../shared/FileDropzone';
import EnvironmentDropdown from '../settings/EnvironmentDropdown';
import Environments from '../../data/Environments';
import Collection from '../../data/Collection';

class ImportAppStateModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            importJsonData: null,
            showHideModal: false,
            importStatus: '',
            importMessage: '',
            fileToUpload:null,
            importOptionType:IMPORT_OPTION.MERGE,
            clearFileInput:false,
            hasImportURL:false,
            errorExists:null,
            errorMessage:'',
            hideAddNewEnvBtn:null
        };

        this.handleFileSelect = this.handleFileSelect.bind(this);
    }

    setImportModalWrapper = (node) => {
        this.importModalRef = node;
    };

    handleClickOutside = (event) => {
        const path = event.path || (event.composedPath && event.composedPath());
        if (this.importModalRef && !this.importModalRef.contains(event.target) && !path.includes(this.importModalRef)) {
            this.props.handleClose();
        }
    };

    closeExportImportModal = () =>{
        this.clearState();
        this.props.handleClose();
    };

    handleFileSelect = (evt) => {
        let files = evt.target.files;
        if (!files.length) {
            this.showFileImportMessage(DATA_IMPORT_STATUS.IMPORT_FAIL, 'No file selected!');
            return;
        }
        if (evt.target.files.length > 0) {
            this.setFile(evt.target.files);
        }
    };

    handleFileSelectDropzone = (files) => {
        this.setFile(files);
    };

    setFile = (files) => {
        // Imp! Immutability: store file by value rather than by ref as `evt.target.files` set value by ref:
        // in order to fire onChange event again with the `SAME FILE` e.g `<input type="file" />` .
        this.setState({ fileToUpload: Object.assign([], files) });
    };

    readFile = () => {
        if (this.isFileExist()) {
            const { fileToUpload } = this.state;
            const file = fileToUpload[0];

            let reader = new FileReader();
            const self = this;
            reader.onload = (event) => {
                self.setFileReadToState(event.target.result, this.props.importType);
            };
            reader.readAsText(file);
            window.location.reload();
        } else {
            this.showFileImportMessage(DATA_IMPORT_STATUS.IMPORT_FAIL, 'Please select file to upload!');
        }
    };

    isFileExist = () => {
        const { fileToUpload } = this.state;
        return fileToUpload ? !!fileToUpload[0] : false;
    };

    setFileReadToState = (content, importType) => {
        let jsonContent;
        try {
            jsonContent = JSON.parse(content);
        } catch (err) {
            console.trace(err);
            this.showFileImportMessage(DATA_IMPORT_STATUS.IMPORT_FAIL, `${err} or Invalid file selected`)
        }

        if (jsonContent) {
            this.setState({
                    importJsonData: jsonContent
                },
                async () => {
                    let isDataImported;
                    if (importType === IMPORT_TYPE.IMPORT_ENVIRONMENTS) {
                        const environmentsImported = await this.importEnvironments();
                        const groupsImported = await this.importGroups();
                        isDataImported = environmentsImported || groupsImported;
                    } else if (importType === IMPORT_TYPE.IMPORT_APP_STATE ||
                        (this.isImportTypeIsOfAppConfig() && this.isModalSourceOfTypeSharedLink())) {
                        const collectionsImported = await this.importCollections();
                        const environmentsImported = await this.importEnvironments();
                        const groupsImported = await this.importGroups();
                        isDataImported = collectionsImported || environmentsImported || groupsImported;
                    } else if (importType === IMPORT_TYPE.IMPORT_CLIENTS ||
                        (this.isImportTypeIsOfClientConfig() && this.isModalSourceOfTypeSharedLink())) {
                        isDataImported = await this.importClients();
                    }
                    if (isDataImported) {
                        if (this.state.importOptionType === IMPORT_OPTION.MERGE) {
                            this.showFileImportMessage(DATA_IMPORT_STATUS.IMPORT_SUCCESS, 'Data merged successfully!')
                        } else {
                            this.showFileImportMessage(DATA_IMPORT_STATUS.IMPORT_SUCCESS, 'Data imported successfully!')
                        }

                        this.closeExportImportModal();
                    } else {
                        this.showFileImportMessage(DATA_IMPORT_STATUS.IMPORT_FAIL, 'Unable to load data from selected file.')
                    }

                });
        }

    };

    showFileImportMessage = (status, message) => {
        this.setState({
            importStatus: status,
            importMessage: message
        })
    };

    clearState = () => {
            this.setState({
                importStatus: '',
                importMessage: '',
                fileToUpload: null,
                clearFileInput: true,
                errorExists:false,
                errorMessage:''
            })
    };

    importCollections = async () => {
        if (Object.prototype.hasOwnProperty.call(this.state.importJsonData, 'collections')) {
            const collections = this.state.importJsonData.collections;
            let response = await this.props.importCollectionsAction(collections, this.state.importOptionType);
            return !!response;
        }
        return false;
    };

    importEnvironments = async () => {
        if (Object.prototype.hasOwnProperty.call(this.state.importJsonData, 'environments')) {
            const environments = this.state.importJsonData.environments;
            let response = await this.props.importEnvironmentsActions(environments, this.state.importOptionType);
            return !!response;
        }
        return false;
    };

    importGroups = async () => {
        if (Object.prototype.hasOwnProperty.call(this.state.importJsonData, 'groups')) {
            const groups = this.state.importJsonData.groups;
            let response = await this.props.importGroupsActions(groups, this.state.importOptionType);
            return !!response;
        }
        return false;
    };

    importClients = async () => {
        if (this.state.importJsonData && Object.keys(this.state.importJsonData).length > 0 ) {
            const clients = this.state.importJsonData;
            let response = await this.props.importEnvironmentsClientsActions(clients, this.state.importOptionType);
            return !!response;
        }
        return false;
    };

    importModalHeaderDynamicInfo = () => {
        let modalTitle;
        let modalDescription;

        if (this.props.importType === IMPORT_TYPE.IMPORT_APP_STATE) {
            modalTitle = 'Import Application State';
            modalDescription ='Import an existing configuration, including environments, collections, and other settings.';
        } else if (this.props.importType === IMPORT_TYPE.IMPORT_ENVIRONMENTS) {
            modalTitle = 'Import Workspaces';
            modalDescription ='Import existing workspaces into OAuth Tools.';
        } else if (this.props.importType === IMPORT_TYPE.IMPORT_CLIENTS) {
            modalTitle = 'Import Clients';
            modalDescription ='Import existing clients into OAuth Tools.';
        }
        return {
            modalTitle,
            modalDescription
        }
    };


    uploadShareConfig = () => {
        if (this.isConfigExists()) {
            this.setFileReadToState(this.props.shareConfigPermalinkModal.config, this.props.importType);
        }
    };

    loadSharedConfig = ($event) => {
        let url = this.sharedConfigTextInput.value;
        const button = $event.currentTarget;
        button.classList.add('button-loading-active');
        const self = this;
        this.props.importShareableConfig({ url })
            .then((decryptedTextResponse) => {
                if (decryptedTextResponse.success) {
                    self.setFileReadToState(decryptedTextResponse.data,this.props.importType);
                } else {
                    this.handleError(decryptedTextResponse);
                }
            },(error) =>{
                // const message = error.error || ERROR_HANDLING_MESSAGES.SHARED_URL_CORRUPTED;
               this.handleError(error);
            })
            .finally(()=>{
                button.classList.remove('button-loading-active');
                this.sharedConfigTextInput.value = null;
                window.location.reload();
            });
    };

    handleError = (error) =>{
        const message = error.error || ERROR_HANDLING_MESSAGES.SHARED_URL_CORRUPTED;
        this.props.showToastNotification(
            TOAST_NOTIFICATION_TYPE.TOAST_ERROR,
            message,
            true
        );

        this.setState({
            ...this.state,
            errorExists:true,
            errorMessage:message
        });
    };

    setSharedConfigTextInputRef = (node) => {
        this.sharedConfigTextInput = node;
    };

    isModalSourceOfTypeSharedLink = () => {
        return this.props.modalSource === IMPORT_CONFIG_MODAL_SOURCE.SHARED_LINK;
    };
    isModalSourceOfTypeButton = () => {
        return this.props.modalSource === IMPORT_CONFIG_MODAL_SOURCE.BUTTON;
    };

    isImportTypeIsOfAppConfig = () => {
        return this.props.importType === IMPORT_TYPE.IMPORT_APP_STATE;
    };

    isImportTypeIsOfClientConfig = () => {
        return this.props.importType === IMPORT_TYPE.IMPORT_CLIENTS;
    };

    isIssuerExists = () => {
        return this.props.shareConfigPermalinkModal.isIssuerExists;
    };

    isConfigExists = () => {
        if (this.props.shareConfigPermalinkModal.config){
            return Object.keys(JSON.parse(this.props.shareConfigPermalinkModal.config)).length>0 &&
                typeof JSON.parse(this.props.shareConfigPermalinkModal.config) === 'object';
        }
    };

    onURLInputChange = (event) => {
        const url = event.currentTarget.value;
        this.setState({
            hasImportURL: !!(url.length && url.startsWith('http'))
        })
    };

    renderSelectedEnvironmentDropDown = () =>{
        let currentCollection = null;
        let environments = null;
        if (Object.keys(this.props.collections).length > 0){
            currentCollection = Collection.getCollection(this.props.appState, this.props.collections);
            environments = Environments.create(this.props.environments);
        }

        return <>

            {
                !this.state.hideAddNewEnvBtn ?
                    <div className="flex justify-start">
                        <p>Select workspace to import the client(s):</p>
                        <EnvironmentDropdown collection={currentCollection}
                                             selectProviderForCollection={this.props.selectProviderForCollection}
                                             changeSelectedEnvironmentWrapper={
                                                this.props.changeSelectedEnvironmentWrapper}
                                             showTooltip={true}
                                             environments={environments}/>


                        <div className="inline-block ml1">
                            <button onClick={this.createAndImportClientInNewEnvironment}
                                    className="button button-tiny button-light">
                                <i className="icon ion-android-add mr1"/>
                                Add to New Workspace
                            </button>
                        </div>
                    </div>

                    : null
            }

        </>
    };

    renderStrategyBtns = () => {
        const strategyBtns = (
            <>
                <div className={'flex-50 flex mt2'}>
                    <div className="radio-btn-group flex">
                        <div
                            data-tooltip-long
                            data-tooltip=" Leave existing configuration that isn’t in the import file and merge the existing configuration with the impotred data"
                        >
                            <div
                                className={`radio-style-button  ${this.state.importOptionType === IMPORT_OPTION.MERGE ? 'radio-style-button-checked' : ''}`}
                                onClick={() => this.setState({ importOptionType: IMPORT_OPTION.MERGE })}
                            >Merge
                            </div>
                        </div>

                        <div
                            data-tooltip-long
                            data-tooltip="Treat any existing config that is missing from the import file as something to be deleted"
                        >
                            <div
                                className={`radio-style-button  ${this.state.importOptionType === IMPORT_OPTION.REPLACE ? 'radio-style-button-checked' : ''}`}
                                onClick={() => this.setState({ importOptionType: IMPORT_OPTION.REPLACE })}
                            >Replace
                            </div>
                        </div>

                        <div
                            data-tooltip-long
                            data-tooltip-long-left
                            data-tooltip="Overwriting any existing config with the config in the import file"
                        >
                            <div
                                className={`radio-style-button  ${this.state.importOptionType === IMPORT_OPTION.OVERRIDE ? 'radio-style-button-checked' : ''}`}
                                onClick={() => this.setState({ importOptionType: IMPORT_OPTION.OVERRIDE })}
                            >Override
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
        return (
            <div className={'flex justify-between'}>
                {
                    !this.isImportTypeIsOfClientConfig() ? strategyBtns : null
                }

                <div className={'flex flex-50 mt2 '+ (this.isImportTypeIsOfClientConfig() ? 'justify-start' : 'justify-end')}>
                    <button className="mr2 block button button-small button-link"
                            onClick={this.closeExportImportModal}>Cancel
                    </button>
                    {
                        this.isModalSourceOfTypeSharedLink() ?
                            <button className={'button button-small button-success '}
                                    disabled={!this.isConfigExists()}
                                    onClick={this.uploadShareConfig}>Import Config
                            </button>
                            :
                            <button className={'button button-small button-success '}
                                    onClick={this.readFile}
                                    disabled={!this.isFileExist()}>Upload
                            </button>
                    }
                </div>
            </div>
        )
    };

    createAndImportClientInNewEnvironment = async () => {
        const getEnvironmentId = await this.addNewEnvironment();
        const selectedEnvironment = await this.props.selectEnvironment(getEnvironmentId);
        const isUpdated = await this.updateEnvironment(selectedEnvironment, {
            issuer: this.props.shareConfigPermalinkModal.issuerName
        });

        if (isUpdated) {
            // HIDE ADD NEW ENVIRONMENT BUTTON:
            this.setState({
                ...this.state,
                hideAddNewEnvBtn: true
            });
        }
    };

    addNewEnvironment = async () => {
        return await this.props.createNewEnvironment(ADD_ENVIRONMENT_DEFAULT_NAME);
    };

    selectEnvironment = async (environmentId) => {
        return await this.props.selectEnvironment(environmentId);
    };

    updateEnvironment = async (environment, keysToUpdate) => {
        if (keysToUpdate.issuer) {
            const updatedEnvironment = environment.withUpdatedValue('issuer', keysToUpdate.issuer);
            return await this.props.updateEnvironmentWrapper(updatedEnvironment);
        }
        return await this.props.updateEnvironmentWrapper(environment);
    };

    render() {
        const { modalTitle, modalDescription } = this.importModalHeaderDynamicInfo();

        const showHideClassName = this.props.showImportModal
            ? 'modal modal-import modal-is-visible'
            : 'modal modal-import modal-is-hidden';

        if (this.props.showImportModal) {
            document.addEventListener('mousedown', this.handleClickOutside);
        } else {
            document.removeEventListener('mousedown', this.handleClickOutside);
        }

        const { fileToUpload } = this.state;
        let file = this.isFileExist() ? fileToUpload[0] : null;

        file = file
            ? ( <span className="mt1 block">{file.name}</span>)
            : ( <span className="mt1 block">No file selected</span>);

        // const { modalSource } = this.props.shareConfigPermalinkModal;

        return (
            /*****Import AppState Modal*****/
            <div className={showHideClassName}>
                <section className="modal-main import-modal" ref={this.setImportModalWrapper}>
                    <header className="modal-main-header">
                        <div className="flex flex-center justify-between">
                            <h3 className="h4 m0">{modalTitle}</h3>
                            <div className="flex flex-center justify-between">
                                <button className="button button-close button-link ml2"
                                        onClick={this.closeExportImportModal}><i className="icon ion-close"/></button>
                            </div>
                        </div>
                    </header>
                    <div className="modal-main-content">
                        <section
                            className={'modal-panel-section ' + (this.state.importStatus === DATA_IMPORT_STATUS.IMPORT_SUCCESS ? '' : 'visually-hidden')}>
                            <div className="alert alert-success">
                                <p className="m0">
                                    <i className="icon ion-checkmark-circled inlineicon"/>
                                    {this.state.importMessage}
                                </p>
                            </div>
                        </section>
                        <section
                            className={'modal-panel-section ' + (this.state.importStatus === DATA_IMPORT_STATUS.IMPORT_FAIL ? '' : 'visually-hidden')}>
                            <div className="alert alert-danger flex justify-between">
                                <p className="m0">
                                    {this.state.importMessage}
                                </p>
                                <button className="alert-close" onClick={this.clearState} aria-label="Close"><i className="icon ion-close"/></button>
                            </div>
                        </section>

                        {
                            this.state.errorExists &&
                                <section
                                    className={'modal-panel-section'}>
                                    <div className="alert alert-danger flex justify-between">
                                        <p className="m0">
                                            {this.state.errorMessage}
                                        </p>
                                        <button className="alert-close" onClick={this.clearState} aria-label="Close"><i className="icon ion-close"/></button>
                                    </div>
                                </section>
                        }

                        <div className="flex justify-between">
                            <p className="mt0">{modalDescription}</p>
                        </div>


                        {/* ENVIRONMENT DROPDOWN SECTION*/}
                        {
                              ((this.isModalSourceOfTypeSharedLink() &&
                                this.isImportTypeIsOfClientConfig()) &&
                                !this.isIssuerExists())
                                ?
                                  this.renderSelectedEnvironmentDropDown()
                                :
                                  null
                        }

                        {/* IMPORT FROM FILE OPTION SECTION:*/}
                        {
                            this.isModalSourceOfTypeButton()
                                ?
                                <details open id="importfromfile">

                                    <summary role="button" aria-controls="importfromfile">
                                        Import from file
                                    </summary>

                                    <FileDropzone
                                        multiple={false}
                                        onChange={this.handleFileSelectDropzone}
                                        handleFileSelect={this.handleFileSelect}
                                        fileRef={file}
                                        clearFileInput={this.state.clearFileInput}
                                    />

                                </details> : null

                        }

                        {this.renderStrategyBtns()}


                        {/*PASTE URL OPTION*/}
                        {
                            this.isModalSourceOfTypeButton()&&
                                <div className="flex justify-between flex-column mt3">

                                    <details className="mt2" id="introspectionsettings">
                                        <summary role="button" aria-controls="introspectionsettings">
                                            Import from URL
                                        </summary>

                                        <div className="flex mt1">
                                            <input
                                                className="field col-12"
                                                placeholder="Example: https://oauth.tools/c/XXXX"
                                                name="sharedConfigLink"
                                                type="text"
                                                autoComplete="off"
                                                data-lpignore="true"
                                                spellCheck="false"
                                                onChange={this.onURLInputChange}
                                                ref={this.setSharedConfigTextInputRef}
                                            />

                                            <button
                                                className={`button button-small button-input button-loading ml1 ${this.state.hasImportURL ? 'button-success' : 'button-light'}`}
                                                title="Load config"
                                                disabled={!this.state.hasImportURL}
                                                onClick={this.loadSharedConfig}>
                                                <span>
                                                    Load config
                                                </span>
                                            </button>
                                        </div>
                                    </details>
                                </div>

                        }

                    </div>
                </section>
            </div>
        )
    }
}

export default ImportAppStateModal

/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React, { useEffect, useRef, useState } from 'react';
import AceEditor from 'react-ace';

import {
    decodeUrlParameter,
    figureTypeFromValue,
    generateKeyPair,
    notEmpty,
    typeForKnownClaims
} from '../../util/util';
import { flows, guides } from '../../util/appConstants';
import CopyToClipboard from '../CopyToClipboard'
import FlowHeader from './FlowHeader';
import JWTTokenOutput from '../JWTTokenOutput';
import MiddlePaneHeader from './MiddlePaneHeader';
import Environments from '../../data/Environments';
import TokenValidationSignature from '../validation/TokenValidationSignature';
import JwtTokenValidation from '../validation/JwtTokenValidation';
import AlgorithmSelector from './settings/AlgorithmSelector';
import JwtKeyValueTypeSelector from './settings/JwtKeyValueTypeSelector';
import Guide from '../guides/Guide';
import { isEmpty } from '../../util/validationUtils';
import ResizablePanels from '../ResizablePanels';
import TokenPurposeSelector from '../token/TokenPurposeSelector';
import { TOKEN_PURPOSE_MAPPING } from '../../util/data/tokenPurposeMapping';

const CreateJWTFlow = (props) => {
    const headerEditorRef = useRef();
    const bodyEditorRef = useRef();
    const [guideIsVisible, setGuideIsVisible] = useState(false)
    const [intermediateHeaderValue, setIntermediateHeaderValue] = useState(null)
    const [intermediateBodyValue, setIntermediateBodyValue] = useState(null)
    const [headerError, setHeaderError] = useState(null)
    const [bodyError, setBodyError] = useState(null)

    const toggleGuideVisible = (isVisible) => {
        setGuideIsVisible(isVisible)
    }

    const clearErrorFromCollection = () => {
        props.setErrorOnCollection(props.collection.id, null)
    };

    const generateKeyPairForJwt = async () => {
        let parameters = props.collection.parameters;

        const alg = parameters.create_jwt_algorithm;

        const [publicKey, privateKey] = await generateKeyPair(alg);

        const collection = props.collection;
        const tokenIds = collection.getTokenIds();

        // In this mode we assume there is only one token
        const tokenId = tokenIds[0];

        props.updateKeyForToken({ jwk: publicKey }, tokenId, props.collection);
        props.updatePrivateKeyForToken({ jwk: privateKey }, tokenId, props.collection);
    };

    const generateJwt = () => {
        const collection = props.collection;
        props.generateJwtForCollection(collection)
    };

    const updateHeaderMode = (event) => {
        const updatedParameters = props.collection.parameters.withUpdatedValue('create_jwt_json_header_mode', event.currentTarget.checked);
        props.updateParameters(props.collection.id, updatedParameters);
    };

    const updateBodyMode = (event) => {
        const updatedParameters = props.collection.parameters.withUpdatedValue('create_jwt_json_body_mode', event.currentTarget.checked);
        props.updateParameters(props.collection.id, updatedParameters);
    };

    const handlePastedHeaderJson = (newValue) => {
        setIntermediateHeaderValue(newValue)
    };

    const handlePastedBodyJson = (newValue) => {
        setIntermediateBodyValue(newValue)
    };

    const handleHeaderSaveJson = () => {
        if (headerEditorRef.current.editor) {
            const newValue = headerEditorRef.current.editor.getValue();
            try {
                const newValueJson = JSON.parse(newValue);
                delete newValueJson.alg;
                const newValueArray = [];

                Object.entries(newValueJson).forEach(([key, value]) => {
                    const type = figureTypeFromValue(value);
                    newValueArray.push({ name: key, value, type, id: Math.random() })
                });
                newValueArray.push({ name: '', value: '', type: 'text', id: Math.random() })

                const updatedParameters = props.collection.parameters.withUpdatedValue('create_jwt_header', newValueArray);
                props.updateParameters(props.collection.id, updatedParameters);

                setIntermediateHeaderValue(null)
                setHeaderError(null)
            } catch (e) {
                setIntermediateHeaderValue(newValue)
                setHeaderError('Could not parse JSON')
                console.error(e);
            }
        }
    };

    const handleBodySaveJson = () => {
        if (bodyEditorRef.current.editor) {
            const newValue = bodyEditorRef.current.editor.getValue();
            try {
                const newValueJson = JSON.parse(newValue);
                const newValueArray = [];

                Object.entries(newValueJson).forEach(([key, value]) => {
                    const type = figureTypeFromValue(value);
                    newValueArray.push({ name: key, value, type, id: Math.random() })
                });
                newValueArray.push({ name: '', value: '', type: 'text', id: Math.random() })

                const updatedParameters = props.collection.parameters.withUpdatedValue('create_jwt_body', newValueArray);
                props.updateParameters(props.collection.id, updatedParameters);

                setIntermediateBodyValue(null)
                setBodyError(null)
            } catch (e) {
                setIntermediateBodyValue(newValue)
                setBodyError('Could not parse JSON')
            }
        }
    };

    const discardHeaderJsonChanges = () => {
        setIntermediateHeaderValue(null);
    };

    const discardBodyJsonChanges = () => {
        setIntermediateBodyValue(null);
    };

    const updateSecret = (event) => {
        const collection = props.collection;
        const tokenIds = collection.getTokenIds();

        // In this mode we assume there is only one token
        const tokenId = tokenIds[0];

        props.updateKeyForToken({ jwk: event.currentTarget.value }, tokenId, props.collection);
        props.updatePrivateKeyForToken({ jwk: event.currentTarget.value }, tokenId, props.collection);
    };

    const emptyBodyParameterMissing = () => {
        let parameters = props.collection.parameters.create_jwt_body;
        const parameterExists = parameters.find((param) => param.name === '' && param.value === '');
        return parameterExists === undefined;
    }

    const addEmptyFormBodyParam = () => {
        if (emptyBodyParameterMissing()) {
            let parameters = props.collection.parameters.create_jwt_body;
            parameters.push({ name: '', value: '', type: 'text', id: Math.random() });
            const updatedParameters = props.collection.parameters.withUpdatedValue('create_jwt_body', parameters);
            props.updateParameters(props.collection.id, updatedParameters);
        }
    };

    const onUpdateBodyParameter = (value, i, field) => {
        let parameters = Array.from(props.collection.parameters.create_jwt_body);
        parameters[i][field] = value;
        const updatedParameters = props.collection.parameters.withUpdatedValue('create_jwt_body', parameters);
        props.updateParameters(props.collection.id, updatedParameters);
        addEmptyFormBodyParam();
    };

    const onDeleteBodyParameter = (event, i) => {
        let body = [...props.collection.parameters.create_jwt_body];
        body.splice(i, 1);
        if (body.length === 0) {
            body.push({ name: '', value: '', type: 'text', id: Math.random() });
        }
        const updatedParameters = props.collection.parameters.withUpdatedValue('create_jwt_body', body);
        props.updateParameters(props.collection.id, updatedParameters);
    };

    const addEmptyFormHeaderParam = () => {
        let parameters = props.collection.parameters.create_jwt_header;
        const parameterExists = parameters.find((param) => param.name === '' && param.value === '');
        if (parameterExists === undefined) {
            parameters.push({ name: '', value: '', type: 'text', id: Math.random() });
            const updatedParameters = props.collection.parameters.withUpdatedValue(
                'create_jwt_header',
                parameters
            );
            props.updateParameters(props.collection.id, updatedParameters);
        }
    };

    const onUpdateHeaderParameter = (value, i, field) => {
        let parameters = Array.from(props.collection.parameters.create_jwt_header);
        parameters[i][field] = value;
        const updatedParameters = props.collection.parameters.withUpdatedValue('create_jwt_header', parameters);
        props.updateParameters(props.collection.id, updatedParameters);
        addEmptyFormHeaderParam();
    };

    const onDeleteHeaderParameter = (event, i) => {
        let body = [...props.collection.parameters.create_jwt_header];
        body.splice(i, 1);
        if (body.length === 0) {
            body.push({ name: '', value: '', type: 'text', id: Math.random() });
        }
        const updatedParameters = props.collection.parameters.withUpdatedValue('create_jwt_header', body);
        props.updateParameters(props.collection.id, updatedParameters);
    };

    const saveAlgorithm = (alg) => {
        const updatedParameters = props.collection.parameters
            .withUpdatedValue('create_jwt_algorithm', alg)
        props.updateParameters(props.collection.id, updatedParameters);
        const tokenId = tokenIds[0];
        props.updateKeyForToken({ jwk: '' }, tokenId, props.collection);
        props.updatePrivateKeyForToken({ jwk: '' }, tokenId, props.collection);

    };

    const onUpdateTokenPurpose = (selectedPurpose) => {
        const claims_to_add = Array.prototype.concat(TOKEN_PURPOSE_MAPPING[selectedPurpose].required_claims,
            TOKEN_PURPOSE_MAPPING[selectedPurpose].recommended_claims,
            TOKEN_PURPOSE_MAPPING[selectedPurpose].optional_claims)

        const claims = props.collection.parameters.create_jwt_body;
        const existingClaims = []
        claims.forEach(existing => existingClaims.push(existing.name));
        claims_to_add.forEach(claim => {
            if (existingClaims.indexOf(claim) < 0) {
                claims.unshift({ name: claim, type: typeForKnownClaims(claim), id: Math.random() })
            }
        })
        if (emptyBodyParameterMissing()) {
            claims.push({ name: '', value: '', type: 'text', id: Math.random() })
        }

        const updatedParameters = props.collection.parameters
            .withUpdatedValue('create_jwt_token_purpose', selectedPurpose)
            .withUpdatedValue('create_jwt_body', claims);
        props.updateParameters(props.collection.id, updatedParameters);
    }

    const clearParseError = () => {
        setBodyError(null)
        setHeaderError(null)
    };

    const collection = props.collection;
    const tokenIds = collection.getTokenIds();

    // In this mode we assume there is only one token
    const tokenId = tokenIds[0];
    const selectedToken = collection.getTokenById(tokenId);
    const environments = Environments.create(props.environments);

    const environment = environments.getEnvironment(collection.provider);

    const currentCollection = props.collection;

    const error = !currentCollection.error ? '' :
        <div className="alert alert-danger mb2">
            <i className="icon ion-ios-close-outline inlineicon"/>
            {decodeUrlParameter(currentCollection.error)}
            <button className="alert-close" onClick={clearErrorFromCollection}>
                <i className="icon ion-close"/>
            </button>
        </div>;

    const headerParseError = (!headerError) ? '' :
        <div className="alert alert-danger mb2">
            <i className="icon ion-ios-close-outline inlineicon"/>
            {headerError}
            <button className="alert-close" onClick={clearParseError}><i className="icon ion-close"/>
            </button>
        </div>;

    const bodyParseError = (!bodyError) ? '' :
        <div className="alert alert-danger mb2">
            <i className="icon ion-ios-close-outline inlineicon"/>
            {bodyError}
            <button className="alert-close" onClick={clearParseError}><i className="icon ion-close"/>
            </button>
        </div>;

    const headerCommand = {
        bindKey: { win: 'Ctrl-S', mac: 'Command-S' },
        exec: handleHeaderSaveJson,
        name: 'Save'
    };

    const bodyCommand = {
        bindKey: { win: 'Ctrl-S', mac: 'Command-S' },
        exec: handleBodySaveJson,
        name: 'Save'
    };

    const create_jwt_header = currentCollection.parameters.create_jwt_header;
    const create_jwt_body = currentCollection.parameters.create_jwt_body;

    const body = {}, header = {};
    create_jwt_header.map((parameter, i) => {
        if (notEmpty(parameter.name)) {
            if (parameter.type === 'date' || parameter.type === 'int') {
                header[parameter.name] = Number(parameter.value)
            } else {
                header[parameter.name] = parameter.value
            }
        }
    });
    header.alg = collection.parameters.create_jwt_algorithm;
    const create_jwt_json_header = intermediateHeaderValue !== null ?
        intermediateHeaderValue : JSON.stringify(header, undefined, 2);


    create_jwt_body.map((parameter, i) => {
        if (notEmpty(parameter.name)) {
            if (parameter.type === 'date' || parameter.type === 'int') {
                body[parameter.name] = Number(parameter.value)
            } else {
                body[parameter.name] = parameter.value
            }
        }
    });
    const create_jwt_json_body = intermediateBodyValue !== null ? intermediateBodyValue :
        JSON.stringify(body, undefined, 2);

    const selectedAlgorithm = props.collection.parameters.create_jwt_algorithm;
    const isSymmetricKey = selectedAlgorithm === 'HS256' || selectedAlgorithm === 'HS384' || selectedAlgorithm === 'HS512';
    const isNoneAlgorithm = selectedAlgorithm === 'none';

    const symmetricKey = typeof selectedToken.private_key.jwk === 'string' ? selectedToken.private_key.jwk : '';


    const jsonHeaderMode = !!props.collection.parameters.create_jwt_json_header_mode;
    const jsonBodyMode = !!props.collection.parameters.create_jwt_json_body_mode;

    const jwtBody = props.collection.parameters.create_jwt_body
    const jwtHeader = props.collection.parameters.create_jwt_header

    useEffect(() => {
        const disableRunButton = ((isSymmetricKey && isEmpty(symmetricKey)) ||
            (!isSymmetricKey && isEmpty(selectedToken.private_key.pem))) && !isNoneAlgorithm;
        if (!disableRunButton) {
            generateJwt()
        }
    }, [jwtBody, jwtHeader, selectedToken.private_key])

    return (
        <React.Fragment>
            <ResizablePanels {...props}>
                <section className="tools-form tools-form-create-jwt">
                    <MiddlePaneHeader
                        collection={props.collection}
                        exportCurrentCollection={props.exportCurrentCollection}/>

                    <div className="tools-form-content">
                        <FlowHeader
                            name={currentCollection.name}
                            description={'Edit header, body and signature to create a JWT Token'}
                        />
                        {error}

                        <fieldset data-token-purpose-selector="true" className="mt2"><p>Select the purpose of the JWT</p>
                            <div className="field-group">
                                <TokenPurposeSelector
                                    purpose={props.collection.parameters.create_jwt_token_purpose}
                                    handleChange={onUpdateTokenPurpose}
                                />
                            </div>
                        </fieldset>


                        <fieldset className="fieldset fieldset-step mt2" data-decoded>
                            <div className="flex flex-center justify-between">
                                <label className="label block">Header</label>
                                <div className="custom-checkbox">
                                    <label className="mr1 label-key label-key-switch" htmlFor="keyval_or_json">
                                        Key/value
                                    </label>
                                    <label className="toggle-switch">
                                        <input
                                            className="form-control custom-checkbox"
                                            type="checkbox"
                                            id="keyval_or_json"
                                            name="keyval_or_json"
                                            onChange={updateHeaderMode}
                                            checked={jsonHeaderMode}
                                        />
                                        <div className="toggle-slider toggle-slider-switch round"/>
                                    </label>
                                    <label className="ml1 label-key label-key-switch" htmlFor="keyval_or_json">
                                        JSON
                                    </label>
                                </div>
                            </div>

                            {/* <!-- HEADER --> */}
                            <details className="mt2" open={true} id="header">
                                <summary aria-controls="header" className="mb2">
                                    Header
                                </summary>
                                <div>
                                    {!jsonHeaderMode && (
                                        <div className="flex justify-between mb1">
                                            <div className="flex-auto pr1">
                                                <input
                                                    className="field col-12"
                                                    type="text"
                                                    placeholder="Key"
                                                    defaultValue="alg"
                                                    disabled={true}
                                                />
                                            </div>
                                            <div className="flex-auto pr1">
                                                <input
                                                    className="field col-12"
                                                    type="text"
                                                    placeholder="Key"
                                                    value={selectedAlgorithm}
                                                    disabled={true}
                                                />
                                            </div>
                                            <div>
                                                <button
                                                    className="button button-light button-small button-input" disabled>
                                                    <i className="icon ion-trash-a h4"/>
                                                </button>
                                            </div>
                                        </div>
                                    )}

                                    {!jsonHeaderMode ? (
                                        create_jwt_header.map((parameter, i) => (
                                            <div className="flex justify-between mb1" key={parameter.id}>
                                                <div className="flex-auto pr1">
                                                    <input
                                                        className="field col-12"
                                                        type="text"
                                                        placeholder="Key"
                                                        onFocus={addEmptyFormHeaderParam}
                                                        onChange={(e) =>
                                                            onUpdateHeaderParameter(
                                                                e.currentTarget.value,
                                                                i,
                                                                'name'
                                                            )
                                                        }
                                                        defaultValue={parameter.name}
                                                    />
                                                </div>
                                                <JwtKeyValueTypeSelector
                                                    pairPosition={i}
                                                    value={parameter.value}
                                                    type={parameter.type}
                                                    hideDate={true}
                                                    hideNonce={true}
                                                    updateBodyParamValue={onUpdateHeaderParameter}
                                                    selected="text"/>
                                                <div className="">
                                                    <button
                                                        className="button button-light button-small button-input"
                                                        onClick={(e) => onDeleteHeaderParameter(e, i)}>
                                                        <i className="icon ion-trash-a h4"/>
                                                    </button>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <>
                                            {headerParseError}
                                            <div className="tools-form-token tools-form-token-active">
                                                <header className="tools-box-header tools-box-header-token">
                                                    <div className="flex flex-center flex-justify">
                                                        <div>
                                                            <label className="white">JSON Editor</label>
                                                            {intermediateHeaderValue &&
                                                                <>
                                                                    <button
                                                                        className="button button-primary-dark button-tiny ml2 mr1"
                                                                        onClick={discardHeaderJsonChanges}>Discard
                                                                    </button>
                                                                    <button
                                                                        className="button button-success button-tiny"
                                                                        onClick={handleHeaderSaveJson}>Save
                                                                    </button>
                                                                    <small><span className="inline-block ml1 primary">(cmd/ctrl + s)</span></small>
                                                                </>
                                                            }
                                                        </div>
                                                        <CopyToClipboard text={create_jwt_json_header}
                                                                         className="mr1"/>
                                                    </div>
                                                </header>

                                                <div className="ace-editor-wrapper">
                                                    <AceEditor
                                                        ref={headerEditorRef}
                                                        name="claims-editor"
                                                        placeholder="Paste JSON"
                                                        mode="json"
                                                        theme="monokai"
                                                        commands={[headerCommand]}
                                                        setOptions={{
                                                            enableBasicAutocompletion: false,
                                                            enableLiveAutocompletion: false,
                                                            enableSnippets: false,
                                                            showLineNumbers: true,
                                                            showGutter: true,
                                                            tabSize: 2
                                                        }}
                                                        indentedSoftWrap={false}
                                                        showPrintMargin={false}
                                                        onChange={handlePastedHeaderJson}
                                                        value={create_jwt_json_header}
                                                        width="100%"
                                                        maxLines={40}
                                                        minLines={10}
                                                        fontSize={12}
                                                        wrapEnabled={true}
                                                        editorProps={{ $blockScrolling: true }}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>

                            </details>
                            <div className="fieldset-connector"/>
                        </fieldset>

                        <fieldset className="fieldset-step mt2" data-decoded>
                            {/* <!-- BODY --> */}

                            <div className="flex flex-center justify-between">
                                <label className="label block">Body</label>
                                <div className="custom-checkbox">
                                    <label className="mr1 label-key label-key-switch" htmlFor="keyval_or_json">
                                        Key/value
                                    </label>
                                    <label className="toggle-switch">
                                        <input
                                            className="form-control custom-checkbox"
                                            type="checkbox"
                                            id="keyval_or_json"
                                            name="keyval_or_json"
                                            onChange={updateBodyMode}
                                            checked={jsonBodyMode}
                                        />
                                        <div className="toggle-slider toggle-slider-switch round"/>
                                    </label>
                                    <label className="ml1 label-key label-key-switch" htmlFor="keyval_or_json">
                                        JSON
                                    </label>
                                </div>
                            </div>

                            <details className="mt2" open={true} id="body">
                                <summary aria-controls="body" className="mb2">
                                    Body
                                </summary>
                                {!jsonBodyMode ? (
                                    create_jwt_body.map((parameter, i) => (
                                        <div className="flex justify-between mb1" key={parameter.id}>
                                            <div className="flex-20 pr1">
                                                <input
                                                    className="field col-12"
                                                    type="text"
                                                    placeholder="Key"
                                                    onFocus={addEmptyFormBodyParam}
                                                    onChange={(e) =>
                                                        onUpdateBodyParameter(e.currentTarget.value, i, 'name')
                                                    }
                                                    defaultValue={parameter.name}
                                                />
                                            </div>
                                            <JwtKeyValueTypeSelector
                                                pairPosition={i}
                                                value={parameter.value}
                                                type={parameter.type}
                                                updateBodyParamValue={onUpdateBodyParameter}
                                                selected="text"/>
                                            <div>
                                                <button
                                                    className="button button-light button-small button-input"
                                                    onClick={(e) => onDeleteBodyParameter(e, i)}>
                                                    <i className="icon ion-trash-a h4"/>
                                                </button>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <>{bodyParseError}
                                        <div className="tools-form-token tools-form-token-active">
                                            <header className="tools-box-header tools-box-header-token">
                                                <div className="flex flex-center flex-justify">
                                                    <div>
                                                        <label className="white">JSON Editor</label>
                                                        {intermediateBodyValue &&
                                                            <>
                                                                <button
                                                                    className="button button-primary-dark button-tiny ml2 mr1"
                                                                    onClick={discardBodyJsonChanges}>Discard
                                                                </button>
                                                                <button className="button button-success button-tiny"
                                                                        onClick={handleBodySaveJson}>Save
                                                                </button>
                                                                <small><span
                                                                    className="inline-block ml1 primary">(cmd/ctrl + s)</span></small>
                                                            </>
                                                        }
                                                    </div>
                                                    <CopyToClipboard text={create_jwt_json_body}
                                                                     className="mr1"/>
                                                </div>
                                            </header>

                                            <div className="ace-editor-wrapper">
                                                <AceEditor
                                                    ref={bodyEditorRef}
                                                    name="claims-editor"
                                                    placeholder="Paste JSON"
                                                    mode="json"
                                                    theme="monokai"
                                                    commands={[bodyCommand]}
                                                    setOptions={{
                                                        enableBasicAutocompletion: false,
                                                        enableLiveAutocompletion: false,
                                                        enableSnippets: false,
                                                        showLineNumbers: true,
                                                        showGutter: true,
                                                        tabSize: 2
                                                    }}
                                                    indentedSoftWrap={false}
                                                    showPrintMargin={false}
                                                    onChange={handlePastedBodyJson}
                                                    value={create_jwt_json_body}
                                                    width="100%"
                                                    maxLines={40}
                                                    minLines={10}
                                                    fontSize={12}
                                                    wrapEnabled={true}
                                                    editorProps={{ $blockScrolling: true }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                            </details>
                            <div className="fieldset-connector"/>
                        </fieldset>

                        <fieldset className="fieldset-step mt2" data-signature>
                            <div className="flex flex-center justify-between"><label
                                className="label block">Signature</label></div>

                            {/* <!-- ALGORITHM --> */}
                            <AlgorithmSelector saveAlgorithm={saveAlgorithm}
                                               selectedAlgorithm={selectedAlgorithm}/>

                            {!isNoneAlgorithm && <>
                                {isSymmetricKey ?
                                    <>
                                        <div className={'flex flex-auto'}>
                                            <input type={'text'}
                                                   value={symmetricKey}
                                                   className={'field col-12'}
                                                   onChange={updateSecret}/>
                                            <div className={'pl1'}>
                                                <button
                                                    className="button button-small button-primary-outline button-input"
                                                    onClick={generateKeyPairForJwt}>Generate key
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <TokenValidationSignature
                                            label="Public key"
                                            token={selectedToken}
                                            collection={collection}
                                            environment={environment}
                                            hideHeader={true}
                                            generateKeyPairForSigning={generateKeyPairForJwt}
                                            showGenerateButton={true}
                                            hideEnvironment={true}
                                            updateKeyForToken={props.updateKeyForToken}
                                        />
                                        <div className="mt2"/>
                                        <TokenValidationSignature
                                            label="Private key"
                                            token={selectedToken}
                                            hideHeader={true}
                                            privateKey={true}
                                            collection={collection}
                                            environment={environment}
                                            showGenerateButton={false}
                                            hideEnvironment={true}
                                            updateKeyForToken={props.updatePrivateKeyForToken}
                                        />
                                    </>
                                }
                            </>}
                            <div className="fieldset-connector"/>
                        </fieldset>

                    </div>
                </section>

                <aside className="tools-sidebar">
                    <Guide area={guides.create_jwt} toggle={toggleGuideVisible}/>

                    {!guideIsVisible && <>
                        <JWTTokenOutput token={selectedToken}
                                        updateToken={props.updateToken}
                                        collectionId={collection.id}/>
                        <JwtTokenValidation token={selectedToken} collection={collection}
                                            flow={flows.none}
                                            environment={environment}/>
                    </>}
                </aside>
            </ResizablePanels>
        </React.Fragment>
    );
}

export default CreateJWTFlow;

/*
 * Copyright (C) 2024 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React from 'react';
import PrettyJson from '../../validation/PrettyJson';
import CopyToClipboard from '../../CopyToClipboard';
import SaveDynamicClient from '../../SaveDynamicClient';

const DynamicClientResponse = (props) => {

    const handleCollapse = (e) => {
        e.preventDefault();
        if (e.target.hasAttribute('data-tooltip') || e.target.parentNode.hasAttribute('data-tooltip')) return
        const receivedToken = e.currentTarget.parentElement;
        receivedToken.classList.toggle('folded');
        receivedToken.setAttribute('aria-expanded', receivedToken.getAttribute('aria-expanded') === 'true' ? 'false' : 'true');
    };

    const rawBody = props.responseBody ? props.responseBody : 'Response Body is empty';

    return (<div className={'received-token '} aria-expanded="true">
        <header className="received-token-header flex flex-center justify-between" onClick={handleCollapse}>
            <h4 className="m0">Registered Client<CopyToClipboard text={props.responseBody} className="ml1"/>
                <SaveDynamicClient client={props.responseBody}
                                   saveClientInWorkspace={props.saveClientInWorkspace}
                                   className="ml1"/></h4>
            <button className="button button-tiny button-fold"/>
        </header>

        <div className="tools-form-token tools-form-token-active">
                <PrettyJson json={rawBody}/>
        </div>
    </div>)

}

export default DynamicClientResponse

/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React, { useEffect, useRef, useState } from 'react';

const WebFinger = props => {
    const componentName = 'WebFinger';
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const inputRef = useRef()
    const discoverWithWebFingerButtonRef = useRef()

    const hideError = () => {
        const currentEnvironment = props.environment;
        const updatedEnvironment = currentEnvironment.withError(null, null);
        props.updateEnvironment(updatedEnvironment);
    };

    const discoverWithWebFinger = (e) => {
        let discoverButton = e.currentTarget;

        discoverButton.classList.add('button-loading-active', 'button-disabled');
        discoverButton.blur();
        if (inputRef.current.value !== '') {
            props.discoverWebFinger(props.environment.id, inputRef.current.value, componentName);
        } else {
            setButtonDisabled(true);
        }
    };

    useEffect(() => {
        if (discoverWithWebFingerButtonRef.current) {
            discoverWithWebFingerButtonRef.current.classList.remove('button-loading-active', 'button-disabled');
        }
    })

    useEffect(() => {
        if (props.environment.issuer) {
            props.close();
        }
    }, [props.environment])

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            discoverWithWebFingerButtonRef.current.click();
        }
    };
    const onChange = (event) => {
        setButtonDisabled(event.currentTarget.value === '')
    };


    const error = props.environment.error &&
    Object.prototype.hasOwnProperty.call(props.environment.error, componentName) ?
        <div className="alert alert-danger mt2">
            <i className="icon ion-ios-close-outline inlineicon"/>
            {props.environment.error[componentName]}
            <button className="alert-close" onClick={hideError}><i className="icon ion-close"/>
            </button>
        </div> : '';

    return (
        <div className="mt4">

            <div className="flex flex-center justify-between">
                <h3 className="m0">WebFinger</h3>
                <a className="block" href="#" onClick={props.close}>Close</a>
            </div>

            <label htmlFor="resource" className="label block mt1">
                Resource
            </label>

            <div className="sm-flex flex-justify flex-center">

                <div className="flex-auto">
                    <input
                        ref={inputRef}
                        type="text"
                        name="resource"
                        className="field col-12"
                        onKeyDown={handleKeyPress}
                        onChange={onChange}
                        placeholder="Enter WebFinger Resource"
                        autoFocus
                        autoComplete="off"
                        data-lpignore="true"
                        spellCheck="false"
                    />
                </div>

                <div className="pl1">
                    <button ref={discoverWithWebFingerButtonRef}
                            className="button button-small button-input button-primary-outline button-loading"
                            onClick={discoverWithWebFinger}
                            disabled={buttonDisabled}
                    >
                        <span>Discover</span>
                    </button>
                </div>
            </div>

            {error}

        </div>
    )

}

export default WebFinger;

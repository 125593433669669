/*
 * Copyright (C) 2019 Curity AB. All rights reserved.
 *
 * The contents of this file are the property of Curity AB.
 * You may not copy or use this file, in either source code
 * or executable form, except in compliance with terms
 * set by Curity AB.
 *
 * For further information, please contact Curity AB.
 */

import React, { useState } from 'react';
import FoldButton from '../FoldButton';
import PrettyJson from './PrettyJson';
import ValidationReport from './ValidationReport';
import { INVALID_JWT_TOKEN } from './shared/Constants';
import CopyToClipboard from '../CopyToClipboard';

const TokenValidationHeader = (props) => {
    const [isFolded, setIsFolded] = useState(false);

    const handleFold = () => {
        setIsFolded(!isFolded)
    };

    const tokenHeader = (props.token.decoded_token.header) ? props.token.decoded_token.header : null;
    let outputData = INVALID_JWT_TOKEN;
    let validationData = null;

    if (tokenHeader !== null) {
        outputData = tokenHeader;
        validationData = (props.token.validation_data !== undefined) ?
            props.token.validation_data.header : [];
    }

    return (

        <section className="tools-sidebar-box tools-sidebar-box-head">
            <header className="tools-box-header">
                <div className="flex flex-center flex-justify">
                    <div className="tools-box-header-title"><i className="icon ion-code inlineicon"/>Header</div>
                    <div className="flex flex-center flex-justify">
                        <CopyToClipboard text={outputData}/>
                        <FoldButton handleFold={handleFold} isFolded={isFolded}/>
                    </div>
                </div>

            </header>
            {(isFolded) ? '' :
                <React.Fragment>
                        <pre className="tools-input">
                            {(tokenHeader !== null) ? <PrettyJson json={outputData}/> : outputData}
                        </pre>
                    {(validationData !== null) ? <ValidationReport validationData={validationData}/> : ''}
                </React.Fragment>
            }
        </section>
    )
}

export default TokenValidationHeader;
